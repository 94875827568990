import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Avatar,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../../config';
import CryptoJS from 'crypto-js';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1, 
    display: "flex", 
    flexDirection: 'column', 
    height: '350px', 
    borderRadius:"15px",
    marginTop: '-20px',
  },
  weekNumbers: {
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-around',
    width: '87%',
  },
  dayNumbers:{
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '90%',
  },
  dayDot:{
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    width: '90%',
  },
  currentDay:{
    display:'block',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: '6px solid #ff9b3f',
    backgroundColor: '#ff9b3f',
    marginLeft:'14px',
    marginTop:'-2px',
  },
  missionDot:{
    display:'block',
    height: '1px',
    width: '1px',
    borderRadius: '50%',
    border: '2px solid #ff9b3f',
    backgroundColor: '#ff9b3f',
    marginLeft:'12px',
    marginTop:'-2px',
  },
  weekView:{
    display:'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    width: '250px',
    height: '100px',
    borderRadius: '10px',
    border: 'solid 2px #a3a3a3',
    alignItems: 'center',
  },
}));

const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const weekNumbers = [
  { id: 1, week: "一" },
  { id: 2, week: "二" },
  { id: 3, week: "三" },
  { id: 4, week: "四" },
  { id: 5, week: "五" },
  { id: 6, week: "六" },
  { id: 7, week: "日" }
];
const dayNumbers = ['5', '6', '7', '8', '9', '10', '11'];
var now = new Date();
const year = now.getFullYear() - 1911;

const dbUrl = envConfig.SERVER_URL;

const Component = ({result}) => {
// const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [nextMonthList, setNextMonthList] = useState([]);
  const [threeMonthList, setThreeMonthList] = useState([]);
  const [sevenDayList, setSevenDayList] = useState([]);
  const [monthYearTextList, setMonthYearTextList] = useState([]);
  const [monthTitle, setMonthTitle] = useState('');
  const [targetDayIndex, setTargetDayIndex] = useState(0);
  const [calList, setCalList] = useState([]);
  const [ClassID, setClassID] = useState('');

  useEffect(() => {
    if(!state.device && !state.device.ClassID) return
    //if(!state.curCalendarList) return
    setClassID(state.device.ClassID)
    let tempCalList = [];
    
    let targetClass = {
        class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
        call_component:'teacher/src/components/main/subItem/weeklyb',
      };

      let targetClass2 = {
        class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
        call_component:'teacher/src/components/main/weeklyb',
      };
  
      let targetClass2Json = JSON.stringify(targetClass2);
  
      const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
      const encryptedData = ciphertext.toString();
      let tempBody = {body: encryptedData}

    // let targetClass = {
    //     class_id: window.localStorage.getItem('ClassID'),
    // };

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCalendarCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          tempCalList = finalData
          setCalList([].concat(tempCalList));
        });
    } catch (error) {
      console.log(error);
    }
     
      // try {
      //   fetch(dbUrl.concat('/classroom/getNotificationCalendar'), {
      //      method: 'POST',
      //      body: JSON.stringify(targetClass),
      //      headers: {
      //        'content-type': 'application/json'
      //      },
      //    }).then(  
      //      r => r.json()
      //    ).then(r => {
      //      tempCalList = r
      //      //console.log(tempCalList)
      //      setCalList([].concat(tempCalList));
      //    })
      // } catch (error) {
      //   console.log(error)
      // }


    let today = new Date();
    let week = today.getDay();
    if(week === 0 ) {
      week = 7;
    }
    const todayNum = today.getDate();
    let tempMonthYearText = doMakeMonText(today); //
    let priMonLastD = moment().subtract(todayNum, "days").date();
    let priMonLastDW = moment().subtract(todayNum, "days").isoWeekday();
    let nextMonLastDW = moment().endOf("month").isoWeekday()+1;

    let priMonList = [];
    let curMonthList = [];
    let nextMonthList = [];
    let finalMonthList = [];
    let d = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let n = d.getDate();
    //console.log(d);
    setMonthTitle(today.getMonth() + 1 +'');
    // result(d);
    curMonthList = doOrigMonList(
      n,
      tempMonthYearText[1].month,
      tempMonthYearText[1].year,
      tempCalList,
    );
    //console.log(curMonthList);

    if (priMonLastDW < 7) {
      priMonList = doPriMonList(
        todayNum,
        tempMonthYearText,
        priMonLastD,
        priMonLastDW,
        tempCalList
      );
    }

    if (nextMonLastDW > 1) {
      nextMonthList = doNextMonList(nextMonLastDW, tempMonthYearText,tempCalList);
    }
    finalMonthList = [].concat(priMonList, curMonthList, nextMonthList);
    dofindWeekRenge(todayNum, week, n, tempMonthYearText, finalMonthList);
  }, [state.device]);

  // 重新整理
  useEffect(() => {
    if(!state.notificationCalendar) return
    //console.log(state.notificationCalendar)
    let tempCalList = state.notificationCalendar
    let today = new Date();
    let week = today.getDay();
    const todayNum = today.getDate();
    let tempMonthYearText = doMakeMonText(today); //
    let priMonLastD = moment().subtract(todayNum, "days").date();
    let priMonLastDW = moment().subtract(todayNum, "days").isoWeekday();
    let nextMonLastDW = moment().endOf("month").isoWeekday()+1;

    let priMonList = [];
    let curMonthList = [];
    let nextMonthList = [];
    let finalMonthList = [];
    let d = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let n = d.getDate();
    //console.log(d);
    //result(d);
    curMonthList = doOrigMonList(
      n,
      tempMonthYearText[1].month,
      tempMonthYearText[1].year,
      tempCalList,
    );
    //console.log(curMonthList);

    if (priMonLastDW < 7) {
      priMonList = doPriMonList(
        todayNum,
        tempMonthYearText,
        priMonLastD,
        priMonLastDW,
        tempCalList
      );
    }

    if (nextMonLastDW > 1) {
      nextMonthList = doNextMonList(nextMonLastDW, tempMonthYearText,tempCalList);
    }
    finalMonthList = [].concat(priMonList, curMonthList, nextMonthList);
    dofindWeekRenge(todayNum, week, n, tempMonthYearText, finalMonthList);
  },[state.notificationCalendar])

  useEffect(() => {
    if(!state.calendarWeekList) return;
    setMonthTitle(state.calendarWeekList.month);
    let tempFullList1 = [].concat(state.calendarWeekList.finalMonthList);
    //state.calendarWeekList.finalMonthList.forEach( item => {tempFullList1.push(item)});
    doBasWeeklyList(
      state.calendarWeekList.dayText, 
      tempFullList1, 
      state.calendarWeekList.week);
  },[state.calendarWeekList]);

  const doMakeMonText = (today) => {
    let curTempMonNum = today.getMonth() + 1;
    let tempMonthText;
    let monthYearText = [];
    if (curTempMonNum > 1 && curTempMonNum < 12) {
      for (let t = -1; t <= 1; t++) {
        if (curTempMonNum + t >= 10) {
          tempMonthText = curTempMonNum + t + "";
          let temp = {
            year: today.getFullYear() + "",
            month: tempMonthText
          };
          monthYearText.push(temp);
        } else {
          tempMonthText = curTempMonNum + t + "";
          setMonthTitle(curTempMonNum);
          tempMonthText = "0" + tempMonthText;
          let temp = {
            year: today.getFullYear() + "",
            month: tempMonthText
          };
          monthYearText.push(temp);
        }
        setMonthYearTextList([].concat(monthYearText));
      }
    } else {
      switch (curTempMonNum) {
        case 12:
          let temp = [
            {
              year: today.getFullYear() + "",
              month: curTempMonNum - 1 + ""
            },
            {
              year: today.getFullYear() + "",
              month: curTempMonNum + ""
            },
            {
              year: today.getFullYear() + 1 + "",
              month: "01"
            }
          ];
          monthYearText = monthYearText.concat(temp);
          setMonthYearTextList([].concat(temp));
          break;
        case 1:
          temp = [
            {
              year: today.getFullYear() - 1 + "",
              month: "12"
            },
            {
              year: today.getFullYear() + "",
              month: curTempMonNum + ""
            },
            {
              year: today.getFullYear() + "",
              month: curTempMonNum + 1 + ""
            }
          ];
          monthYearText = monthYearText.concat(temp);
          setMonthYearTextList([].concat(temp));
          break;
        default:
          break;
      }
    }
    return monthYearText;
  };

  const doOrigMonList = (dayNum, curM, curY, tempCalList) => {
    let tempMonthList = [];
    let tempDay;
    let targetDay;
    for (let i = 1; i <= dayNum; i++) {
      if (i < 10) {
        // tempDay = curY + curM + "0" + i;
        //tempDay = curM>9 ? curY + '/' + curM + '/' + "0" + i : curY + '/' + "0" + curM + '/' + "0" + i ;
        tempDay = curY + '/' + curM + '/' + "0" + i;
      } else {
        // tempDay = curY + curM + i;
        // tempDay = curM>9 ? curY + '/' + curM + '/' + i : curY + '/' + "0" + curM + '/' + i ;
        tempDay = curY + '/' + curM + '/' + i;
      }
      targetDay = tempCalList.find((item) => {
        return item.eventDate === tempDay;
      });

      if (targetDay) {
        //console.log(targetDay)
        let temp = [
          {
            dayNo: i,
            dayContent: targetDay.count,
            daytext: tempDay,
            chooseDay: false,
            eventDateTimeTick: targetDay.eventDateTimeTick
          }
        ];
        tempMonthList = tempMonthList.concat(temp);
      } else {
        let temp = [
          {
            dayNo: i,
            dayContent: 0,
            daytext: tempDay,
            chooseDay: false,
          }
        ];
        tempMonthList = tempMonthList.concat(temp);
      }
    }
    [].concat(tempMonthList);
    return tempMonthList;
  };

  const doNextMonList = (nextMonLastDW, tempMonthYearText,tempCalList) => {
    let tempMonthList = [];
    let nextMonthRange = 7 - nextMonLastDW + 1;
    for (let i=1; i<=nextMonthRange; i++) {
      let tempDay =
        tempMonthYearText[2].year + '/' + tempMonthYearText[2].month + '/' + "0" + i;
      let targetDay = tempCalList.find((item) => {
        return item.eventDate === tempDay;
      });

      if(targetDay) {
        let temp = [
          {
            dayNo: i,
            dayContent: targetDay.count,
            daytext: tempDay,
            chooseDay: false,
            eventDateTimeTick: targetDay.eventDateTimeTick
          }
        ];
        tempMonthList = tempMonthList.concat(temp);
      } else {
        let temp = [
          {
            dayNo: i,
            dayContent: 0,
            daytext: tempDay,
            chooseDay: false,
          }
        ];
        tempMonthList = tempMonthList.concat(temp);
      }
    }
    return tempMonthList;
  };

  const doPriMonList = (
    todayNum,
    tempMonthYearText,
    priMonLastD,
    priMonLastDW,
    tempCalList
  ) => {
    let tempPriMonList = [];
    let priFistDay = priMonLastD - priMonLastDW + 1;
    for (let i = priFistDay; i < priMonLastD + 1; i++) {
      let tempDay =
        tempMonthYearText[0].year + '/' + tempMonthYearText[0].month + '/' + i + "";

      let targetDay = tempCalList.find((item) => {
        return item.eventDate === tempDay;
      });

      if(targetDay) {
        let temp = {
          dayNo: i,
          dayContent: targetDay.count,
          daytext: tempDay,
          chooseDay:false,
          eventDateTimeTick: targetDay.eventDateTimeTick,
        };
        tempPriMonList.push(temp);
      } else {
        let temp = {
          dayNo: i,
          dayContent: 0,
          daytext: tempDay,
          chooseDay:false,
        };
        tempPriMonList.push(temp);
      }
    }
    return tempPriMonList;
  };

  const dofindWeekRenge = (
    today,
    week,
    md,
    tempMonthYearText,
    finalMonthList
  ) => {
    let todayText = doMakeTodayText(
      today,
      tempMonthYearText[1].month,
      tempMonthYearText[1].year
    );
    doBasWeeklyList(todayText, finalMonthList, week);
  };

  const doMakeTodayText = (today, curM, curY) => {
    if (today < 10) {
      return curY + '/' + curM + '/'  + "0" + today;
    } else {
      return curY + '/'  + curM + '/'  + today;
    }
  };

  const doBasWeeklyList = (todayText, finalMonthList, week) => {
    let tempWeeklyList = [];
    let todayIndex;
    //console.log(todayText);
    //console.log(finalMonthList);
    //console.log(week);
    
    todayIndex = finalMonthList.findIndex((item) => item.daytext === todayText);
    // setState({targetDay: finalMonthList[todayIndex]})
    //dispatch({targetDay: finalMonthList[todayIndex]});
    
    let startIndex = todayIndex - (week - 1);
    for (let i = startIndex; i <= startIndex + 6; i++) {
      tempWeeklyList.push(finalMonthList[i]);
    }
    dispatch({ type: 'SET_WEEKLIST', payload: tempWeeklyList});
    tempWeeklyList.forEach((i,index) => {
      if (i.daytext === todayText) {
        i.chooseDay = true;
        i.weekText = weekNumbers[week-1].week;
        //i.ClassID = state.device.ClassID;
        result(i);
        setTargetDayIndex(index);
        //console.log(i)
      } else {
        i.chooseDay = false;
      }
    });
    //console.log(tempWeeklyList[week - 1]);
    //tempWeeklyList.findIndex(item => item.daytext === )
    dispatch({ type: 'SET_TODAY', payload: tempWeeklyList[week - 1]});
    //dispatch({ type: 'SET_TARGETDAY', payload: tempWeeklyList[week - 1]});
    //console.log(tempWeeklyList);
    setSevenDayList([].concat(tempWeeklyList));
  };
  
  const doSetTargetDay = (sevenDayList,index,item) => {
    sevenDayList[targetDayIndex].chooseDay = false;
    sevenDayList[index].chooseDay = true;
    sevenDayList[index].weekText = item.week;
    setTargetDayIndex(index);
    setSevenDayList([].concat(sevenDayList));
    dispatch({ type: 'SET_TARGETDAY', payload: sevenDayList[index]});
  }

  return (
  <div className={classes.weekView}>
    <div style={{marginTop:'8px', marginBottom:'12px'}}>
      {monthTitle}月
    </div>
    {sevenDayList.length > 0 ? (
        <div className={classes.root}>
          <div
            elevation={3}
            style={{
              width: "250px",
              padding: "2",
              disply: "flex",
              flexDirection: "column"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                padding: "10px"
              }}
            >
              {weekNumbers.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                  }}
                  key={item.id}
                  onClick={() => doSetTargetDay(sevenDayList,index,item)}
                >
                  <div>{item.week}</div>
                  <Avatar
                    style={{
                      color: sevenDayList[index].chooseDay ? "white" : "black",
                      backgroundColor: sevenDayList[index].chooseDay
                        ? "#ff9b3f"
                        : "white",
                      height: "25px",
                      width: "25px",
                      fontSize: "14px",
                      marginTop: "4px",
                      zIndex: "0"
                    }}
                    key={sevenDayList[index].daytext}
                  >
                    {sevenDayList[index].dayNo}
                  </Avatar>
                  <div
                  style={{
                          height: "1px",}}
                  />
                  {sevenDayList[index].dayContent>0 ? (
                    <div>
                      <div style={{height: "1px"}}/>
                      <div
                        style={{
                          display: "block",
                          height: "1px",
                          width: "1px",
                          borderRadius: "50%",
                          border: "2px solid #ff9b3f",
                          backgroundColor: "#ff9b3f",
                          marginTop: "-0.5px",
                          zIndex: "1"
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <div style={{height: "1px"}}/>
                      <div style={{
                            display: "block",
                            height: "1px",
                            width: "1px",
                            borderRadius: "50%",
                            border: "2px solid white",
                            backgroundColor: "white",
                            marginTop: "-0.5px",
                            zIndex: "1"
                      }}/>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div
            elevation={3}
            style={{
              width: "250px",
              padding: "2",
              disply: "flex",
              flexDirection: "column"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                padding: "10px"
              }}
            >
              {weekNumbers.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center"
                  }}
                  key={item.id}
                >
                  <div>{item.week}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <CircularProgress size="25px" />
            </div>
          </div>
        </div>
      )}
  </div>
  );
};

export default Component;
