import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import { HighlightOffOutlined as CloseIcon } from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';
import envConfig from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, .77)',
    width: theme.spacing(40),
    height: theme.spacing(60),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    minHeight: theme.spacing(7),
  },
  content: {
    flex: 1,
    overflow: 'auto',
    marginTop: theme.spacing(1),
  },
  messageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  messageText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    margin: theme.spacing(0.5),
    wordBreak: 'break-all',
  },
}));

const itemStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1, 1, 2, 1),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.spacing(1),
  },
  msgAuthor: {
    color: '#1de9b6',
    fontSize: '1rem',
  },
  msgContent: {
    color: '#f1f1f1',
    fontSize: '1.2rem',
    wordBreak: 'break-all',
    lineHeight: 1.25,
    marginTop: theme.spacing(0.5),
  },
  plusAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  negAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const itemClasses = itemStyles();
  const { state } = useContext(AppContext);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/chats`)
      .onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const item = doc.data();

          let count = 0;
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              count += item[key];
            }
          });

          data.push({
            id: doc.id,
            ...item,
            count,
          });
        });
        data.sort((x, y) => (x.time < y.time ? 1 : -1));

        setChats(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const renderMessage = () =>
    chats.length === 0 && (
      <div className={classes.messageContent}>
        <div className={classes.messageText}>尚無任何互動訊息或問題</div>
        <div className={classes.messageText}>請學生開啟以下連結開始互動</div>
        <div className={classes.messageText}>
          {envConfig.BASE_URL.replace(/teacher/, 'student')}
        </div>
      </div>
    );

  const renderChats = () => (
    <List style={{ overflow: 'auto' }}>
      {chats.map((item) => (
        <ListItem key={item.id}>
          <ListItemText
            primary={item.content}
            secondary={`${item.author} - ${new Date(
              item.time,
            ).toLocaleTimeString()}`}
            classes={{
              primary: itemClasses.msgContent,
              secondary: itemClasses.msgAuthor,
            }}
          />
          {item.count !== 0 && (
            <Avatar
              className={
                item.count > 0 ? itemClasses.plusAvatar : itemClasses.negAvatar
              }
            >
              {item.count}
            </Avatar>
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Slide in direction="left" timeout={1000}>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5" style={{ color: '#f1f1f1' }}>
            互動問答
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton size="small" onClick={callback}>
            <CloseIcon style={{ color: '#f1f1f1', fontSize: '2rem' }} />
          </IconButton>
        </div>
        {renderMessage()}
        {renderChats()}
      </Paper>
    </Slide>
  );
};

export default Component;
