import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Fade,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Slide,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import {
  HighlightOffOutlined as CloseIcon,
  ThumbUpOutlined as ScoreIcon,
} from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  scoreIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 240px)',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    margin: theme.spacing(2),
  },
  content0: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  contentsw: {
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const audioStep = useRef(null);
  const { state } = useContext(AppContext);
  const [targets, setTargets] = useState([]);
  const [results, setResults] = useState([]);
  const [view, setView] = useState('');
  const [transition, setTransition] = useState('Fade');
  const [current, setCurrent] = useState(0);
  const [scores, setScores] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let interval;
    let timeout;

    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();

          setTransition(data.transition || 'Fade');
          setTargets(data.results.join().split(','));

          if (['全班', '座號'].includes(data.target)) {
            const items = data.results
              .join()
              .split(',')
              .filter((item) => item !== '')
              .sort((x, y) => (x - y > 0 ? 1 : -1));

            const [first] = state.students.filter(
              (student) => `${student.ID}` === items[0],
            );

            if (audioStep && audioStep.current && first) {
              audioStep.current.play();

              let _interval = 0;
              interval = setInterval(() => {
                _interval += 1;
                setCurrent(_interval);
                if (_interval === data.number - 1) {
                  window.clearInterval(interval);
                }
              }, 1000);

              timeout = setTimeout(() => {
                if (audioStep && audioStep.current) {
                  audioStep.current.pause();
                }
                state.tts.speak(`隨機抽中${first.Name}等${items.length}位同學`);
              }, 1000 * (data.number - 1));
            }

            setResults([
              {
                caption: '',
                data: state.students.filter((student) =>
                  data.results.join().split(',').includes(`${student.ID}`),
                ),
              },
            ]);
            setView('card');
          } else {
            const items = data.results
              .join()
              .split(',')
              .filter((item) => item !== '')
              .sort((x, y) => (x - y > 0 ? 1 : -1));

            const [first] = state.students.filter(
              (student) => `${student.ID}` === items[0],
            );

            if (audioStep && audioStep.current && first) {
              audioStep.current.play();

              let _interval = 0;
              interval = setInterval(() => {
                _interval += 1;
                setCurrent(_interval);
                if (_interval === data.number - 1) {
                  window.clearInterval(interval);
                }
              }, 1000);

              timeout = setTimeout(() => {
                if (audioStep && audioStep.current) {
                  audioStep.current.pause();
                }
                state.tts.speak(`隨機抽中${first.Name}等${items.length}位同學`);
              }, 1000 * (data.number - 1));
            }

            setResults(
              data.results.map((item, index) => ({
                caption: `第 ${index + 1} 組 ${item.split(',').length} 人`,
                data: state.students.filter((student) =>
                  item.split(',').includes(`${student.ID}`),
                ),
              })),
            );
            setView('list');
          }
        } else {
          setResults([]);
          setView('');
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setScores(doc.data());
        }
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
      state.tts.stop();
      window.clearInterval(interval);
      window.clearTimeout(timeout);

      state.communication.postMessage(
        JSON.stringify({
          action: 'PAUSE_VIDEO',
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  const plus = () => {
    const data = {
      timestamp: Date.now(),
    };

    targets.forEach((id) => {
      let score = scores[`student_${id}`] || 0;
      score += 1;
      data[`student_${id}`] = score;
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(data);

    setOpen(false);
  };

  const neg = () => {
    const data = {
      timestamp: Date.now(),
    };

    targets.forEach((id) => {
      let score = scores[`student_${id}`] || 0;
      score -= 1;
      data[`student_${id}`] = score;
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(data);

    setOpen(false);
  };

  const renderCardView = () =>
    view === 'card' && (
      <Grow in timeout={1000}>
        <div className={classes.content}>
          {results.map((item) =>
            item.data.map((student, index) => {
              return withTransition(
                <Paper style={{ padding: theme.spacing(2) }}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.photo}>
                        {student.Custom ? index + 1 : student.SeatNo}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={student.Name}
                      secondary={student.ClassName}
                    />
                  </ListItem>
                </Paper>,
                transition,
                index,
                current,
              );
            }),
          )}
        </div>
      </Grow>
    );

  const renderListView = () =>
    view === 'list' && (
      <Grow in timeout={1000}>
        <div className={classes.content}>
          {results.map((item, idx) => (
            <Paper key={`group-${idx + 1}`}>
              <Typography
                variant="h5"
                color="primary"
                style={{ padding: theme.spacing(2) }}
              >
                {item.caption}
              </Typography>
              <List>
                {item.data.map((student, index) => {
                  return withTransition(
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className={classes.photo}>
                          {student.Custom ? index + 1 : student.SeatNo}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={student.Name}
                        secondary={student.ClassName}
                      />
                    </ListItem>,
                    transition,
                    index,
                    current,
                  );
                })}
              </List>
            </Paper>
          ))}
        </div>
      </Grow>
    );

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>是否對學生加減分？</DialogTitle>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={neg}>
            減 1 分
          </Button>
          <Button variant="contained" color="primary" onClick={plus}>
            加 1 分
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          隨機抽人
        </Typography>
        <div style={{ flex: 1 }} />
      </div>
      <div className={classes.content0}>
        {renderCardView()}
        {renderListView()}
        <audio loop ref={audioStep}>
          <track kind="captions" />
          <source src={Assets.Audio.Process} type="audio/mpeg" />
        </audio>
        <div className={classes.contentsw}>
          <Tooltip title="學生加減分">
            <IconButton onClick={() => setOpen(true)}>
              <Button 
                  variant="contained"
                  color="primary"
                >
                  學生加減分
                </Button>
            </IconButton>
          </Tooltip>
          <Tooltip title="關閉">
            <IconButton onClick={callback}>
            <Button 
                variant="contained"
                color="primary"
              >
                回到主畫面
              </Button>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const withTransition = (content, transition, index, current) => {
  switch (transition) {
    case 'Fade': {
      return (
        <Fade in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Fade>
      );
    }
    case 'Grow': {
      return (
        <Grow in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Grow>
      );
    }
    case 'Slide': {
      return (
        <Slide
          in={current >= index}
          direction="up"
          timeout={1000}
          key={`student-${index}`}
        >
          {content}
        </Slide>
      );
    }
    case 'Zoom': {
      return (
        <Zoom in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Zoom>
      );
    }
    default: {
      return (
        <Fade in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Fade>
      );
    }
  }
};

export default Component;
