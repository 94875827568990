import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grow,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  HighlightOffOutlined as CloseIcon,
  RecordVoiceOver as VoiceIcon,
  TextFormat as CodeIcon,
} from '@material-ui/icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import QRCode from 'qrcode.react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';
import envConfig from '../../../config';

library.add(fas);

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2, 0),
    minWidth: theme.spacing(40),
  },
  textCode: {
    fontSize: '10rem',
    padding: theme.spacing(1, 4),
  },
  qrCode: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  labelBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:768px)');
  const { state } = useContext(AppContext);
  const [textCode, setTextCode] = useState(true);
  const [speachCode, setSpeachCode] = useState(Date.now());

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/roomcode`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { textCode: _textCode, speachCode: _speachCode } = doc.data();

          if (_speachCode > speachCode) {
            state.tts.stop();
            state.tts.speak(state.target.code.split('').join());
          }

          setTextCode(_textCode);
          setSpeachCode(_speachCode);
        }
      });

    state.tts.speak(state.target.code.split('').join());

    return () => {
      unsubscribe();
      state.tts.stop();
    };
    // eslint-disable-next-line
  }, []);

  const toggleView = () => {
    state.tts.stop();

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/roomcode`)
      .set({
        textCode: !textCode,
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          教室代碼
        </Typography>
        <div style={{ flex: 1 }} />
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <Grow in timeout={1000}>
        <div className={classes.content}>
          <div className={classes.main}>
            <div
              className={classes.card}
              style={{
                zoom: matches
                  ? 1
                  : textCode
                  ? Math.min(window.innerWidth / 740, window.innerHeight / 360)
                  : Math.min(window.innerWidth / 500, window.innerHeight / 620),
              }}
            >
              {textCode ? (
                <React.Fragment>
                  <div className={classes.textCode}>{state.target.code}</div>
                  <Typography
                    variant="h5"
                    style={{ color: '#454545', textAlign: 'center' }}
                  >
                    {`${envConfig.SERVER_URL}/student?code=${state.target.code}`}
                  </Typography>
                </React.Fragment>
              ) : (
                <div className={classes.qrCode}>
                  <QRCode
                    // value={`${envConfig.SERVER_URL}/student?code=${state.target.code}`}
                    value={`https://cooc.tp.edu.tw/app_links/classroom?th=s&code=${state.target.code}`}
                    size={400}
                    fgColor="#212121"
                    bgColor="transparent"
                    renderAs="svg"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: '#454545',
                      marginTop: 20,
                      textAlign: 'center',
                    }}
                  >
                    {`${envConfig.SERVER_URL}/student?code=${state.target.code}`}
                  </Typography>
                </div>
              )}
              <div className={classes.labelBar}>
                {state.tts.exists && (
                  <Tooltip title="語音" placement="top">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        state.tts.speak(state.target.code.split('').join())
                      }
                    >
                      <VoiceIcon style={{ fontSize: '3rem' }} />
                    </IconButton>
                  </Tooltip>
                )}
                <div style={{ width: theme.spacing(3) }} />
                <Tooltip
                  title={textCode ? 'QRcode' : '教室代碼'}
                  placement="top"
                >
                  <IconButton color="primary" onClick={toggleView}>
                    {textCode ? (
                      <FontAwesomeIcon
                        icon={['fas', 'qrcode']}
                        style={{ fontSize: '3rem' }}
                      />
                    ) : (
                      <CodeIcon style={{ fontSize: '3rem' }} />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Grow>
    </div>
  );
};

export default Component;
