import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Chat as ChatIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  ColorLens as ThemeIcon,
  Dashboard as WhiteboardIcon,
  ExitToApp as ExitIcon,
  Menu as MenuIcon,
  MoreVert as MoreIcon,
  TextFormat as CodeIcon,
  VoiceChat as VoiceChatIcon,
} from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

import Apps from './apps';
import Students from './students';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  logoHeader: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareWhiteboard, setShareWhiteboard] = useState(false);
  const [whiteboardUrl, setWhiteboardUrl] = useState('');
  const [shareGoogleMeet, setShareGoogleMeet] = useState(false);
  const [googleMeetUrl, setGoogleMeetUrl] = useState('');
  const [exitApp, setExitApp] = useState(false);
  const [broadcastAlert, setBroadcastAlert] = useState(false);
  const [broadcastOpen, setBroadcastOpen] = useState(false);
  const [broadcast, setBroadcast] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/pubsubs/broadcast.${state.device.schoolCode}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.targets.includes(state.device.id)) {
            // if (data.priority === 'normal') {
              
            if (data.priority === 'high') {
              if (data.type === 'video') {
                const regYoutube = data.url.match(
                  /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
                );
                const regVimeo = data.url.match(
                  /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
                );

                if (regYoutube) {
                  data.url = `https://www.youtube.com/embed/${regYoutube[1]}`;
                } else if (regVimeo) {
                  data.url = `https://player.vimeo.com/video/${regVimeo[5]}`;
                }
              }
              setBroadcast(data);
              setBroadcastAlert(true);
            }
            // } else {
            //   if (data.type === 'video') {
            //     const regYoutube = data.url.match(
            //       /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
            //     );
            //     const regVimeo = data.url.match(
            //       /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
            //     );

            //     if (regYoutube) {
            //       data.url = `https://www.youtube.com/embed/${regYoutube[1]}`;
            //     } else if (regVimeo) {
            //       data.url = `https://player.vimeo.com/video/${regVimeo[5]}`;
            //     }
            //   }
            //   setBroadcast(data);
            //   setBroadcastAlert(true);
            // }
          }
        }
      });

    return () => unsubscribe();
  }, [state.device]);

  useEffect(() => {
    if (!state.roomId) return;

    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { darkMode } = doc.data();
          if (darkMode !== undefined) {
            dispatch({
              type: 'TOGGLE_DARK_MODE',
              payload: darkMode,
            });
          }
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/meet`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { url } = doc.data();
          setGoogleMeetUrl(url);
        }
      });

    const unsubscribe3 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/whitebaord`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { url } = doc.data();
          setWhiteboardUrl(url);
        }
      });
      //console.log(state);

    return () => {
      unsubscribe1();
      unsubscribe2();
      unsubscribe3();
    };
    // eslint-disable-next-line
  }, [state.roomId]);

  const clickHandler = (key) => {
    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key,
      timestamp: Date.now(),
    });
  };

  return (
    <div className={classes.root}>
      {broadcastAlert && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={broadcastAlert}
          onClose={() => setBroadcastAlert(false)}
          // message="有新的廣播，是否現在開啟？"
          message="有緊急廣播，是否現在開啟？"
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  if (broadcast.docId) {
                    firebase
                      .firestore()
                      .doc(
                        `/broadcasts/${broadcast.docId}/devices/${state.device.id}`,
                      )
                      .set({
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                  }

                  setBroadcastOpen(true);
                  setBroadcastAlert(false);
                }}
              >
                開啟
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setBroadcastAlert(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      {broadcastOpen && (
        <Dialog open={broadcastOpen} onClose={() => setBroadcastOpen(false)}>
          <DialogTitle style={{ wordBreak: 'break-all' }}>
            {broadcast.title}
          </DialogTitle>
          <DialogContent style={{ minWidth: 320 }}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              gutterBottom
              style={{ wordBreak: 'break-all' }}
            >
              {broadcast.time}
            </Typography>
            {broadcast.type !== 'video' && (
              <DialogContentText
                style={{
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-line',
                  marginTop: 10,
                }}
              >
                {broadcast.content}
              </DialogContentText>
            )}
            {broadcast.type === 'video' && (
              <iframe
                title="Broadcast"
                src={broadcast.url}
                style={{
                  width: 552,
                  height: 310,
                  border: 'none',
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
      {shareWhiteboard && (
        <Dialog open={true} onClose={() => setShareWhiteboard(false)}>
          <DialogTitle>請輸入 Whiteboard 網址</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              placeholder="https://r2.whiteboardfox.com/123456-7890-1234"
              variant="outlined"
              value={whiteboardUrl}
              style={{ minWidth: 300 }}
              onChange={(e) => setWhiteboardUrl(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() =>
                state.communication.postMessage(
                  JSON.stringify({
                    action: 'CREATE_NEW_PAGE',
                    data: {
                      key: 'https://whiteboardfox.com/',
                      url: 'https://whiteboardfox.com/',
                    },
                  }),
                )
              }
            >
              WhiteboardFox
            </Button>
            <div style={{ flex: 1 }} />
            <Button color="secondary" onClick={() => setShareWhiteboard(false)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (!whiteboardUrl) return;

                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/events/whiteboard`)
                  .set({ url: whiteboardUrl, timestamp: Date.now() });

                setShareWhiteboard(false);
              }}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {shareGoogleMeet && (
        <Dialog open={true} onClose={() => setShareGoogleMeet(false)}>
          <DialogTitle>請輸入 Google Meet 會議連結</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              placeholder="https://meet.google.com/xxx-xxxx-xxx"
              variant="outlined"
              value={googleMeetUrl}
              style={{ minWidth: 300 }}
              onChange={(e) => setGoogleMeetUrl(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() =>
                state.communication.postMessage(
                  JSON.stringify({
                    action: 'CREATE_NEW_PAGE',
                    data: {
                      key: 'https://meet.google.com',
                      url: 'https://meet.google.com',
                    },
                  }),
                )
              }
            >
              Google Meet
            </Button>
            <div style={{ flex: 1 }} />
            <Button color="secondary" onClick={() => setShareGoogleMeet(false)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (!googleMeetUrl) return;

                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/events/meet`)
                  .set({ url: googleMeetUrl, timestamp: Date.now() });

                setShareGoogleMeet(false);
              }}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {exitApp && (
        <Dialog open={true}>
          <DialogTitle>確定離開教室？</DialogTitle>
          <DialogActions>
            <div style={{ flex: 1 }} />
            <Button color="secondary" onClick={() => setExitApp(false)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() =>{
                dispatch({  // 測試離開畫面顯示判斷的變數
                      type: 'SET_LEAVING',
                      payload: { state: true },
                });
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}`)
                  .update({ status: 'close' })
                }}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {state.myInfo && state.target && (
            <Typography variant="h6" noWrap>
              {/* {`${state.myInfo.name} 老師您好，這堂課是 ${state.target.classroom.name}`} */}
              {state.target.classroom.name}
            </Typography>
          )}
          <div style={{ flex: 1 }} />
            <Typography variant="h6" noWrap>
              教室代碼：
            </Typography>
          {state.target && (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                clickHandler('classroom.code.open');
              }}
            >
              <Typography>{state.target.code}</Typography>
            </Button>
          )}
          <div style={{ width: theme.spacing(2) }} />
          <React.Fragment>
            <IconButton
              color="inherit"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="signin-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  clickHandler('classroom.chat.open');
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <ChatIcon />
                </ListItemIcon>
                <ListItemText>互動問答</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  clickHandler('classroom.code.open');
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText>教室代碼</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  firebase
                    .firestore()
                    .doc(`/classrooms/${state.roomId}`)
                    .update({ darkMode: !state.darkMode });

                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <ThemeIcon />
                </ListItemIcon>
                <ListItemText>切換佈景</ListItemText>
              </MenuItem>
              <Divider variant="fullWidth" />
              {/* <MenuItem
                onClick={() => {
                  setShareWhiteboard(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <WhiteboardIcon />
                </ListItemIcon>
                <ListItemText>Whiteboard</ListItemText>
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setShareGoogleMeet(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <VoiceChatIcon />
                </ListItemIcon>
                <ListItemText>Google Meet</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  state.tts.speak('確定離開教室');
                  setExitApp(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <ExitIcon />
                </ListItemIcon>
                <ListItemText>離開教室</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.logoHeader}>
            <Typography variant="h5" color="primary">
              課堂工具
            </Typography>
          </div>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Students />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Apps />
      </main>
    </div>
  );
};

export default Component;
