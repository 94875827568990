import React, { useContext, useEffect, useState } from 'react';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Fab from "@material-ui/core/Fab";
import PropTypes from 'prop-types';
import envConfig from '../../../config.js'

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDownIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Event as ScheduleIcon,
  LowPriority as PriorityLowIcon,
  PriorityHigh as PriorityHighIcon,
  Schedule as NowIcon,
  Send as SendIcon,
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    margin: theme.spacing(2),
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  priorityrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  typerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  schedulerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  targetbox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 1, 1),
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  targetlabel: {
    position: 'absolute',
    top: theme.spacing(-2),
    background: '#fff',
    padding: theme.spacing(3 / 4),
  },
  targetrow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  card: {
    minWidth: 150,
    margin: theme.spacing(2),
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    width: "120px",
    height: "120px",
    fontSize: "72px",
    borderRadius: "30px"
    // width: theme.spacing(16),
    // height: theme.spacing(16),
    // margin: theme.spacing(1),
  },
  contentcard: {
    display: 'flex',
    flexDirection: 'row',
  },
}));



function CustomPagination(props) {
  const { pagination, api } = props;
  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      color="primary"
      page={pagination.page}
      count={pagination.pageCount}
      onChange={(event, value) => api.current.setPage(value)}
    />
  );
}

CustomPagination.propTypes = {
  /**
   * ApiRef that let you manipulate the grid.
   */
  api: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
  /**
   * The object containing all pagination details in [[PaginationState]].
   */
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    paginationMode: PropTypes.oneOf(['client', 'server']).isRequired,
    rowCount: PropTypes.number.isRequired,
  }).isRequired,
};

// 處理沒資料圖示顯示
function CustomNoRowsOverlay() {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.root}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>沒有資料</div>
    </GridOverlay>
  );
}

const dbUrl = envConfig.SERVER_URL;
const colorItems = [
  "#FDC446",
  "#FC6E51",
  "#23C0ED",
  "#967ADC",
  "#EB5E60",
  "#F7F7F7",
  "#1BC1BF",
];

const Component = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [defConsfig, setDefConsfig] = useState([]);
  const [openDefDia, setOpenDefDia] = useState(false);
  const [defDiaTitle, setDefTitle] = useState('');
  const [defDiaAbout, setDefAbout] = useState('沒有相關資訊');
  const [config, setConfig] = useState([]);
  const [dbconfig, setDBConfig] = useState([]);
  const [allDBconfig, setAllDBconfig] = useState([]);
  const [targetName, setTargetName] = useState('');
  const [targetURL, setTargetURL] = useState('');
  const [configItem, setConfigItem] = useState();
  const [allConfigs, setAllConfigs] = useState();
  const [warningTxt, setWarningTxt] = useState('');
  const [myclouds, setMyClouds] = useState([]);
  const [editTitle, seteditTitle] = useState('');
  const [editDialog, seteditDialog] = useState(false);
  const [editInfo, seteditInfo] = useState({});
  const [deleteErr, setDeleteErr] = useState(false);
  const [color, setColor] = useState('');


  useEffect(() => {
    if (defConsfig.length > 0) return;
    if (defConsfig.length === 0) {
      fetch(dbUrl.concat('/classroom/getDefaultConfig'), {
        // fetch('http://localhost:8088/classroom/getScoreRec', {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      }).then(
        r => r.json()

      ).then(r => {
        if(r.results){
          setDefConsfig(r.results[1].items);
          setAllConfigs(r.results);
        }
      });
    }
    fetch(dbUrl.concat('/classroom/getCloudConfig'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ teacher_identity: state.author.id_number, call_component: 'teacher/src/components/manager/cloud/editor' })
    }).then(
      r => r.json()
    ).then(r => {
      if(r){
        if(r.results){
          setDBConfig(r.results[2].items);
        }
      }
      //setDBConfig(r.results[2].items);
      setAllDBconfig(r.results);
    });
  });

  const handleClickOpen = () => {
    seteditTitle('新增我的雲端平台');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDefDia(false);
    seteditDialog(false);
    setDeleteErr(false);
  };
  const handleDefDiaClose = () => {
    setOpenDefDia(false);
  };

  const saveDefault = () => {
    // const cloudName = document.getElementById('name').value;
    // const cloudURL = document.getElementById('url').value;
    // console.log(cloudName);
    // console.log(cloudURL);
    // const targetResult = [
    //   {
    //     key: cooc.tp.edu.tw,
    //     title: cloudName,
    //     icon: /assets/cooc.png,
    //     url: cloudURL,
    //   }
    // ]

    const targetResult = [{
      type: 'tools',
      title: '課間工具',
      items: [
        {
          key: 'classroom.gdrive',
          title: ' 雲端硬碟 (Google)',
          icon: '/assets/gdrive.svg'
        },
        {
          key: 'classroom.interactive',
          title: '快問快答',
          icon: '/assets/interactive.svg'
        },
        {
          key: 'classroom.assess',
          title: '學生互評',
          icon: '/assets/assess.svg'
        },
        {
          key: 'classroom.vote',
          title: '學生互投',
          icon: '/assets/vote.svg'
        },
        {
          key: 'classroom.attendance',
          title: '課間呼叫',
          icon: '/assets/attendance.svg'
        },
        {
          key: 'classroom.timer',
          title: '計時器',
          icon: '/assets/timer.svg'
        },
        {
          key: 'classroom.random',
          title: '隨機抽人',
          icon: '/assets/random.svg'
        },
        {
          key: 'classroom.group',
          title: '學生分組',
          icon: '/assets/group.svg'
        }
      ]
      // items: [
      //   {
      //     key: 'classroom.gdrive',
      //     title: ' 雲端硬碟 (Google)',
      //     icon: '/assets/gdrive.svg'
      //   },
      //   {
      //     key: 'classroom.interactive',
      //     title: '快問快答',
      //     icon: '/assets/interactive.svg'
      //   },
      //   {
      //     key: 'classroom.assess',
      //     title: '學生互評',
      //     icon: '/assets/assess.svg'
      //   },
      //   {
      //     key: 'classroom.vote',
      //     title: '學生互投',
      //     icon: '/assets/vote.svg'
      //   },
      //   {
      //     key: 'classroom.attendance',
      //     title: '上線紀錄',
      //     icon: '/assets/attendance.svg'
      //   },
      //   {
      //     key: 'classroom.timer',
      //     title: '計時器',
      //     icon: '/assets/timer.svg'
      //   },
      //   {
      //     key: 'classroom.random',
      //     title: '隨機抽人',
      //     icon: '/assets/random.svg'
      //   },
      //   {
      //     key: 'classroom.group',
      //     title: '學生分組',
      //     icon: '/assets/group.svg'
      //   }
      // ]
    },
    {
      type: 'clouds',
      title: '雲端平台',
      items: [
        {
          key: 'cooc.tp.edu.tw',
          title: '臺北酷課雲',
          icon: '/assets/cooc.png',
          url: 'https://cooc.tp.edu.tw/auth/SyncSignInAll?access_token=_ACCESSTOKEN_'
        },
        {
          "key": "e-portfolio.cooc.tp.edu.tw",
          "title": "高中職學習歷程",
          "icon": "/assets/eportfolio.png",
          "url": "https://cooc.tp.edu.tw/auth/SyncSignInAll?access_token=_ACCESSTOKEN_&next=https%3A%2F%2Fsso.tp.edu.tw%2Foauth2%2Foauth%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D364257692326-k0djg7r8e27f3oottmt6io147tna39g5.apps.cooc%26redirect_uri%3Dhttps%253A%252F%252Fe-portfolio.cooc.tp.edu.tw%252Fssoauth.do%26scope%3Dget_user_info%26state%3D364257692326"
        }
      ]
    }
    ]

    let targetConfig = {
      role_detail: state.author.role_detail,
      teacher_identity: 'default',
      teacher_name: state.author.name,
      results: targetResult,
      cell_component: 'teacher/src/components/manager/cloud/editor'
    }
    fetch(dbUrl.concat('/classroom/updateDefaultConfig'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetConfig)
    });

    setOpen(false);
  };

  const saveConfig = () => {
    let myResult = allConfigs;
    const cloudName = document.getElementById('name').value;
    const cloudURL = document.getElementById('url').value;
    if (!cloudName || !cloudURL) {
      setWarningTxt('必要欄位未輸入')
      return
    }
    let a = new Date();
    let iconColor = colorItems[Math.floor(Math.random() * colorItems.length)]
    //console.log(iconColor);
    const cloudItem = {
      key: a.getTime(),
      title: cloudName,
      icon: iconColor,
      url: cloudURL,
      type: 'mycloud'
    }

    dbconfig.push(cloudItem);
    let tempItem = dbconfig;
    setDBConfig(tempItem);
    const temp = {
      type: 'myclouds',
      title: '我的雲端平台',
      items: tempItem
    }

    myResult.push(temp);

    let myConfig = {
      role_detail: state.author.role_detail,
      teacher_identity: state.author.id_number,
      teacher_name: state.author.name,
      results: myResult,
      call_component: 'teacher/src/component/manager/cloud/editor'
    };

    fetch(dbUrl.concat('/classroom/addCloudConfig'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(myConfig)
    });

    setOpen(false);
    getCloudConfig();
  };

  const editConfig = (item) => {
    //console.log(item);
    seteditTitle('編輯我的雲端平台');
    seteditDialog(true);
    seteditInfo(item);
  }

  const saveEdit = () => {
    const cloudName = document.getElementById('name').value;
    const cloudURL = document.getElementById('url').value;
    if (!cloudName || !cloudURL) {
      setWarningTxt('必要欄位未輸入')
      return
    }
    let myEditResult = allDBconfig;
    myEditResult[2].items.forEach(item => {
      if (item.key === editInfo.key) {
        item.title = cloudName;
        item.url = cloudURL;
      }
    })
    setDBConfig(myEditResult[2].items);

    let myEditConfig = {
      role_detail: state.author.role_detail,
      teacher_identity: state.author.id_number,
      teacher_name: state.author.name,
      results: myEditResult,
      call_component: 'teacher/src/component/manager/cloud/editor'
    };

    fetch(dbUrl.concat('/classroom/addCloudConfig'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(myEditConfig)
    });

    seteditDialog(false);
  }

  const doDelete = (item) => {
    let myResult = allDBconfig;
    //console.log(myResult);

    myResult[2].items = myResult[2].items.filter(items => {
      return items.key !== item.key
    });

    let myDeleteConfig = {
      role_detail: state.author.role_detail,
      teacher_identity: state.author.id_number,
      teacher_name: state.author.name,
      results: myResult,
      call_component: 'teacher/src/component/manager/cloud/editor'
    };
    //console.log(myDeleteConfig);
    try {
      fetch(dbUrl.concat('/classroom/addCloudConfig'), {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(myDeleteConfig)
      });
      setDBConfig(myResult[2].items);
    } catch (error) {
      console.log(error);
      setDeleteErr(true);
    }
    //console.log(myResult);
  }

  const getCloudConfig = () => {
    fetch(dbUrl.concat('/classroom/getCloudConfig'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ teacher_identity: state.author.id_number,call_component: 'teacher/src/components/manager/cloud/editor' })
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r.results);
      // setDBConfig(r.results[2].items);
    });
  };

  const openDef = (item) => {
    setDefTitle(item.title);
    setDefAbout(item.about);
    setConfigItem(item);
    setOpenDefDia(true);
  };

  if (defConsfig.length === 0) {
    return (
      <div style={{ height: 300, width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        {/* <h2 style={{ marginBottom: theme.spacing(-1) }}>
          教育局提供
        </h2>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {defConsfig.map((item) => (
            <Card className={styles.card}>
              <CardContent>
                <img
                  src={String(item.icon)}
                  className={styles.image}
                  alt={item.title}
                />
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  {item.title}
                </Typography>
              </CardContent>
              <div style={{ textAlign: 'end', marginBottom: theme.spacing(1) }}>
                <Button size="small" color="primary" onClick={() => openDef(item)}>
                  關於
                </Button>
              </div>
            </Card>
          ))}
        </div> */}
        <h2 style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(-1) }}>
          我的雲端平台
        </h2>
        <div className={styles.contentcard}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {defConsfig.map((item) => (
              <Card className={styles.card}>
                <CardContent>
                  <img
                    src={String(item.icon)}
                    className={styles.image}
                    alt={item.title}
                  />
                  <Typography variant="body1" style={{ textAlign: 'center', marginTop: theme.spacing(1) }}>
                    {item.title}
                  </Typography>
                </CardContent>
                <div style={{ textAlign: 'end', marginBottom: theme.spacing(1) }}>
                  <Button size="small" color="primary" onClick={() => openDef(item)}>
                    關於
                  </Button>
                </div>
              </Card>
            ))}
          </div>
          {/* { dbconfig.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              尚未建立我的雲端平台
            </div>
          )} */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {dbconfig.map((item) => (
              <Card className={styles.card}>
                {/* <img
                  src={String(item.icon)}
                  className={styles.image}
                  alt={item.title}
                /> */}

                <CardContent>
                  <Avatar
                    variant="rounded"
                    className={styles.image}
                    style={{ backgroundColor: item.icon }}
                  >
                    {item.title.substr(0, 1)}
                  </Avatar>
                  <Typography variant="body1" style={{ textAlign: 'center', marginTop: theme.spacing(1) }}>
                    {item.title}
                  </Typography>
                </CardContent>
                <div style={{ textAlign: 'center', marginBottom: theme.spacing(1) }}>
                  <Button size="small" color="primary" onClick={() => editConfig(item)}>
                    編輯
                </Button>
                  <Button size="small" color="primary" onClick={() => doDelete(item)}>
                    刪除
                </Button>
                </div>
              </Card>
            ))}
          </div>
        </div>  
        <Fab
          color="primary"
          aria-label="add"
          style={{ float: 'right', margin: theme.spacing.unit, position: "fixed", bottom: theme.spacing(2), right: theme.spacing(2) }}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{editTitle}</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                fullWidth
                required
                autoFocus
                margin="dense"
                id="name"
                label="名稱"
                type="text"
                variant="outlined"
                defaultValue={targetName}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="建議5個字以內，避免跑版"
              />
            </div>
            <div>
              <TextField
                fullWidth
                required
                margin="dense"
                id="url"
                label="URL"
                type="url"
                defaultValue={targetURL}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>

          </DialogContent>
          <DialogActions>
            <div style={{ color: "red" }}>
              {warningTxt}
            </div>
            {/* <Button onClick={saveDefault} color="primary">
              儲存預設
            </Button> */}
            <Button onClick={handleClose} color="primary">
              取消
            </Button>
            <Button onClick={saveConfig} color="primary">
              儲存
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDefDia}
          onClose={handleClose}
          aria-labelledby="default-dialog-config"
          fullWidth
        >
          <DialogTitle id="default-dialog-config">{defDiaTitle}</DialogTitle>
          <DialogContentText id="default-dialog-config" style={{ marginLeft: theme.spacing(3) }}>
            {defDiaAbout}
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              關閉
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={editDialog}
          onClose={handleClose}
          aria-labelledby="edit-cloud-config"
          fullWidth
        >
          <DialogTitle id="edit-cloud-config">{editTitle}</DialogTitle>
          <DialogContent>
            <div>
              <TextField
                fullWidth
                required
                autoFocus
                margin="dense"
                id="name"
                label="名稱"
                type="text"
                variant="outlined"
                defaultValue={editInfo.title}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="建議5個字以內，避免跑版"
              />
            </div>
            <div>
              <TextField
                fullWidth
                required
                margin="dense"
                id="url"
                label="URL"
                type="url"
                defaultValue={editInfo.url}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>

          </DialogContent>
          <DialogActions>
            <div style={{ color: "red" }}>
              {warningTxt}
            </div>
            <Button onClick={handleClose} color="primary">
              取消
            </Button>
            <Button onClick={saveEdit} color="primary">
              儲存
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={deleteErr}
          autoHideDuration={6000}
          onClose={handleClose}
          message="刪除失敗，請洽客服人員"
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>

    );
  }
};

// const DefDia = (defInfo) => {
//   const [open, setOpen] = useState(true);

//   console.log(defInfo);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="default-dialog-config"
//       fullWidth
//     >
//       <DialogTitle id="default-dialog-config">{defInfo.item.title}</DialogTitle>
//       <DialogContentText id="default-dialog-config">
//         測試
//               </DialogContentText>
//       <DialogActions>
//         <Button onClick={handleClose} color="primary">
//           關閉
//                 </Button>
//       </DialogActions>
//     </Dialog>
//   )
// };


export default Component;
