const Background = require('./background.png');
const LightLogo = require('./light_logo.svg');
const DarkLogo = require('./dark_logo.svg');
const Photo = require('./photo.png');

const Process = require('./process.mp3');
const Finish = require('./finish.mp3');
const Step = require('./step.mp3');

const Audio = {
  Process,
  Finish,
  Step,
};

export { Background, LightLogo, DarkLogo, Photo, Audio };
