import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AccountCircle as CustomIcon,
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  List as ListIcon,
  MoreVert as MoreIcon,
  Pets as PetsIcon,
  PhotoFilter as TransitionIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import envConfig from '../../../config.js'

import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(0.5, 0),
  },
}));

const dbUrl = envConfig.SERVER_URL;
export class StuInfo {
  /** 學生姓名 */
  Name;
  /** 座號 */
  SeatNo;
  /** 學號 */
  StudentNumber;
}

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [category, setCategory] = useState('依組數分組');
  const [target, setTarget] = useState('全班');
  const [method, setMethod] = useState('隨機');
  const [number, setNumber] = useState(4);
  const [rangeNoA, setRangeNoA] = useState(1);
  const [rangeNoB, setRangeNoB] = useState(1);
  const [transition, setTransition] = useState('Fade');
  const [inputText, setInputText] = useState(1);
  const [seatNoRange, setSeatNoRange] = useState([
    1,
    state.students[state.students.length - 1].SeatNo,
  ]);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [targetVisible, setTargetVisible] = useState(false);
  const [methodVisible, setMethodVisible] = useState(false);
  const [numberVisible, setNumberVisible] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputRangeVisible, setInputRangeVisible] = useState(false);
  const [transitionVisible, setTransitionVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);
  const [customVisible, setCustomVisible] = useState(false);
  const [customGroups, setCustomGroups] = useState([]);

  useEffect(() => {
    //console.log(state);
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/groups`)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            let primaryText = '';
            if (data.custom) {
              primaryText = '自訂分組';
            } else if (data.category === '依組數分組') {
              primaryText =
                data.target === '座號'
                  ? `${data.category}：從 ${data.rangeNoA} - ${data.rangeNoB} 號學生中 ${data.method} 分 ${data.number} 組`
                  : `${data.category}：從 ${data.target} 學生中 ${data.method} 分 ${data.number} 組`;
            } else {
              primaryText =
                data.target === '座號'
                  ? `${data.category}：從 ${data.rangeNoA} - ${data.rangeNoB} 號學生中每 ${data.number} 人 ${data.method} 一組`
                  : `${data.category}：從 ${data.target} 學生中每 ${data.number} 人 ${data.method} 一組`;
            }

            items.push({
              id: doc.id,
              data,
              primaryText,
              secondaryText: moment(data.timestamp).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            });
          }
        });

        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        setHistories(
          items.map((item, index) => ({ ...item, avatar: index + 1 })),
        );
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const start = () => {
    if (number === 0) {
      return;
    }
    // 呼叫「報表列印API」
    //console.log(state);
    let targetLog = [{
     system: "課堂工具",
     action: "使用課堂工具功能",
     school_type: state.target.schoolType,
     school_code: state.target.dsns,
     role_type: "teacher",
     identity: state.appsChooseItem.title,
     call_component: 'teacher/src/components/teach/action/group',
     id_number:`${state.target.classroom.name}(${state.target.schoolYear})`,
   }];
   //console.log(targetLog);
   fetch(dbUrl.concat('/classroom/postToLog'), {
     method: 'POST',
     body: JSON.stringify(targetLog),
     headers: {
       'content-type': 'application/json'
     },
   }).then(
     r => r.json()
   ).then(r => {
     //console.log(r);
   })


    const timestamp = Date.now();
    let event = {};
    let data = [];
    let results = [];
    let targetGroupList = [];

    if (target === '全班') {
      data = state.students
        .map((item) => {
          const student = { ...item };
          student.key = `student-${item.ID}`;
          student.sort = Math.random();
          student.type = 'result_student';
          return student;
        })
        .sort((x, y) => x.sort - y.sort);
    } else if (target === '座號') {
      data = state.students
        .filter((item) => item.SeatNo >= rangeNoA && item.SeatNo < rangeNoB)
        .map((item) => {
          const student = { ...item };
          student.key = `student-${item.ID}`;
          student.sort = Math.random();
          student.type = 'result_student';
          return student;
        })
        .sort((x, y) => x.sort - y.sort);
    }

    if (category === '依組數分組') {
      for (let i = 0; i < number; i += 1) {
        results.push([]);
      }

      data.forEach((item, index) => {
        results[index % number].push(item);
      });
    } else if (category === '依人數分組') {
      const team = Math.ceil(data.length / number);

      for (let i = 0; i < team; i += 1) {
        results.push([]);
      }

      let index = 0;
      data.forEach((item) => {
        if (results[index].length < number) {
          results[index].push(item);
        } else {
          index += 1;
          results[index].push(item);
        }
      });
    }

    let targetResult = results;
    results = results.map((group) => group.map((student) => student.ID).join());
    //console.log(results);
    if (target === '全班') {
      event = {
        timestamp,
        category,
        target,
        method,
        number,
        transition,
        results,
      };
    } else if (target === '座號') {
      event = {
        timestamp,
        category,
        target,
        method,
        number,
        transition,
        rangeNoA,
        rangeNoB,
        results,
      };
    }
    
    //console.log(targetGroupList);
    for( let i = 0; i < results.length; i++){
      let targetResultGroup = [];
      targetResult[i].forEach((item, index) =>{
        let targetItem = {
          Name: item.Name,
          SeatNo: item.SeatNo,
          StudentNumber: item.StudentNumber,
        };
        targetResultGroup.push(targetItem);
      });
      let targetGroup = {
        school_code: state.target.dsns ? state.target.dsns : '',
        school_name: state.target.schoolName ? state.target.schoolName : '',
        school_type: state.target.schoolType ? state.target.schoolType : '',
        room_type: state.target.classroom.type ? state.target.classroom.type : '',
        room_id: state.target.classroom.uid ? state.target.classroom.uid : '',
        room_name: state.target.classroom.name ? state.target.classroom.name : '',
        teacher_identity: state.myInfo.id_number ? state.myInfo.id_number : '',
        teacher_name: state.myInfo.name ? state.myInfo.first_name : '某某老師',
        group_category: event.category ? event.category : '',
        group_by: event.target ? event.target : '',
        group_number: event.number ? event.number : '',
        results: targetResultGroup,
        rangenoa: event.rangeNoA ? event.rangeNoA : 1,
        rangenob: event.rangeNoB ? event.rangeNoB : state.students.length + 1,
        group_id: event.timestamp,
        group: i+1,
        call_component: 'teacher/src/components/teach/action/group'
      }
      targetGroupList.push(targetGroup);
    }
    //console.log(targetGroupList);
    fetch(dbUrl.concat('/classroom/addGroupRec'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetGroupList)
    })

    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.group.open',
      timestamp,
    });
    //console.log(event);


    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/${timestamp}`)
      .set(event);
  };

  const uploadCustom = () => {
    if (customGroups.length === 0) return;

    const timestamp = Date.now();
    const event = {
      timestamp,
      number: customGroups.length,
      transition: 'Fade',
      custom: true,
      results: customGroups,
    };

    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.group.open',
      timestamp,
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/${timestamp}`)
      .set(event);
  };

  const renderCategoryModal = () =>
    categoryVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'category-1', value: '依組數分組' },
            { key: 'category-2', value: '依人數分組' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={category === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setCategoryVisible(false);
                setCategory(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setCategoryVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderTargetModal = () =>
    targetVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'target-1', value: '全班' },
            // { key: 'target-2', value: '座號' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={target === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setTargetVisible(false);
                setTarget(item.value);

                if (item.value === '座號') {
                  setInputRangeVisible(true);
                }
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setTargetVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderMethodModal = () =>
    methodVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[{ key: 'method-1', value: '隨機' }].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={method === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setMethodVisible(false);
                setMethod(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setMethodVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderNumberModal = () =>
    numberVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'number-1', value: 4 },
            { key: 'number-2', value: 5 },
            { key: 'number-3', value: 6 },
            { key: 'number-4', value: 7 },
            { key: 'number-5', value: 8 },
            { key: 'number-6', value: '自訂' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={number === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setNumberVisible(false);
                if (item.value !== '自訂') {
                  setNumber(item.value);
                } else {
                  setInputVisible(true);
                }
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setNumberVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderInputModal = () =>
    inputVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <TextField
            autoFocus
            label={category === '依組數分組' ? '請輸入組數' : '請輸入人數'}
            type="number"
            variant="outlined"
            value={inputText}
            inputProps={{
              min: 1,
            }}
            onInput={(e) => {
              setInputText(e.target.value);
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton color="secondary" onClick={() => setInputVisible(false)}>
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputVisible(false);
              setInputText(inputText || 1);
              setNumber(inputText || 1);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderInputRangeModal = () =>
    inputRangeVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <Typography variant="h6" gutterBottom>
            開始座號：{seatNoRange[0]}
          </Typography>
          <Typography variant="h6" gutterBottom>
            結束座號：{seatNoRange[1]}
          </Typography>
          <Slider
            min={1}
            max={state.students[state.students.length - 1].SeatNo}
            value={seatNoRange}
            onChange={(e, v) => setSeatNoRange(v)}
            valueLabelDisplay="auto"
          />
        </div>
        <div className={classes.row}>
          <IconButton
            color="secondary"
            onClick={() => setInputRangeVisible(false)}
          >
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputRangeVisible(false);
              setRangeNoA(seatNoRange[0]);
              setRangeNoB(seatNoRange[1]);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderTransitionModal = () =>
    transitionVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'transition-1', value: 'Fade', label: '淡進' },
            { key: 'transition-2', value: 'Grow', label: '擴展' },
            { key: 'transition-3', value: 'Slide', label: '滑動' },
            { key: 'transition-4', value: 'Zoom', label: '放大' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.label}
              variant="default"
              color={transition === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setTransitionVisible(false);
                setTransition(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton
            color="primary"
            onClick={() => setTransitionVisible(false)}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

  const renderCustomModal = () =>
    customVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <Typography variant="subtitle1">
            1、請先下載學生名單，取得學生編號。
          </Typography>
          <Typography variant="subtitle1">
            2、每列資料為一個群組，輸入每位學生的編號，並且使用逗號分隔。
          </Typography>
          <Typography variant="subtitle1">
            3、將資料複製貼到下方輸入框中。
          </Typography>
          <div style={{ height: theme.spacing(4) }} />
          <Typography variant="body1" color="secondary">
            範例：
          </Typography>
          <Typography variant="body1" color="textSecondary">
            A100000001,A100000002
          </Typography>
          <Typography variant="body1" color="textSecondary">
            B200000003,B300000004
          </Typography>
          <Typography variant="body1" color="textSecondary">
            C100000005,C100000006
          </Typography>
          <Typography variant="body1" color="textSecondary">
            D200000007,D200000008
          </Typography>
          <div style={{ height: theme.spacing(4) }} />
          <TextField
            autoFocus
            fullWidth
            multiline
            rows="8"
            rowsMax="8"
            label="學生分組資料"
            variant="outlined"
            onChange={(e) => {
              setCustomGroups(e.target.value.split('\n'));
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton
            disabled={customGroups.length === 0}
            color="primary"
            onClick={uploadCustom}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        <div className={classes.row}>
          <Button 
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          onClick={() => setCategoryVisible(true)}
          >
            <Typography variant="h4" color="white">
              {category}
            </Typography>
          </Button>
        </div>
        {target === '座號' ? (
          <div className={classes.row}>
            <Typography variant="h5">從</Typography>
            <Button 
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              onClick={() => setTargetVisible(true)}
              >
              <Typography variant="h4" color="white">
                {rangeNoA}
              </Typography>
              <Typography variant="h5" style={{ margin: theme.spacing(0, 1) }}>
                -
              </Typography>
              <Typography variant="h4" color="white">
                {rangeNoB}
              </Typography>
            </Button>
            <Typography variant="h5">號學生中</Typography>
          </div>
        ) : (
          <div className={classes.row}>
            <Typography variant="h5">從</Typography>
            {/* <Button onClick={() => setTargetVisible(true)}>
              <Typography variant="h4" color="primary">
                {target}
              </Typography>
            </Button> */}
            <Typography variant="h5">{target}學生中</Typography>
          </div>
        )}
        {category === '依組數分組' ? (
          <div className={classes.row}>
            {/* <Button 
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setMethodVisible(true)}>
                {method} */}
              {/* <Typography variant="h4" color="primary">
              </Typography> */}
            {/* </Button> */}
            <Typography variant="h5"> 隨機分 </Typography>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => setNumberVisible(true)}>
              <Typography variant="h4" color="white">
                {number}
              </Typography>
            </Button>
            <Typography variant="h5">組</Typography>
          </div>
        ) : (
          <div className={classes.row}>
            <Typography variant="h5">每</Typography>
            <Button 
              variant="contained"
              color="primary"
               onClick={() => setNumberVisible(true)}>
              <Typography variant="h4" color="white">
                {number}
              </Typography>
            </Button>
            <Typography variant="h5">人隨機一組</Typography>
            {/* <Button onClick={() => setMethodVisible(true)}>
              <Typography variant="h4" color="primary">
                {method}
              </Typography>
            </Button>
            <Typography variant="h5">一組</Typography> */}
          </div>
        )}
        <div className={classes.row} style={{ marginTop: theme.spacing(2) }}>
          {/* <IconButton color="primary" onClick={start}>
            <PetsIcon style={{ fontSize: '3rem' }} />
          </IconButton> */}
          {/* <Button
                startIcon={<PlayArrowIcon />}
                variant="contained"
                color="secondary"
                style={{ margin: theme.spacing(2) }}
                onClick={start}
              >開始</Button> */}
          <Button 
            variant="contained"
            size="medium"
            color="primary"
            onClick={start}
            >
              <PlayArrowIcon style={{ fontSize: '3rem' }} color="white"/>
          </Button>
        </div>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
          <BottomNavigationAction
            label="自訂分組"
            icon={<CustomIcon />}
            onClick={() => setCustomVisible(true)}
          />
          <BottomNavigationAction
            label="特效設定"
            icon={<TransitionIcon />}
            onClick={() => setTransitionVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderCategoryModal()}
        {renderTargetModal()}
        {renderMethodModal()}
        {renderNumberModal()}
        {renderInputModal()}
        {renderInputRangeModal()}
        {renderTransitionModal()}
        {renderHistoryModal()}
        {renderCustomModal()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            const results = item.data.results.map((item, index) => ({
              caption: `第 ${index + 1} 組 ${item.split(',').length} 人`,
              data: state.students.filter((student) =>
                item.split(',').includes(`${student.ID}`),
              ),
            }));

            const timestamp = moment(item.data.timestamp).format('MMDDHHmmss');
            const content = [];
            if (item.data.custom) {
              content.push('自訂分組');
            } else if (item.data.category === '依組數分組') {
              content.push(
                item.data.target === '座號'
                  ? `${item.data.category}：從 ${item.data.rangeNoA} - ${item.data.rangeNoB} 號學生中 ${item.data.method} 分 ${item.data.number} 組`
                  : `${item.data.category}：從 ${item.data.target} 學生中 ${item.data.method} 分 ${item.data.number} 組`,
              );
            } else {
              content.push(
                item.data.target === '座號'
                  ? `${item.data.category}：從 ${item.data.rangeNoA} - ${item.data.rangeNoB} 號學生中每 ${item.data.number} 人 ${item.data.method} 一組`
                  : `${item.data.category}：從 ${item.data.target} 學生中每 ${item.data.number} 人 ${item.data.method} 一組`,
              );
            }
            results.forEach((item) => {
              content.push(item.caption);
              item.data.map((i) =>
                content.push(`${i.ID},${i.ClassName},${i.SeatNo},${i.Name}`),
              );
            }
            );
            //console.log(results);

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              'data:text/plain;charset=utf-8,' +
                encodeURIComponent(content.join('\r\n')),
            );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_學生分組_${timestamp}.csv`,
            );
            element.style.display = 'none';
            (document.body || {}).appendChild(element);
            element.click();
            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/groups/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.group.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
