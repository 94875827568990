import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  Typography,
} from '@material-ui/core';
import { List as ListIcon, MoreVert as MoreIcon } from '@material-ui/icons';
import envConfig from '../../../config.js'
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));
const dbUrl = envConfig.SERVER_URL;
const Component = ({ callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/attendances`)
      .onSnapshot((querySnapshot) => {
        //console.log(querySnapshot);
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            items.push({
              id: doc.id,
              data,
              primaryText: moment(data.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            });
          }
        });

        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        setHistories(
          items.map((item, index) => ({ ...item, avatar: index + 1 })),
        );
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const start = () => {
     // 呼叫「報表列印API」
     let targetLog = [{
      system: "課堂工具",
      action: "使用課堂工具功能",
      school_type: state.target.schoolType,
      school_code: state.target.dsns,
      role_type: "teacher",
      identity: state.appsChooseItem.title,
      call_component: 'teacher/src/components/teach/action/attendance',
      id_number:`${state.target.classroom.name}(${state.target.schoolYear})`,
    }];
    //console.log(targetLog);
    fetch(dbUrl.concat('/classroom/postToLog'), {
      method: 'POST',
      body: JSON.stringify(targetLog),
      headers: {
        'content-type': 'application/json'
      },
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
    })

    const timestamp = Date.now();
    state.attendances_id = timestamp;
    const event = { timestamp, status: 'open' };
    state.students.forEach((student) => {
      event[`student_${student.ID}`] = {
        className: student.ClassName,
        seatNo: student.SeatNo,
        name: student.Name,
        status: 'X',
        timestamp: '',
      };
    });
    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.attendance.open',
      timestamp: Date.now(),
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/${timestamp}`)
      .set(event);
  };

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        <Button 
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={start}>
            開始點名
        </Button>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderHistoryModal()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            const timestamp = moment().format('MMDDHHmm');
            const content = [`學生編號,班級名稱,座號,姓名,出席狀況,回覆時間`];
            const students = [];
            Object.keys(item.data).forEach((key) => {
              if (key.includes('student')) {
                students.push({ ...item.data[key], id: key.split('_')[1] });
              }
            });
            students
              .sort((x, y) => (x.seatNo > y.seatNo ? 1 : -1))
              .forEach((student) => {
                content.push(
                  `${student.id},${student.className},${student.seatNo},${
                    student.name
                  },${student.status},${
                    student.timestamp
                      ? moment(student.timestamp).format('YYYY-MM-DD HH:mm:ss')
                      : ''
                  }`,
                );
              });

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              'data:text/plain;charset=utf-8,' +
                encodeURIComponent(content.join('\r\n')),
            );
            // element.setAttribute(
            //   'download',
            //   `${state.target.classroom.name}_上線紀錄_${timestamp}.csv`,
            // );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_課間呼叫_${timestamp}.csv`,
            );

            element.style.display = 'none';
            (document.body || {}).appendChild(element);

            element.click();

            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/attendances/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.attendance.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.primaryText} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
