import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import Clock from 'react-live-clock';
import { 
  Fade, 
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Chip,
  Button,
  TextField,
  Avatar,
  Paper,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import signin from '../../assets/signin.png';
import vote from '../../assets/vote.png';

import { AppContext } from '../../App';
import Assets from '../../assets/cooc.png';
import Setting from '../../assets/setting.svg';
import Close from '../../assets/Close.svg';
import moment from 'moment';
import Signin from './signin';
import Bind from './bind';
import Broadcast from './broadcast';
import Broadcast2 from './broadcast2';
import Signin2 from './signin2';
import Calendar from './calendar';
import Attendance from './attendance';
import Onduty from './onduty';
import Today from './today';
import Tomorrow from './tomorrow'
import QRCode from 'qrcode.react';
import envConfig from '../../config';
import { 
  AccountCircleOutlined as AccountIcon,
  AssignmentTurnedInOutlined,
  Close as CloseIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
} from '@material-ui/icons';
//import * as firebase from 'firebase/app';
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import CryptoJS from 'crypto-js';

// 原本畫面
const useStyles1 = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));


const Component1 = () => {
  const classes = useStyles1();
  const { state } = useContext(AppContext);

  return (
    <Fade in timeout={1000}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Signin />
          {state.device.id !== 'classroom.tp.edu.tw' && <Broadcast />}
        </div>
        <div className={classes.footer}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Ver.{state.version}
          </Typography>
        </div>
      </div>
    </Fade>
  );
};

// export default Component;


// 遊石
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root2: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  signinroot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(3),
  },
  content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(5),
        marginTop: '2px',
      },
  content2: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
        paddingBottom:'10px',
        paddingTop:'20px',
      },
  bccontent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
    marginTop: '15px',
  },
  subcontent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '120px',
    marginBottom: '12px',
    marginTop: '18px',
  },
  thrcontent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '25px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //marginTop: '-30px',
  },
  header: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    height: '60px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '30px',
    paddingLeft: '30px',
  },
  image: {
    width: '300px',
    height: '41px',
    margin: theme.spacing(1),
  },
  headerText: {
    margin: theme.spacing(1.5),
  },
  vote:{
    display:'block',
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    border: '3px solid #ff9b3f',
    backgroundColor: 'white',
    marginRight: theme.spacing(2),
  },
  signinIcon:{
    display:'block',
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    border: '6px solid #ff9b3f',
    backgroundColor: '#ff9b3f'
  },
  contentds: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderWidth: 1,
    backgroundColor: 'rgba(255,255,255,1)',
  },
  codeValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceId: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  dialogStyle:{
    borderRadius: 15,
    position: 'absolute',
    right: 9,
    top: 145
  },
  dialogStyle2:{
    width:'280px',
    borderRadius: 15,
    position: 'absolute',
  }
}));
const ColorButton = withStyles((theme) => ({
  root: {
    width: '100px',
    height: '30px',
    frontSize:'15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

const dbUrl = envConfig.SERVER_URL;
let schedule = [];
let baseClickNum = 0;
let clickCloseNum = 0;
const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const Component = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const theme = useTheme();
  const [clock, setClock] = useState(moment());
  const [classroomName, setClassroomName] = useState('尚未綁定教室');
  const [signInOpen, setSignInOpen] = useState(false);
  const [bindDialogOpen, setBindDialogOpen] = useState(false);
  const [codeValue, setCodeValue] = useState(
    Math.random().toString(10).substr(2, 6),
  );
  const [timestamp, setTimestamp] = useState(300);
  const [ClassID] = useState(
    window.localStorage.getItem('ClassID'),
  );
  const [lockState, setLockState] = useState(false);
  const [unLockView, setUnLockView] = useState(false);
  const [inputPassward, setInputPassward] = useState("");
  const [screenCode, setScreenCode] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [active, setActive] = useState('notActive');
  const [loading, setLoading]= useState(false);
  const [selectRemoveView, setSelectRemoveView] = useState(false);
  const [removeMoringCheck, setRemoveMoringCheck] = useState(false);
  const [removeNightCheck, setRemoveNightCheck] = useState(false);
  const [removeFullCheck, setRemoveFullCheck] = useState(false);
  const [removeSelList, setRemoveSelList] = useState([]);
  const [targetRemoveClass, setTargetRemoveClass] = useState({})
  const [settingView, setSettingView] = useState(false);
  const [unLockScreenPasswordLoading, setUnLockScreenPasswordLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [curPeriod, setCurPeriod] = useState(0);
  const [curCourse, setCurCourse] = useState({});
  const [zoom, setZoom] = useState(1);
  

  useEffect(() => {
    if (!state.device.ClassID) return;
    //console.log(state.device);
    let tempWidth = window.innerWidth / 1280;
    setZoom(tempWidth);
    
    const unsubscribe = firebase
        .firestore()
        .doc(`/date/dateInfo`)
        .onSnapshot((doc) => {
          if(!doc || !doc.data()){
            return
           } else {
             let temp =  doc.data();
             let tempCurToday = moment().format('YYYYMMDD');
             let tempTargetSchool = doMakeTargetSchoolInfo();
             if(temp.dateText !== tempCurToday){
                doGetCourseSchedule(tempTargetSchool);
                dispatch({ type: 'SET_CHANGE_TODAY', payload: temp });
             }
           }
        });

    return () => unsubscribe();
  },[state.device]);

  
  // 嘗試在index這層處理學期轉換的部分
  // 概念是在state.device裡面建立一個finalClassID
  // 然後依此classroomName 再去比對從中介取回來該校的班級清單
  // 取得切換學期後的ClassID 並寫入 state.device.finalClassID
  // 之後讓所有其他的模組從原本讀取state.device.ClassID改讀state.device.finalClassID
  
  useEffect(() => {
    if (!state.device.ClassID) return;
    let temp = state.device

    let tempTargetSchool = doMakeTargetSchoolInfo();

    switch (tempTargetSchool.schoolType) {
      case '國小':
        tempTargetSchool.school_key = '01' + tempTargetSchool.schoolCode;
        break;
      case '國中':
        tempTargetSchool.school_key = '02' + tempTargetSchool.schoolCode;
        break;
      case '高中':
        tempTargetSchool.school_key = '03' + tempTargetSchool.schoolCode;
        break;
      case '高職':
        tempTargetSchool.school_key = '03' + tempTargetSchool.schoolCode;
        break;
      default:
        tempTargetSchool.school_key = '00' + tempTargetSchool.schoolCode;
        break;
    }
    doGetCourseSchedule(tempTargetSchool); //取得課表
    //console.log(tempTargetSchool)

    try {
      fetch(dbUrl.concat('/classroom/getSecondSemesterClassID'), {
        method: 'POST',
        body: JSON.stringify(tempTargetSchool),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          //console.log(r)
          temp.finalClassID = r[0].ClassID;
          dispatch({
            type: 'SET_DEVICE',
            payload: { id: temp.deviceId, ...temp },
          });
        })      
    } catch (error) {
      console.log(error)
    }

      const unsubscribe = firebase
        .firestore()
        .doc(`/schedule/${tempTargetSchool.school_key}`)
        .onSnapshot((doc) => {
          if(!doc || !doc.data() || schedule.length === 0){
            return
           } else {
             let temp =  doc.data();
             //console.log(temp.currentPeriod);
             setCurPeriod(temp.currentPeriod);
             doFindCurCourse(schedule, temp.currentPeriod);
           }
        });

    return () => unsubscribe();
  },[state.device.ClassID]);

  useEffect(() => {
    if (!state.device.classroomName) return;
    //console.log(state.device);
    setClassroomName(state.device.classroomName);
    setScreenCode(state.device.screenCode);
    //dispatch({ type: 'SET_CURCALENDARLIST', payload: true});
  },[state.device.classroomName]);

  const doMakeTargetSchoolInfo = () => {
    let temp = state.device
    //console.log(state);
    let tempD = moment(); 
    let tempNowYear = tempD.format('YYYY');
    let tempNowMonth = parseInt(tempD.format('MM')) < 10 ? '0' + tempD.format('MM') : tempD.format('MM');
    let tempDayText = parseInt(tempD.format('DD')) < 10 ? '0' + tempD.format('DD') : tempD.format('DD');

    // {"start_date": "20211014", 
    // "school_code": "353301", 
    // "school_type": "高中",
    // "class_id": "287985"}
    let tempTargetSchool = {
      start_date: '' + tempNowYear + tempNowMonth + tempDayText,
      weekDay: moment().isoWeekday(),
      class_id: state.device.ClassID,
      schoolCode: temp.schoolCode,
      schoolType: temp.schoolType,
      classroomName: temp.classroomName
    };
    //console.log(tempTargetSchool);

    return tempTargetSchool
  }

  const doGetCourseSchedule = (tempTargetSchool) => {
    //let tempCourseSchedule = [];
    try {
      fetch(dbUrl.concat('/classroom/getGetCourseSchedule'), {
        method: 'POST',
        body: JSON.stringify(tempTargetSchool),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          //console.log(r);
          if(r.StatusCode === '00'){ // 中介API定義的代碼
            schedule = [].concat(r.DATA_LIST);
            //setCourseSchedule([].concat(r.DATA_LIST));
          }
          firebase
          .firestore()
          .doc(`/schedule/${tempTargetSchool.school_key}`)
          .get()
          .then((doc) => {
            if(!doc || !doc.data()){
              return
             } else {
               let temp =  doc.data();
               //console.log(temp.currentPeriod);
               doFindCurCourse(schedule, temp.currentPeriod);
               setCurPeriod(temp.currentPeriod);
             }
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const doFindCurCourse = (courseList, currentPeriod) => {
    if(currentPeriod > 0) {
      let temp = courseList.find( x => x.Period + 0 === currentPeriod + 0 );
      if(typeof temp === 'undefined') {
        temp.SubjectName = '';
      }
      //console.log(temp);
      setCurCourse(temp);
    }
  } 

  const renderDialog = () =>
  signInOpen && (
    <Dialog
      open={signInOpen} 
      onClose={() => setSignInOpen(false)}
      classes={{
            paper: classes.dialogStyle
          }}
    >
      <DialogTitle
        style={{ backgroundColor: "#ff9b3f", color: "white" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent:'space-between'}}>
            <div/>
              <Typography>酷課雲</Typography>
              {/* <CloseIcon onClick={() => {
                      setSignInOpen(false);
                    }}/> */}
              <div/>
            </div>
      </DialogTitle>
      <Signin2/>
    </Dialog>
  );

  const doOpenBindView = () => {
    let tempCodeValue = Math.random().toString(10).substring(2, 6)
    setCodeValue(tempCodeValue);
    setBindDialogOpen(true)
    dispatch({ type: 'CODE_VALUE', payload: tempCodeValue });
    let roomList = JSON.parse(window.localStorage.getItem('List'));
    //console.log(roomList);
    // roomList.forEach((i) => {
    // })
    let temp = {
      curClassroomList: roomList
    }
    if(roomList) {
      firebase.firestore().doc(`/curclasslist/${tempCodeValue}`).set(temp);
    }

  }

  const unLock = () => {
    //console.log('unlock')
    //console.log(screenCode);
    //console.log(window.localStorage.getItem('screenCode'));
    if(clickCloseNum> baseClickNum ) {
      baseClickNum = clickCloseNum
    } else {
      //console.log(state);
      setUnLockScreenPasswordLoading(true);
      setUnLockView(true);
    }
  };

  const clickConfirm = () => {
    setConfirmLoading(true);
    //console.log(state);
    // state.deviceId
    // state.deviceKey
    // state.device.id
    // 上面三個都是deviceId
    // 讀取firebase最新的密碼
    let dID = state.deviceKey === '' ? state.device.id : state.deviceKey;
    try {
      firebase.firestore()
      .doc(`/devices/${dID}`)
      .get()
      .then((doc) => {
      let data = doc.data();
      // console.log(doc.data())
      // console.log(data);
      if(data.screenCode === inputPassward){
        setLockState(false);
        closeUnLockView();
        setErrorCode(false);
        setConfirmLoading(false);
      } else {
        setErrorCode(true);
        setConfirmLoading(false);
      };
     });
    } catch (error) {
      console.log(error);
    }
    // 讀取local存放的密碼
    // if (screenCode === inputPassward) {
    //   setLockState(false);
    //   closeUnLockView();
    //   setErrorCode(false);
    //   setConfirmLoading(false);
    // } else {
    //   setErrorCode(true);
    //   setConfirmLoading(false);
    // }
  };

  const clickRemoveConfirm = () => {
    try {
      firebase.firestore()
      .doc(`/devices/${state.deviceKey}`)
      .get()
      .then((doc) => {
      let data = doc.data();
      // console.log(doc.data())
      // console.log(data);
      if(data.screenCode === inputPassward){
        remove();
        setErrorCode(false);
      } else {
        setErrorCode(true);
      };
     });
    } catch (error) {
      console.log(error);
    }
    // if (screenCode === inputPassward) {
    //   remove();
    //   setErrorCode(false);
    // } else {
    //   setErrorCode(true);
    // }


    // let temp = [].concat(removeSelList)
    // if (targetRemoveClass.class.screenCode === inputPassward){
    //   // temp[targetRemoveClass.index].select = true;
    //   // console.log(temp)
    //   // setRemoveSelList([].concat(temp))
    //   remove();
    //   setErrorCode(false);
    // } else {
    //   setErrorCode(true);
    // }
  };

  const input = (event) => {
    setInputPassward(event.target.value);
  };

  const closeUnLockView = () => {
    setUnLockView(false);
    setInputPassward("");
    setErrorCode(false);
    //console.log(removeView)
  };

  const closeRemoveView = () => {
    //console.log(state.device)
    setInputPassward("");
    setRemoveView(false);
    setErrorCode(false);
    
  };

  const doLock = () => {
    //console.log('screen lock')
    if(lockState) {
      setUnLockView(true);
    } else {
      setSettingView(false);
      setLockState(true);
    }
  }
  
  const remove = () => {
    const key = state.deviceKey
      .split(':')
      .filter((item) => item !== state.device.deviceId);

    if (key.length > 0) {
      state.communication.postMessage(
        JSON.stringify({
          action: 'SAVE_DEVICE_ID',
          data: { deviceId: key.join(':') },
        }),
      );
    } else {
      state.communication.postMessage(
        JSON.stringify({
          action: 'REMOVE_DEVICE_ID',
        }),
      );
    }

    window.location.reload();
  };

  // const remove = () => {
  //   console.log('remove')
  //   state.communication.postMessage(
  //     JSON.stringify({
  //       action: 'REMOVE_DEVICE_ID',
  //     }),
  //   );
  //   window.localStorage.clear();
  //   window.location.reload();
  //   console.log(state)
  // };

  const removehandleChange = (item, index) => {
    setTargetRemoveClass({
      class: item,
      index: index,
    })
    let temp = [].concat(removeSelList)
    if(item.select) {
      temp[index].select = false;
      setRemoveSelList([].concat(temp))
    } else {
      setRemoveView(true)
      //setTargetRemoveClass(item)
      //removeDialog(item, index , list)
      // temp[index].select = true;
      // setRemoveSelList([].concat(temp))
    }
    //console.log(item)
  }

  const selectRemoveDialog = () => 
    selectRemoveView && (
      <Dialog
          open={selectRemoveView}
          onClose={() => setSelectRemoveView(false)}
          style={{display:'flex',flexDirection:'column', justifyContent: 'center',padding:'10px'}}
          // classes={{
          //     paper: classes.dialogStyle
          //   }}
        >
          <DialogTitle style={{display:'flex',flexDirection:'row', justifyContent: 'center'}}>
            請選擇要解除的班牌
          </DialogTitle>
          
          <DialogContent>
           { removeSelList.length < 0 ? (
              <div style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress/>
              </div>
            ) : (
              <>
                <div style={{display: 'flex', flexDirection:'column'}}>
                  {removeSelList.map((citem, cindex) => (
                    <div>
                     <Typography>{citem.timeText === '全時段' ? citem.timeText : `目前${citem.timeText}綁定的班牌`} </Typography> 
                      <FormControlLabel
                        control={<Checkbox checked={citem.select} onChange={() => removehandleChange(citem,cindex)} name="checkedA" />}
                        label={citem.classroomName}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
        </DialogContent>
          <DialogActions style={{display:'flex',flexDirection:'row', justifyContent: 'center'}}>
          <ColorButton variant="contained" color="primary">
            確認
          </ColorButton>
        </DialogActions>
        </Dialog>
    )

  const removeDialog = () => 
  removeView && (
    <Dialog open={removeView} onClose={() => closeRemoveView()} style={{padding:'10px'}}>
        <div style={{display: 'flex',alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{flex: '1'}}/>
          <DialogTitle style={{flex: '2',marginLeft:'13px'}}>解除綁定</DialogTitle>
          <IconButton size="small" style={{marginRight:'6px'}}>
              <CloseIcon onClick={() => closeRemoveView()} />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
            <Typography> 請輸入 </Typography> 
            <Typography style={{ color: '#1db7bd'}}>{state.device.classroomName}</Typography>
            <Typography> 的大屏密碼</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            id="unlock-passward"
            label="輸入密碼"
            autoComplete="off"
            variant="outlined"
            value={inputPassward}
            onChange={input}
            style={{width:'210px'}}
          />
          {errorCode && <Typography
            variant="body1"
            color="secondary"
            style={{ margin: theme.spacing(1, 1, 0) }}
          >
            請輸入正確密碼
          </Typography>}
        </DialogContent>
        <div style={{display: 'flex',justifyContent: 'center',width:'100%'}}>
          <DialogActions>
            <ColorButton 
              onClick={() => clickRemoveConfirm()}
              variant="contained" color="primary">
              確認
            </ColorButton>
          </DialogActions>
        </div>
      </Dialog>
  );

  

  const unLockDialog = () =>
    unLockView && (
      <Dialog open={unLockView} onClose={() => closeUnLockView()}>
        <div style={{display: 'flex',alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{flex: '1'}}/>
          <DialogTitle style={{flex: '2',marginLeft:'	5px'}}>解鎖大屏</DialogTitle>
          <IconButton size="small" style={{marginRight:'6px',zIndex: 9989}} onClick={() => {
                closeUnLockView();
                clickCloseNum++;
              }} >
              <CloseIcon />
          </IconButton>
        </div>
        {confirmLoading ? (
          <div style={{ display: "flex", flexDirection: "column", padding: "12px", width: "255px", height:'255px', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
            <div style={{marginTop: '10px' }}>密碼比對中</div>
          </div>
        ):(
          <>
            <DialogContent>
              <TextField
                id="unlock-passward"
                label="輸入密碼"
                autoComplete="off"
                variant="outlined"
                value={inputPassward}
                onChange={input}
              />
              {errorCode && <Typography
                variant="body1"
                color="secondary"
                style={{ margin: theme.spacing(1, 1, 0) }}
              >
                請輸入正確密碼
              </Typography>}
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={() => clickConfirm()} variant="contained" 
                  color="primary"
                  style={{ marginRight:'15px'}} >
                確定
              </Button>
            </DialogActions> */}
            <div style={{display: 'flex',justifyContent: 'center',width:'100%'}}>
              <DialogActions>
                <ColorButton 
                  onClick={() => clickConfirm()}
                  variant="contained" color="primary">
                  確認
                </ColorButton>
              </DialogActions>
            </div>
          </>
        )}
      </Dialog>
    );
  
  const bindDialog = () => 
  bindDialogOpen && (
    <Dialog
      open={bindDialogOpen} 
      onClose={() => {
        firebase.firestore().doc(`/curclasslist/${codeValue}`).delete();
        setBindDialogOpen(false);
      }}
      classes={{
            paper: classes.dialogStyle2
          }}
      BackdropProps={{style: {backgroundColor: 'rgba(92, 92, 92, 0.95)'}}}
    >
      <DialogTitle
        style={{ backgroundColor: "#1db7bd", color: "white" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent:'space-between'}}>
            <div/>
              <Typography variant='h5'>新增或覆蓋班牌</Typography>
              <CloseIcon onClick={() => {
                      setBindDialogOpen(false);
                    }}/>
              <div/>
            </div>
      </DialogTitle>
      <div className={classes.signinroot}>
          <div className={classes.contentds}>
            <div className={classes.signin}>
                  <Typography variant="body1" style={{ color: '#1db7bd'}}>
                    請使用酷課 App
                  </Typography>
                  <Typography variant="body1" style={{ color: '#1db7bd'}}>
                    掃描 QRCode 進行綁定
                  </Typography>
                  <div style={{ height: theme.spacing(3) }} />
                  {/* <Fade in={timestamp > 2} timeout={2000}> */}
                    <div className={classes.codeValue}>
                      <QRCode
                        value={`https://cooc.tp.edu.tw/app_links/classroom?th=b&codeValue=${codeValue}`}
                        size={180}
                        fgColor="#454545"
                        bgColor="transparent"
                      />
                      <div style={{ height: theme.spacing(2) }} />
                      <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
                        {codeValue}
                      </Typography>
                    </div>
              </div>
            </div>
        </div>
      {/* <Bind/> */}
    </Dialog>
  )
  
  //console.log(tempClassID);
  // {state.leaving.state ? (
  //   <div style={{display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       margin: theme.spacing(3),}}>
  //     <CircularProgress
  //       color="primary"
  //       style={{ margin: theme.spacing(2), color: '#f1f1f1' }}
  //     />
  //     <Typography variant="h6" style={{ color: '#f1f1f1' }}>
  //       資料讀取中...
  //     </Typography>
  //   </div>
  // ) : (<Signin/>)}

  return (
    <ErrorBoundary>
      {state.device.id === 'loading'? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: theme.spacing(3),
          width: '100%', 
          height: '100%'
        }}>
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2), color: 'white' }}
                />
                <Typography variant="h6" style={{ color: 'white' }}>
                  資料讀取中...
                </Typography>
        </div>
      ):(
        <div style={{zoom:zoom}}>
          {selectRemoveDialog()}
          {state.device.id === 'classroom.tp.edu.tw'? (
            <div>
              <div className={classes.root}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className={classes.content}>
                    <Signin/>
                  </div>
                  {/* <div className={classes.content}>
                    <Bind/>
                  </div> */}
                  {/* {bindDialog()}
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => doOpenBindView()}
                    style={{width:'70%'}}
                    >班牌綁定</Button> */}
                  <div className={classes.footer}>
                    <Typography variant="caption" color="textSecondary" gutterBottom>
                      Ver.{state.version}
                    </Typography>
                  </div>
                </div>
                {/* <div className={classes.footer}>
                  <Typography variant="caption" color="textSecondary" gutterBottom>
                    Ver.{state.version}
                  </Typography>
                </div> */}
              </div>
            </div>
          ):(
            <div className={classes.root2}>
              {lockState && <div
                style={{
                  // height:'200px',
                  // width:'200px',
                  position: "fixed",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  zIndex: 9900
                }}
                onClick={unLock}
              >{unLockDialog()}</div>}
              <div className={classes.header}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                  <img 
                    src={Assets}
                    className={classes.image}
                  />
                  { curPeriod > 0 && <div style={{padding:'12px', marginLeft:'75px'}}>
                    <Typography variant="h5" noWrap>
                        {classroomName}
                    </Typography>
                  </div>}
                </div>
                { curPeriod === 0 && <div style={{padding:'12px'}}>
                    <Typography variant="h5" noWrap>
                        {classroomName}
                    </Typography>
                </div>}
                <div className={classes.headerText} style={{display:'flex'}}>
                  {curPeriod > 0 && <Typography variant="h5" noWrap style={{marginRight:'20px'}}>
                    {curCourse.SubjectName}
                  </Typography>}
                  <Typography variant="h5" noWrap>
                    {`${clock.format('YYYY/MM/DD')} ${
                      [
                        '週日',
                        '週一',
                        '週二',
                        '週三',
                        '週四',
                        '週五',
                        '週六',
                      ][clock.day()]
                    }`}
                    {' '}
                    {/* {clock.format('HH:mm')} */}
                  <Clock ticking={true}/>
                  </Typography>
                </div>
              </div>
              <div className={classes.content2}>
                <div className={classes.bccontent}>
                    <Broadcast2 />
                </div>
                <div className={classes.subcontent}>
                  <div style={{flex:1, display: 'flex', }}>
                    <Calendar />
                  </div>
                  <div style={{flex:2, marginLeft: theme.spacing(2), marginRight: theme.spacing(2), display: 'flex'}}>
                    <Today />
                  </div>
                  <div style={{flex:1, display: 'flex'}}>
                    <Tomorrow />
                  </div>
                </div>
                <div className={classes.thrcontent}>
                  <div style={{flex:1, display: 'flex'}}>
                    <Onduty />
                  </div>
                  <div style={{flex:2, display: 'flex', marginLeft: theme.spacing(2), marginRight: theme.spacing(2), alignItems: 'center'}}>
                    <div style={{flex:1}}>
                      <Attendance />
                    </div>
                    
                    <div style={{flex:1}}></div>
                    <div>
                    {bindDialog()}
                    {settingView && <div
                        style={{
                          height:'20px',
                          width:'20px',
                          position: "fixed",
                          top: '75%',
                          right: 0,
                          left: '73%',
                          bottom: 0,
                          zIndex: 1
                        }}
                      ><Button variant="contained" style={{
                          display:'block',
                          height:'35px',
                          width: '100px',
                          borderRadius: '25px',
                          border: '3px solid #ff9b3f',
                          backgroundColor: 'white',
                          display:'flex',
                        }}
                          onClick={doLock}
                        >
                          <div style={{width:'100%', color:'#ff9b3f'}}>鎖定螢幕</div>
                        </Button>
                      </div>}
                      {settingView && <div
                        style={{
                          height:'20px',
                          width:'20px',
                          position: "fixed",
                          top: '81%',
                          right: 0,
                          left: '71%',
                          bottom: 0,
                          zIndex: 1
                        }}
                      ><Button variant="contained" style={{
                          display:'block',
                          height:'35px',
                          width: '100px',
                          borderRadius: '25px',
                          border: '3px solid #ff9b3f',
                          backgroundColor: 'white',
                          display:'flex',
                        }}
                        onClick={() =>{
                          doOpenBindView()
                        }}
                        >
                          <div style={{width:'100%', color:'#ff9b3f'}}>綁定班牌</div>
                        </Button>
                      </div>}
                      {settingView && <div
                        style={{
                          height:'20px',
                          width:'20px',
                          position: "fixed",
                          top: '87%',
                          right: 0,
                          left: '73%',
                          bottom: 0,
                          zIndex: 1
                        }}
                      ><Button variant="contained" style={{
                          display:'block',
                          height:'35px',
                          width: '100px',
                          borderRadius: '25px',
                          border: '3px solid #ff9b3f',
                          backgroundColor: 'white',
                          display:'flex',
                        }}
                        onClick={() => {
                        //console.log(state)
                        setRemoveView(true)
                        }}
                        >
                          <div style={{width:'100%', color:'#ff9b3f'}}>解除綁定</div>
                        </Button>
                      </div>}
                      {/* <Paper style={{
                          flex: 1, 
                          display: "flex", 
                          flexDirection: 'column', 
                          height: '100px', 
                          borderRadius:"15px",
                          width: '100px',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        onClick={() =>{
                          doOpenBindView()
                        }}
                        >
                          {bindDialog()}
                          <QRCode
                            value={`https://cooc.tp.edu.tw/app_links/classroom2?th=b&codeValue=${codeValue}`}
                            size={60}
                            fgColor="#454545"
                            bgColor="transparent"
                          />
                          <Typography>綁定班牌</Typography>
                      </Paper> */}
                    </div>
                  </div>
                  {removeDialog()}
                  <div style={{
                    flex:1, 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: theme.spacing(0.5)}}>
                    <div style={{
                        display:'block',
                        height: settingView ? '80px': '100px',
                        width:  settingView ? '80px': '100px',
                        borderRadius: '50%',
                        border: '3px solid #ff9b3f',
                        backgroundColor: 'white',
                        marginRight: theme.spacing(2),
                      }}
                      onClick={() => {
                        settingView ? setSettingView(false) : setSettingView(true)
                      }}
                    >
                        <img 
                          src={settingView ? Close : Setting}
                          style={{
                            width: settingView ? '40px': '50px',
                            height: settingView ? '40px': '50px',
                            display:'block',
                            marginLeft: settingView ? '20px': '25px',
                            marginTop: '12px',
                          }}
                        />
                        <Typography 
                          variant="subTitle2" 
                          style={{ 
                            color: '#ff9b3f',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            }}>{ settingView ? '取消': '設定'}</Typography>
                    </div>
                    <div 
                      className={classes.signinIcon}
                      onClick={() => {
                          setSignInOpen(true);
                        }}
                    >
                    <img 
                          src={signin}
                          style={{
                            width: '50px',
                            height: '50px',
                            display:'block',
                            marginLeft: '25px',
                            marginTop: '12px',
                          }}
                        />
                        {renderDialog()}
                        <Typography 
                          variant="subTitle2" 
                          style={{ 
                            color: 'white',
                            marginLeft: '20px',
                            }}>教師登入</Typography>
                    </div>
                  </div>
                </div>
              </div>
            <div className={classes.footer}>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  Ver.{state.version}
                </Typography>
            </div>
          </div>
          )}
      </div>   
      )}
    </ErrorBoundary>
  );
};

export default Component;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    // 更新 state 以至於下一個 render 會顯示 fallback UI
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // 你可以 render 任何客製化的 fallback UI
      // return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
