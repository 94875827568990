import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  CircularProgress,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import {
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
} from '@material-ui/icons';
import { Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    position: 'relative',
  },
  normalAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  selectedAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
  addFab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
}));

const Component = () => {
  const styles = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!state.school) return;

    const unsubscribe = firebase
      .firestore()
      .collection('broadcasts')
      .where('schoolCode', '==', state.school.school_code)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          data.push({
            ...item,
            key: doc.id,
            sort: moment(item.timestamp.toDate()),
          });
        });
        data.sort((x, y) => (y.sort > x.sort ? 1 : -1));
        //console.log(data);
        setItems(data);
        setLoading(false);
      });

    return () => unsubscribe();
  }, [state.school]);

  return loading ? (
    <div className={styles.loading}>
      <CircularProgress size="3rem" color="primary" />
    </div>
  ) : (
    <div className={styles.content}>
      <List style={{ flex: 1 }}>
        {items.map((item, index) => (
          <React.Fragment key={item.key}>
            <ListItem
              button
              selected={state.target && item.key === state.target.key}
              onClick={() => dispatch({ type: 'SET_TARGET', payload: item })}
            >
              <ListItemAvatar>
                <Avatar
                  className={
                    state.target && item.key === state.target.key
                      ? styles.selectedAvatar
                      : styles.normalAvatar
                  }
                >
                  {
                    {
                      text: <TextIcon />,
                      video: <VideoIcon />,
                    }[item.type]
                  }
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={item.sort.format('YYYY-MM-DD HH:mm')}
              />
            </ListItem>
            {index < items.length - 1 && <Divider variant="fullWidth" />}
          </React.Fragment>
        ))}
      </List>
      <Fab
        className={styles.addFab}
        color="secondary"
        aria-label="add"
        onClick={() =>
          dispatch({
            type: 'SET_TARGET',
            payload: {
              key: Date.now(),
              level: 'normal',
              type: 'text',
              isNew: true,
            },
          })
        }
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Component;
