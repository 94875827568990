import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Fade,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Chip,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ControlPoint as ControlPointIcon,
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { AppContext } from '../../../App';
import envConfig from '../../../config';

const useStyles = makeStyles((theme) => ({}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [curItem, setCurItem] = useState([]);

  useEffect(() => {
    if (!state.editUndone) return;
    //console.log(state);
    setCurItem([].concat(state.editUndone));
  }, [state.editUndone]);

  const doCheckStu = (index) => {
    let tempCheck = [].concat(curItem);
    //console.log(tempCheck[0].student);
    //console.log(tempCheck[0].student[index]);
    if (tempCheck[0].student[index].undone) {
      //console.log(tempCheck[0].student[index].undone);
      tempCheck[0].student[index].undone = false;
    } else {
      //console.log(tempCheck[0].student[index].undone);
      tempCheck[0].student[index].undone = true;
    }
    setCurItem([].concat(tempCheck));
  };

  return (
    <>
      {curItem.length === 0 ? (
        <div>尚無相關項目的學生</div>
      ) : (
        <div>
          {curItem[0].student.map((curitem, curindex) =>
            curitem.undone ? (
              <Button
                onClick={() => doCheckStu(curindex)}
                variant="outlined"
                style={{
                  border: 'solid 2px #e0e0e0',
                  borderRadius: '100px',
                  margin: '5px',
                }}
              >
                <Typography style={{ color: '#323232' }}>
                  {curitem.seatNo > 10 ? curitem.seatNo : '0' + curitem.seatNo}{' '}
                  {curitem.studentName}
                </Typography>
                <ControlPointIcon
                  fontSize="small"
                  style={{
                    marginLeft: '20px',
                    marginRight: '-10px',
                    color: '#a3a3a3',
                  }}
                />
              </Button>
            ) : (
              <Button
                onClick={() => doCheckStu(curindex)}
                variant="outlined"
                style={{
                  border: 'solid 2px #1db7bd',
                  borderRadius: '100px',
                  margin: '5px',
                }}
              >
                <Typography style={{ color: '#323232' }}>
                  {curitem.seatNo > 9 ? curitem.seatNo : '0' + curitem.seatNo}{' '}
                  {curitem.studentName}
                </Typography>
                <CheckCircleIcon
                  fontSize="small"
                  style={{
                    marginLeft: '20px',
                    marginRight: '-10px',
                    color: '#1fb6bd',
                  }}
                />
              </Button>
            ),
          )}
        </div>
      )}
    </>
  );
};

export default Component;
