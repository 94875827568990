import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  Close as UncheckIcon,
  Done as CheckIcon,
  HighlightOffOutlined as CloseIcon,
} from '@material-ui/icons';
import envConfig from '../../../config.js'

import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.75)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    margin: theme.spacing(2),
  },
  content0: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  contentsw: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // position:"absolute", 
    // right:"0",
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
  },
  checkAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  uncheckAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));
const dbUrl = envConfig.SERVER_URL;

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('close');

  useEffect(() => {
    //console.log(state);
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();
          const students = [];
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              const student = item[key];
              students.push(student);
            }
          });
          students.sort((x, y) => (x.seatNo > y.seatNo ? 1 : -1));

          setData(students);
          setStatus(item.status);
        }
      });

    return () => {
      unsubscribe();
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
        .update({ status: 'close' });
    };
    // eslint-disable-next-line
  }, []);

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .update({
        status: status === 'open' ? 'close' : 'open',
      });
  };

  const upgradeTeacher = () => {
    //console.log(state);
    //console.log(data);
    let targetTeacher = {
      attendance_id: state.attendances_id,
      room_id: state.target.classroom.uid ? '' + state.target.classroom.uid : '',
      teacher_identity: state.myInfo.id_number ? state.myInfo.id_number : '',
      call_component:'teacher/src/components/teach/content/attendance'
    }
    //console.log(targetTeacher);
    fetch(dbUrl.concat('/classroom/addTeacherARec'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetTeacher)
    })

    callback();
  };


  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          課間呼叫
        </Typography>
        <div style={{ flex: 1 }} />
      </div>
      <div className={classes.content}>
        <div className={classes.items}>
          {data.map((item, idx) => (
            <Paper
              key={`student-${idx + 1}`}
              style={{ width: 240, margin: theme.spacing(1) }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    className={
                      item.status === 'V'
                        ? classes.checkAvatar
                        : classes.uncheckAvatar
                    }
                  >
                    {item.status === 'V' ? <CheckIcon /> : <UncheckIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${item.seatNo} ${item.name}`}
                  secondary={
                    item.timestamp
                      ? moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
                      : ''
                  }
                />
              </ListItem>
            </Paper>
          ))}
        </div>
            <div className={classes.contentsw}>
                <Tooltip title={status === 'open' ? '開啟點名' : '關閉點名'}>
                <IconButton onClick={toggleStatus}>
                  {status === 'open' ? (
                    <Button 
                        variant="contained"
                        color="primary"
                      >
                        開啟呼叫中
                      </Button>
                    ) : (
                      <Button 
                        variant="contained"
                        color="secondary"
                      >
                        已關閉呼叫
                      </Button>
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="關閉">
                <IconButton onClick={upgradeTeacher}>
                    <Button 
                      variant="contained"
                      color="primary"
                    >
                      回到主畫面
                    </Button>
                </IconButton>
              </Tooltip>
          </div>
      </div>
    </div>
  );
};

export default Component;
