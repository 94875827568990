import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';

import { teal } from '@material-ui/core/colors';
import { useMediaQuery } from '@material-ui/core';
import { Route, Router, Switch } from 'react-router-dom';
import { createMemoryHistory } from 'history';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/performance';

import envConfig from './config';
import { Broadcast, Chooser, Main, Teach, Manager } from './components';

export const AppContext = createContext({});
const dbUrl = envConfig.SERVER_URL;
firebase.initializeApp({
  apiKey: 'AIzaSyA15KFsCnvpVETAblOT1UGeCvOLwEMq1xU',
  authDomain: 'tp-cooc.firebaseapp.com',
  databaseURL: 'https://tp-cooc.firebaseio.com',
  projectId: 'tp-cooc',
  storageBucket: 'tp-cooc.appspot.com',
  messagingSenderId: '141220712448',
  appId: '1:141220712448:web:246c307a4e9c15592808e5',
  measurementId: 'G-10RFJ1H108',
});

const initialState = {
  version: '20N09M15',
  darkMode: false,
  communication: {
    connected: false,
    postMessage: (message) => {
      try {
        const { action, data } = JSON.parse(message);
        if (action === 'CREATE_NEW_PAGE') {
          window.open(data.url, '_blank');
        } else if (action === 'SAVE_DEVICE_ID') {
          window.localStorage.setItem('deviceID', data.deviceId);
        }
      } catch {
        // empty
      }
    },
  },
  container: { type: 'chrome', version: '' },
  device: { id: 'classroom.tp.edu.tw' }, // 讓網頁可以綁班牌
  //device: { id: 'loading' }, // 讓網頁不可以綁班牌
  active: { status: 'notActive' },
  leaving: {state: false},
  deviceKey: '',
  tts: {
    speak: (text) => {
      if (!window.speechSynthesis) {
        return;
      }

      window.speechSynthesis.cancel();

      const tts = new SpeechSynthesisUtterance();
      tts.lang = 'zh-TW';
      tts.text = text;
      window.speechSynthesis.speak(tts);
    },
    stop: () => {
      if (!window.speechSynthesis) {
        return;
      }

      window.speechSynthesis.cancel();
    },
    exists: !!window.speechSynthesis,
  },
};

const stateReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_COMMUNICATION': {
      return { ...state, communication: payload };
    }
    case 'SET_CONTAINER': {
      return { ...state, container: payload };
    }
    case 'SET_DEVICE_KEY': {
      return { ...state, deviceKey: payload };
    }
    case 'SET_DEVICE': {
      return { ...state, device: payload };
    }
    case 'CODE_VALUE': {
      return { ...state, codeValue: payload };
    }
    case 'SET_ACCESS_TOKEN': {
      return { ...state, accessToken: payload };
    }
    case 'SET_MY_INFO': {
      return { ...state, myInfo: payload };
    }
    case 'SET_ROOM_ID': {
      return { ...state, roomId: payload };
    }
    case 'SET_TARGET': {
      return { ...state, target: payload };
    }
    case 'SET_CONFIG': {
      return { ...state, config: payload };
    }
    case 'SET_STUDENTS': {
      return { ...state, students: payload };
    }
    case 'APPS_CHOOSE_ITEM': {
      return { ...state, appsChooseItem: payload };
    }
    case 'TOGGLE_DARK_MODE': {
      return { ...state, darkMode: payload };
    }
    case 'SET_AUTHOR': {
      return { ...state, author: payload };
    }
    case 'SET_TARGETDAY': {
      return { ...state, targetDay: payload };
    }
    case 'SET_WEEKLIST': {
      return { ...state, weekList: payload };
    }
    case 'SET_CALENDARWEEKLIST': {
      return { ...state, calendarWeekList: payload };
    }
    case 'SET_NOTIFICATIONCALEDAR': {
      return { ...state, notificationCalendar: payload };
    }
    case 'SET_EDITUNDONE': {
      return { ...state, editUndone: payload };
    }
    case 'SET_CLASSLIST': {
      return { ...state, targetClassList: payload };
    }
    case 'SET_ACTIVE': {
      return { ...state, active: payload };
    }
    case 'SET_TODAY': {
      return { ...state, today: payload };
    }
    case 'SET_CHANGE_TODAY':{
      return { ...state, changeToday: payload }
    }
    default:
      return state;
  }
};

const history = createMemoryHistory();

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundColor: '#28a093',
  },
}));

const Component = () => {
  const classes = useStyles();
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [state, dispatch] = useReducer(stateReducer, initialState);

  // useMemo(
  //   () =>
  //     dispatch({
  //       type: 'TOGGLE_DARK_MODE',
  //       payload: prefersDarkMode,
  //     }),
  //   [prefersDarkMode],
  // );

  let theme = useMemo(() => {
    return createMuiTheme ({
    //return createTheme({
      palette: {
        primary: { main: teal[700] },
        type: 'light',
      },
      typography: {
        fontFamily: [
          'Roboto',
          'Helvetica',
          'Noto Sans TC',
          'sans-serif',
        ].join(),
      },
    });
    // eslint-disable-next-line
  }, [state.darkMode]);

  useEffect(() => {
    window.localStorage.removeItem('students');
    let tokenInfo = window.localStorage.getItem('tokenInfo');
    const roomId = window.localStorage.getItem('roomId');
    const accessToken = window.localStorage.getItem('accessToken');
    // console.log(tokenInfo);
    if (tokenInfo) {
      
      tokenInfo = JSON.parse(window.localStorage.getItem('tokenInfo'));

      history.push('/chooser');
    } else if (roomId && accessToken) {
      dispatch({ type: 'SET_ROOM_ID', payload: roomId });
      dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
      history.push(`/teach/false`);
    }
    else {  
      //讓網頁可以綁班牌
      //const deviceID = window.localStorage.getItem('deviceID');
      
      // const deviceID = '319998312818'
      // if(deviceID){
      //   firebase
      //           .firestore()
      //           .doc(`/devices/${deviceID}`)
      //           .get()
      //           .then((doc) => {
      //             if (doc.exists) {
      //               const device = doc.data();
      //               //console.log(device)

      //               dispatch({
      //                 type: 'SET_DEVICE',
      //                 payload: { id: device.deviceId, ...device },
      //               });
      //               // dispatch({  // 測試離開畫面顯示判斷的變數
      //               //   type: 'SET_LEAVING',
      //               //   payload: { state: false },
      //               // });
      //             }
      //           });
      //         } else {
      //           //console.log(state.device.id);
      //           dispatch({
      //             type: 'SET_DEVICE',
      //             payload: { id: 'classroom.tp.edu.tw' },
      //           });

      //           // dispatch({  // 測試離開畫面顯示判斷的變數
      //           //   type: 'SET_LEAVING',
      //           //   payload: { state: false },
      //           // });
      //         }
      // 讓網頁可以綁班牌
      history.push('/main');
    }

    const messageListener = (event) => {

      try {
        let { action, data } = JSON.parse(event.data);
        // console.log(action);
        // console.log(data);
        if (action === 'START_COMMUNICATION') {
          dispatch({
            type: 'SET_COMMUNICATION',
            payload: {
              connected: true,
              postMessage: (value) => {
                if (window.android) {
                  window.android.postMessage(value);
                } else {
                  event.source.postMessage(value, event.origin);
                }
              },
            },
          });

          dispatch({
            type: 'SET_CONTAINER',
            payload: { ...data },
          });

          if (data.type === 'native') {
            dispatch({
              type: 'SET_TTS',
              payload: {
                speak: (text) => {
                  (window.android || window).postMessage(
                    JSON.stringify({
                      action: 'TEXT_TO_SPEECH_START',
                      data: { text },
                    }),
                  );
                },
                stop: () => {
                  (window.android || window).postMessage(
                    JSON.stringify({
                      action: 'TEXT_TO_SPEECH_STOP',
                    }),
                  );
                },
              },
            });
          }

          if (data.deviceId) {
            dispatch({ type: 'SET_DEVICE_KEY', payload: data.deviceId });

            const deviceKeys = data.deviceId.split(':');
            //console.log(deviceKeys);
            firebase
              .firestore()
              .collection('devices')
              .where(
                firebase.firestore.FieldPath.documentId(),
                'in',
                deviceKeys,
              )
              .get()
              .then((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                  docs.push(doc.data());
                });

                window.localStorage.setItem('List', JSON.stringify(docs));
                dispatch({ type: 'SET_CLASSLIST', payload: docs });
                background(docs);
              });
          } else {
            // 確定沒有班牌時，才寫入classroom.tp.edu.tw，讓QRCode顯示出來。
            dispatch({
              type: 'SET_DEVICE',
              payload: { id: 'classroom.tp.edu.tw' },
            });
            // 確定沒有班牌時，才寫入classroom.tp.edu.tw，讓QRCode顯示出來。
          }

          history.push('/main');
        }
      } catch (error) {
        //console.log(error);
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.targetClassList) return;
    const [device] = state.targetClassList;
    // 8/4新增學期切換後，自動判斷ClassID，作法：拿班級名稱去找目前的ClassID（因為目前班級ID每學期會更換新的）
    // 有跟告知臺北市教育局，要達到上述功能，上下學期的班級名稱必須一致
    // fetch(dbUrl.concat('/classroom/getCurClassID'), {
    //   method: 'POST',
    //   body: JSON.stringify({classroomName: device.classroomName,call_component:'teacher/src/App'}),
    //   headers: {
    //     'content-type': 'application/json',
    //   },
    // })
    //   .then((r) => r.json())
    //   .then((r) => {
    //     //console.log(r);
    //   })
    // device.classroomName
    //const [device] = state.targetClassList;
    //console.log(device);
    if (device.timeText === '全時段') {
      dispatch({
        type: 'SET_DEVICE',
        payload: { id: device.deviceId, ...device },
      });
      dispatch({  // 測試離開畫面顯示判斷的變數
        type: 'SET_LEAVING',
        payload: { state: false },
      });

      return;
    }

    const unsubscribe = () => {};
    //console.log(device)
    let timeset = device.schoolCode;
    let timeset2 = device.scSchoolCode

    firebase
      .firestore()
      .doc(`/timeset/${timeset2}`)
      .get()
      .then((doc) => {
        if (!doc.exists) timeset = '000000';

        firebase
          .firestore()
          .doc(`/timeset/${timeset2}`)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const { active } = doc.data();

              const [device] = state.targetClassList.filter(
                (item) => item.timeText === { 1: '早上', 2: '晚上' }[active],
              );

              if (device) {
                dispatch({
                  type: 'SET_DEVICE',
                  payload: { id: device.deviceId, ...device },
                });
                dispatch({  // 測試離開畫面顯示判斷的變數
                  type: 'SET_LEAVING',
                  payload: { state: false },
                });
              } else {
                dispatch({
                  type: 'SET_DEVICE',
                  payload: { id: 'classroom.tp.edu.tw' },
                });
                dispatch({  // 測試離開畫面顯示判斷的變數
                  type: 'SET_LEAVING',
                  payload: { state: false },
                });
              }

              history.push('/main');
            }
          });
      });

    return () => unsubscribe();
  }, [state.targetClassList]);

  useEffect(() => {
    if (!state.codeValue) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/pubsubs/${state.codeValue}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          doc.ref.delete();

          switch (data.event) {
            case 'MANGER_IN': {
              const { accessToken } = data;

              window.localStorage.setItem('accessToken', accessToken);
              dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });

              history.push(`/manager`);
              return;
            }
            case 'SIGN_IN': {
              const { accessToken, roomId } = data;

              state.communication.postMessage(
                JSON.stringify({
                  action: 'SIGNIN',
                  data: { roomId },
                }),
              );

              window.localStorage.setItem('roomId', roomId);
              dispatch({ type: 'SET_ROOM_ID', payload: roomId });

              window.localStorage.setItem('accessToken', accessToken);
              dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });

              history.push(`/teach/true`);
              break;
            }
            case 'SAVE_DEVICE_ID': {
              // 123:345:567
              const { deviceId } = data;
              //console.log(data)
              state.communication.postMessage(
                JSON.stringify({
                  action: 'SAVE_DEVICE_ID',
                  data: { deviceId },
                }),
              );
              window.location.reload();
              break;
            }
            case 'REMOVE_DEVICE_ID': {
              // 123:345:567
              const { deviceId } = data;
              const key = state.deviceKey
                .split(':')
                .filter((item) => item !== deviceId);

              if (key.length > 0) {
                state.communication.postMessage(
                  JSON.stringify({
                    action: 'SAVE_DEVICE_ID',
                    data: { deviceId: key.join(':') },
                  }),
                );
              } else {
                state.communication.postMessage(
                  JSON.stringify({
                    action: 'REMOVE_DEVICE_ID',
                  }),
                );
              }

              window.location.reload();
              break;
            }
            default: {
              /** empty */
            }
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.communication, state.codeValue]);

  const chooserCallback = (data) => {
    window.localStorage.removeItem('tokenInfo');

    const { action, payload } = data;

    switch (action) {
      case 'MANGER_IN': {
        window.localStorage.setItem('accessToken', payload.accessToken);
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: payload.accessToken });

        history.push(`/manager`);
        return;
      }

      case 'SIGN_IN': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'SIGNIN',
            data: { roomId: payload.roomId },
          }),
        );

        window.localStorage.setItem('roomId', payload.roomId);
        dispatch({ type: 'SET_ROOM_ID', payload: payload.roomId });

        window.localStorage.setItem('accessToken', payload.accessToken);
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: payload.accessToken });

        history.push(`/teach/true`);
        return;
      }
      case 'EXIT_CHOOSER': {
        fetch('https://cooc.tp.edu.tw/api/auth/logout');
        window.location.replace(
          `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
        );
        break;
      }
      default: {
        /** empty */
      }
    }
  };

  const background = (docs) => {
    //console.log(docs)
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Broadcast />
          <Router history={history}>
            <Switch>
              <Route
                path="/chooser"
                children={<Chooser callback={chooserCallback} />}
              />
              <Route path="/main" children={<Main />} />
              <Route path="/manager" children={<Manager />} />
              <Route path="/teach/:first" children={<Teach />} />
            </Switch>
          </Router>
        </div>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
};

export default Component;
