import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chip, Fade, Typography } from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import cooctoy from '../../assets/cooctoy.png';
import cooctoy2 from '../../assets/cooctoy2.svg';
import { AppContext } from '../../App';
import envConfig from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //padding: '0 24px 24px 24px',
    borderWidth: 1,
    borderStyle: 'solid',
    //borderColor: 'rgba(0,0,0,.37)',
    borderColor: 'rgb(29, 183, 189)',
    borderRadius: theme.spacing(3),
    //backgroundColor: 'rgba(255,255,255,1)',
    backgroundColor: 'rgb(29, 183, 189)',
  },
  codeValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceId: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [codeValue, setCodeValue] = useState(
    Math.random().toString(10).substr(2, 6),
  );
  const [timestamp, setTimestamp] = useState(300);

  useEffect(() => {
    dispatch({ type: 'CODE_VALUE', payload: codeValue });

    let _timestamp = 300;
    //let _timestamp = 10;
    const interval = setInterval(() => {
      _timestamp -= 1;
      if (_timestamp === 0) {
        //console.log(codeValue)
        firebase.firestore().doc(`/curclasslist/${codeValue}`).delete();
        _timestamp = 300;
        //_timestamp = 10;
        const codeValue = Math.random().toString(10).substr(2, 6);
        setCodeValue(codeValue);
        dispatch({ type: 'CODE_VALUE', payload: codeValue });

        // dispatch({ type: 'CODE_VALUE', payload: codeValue });
        let roomList = JSON.parse(window.localStorage.getItem('List'));
        //console.log(roomList);
        // roomList.forEach((i) => {
        // })
        //console.log(state.targetClassList)
        //console.log(temp);
        let temp = {
          curClassroomList: roomList,
        };
        //console.log(codeValue);
        if (roomList) {
          firebase.firestore().doc(`/curclasslist/${codeValue}`).set(temp);
        }
      }

      setTimestamp(_timestamp);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //console.log(codeValue);
    dispatch({ type: 'CODE_VALUE', payload: codeValue });
    let roomList = [];
    if(state.targetClassList) {
      roomList = [].concat(state.targetClassList);
    } 
    //console.log(roomList);
    //console.log(state.targetClassList)
    let temp = {
      curClassroomList: roomList,
    };
    if (roomList) {
      firebase.firestore().doc(`/curclasslist/${codeValue}`).set(temp);
    }
  }, [codeValue]);

  useEffect(() => {
    if (!state.targetClassList) return;
    //console.log(codeValue);
    let roomList = [];
    if(state.targetClassList) {
      roomList = [].concat(state.targetClassList);
    } else {
      roomList = JSON.parse(window.localStorage.getItem('List'));
    }
    //console.log(roomList);
    //console.log(state.targetClassList)
    let temp = {
      curClassroomList: roomList,
    };
    if (roomList) {
      firebase.firestore().doc(`/curclasslist/${codeValue}`).set(temp);
    }
  },[state.targetClassList]);

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: '#f1f1f1',
            marginBottom: theme.spacing(2),
            borderRadius: '15px',
            backgroundColor: 'white',
            width: '260px',
            padding: '5px',
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent:'space-around'}}>
            <div style={{height:'10px'}}/>
            <img src={cooctoy2} width="70" height="70"/>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <Typography variant="h5" style={{ color: 'black' }}>
              歡迎來到酷課雲
            </Typography>
            <div style={{ height: '5px' }} />
            <Typography variant="body1" style={{ color: 'black' }}>
              請協助綁定班級教室
            </Typography>
            <Typography variant="body1" style={{ color: 'black' }}>
              開啟班級行事曆服務
            </Typography>
          </div>
        </div>
        {/* <Typography
          variant="h4"
          style={{ color: '#f1f1f1', marginBottom: theme.spacing(2) }}
        >
          班牌綁定
        </Typography> */}
        <div className={classes.content}>
          <div className={classes.signin}>
            <Typography
              variant="h5"
              style={{
                backgroundColor: '#1db7bd',
                color: 'white',
                padding: '10px',
              }}
            >
              綁定班牌
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '0px 0px 15px 15px',
                width: '250px',
              }}
            >
              <Typography variant="body1" style={{ color: '#1db7bd' }}>
                請使用酷課 App
              </Typography>
              <Typography variant="body1" style={{ color: '#1db7bd' }}>
                掃描 QRCode 進行綁定
              </Typography>
              <div style={{ height: theme.spacing(3) }} />
              <div>
                <div className={classes.codeValue}>
                  <QRCode
                    value={`https://cooc.tp.edu.tw/app_links/classroom?th=b&codeValue=${codeValue}`}
                    //value={`${envConfig.SERVER_URL}/bind/c/R/${codeValue}`}
                    size={220}
                    fgColor="#454545"
                    bgColor="transparent"
                  />
                  <div style={{ height: theme.spacing(2) }} />
                  <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
                    {codeValue}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;

// import React, { useContext, useEffect, useState } from 'react';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Chip, Fade, Typography } from '@material-ui/core';
// import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
// import QRCode from 'qrcode.react';

// import { AppContext } from '../../App';
// import envConfig from '../../config';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flex: 1,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   content: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   signin: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: theme.spacing(3),
//     borderWidth: 1,
//     borderStyle: 'solid',
//     borderColor: 'rgba(0,0,0,.37)',
//     borderRadius: theme.spacing(3),
//     backgroundColor: 'rgba(255,255,255,1)',
//   },
//   codeValue: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   deviceId: {
//     display: 'flex',
//     flexDirection: 'column',
//     padding: theme.spacing(3, 3, 1),
//     margin: theme.spacing(1),
//     borderRadius: theme.spacing(1),
//   },
// }));

// const Component = () => {
//   const classes = useStyles();
//   const theme = useTheme();
//   const { state, dispatch } = useContext(AppContext);
//   const [codeValue, setCodeValue] = useState(
//     Math.random().toString(10).substr(2, 6),
//   );
//   const [timestamp, setTimestamp] = useState(300);

//   useEffect(() => {
//     dispatch({ type: 'CODE_VALUE', payload: codeValue });

//     let _timestamp = 300;
//     const interval = setInterval(() => {
//       _timestamp -= 1;
//       if (_timestamp === 0) {
//         _timestamp = 300;

//         const codeValue = Math.random().toString(10).substr(2, 6);

//         setCodeValue(codeValue);
//         dispatch({ type: 'CODE_VALUE', payload: codeValue });
//       }

//       setTimestamp(_timestamp);
//     }, 1000);

//     return () => clearInterval(interval);
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <Fade in timeout={1000}>
//       <div className={classes.root}>
//         <Typography
//           variant="h4"
//           style={{ color: '#f1f1f1', marginBottom: theme.spacing(2) }}
//         >
//           課堂互動工具
//         </Typography>
//         <div className={classes.content}>
//           <div className={classes.signin}>
//             <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
//               請使用臺北親師生 App
//             </Typography>
//             <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
//               掃描 QRCode 或輸入代碼登入
//             </Typography>
//             <div style={{ height: theme.spacing(3) }} />
//             <Fade in={timestamp > 2} timeout={2000}>
//               <div className={classes.codeValue}>
//                 <QRCode
//                   value={`${envConfig.SERVER_URL}/c/R/${codeValue}`}
//                   size={220}
//                   fgColor="#454545"
//                   bgColor="transparent"
//                 />
//                 <div style={{ height: theme.spacing(2) }} />
//                 <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
//                   {codeValue}
//                 </Typography>
//               </div>
//             </Fade>
//             {state.container.type === 'chrome' && (
//               <Chip
//                 label="使用帳號登入"
//                 icon={<AccountIcon style={{ color: 'rgba(0,0,0,.54)' }} />}
//                 variant="outlined"
//                 style={{
//                   color: 'rgba(0,0,0,.54)',
//                   borderColor: 'rgba(0,0,0,.54)',
//                   marginTop: theme.spacing(3),
//                 }}
//                 onClick={() => window.location.replace(envConfig.AUTHORIZE_URL)}
//               />
//             )}
//           </div>
//         </div>
//       </div>
//     </Fade>
//   );
// };

// export default Component;
