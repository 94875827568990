import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  Close as UncheckIcon,
  Done as CheckIcon,
  HighlightOffOutlined as CloseIcon,
  ThumbUpOutlined as ScoreIcon,
} from '@material-ui/icons';

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../../config.js'
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  scoreIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  contentsw: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  students: {
    overflow: 'auto',
    minWidth: 200,
  },
  chart: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  uncheckAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
  buttons1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  buttons2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

let temptargetOption = 
{students:[
  {
    ClassName: "五年三班",
    DSNS: true,
    ID: "YA96106024",
    Name: "連子忠",
    SeatNo: 4,
    StudentNumber: "106002"},
    {
      ClassName: "五年三班",
      DSNS: true,
      ID: "YA96106024",
      Name: "連子忠",
      SeatNo: 4,
      StudentNumber: "106002"},
      {
        ClassName: "五年三班",
        DSNS: true,
        ID: "YA96106024",
        Name: "連子忠",
        SeatNo: 4,
        StudentNumber: "106002"},
        {
          ClassName: "五年三班",
          DSNS: true,
          ID: "YA96106024",
          Name: "連子忠",
          SeatNo: 4,
          StudentNumber: "106002"},
          {
            ClassName: "五年三班",
            DSNS: true,
            ID: "YA96106024",
            Name: "連子忠",
            SeatNo: 4,
            StudentNumber: "106002"},
            {
              ClassName: "五年三班",
              DSNS: true,
              ID: "YA96106024",
              Name: "連子忠",
              SeatNo: 4,
              StudentNumber: "106002"},
              {
                ClassName: "五年三班",
                DSNS: true,
                ID: "YA96106024",
                Name: "連子忠",
                SeatNo: 4,
                StudentNumber: "106002"},
                {
                  ClassName: "五年三班",
                  DSNS: true,
                  ID: "YA96106024",
                  Name: "連子忠",
                  SeatNo: 4,
                  StudentNumber: "106002"},
                  {
                    ClassName: "五年三班",
                    DSNS: true,
                    ID: "YA96106024",
                    Name: "連子忠",
                    SeatNo: 4,
                    StudentNumber: "106002"},
                    {
                      ClassName: "五年三班",
                      DSNS: true,
                      ID: "YA96106024",
                      Name: "連子忠",
                      SeatNo: 4,
                      StudentNumber: "106002"},
  {
  ClassName: "五年三班",
  DSNS: true,
  ID: "YA96106024",
  Name: "連子忠",
  SeatNo: 4,
  StudentNumber: "106002"},
  {
    ClassName: "五年三班",
    DSNS: true,
    ID: "YA96106024",
    Name: "連子忠",
    SeatNo: 4,
    StudentNumber: "106002"},
    {
      ClassName: "五年三班",
      DSNS: true,
      ID: "YA96106024",
      Name: "連子忠",
      SeatNo: 4,
      StudentNumber: "106002"},
      {
        ClassName: "五年三班",
        DSNS: true,
        ID: "YA96106024",
        Name: "連子忠",
        SeatNo: 4,
        StudentNumber: "106002"},
        {
          ClassName: "五年三班",
          DSNS: true,
          ID: "YA96106024",
          Name: "連子忠",
          SeatNo: 4,
          StudentNumber: "106002"},
          {
            ClassName: "五年三班",
            DSNS: true,
            ID: "YA96106024",
            Name: "連子忠",
            SeatNo: 4,
            StudentNumber: "106002"},
            {
              ClassName: "五年三班",
              DSNS: true,
              ID: "YA96106024",
              Name: "連子忠",
              SeatNo: 4,
              StudentNumber: "106002"},
              {
                ClassName: "五年三班",
                DSNS: true,
                ID: "YA96106024",
                Name: "連子忠",
                SeatNo: 4,
                StudentNumber: "106002"},
                {
                  ClassName: "五年三班",
                  DSNS: true,
                  ID: "YA96106024",
                  Name: "連子忠",
                  SeatNo: 4,
                  StudentNumber: "106002"},
                  {
                    ClassName: "五年三班",
                    DSNS: true,
                    ID: "YA96106024",
                    Name: "連子忠",
                    SeatNo: 4,
                    StudentNumber: "106002"},

]}

const dbUrl = envConfig.SERVER_URL;
let startCount = false;

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [result, setResult] = useState({});
  const [scores, setScores] = useState({});
  const [status, setStatus] = useState('');
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [correct, setCorrect] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [timer, setTimer] = useState(0);
  const [optionsList, setOptionsList] = useState([]);
  const [optionStudentsView, setOptionStudentsView] = useState(false);
  const [targetOption, setTargetOption] = useState({});

  useEffect(() => {
    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();
      
          doMakeOptionList(item);
          setResult(item);
          setNumber(item.number);
          if(!startCount){
            if (item.time && item.time > 0) {
              let _time = item.time;
              const _timer = setInterval(() => {
                startCount = true;
                if (_time === 0) {
                  firebase
                    .firestore()
                    .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
                    .update({
                      status: 'close',
                      time: firebase.firestore.FieldValue.delete(),
                      timestamp: Date.now(),
                    });
  
                  return clearInterval(_timer);
                }
                _time = _time - 1;
                setCurrentTime(_time);
              }, 1000);
              setTimer(_timer);
            }
          }
          // if (item.time && item.time > 0) {
          //   let _time = item.time;
          //   const _timer = setInterval(() => {
          //     if (_time === 0) {
          //       firebase
          //         .firestore()
          //         .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
          //         .update({
          //           status: 'close',
          //           time: firebase.firestore.FieldValue.delete(),
          //           timestamp: Date.now(),
          //         });

          //       return clearInterval(_timer);
          //     }

          //     _time = _time - 1;
          //     setCurrentTime(_time);
          //   }, 1000);
          //   setTimer(_timer);
          // }

          const data = Array.from({ length: item.number }).map(() => 0);
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              data[item[key].content - 1] = data[item[key].content - 1] + 1;
            }
          });
      
          const labels = Array.from({ length: item.number }).map(() => '');
          Array.from({ length: item.number }).forEach((i, idx) => {
            labels[idx] = `第 ${idx + 1} 項 ${data[idx]} 票`;
          });

          var ctx = (document.getElementById('chart') || {}).getContext('2d');

          new Chart(ctx, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{ data }],
            },
            options: {
              responsive: true,
              legend: {
                position: 'right',
                labels: { fontSize: 14, fontColor: '#f1f1f1' },
              },
              layout: {
                padding: {
                  left: theme.spacing(2),
                  right: theme.spacing(2),
                  top: theme.spacing(2),
                  bottom: theme.spacing(2),
                },
              },
              tooltips: { enabled: false },
              plugins: {
                colorschemes: {
                  scheme: Tableau10,
                },
              },
            },
          });

          if(item.target && item.target.state === 'open'){
            // console.log('open list');
            // console.log(item.target);
            setTargetOption(item.target);
            setOptionStudentsView(true);
          } else {
            setOptionStudentsView(false);
            setTargetOption(JSON.parse(JSON.stringify([])));
          }

          setStatus(item.status);
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setScores(doc.data());
        }
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
      clearInterval(timer);

      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
        .update({
          status: 'close',
          time: firebase.firestore.FieldValue.delete(),
          timestamp: Date.now(),
        });
    };
    // eslint-disable-next-line
  }, []);

  const giveScore = () => {
    const data = {
      timestamp: Date.now(),
    };

    Object.keys(result).forEach((key) => {
      if (result[key].content === correct) {
        const score = scores[key] || 0;
        data[key] = score + 1;
      }
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(data);

    setOpen(false);
  };

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
      .update({
        status: status === 'open' ? 'close' : 'open',
        time: firebase.firestore.FieldValue.delete(),
      });

    setCurrentTime(0);
    clearInterval(timer);
  };

  const upgradeTeacher = () => {
    let targetTeacher = {
      quiz_id: result.timestamp,
      room_id: state.target.classroom.uid ? '' + state.target.classroom.uid : '',
      teacher_identity: state.myInfo.id_number ? state.myInfo.id_number : '',
      call_component:'mobile/src/components/teach/content/interactive'
    }
    //console.log(targetTeacher);
    fetch(dbUrl.concat('/classroom/addTeacherQRec'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetTeacher)
    })

    callback();
  };

  const doMakeOptionList = (item) => {
    //console.log(item);
    let tempList = [];
      for(let i=1; i < item.number+1; i++) {
        //console.log(i);
        let baseObj = {
          id: i,
          students:[],
        }
        tempList.push(baseObj);
      } 
      //console.log(tempList);
      if(state.students){
        state.students.map((student) => {
          item[`student_${student.ID}`] ? tempList[item[`student_${student.ID}`].content -1].students.push(student) : console.log('no student');
        });
      }
      setOptionsList([].concat(tempList));
  }

  const doOpenStuListDialog = (option) => {
    let temp = option
    temp.state = 'open'
    firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
        .update({
          target: temp,
        });
  }

  const optionStudentsDialog = () =>
  optionStudentsView && (
    <Dialog
      open={optionStudentsView}
      disableBackdropClick
      onClose={() => setOptionStudentsView(false)}
    >
      <DialogTitle>投選項{targetOption.id}的人</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'center'}}>
        <List>
            {targetOption.students.map((student) => (
              <ListItem key={`student-${student.ID}`}>
                <ListItemText primary={`${student.SeatNo} ${student.Name}`} />
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>
      <div style={{display: 'flex', flexDirection:'row',justifyContent: 'center'}}>
        <DialogActions>
            <Button onClick={() => {
              let temp = {
                id:0,
                state: 'close',
                students: [],
              }
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
                .update({
                  target: temp
                });
            }} color="primary" autoFocus>
              確定
            </Button>
        </DialogActions>
      </div>
    </Dialog>
  );

  // return (
  //   <div className={classes.root}>
  //     <Dialog open={open} onClose={() => setOpen(false)}>
  //       <Stepper activeStep={activeStep} orientation="vertical">
  //         <Step key="step-1">
  //           <StepLabel>請選擇正確答案</StepLabel>
  //           <StepContent>
  //             <div className={classes.buttons1}>
  //               {Array.from({ length: number }).map((_, idx) => (
  //                 <Button
  //                   key={`ans-${idx}`}
  //                   variant="contained"
  //                   color="primary"
  //                   style={{ margin: theme.spacing(0.5) }}
  //                   onClick={() => {
  //                     setCorrect(idx + 1);
  //                     setActiveStep(activeStep + 1);
  //                   }}
  //                 >
  //                   {idx + 1}
  //                 </Button>
  //               ))}
  //             </div>
  //           </StepContent>
  //         </Step>
  //         <Step key="step-2">
  //           <StepLabel>確定回答 {correct} 的學生加 1 分？</StepLabel>
  //           <StepContent>
  //             <div className={classes.buttons2}>
  //               <Button
  //                 color="primary"
  //                 onClick={() => setActiveStep(activeStep - 1)}
  //               >
  //                 上一步
  //               </Button>
  //               <Button color="primary" onClick={giveScore}>
  //                 確定
  //               </Button>
  //             </div>
  //           </StepContent>
  //         </Step>
  //       </Stepper>
  //     </Dialog>
  //     <div className={classes.header}>
  //       <Typography variant="h4" className={classes.caption}>
  //         快問快答
  //       </Typography>
  //       {currentTime > 0 && (
  //         <Typography
  //           variant="h4"
  //           className={classes.caption}
  //           style={{ marginLeft: 40 }}
  //         >
  //           倒數 {currentTime} 秒
  //         </Typography>
  //       )}
  //       <div style={{ flex: 1 }} />
  //       <Tooltip title="學生加分">
  //         <IconButton
  //           onClick={() => {
  //             setActiveStep(0);
  //             setOpen(true);
  //           }}
  //         >
  //           <ScoreIcon className={classes.scoreIcon} />
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title={status === 'open' ? '開啟互動' : '關閉互動'}>
  //         <IconButton onClick={toggleStatus}>
  //           {status === 'open' ? (
  //             <AlarmOnIcon className={classes.statusIcon} />
  //           ) : (
  //             <AlarmOffIcon className={classes.statusIcon} />
  //           )}
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title="關閉">
  //         <IconButton onClick={callback, upgradeTeacher}>
  //           <CloseIcon className={classes.closeIcon} />
  //         </IconButton>
  //       </Tooltip>
  //     </div>
  //     <div className={classes.content}>
  //       <Paper className={classes.students}>
  //         <List>
  //           {state.students.map((student) => (
  //             <ListItem key={`student-${student.ID}`}>
  //               <ListItemAvatar>
  //                 <Avatar
  //                   className={
  //                     result[`student_${student.ID}`]
  //                       ? classes.checkAvatar
  //                       : classes.uncheckAvatar
  //                   }
  //                 >
  //                   {result[`student_${student.ID}`] ? (
  //                     <CheckIcon />
  //                   ) : (
  //                     <UncheckIcon />
  //                   )}
  //                 </Avatar>
  //               </ListItemAvatar>
  //               <ListItemText primary={`${student.SeatNo} ${student.Name}`} />
  //             </ListItem>
  //           ))}
  //         </List>
  //       </Paper>
  //       <div className={classes.chart}>
  //         <canvas id="chart"></canvas>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div className={classes.root}>
      {optionStudentsDialog()}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="step-1">
            <StepLabel>請選擇正確答案</StepLabel>
            <StepContent>
              <div className={classes.buttons1}>
                {Array.from({ length: number }).map((_, idx) => (
                  <Button
                    key={`ans-${idx}`}
                    variant="contained"
                    color="primary"
                    style={{ margin: theme.spacing(0.5) }}
                    onClick={() => {
                      setCorrect(idx + 1);
                      setActiveStep(activeStep + 1);
                    }}
                  >
                    {idx + 1}
                  </Button>
                ))}
              </div>
            </StepContent>
          </Step>
          <Step key="step-2">
            <StepLabel>確定回答 {correct} 的學生加 1 分？</StepLabel>
            <StepContent>
              <div className={classes.buttons2}>
                <Button
                  color="primary"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  上一步
                </Button>
                <Button color="primary" onClick={giveScore}>
                  確定
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </Dialog>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          快問快答
        </Typography>
        {currentTime > 0 && (
          <Typography
            variant="h4"
            className={classes.caption}
            style={{ marginLeft: 40 }}
          >
            倒數 {currentTime} 秒
          </Typography>
        )}
        <div style={{ flex: 1 }} />
      </div>
      <div className={classes.content}>
        {/* <Paper className={classes.students}>
          <List>
            {state.students.map((student) => (
              <ListItem key={`student-${student.ID}`}>
                <ListItemAvatar>
                  <Avatar
                    className={
                      result[`student_${student.ID}`]
                        ? classes.checkAvatar
                        : classes.uncheckAvatar
                    }
                  >
                    {result[`student_${student.ID}`] ? (
                      <CheckIcon />
                    ) : (
                      <UncheckIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${student.SeatNo} ${student.Name}`} />
              </ListItem>
            ))}
          </List>
        </Paper> */}
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'auto', minWidth: '200px',padding: '20px'}}>
          {optionsList.map((option) => (
            <Button 
              key={option.id}
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding:'10px', marginBottom: '20px', backgroundColor:'white'}}
              onClick={() => {
                // console.log(option);
                // setTargetOption(option);
                // setOptionStudentsView(true);
                doOpenStuListDialog(option);
              }}
            >
              <Typography variant="h4">選項 {option.id}</Typography>
              <Typography variant="h4" style={{marginRight: '5px', marginLeft: '5px'}}>:</Typography>
              <Typography variant="h4">{option.students.length}</Typography>
            </Button>
          ))}
        </div>
        <div className={classes.chart}>
          <canvas id="chart"></canvas>
        </div>
          <div className={classes.contentsw}>           
            <Tooltip title="學生加分">
              <IconButton
                onClick={() => {
                  setActiveStep(0);
                  setOpen(true);
                }}
              >
                {/* <ScoreIcon className={classes.scoreIcon} /> */}
                <Button 
                  variant="contained"
                  color="primary"
                >
                  給學生加分
                </Button>
              </IconButton>
            </Tooltip>
            <Tooltip title={status === 'open' ? '開啟互動' : '關閉互動'}>
              <IconButton onClick={toggleStatus}>
                {status === 'open' ? (
                  <Button 
                    variant="contained"
                    color="primary"
                  >
                    開啟互動中
                  </Button>
                ) : (
                  <Button 
                    variant="contained"
                    color="secondary"
                  >
                    已關閉互動
                  </Button>
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="關閉">
              <IconButton onClick={upgradeTeacher}>
                <Button 
                  variant="contained"
                  color="primary"
                >
                  回到主畫面
                </Button>
              </IconButton>
            </Tooltip>
          </div> 
      </div>
    </div>
  );
};

export default Component;
