import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import {
  Paper,
  Typography,
  Tabs,
  Tab,
  FormControl,
  RadioGroup,
  Button,
  FormControlLabel,
  Radio,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ControlPoint as ControlPointIcon,
  CheckCircle as CheckCircleIcon,
  Refresh as RefreshIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import Close from '../../assets/Close.png';
import Edit from '../../assets/edit.png';
import refresh from '../../assets/refresh.svg';
import moment from 'moment';
import Editundone from './subItem/editundone';
import envConfig from '../../config';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    borderRadius: '15px',
    marginTop: '-20px',
  },
  indicator: {
    backgroundColor: '#1db7bd',
    maxWidth: '105px',
    width: '100%',
    marginLeft: '20px',
    opacity: '0.6',
    paddingBottom: '4px',
    marginBottom: '15px',
  },
  todayList: {
    display: 'flex',
    flexDirection: 'row',
    width: '510px',
    borderRadius: '10px',
    //marginLeft: theme.spacing(2),
    backgroundColor: 'rgba(255,155,63,0.12)',
  },
  todayList2: {
    display: 'flex',
    flexDirection: 'row',
    width: '490px',
    borderRadius: '10px',
    //marginLeft: theme.spacing(2),
    backgroundColor: '#f6f6f6',
  },
  seatNoI: {
    color: 'white',
    marginTop: '-3.9px',
    marginLeft: '-1.2px',
  },
  seatNoO: {
    display: 'block',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: '6px solid #1db7bd',
    backgroundColor: '#1db7bd',
    marginLeft: '12px',
    marginTop: '12px',
  },
  numberArea: {
    display: 'flex',
    flexDirection: 'row',
    //flexWrap: 'wrap',
    padding: '5px',
    width: '46%',
    //overflow:'hidden',
  },
  numberArea1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '280px',
    marginTop: '13px',
    marginLeft: '80px',
  },
  crossO: {
    display: 'block',
    height: '0px',
    width: '0px',
    borderRadius: '50%',
    border: '6px solid #ff4438',
    backgroundColor: '#ff4438',
    marginLeft: '29px',
    marginTop: '-32px',
  },
  crossI: {
    width: '7px',
    height: '7px',
    display: 'block',
    marginLeft: '-3px',
    marginTop: '-4px',
  },
  edit: {
    width: '25px',
    height: '25px',
    //marginTop: '4px',
  },
  editIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '-21px',
    marginTop: '4px',
  },
  circle: {
    display: 'block',
    height: '17px',
    width: '17px',
    borderRadius: '50%',
    border: '2px solid #615e5e',
    backgroundColor: 'white',
    marginLeft: '20px',
  },
  formControlLabelTitle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '40%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'blcak !important',
  },
  formControlLabelTitle2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    //width:'40%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'blcak !important',
  },
  dialogStyle: {
    borderRadius: 20,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: '120px',
    height: '30px',
    frontSize: '15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

const RefreshButton = withStyles((theme) => ({
  root: {
    width: '100px',
    height: '30px',
    frontSize: '15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

//const tempList = [];
const dbUrl = envConfig.SERVER_URL;
const mathHWL = ['03', '04', '13', '15', '17', '21', '36', '39'];
const takePen = ['05', '06', '22'];
let confirmClick = 0;
const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [value, setValue] = useState(0);

  const [schoolCode] = useState(window.localStorage.getItem('schoolCode'));

  const [ClassID, setClassID] = useState('');

  const [titleValue, setTitleValue] = useState(0);
  const [curValue, setCurValue] = useState(0);
  const [onDutyList, setOnDutyList] = useState([]); // 存放確認中的任務
  const [finishList, setFinishList] = useState([]); // 存放已完成的任務
  const [rendeList, setRenderList] = useState([]);
  const [cancelProcess, setCancelProcess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stuListAlert, setStuListAlert] = useState(false);
  const [curItem, setCurItem] = useState([]); // 設定當前選定的項目（目前用於：編輯學生）

  const [curIndex, setCurIndex] = useState(-1); // 設定當前選定項目的index（目前用於：編輯學生）

  const [oriRenderList, setOriRenderList] = useState([]);
  const [accomplishList, setAccomplishList] = useState([]);

  const [tranferAccomplishDialog, setTranferAccomplishDialog] = useState(false);
  const [recoveryDialog, setRecoveryDialog] = useState(false);

  const [viewState, setViewState] = useState(true); // 切換目前是查看「確認中的任務」(true)還是「完成的任務」(false)

  const [saveAlert, setSaveAlert] = useState(false);

  const [targetClass, setTargetClass] = useState();

  const [localdeviceId, setLocaldeviceId] = useState('沒有');

  const [unTranferDialog, setUnTranferDialog] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [curMS, setcurMS] = useState('00:00');

  useEffect(() => {
    if(!state.changeToday) return;
    doRefresh();
  },[state.changeToday]);

  useEffect(() => {
    if (!state.device && !state.device.ClassID) return;
    
    let tdn = moment().valueOf();
    let tdnt = moment(tdn).format('YYYY/MM/DD');
    setClassID(state.device.finalClassID)

    let tempTargetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      date: tdnt,
      call_component:'teacher/src/components/main/today',
    };
    setTargetClass(tempTargetClass2);

    let targetClass2Json = JSON.stringify(tempTargetClass2);
      
    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          let temp = finalData.filter((i) => i.category !== '通知事項' && !i.isCancel);
          temp.forEach((i) => {
            switch (i.category) {
              case '作業考試':
                i.prioritize = 2;
                break;
              case '攜帶物品':
                i.prioritize = 3;
                break;
              default:
                break;
            }
          });
          temp.sort((a, b) => {
            if (a.prioritize < b.prioritize) {
              return -1;
            }
            if (a.prioritize > b.prioritize) {
              return 1;
            }
            return 0;
          });
          doFiliterUnDone(temp);
        });
    } catch (error) {
      console.log(error);
    }


    // try {
    //   fetch(dbUrl.concat('/classroom/getNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(tempTargetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       console.log(r);
    //       let temp = r.filter((i) => i.category !== '通知事項' && !i.isCancel);
    //       temp.forEach((i) => {
    //         switch (i.category) {
    //           case '作業考試':
    //             i.prioritize = 2;
    //             break;
    //           case '攜帶物品':
    //             i.prioritize = 3;
    //             break;
    //           default:
    //             break;
    //         }
    //       });
    //       temp.sort((a, b) => {
    //         if (a.prioritize < b.prioritize) {
    //           return -1;
    //         }
    //         if (a.prioritize > b.prioritize) {
    //           return 1;
    //         }
    //         return 0;
    //       });
    //       console.log(temp)
    //       doFiliterUnDone(temp);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }

  }, [state.device]);

  useEffect(() => {
    if (!state.device) return;
    if (state.device.ClassID) {
      setClassID(state.device.finalClassID)
      doRefresh();
    }
  }, [state.device]);

  useEffect(() => {
    if (stuListAlert || confirmClick > 0) return;
    if (rendeList.length > 0) {
      doFiliterUnDone(rendeList.concat(accomplishList));
      let rendeListJson = JSON.stringify(rendeList[curIndex]);
      const ciphertext = CryptoJS.AES.encrypt(rendeListJson, ajhslkdfhk, {iv: hakjlsdhfk});
      const encryptedData = ciphertext.toString();
      let tempBody = {body: encryptedData}

      try {
        fetch(dbUrl.concat('/classroom/updateNotificationCry'), {
          method: 'POST',
          body: JSON.stringify(tempBody),
          headers: {
            'content-type': 'application/json',
          },
        }).then(
          r => r.json()
        ).then((r) => console.log(r))
      } catch (error) {
        console.log(error)
      }

      // try {
      //   fetch(dbUrl.concat('/classroom/updateNotification'), {
      //     method: 'POST',
      //     body: JSON.stringify(rendeList[curIndex]),
      //     headers: {
      //       'content-type': 'application/json',
      //     },
      //   })
      //     .then((r) => 
      //     console.log(r)
      //     )
      // } catch (error) {
      //   console.log(error)
      // }
    }
  }, [stuListAlert]);

  const doFiliterList = (item, accomplishList) => {
    //let temp = item.concat(accomplishList);
    let temp = [].concat(item);
    let tempD = [];
    let tempF = [];
    temp.forEach((i) => {
      if (!i.isCancel) {
        i.isAccomplish ? tempD.push(i) : tempF.push(i);
      }
    });
    setAccomplishList(tempD);
    setRenderList(tempF);
  };

  const doFiliterUnDone = (item) => {
    let temp = [].concat(item);
    //console.log(temp);
    temp.forEach((i) => {
      let undoneNum = 0;
      i.student.forEach((stu) => {
        if (stu.undone) {
          undoneNum++;
        }
        if (undoneNum > 0) {
          i.bothDone = false;
        }
      });
      if (undoneNum === 0) {
        i.bothDone = true;
      }
      i.undoneNum = undoneNum;
    });
    //console.log(temp);
    //setRenderList([].concat(temp));
    doFiliterList([].concat(temp));
    //console.log(accomplishList)
  };

  const handleChange = (event) => {
    let tempList = rendeList;

    if (saving) return;
    setSaving(true);

    if (!event.target.value) {
      setSaving(false);
      return;
    }

    if (cancelProcess) {
      setSaving(false);
      setCancelProcess(false);
      return;
    }

    let targetIndex = tempList.findIndex(
      (rd) => rd.id === parseInt(event.target.value),
    );

    // 這邊判斷人數未全部完成，跳出提示
    if (tempList[targetIndex].undoneNum > 0) {
      setUnTranferDialog(true);
      setSaving(false);
      return;
    }

    // 原先為了不要連續點到，但會跟後面起衝突，所以要做些調整
    // if(curValue !== event.target.value) {
    //   setValue(parseInt(event.target.value));
    //   setCurValue(event.target.value);
    // } else {
    //   setValue(-1);
    //   setCurValue(-1);
    // }
    setCurIndex(targetIndex);
    setValue(parseInt(event.target.value));
    setCurValue(event.target.value);

    setTranferAccomplishDialog(true);
    setSaving(false);
  };

  const handleTitleChange = (event, newValue) => {
    if (newValue === titleValue) return;
    setTitleValue(newValue);
  };

  const doTodayItemEdit = (item, index) => {
    if (saving) return;
    confirmClick = 0;    
    setSaving(true);
    const tempDoEditItem = [].concat(item);
    setCurItem([].concat(tempDoEditItem));

    // 測試1先快照一個編輯前的清單(無效,一樣會被覆蓋)
    let tempRen = rendeList;
    setOriRenderList([].concat(tempRen));

    //測試2改用額外建立component，並用dispatch傳遞資料(無效,一樣會被覆蓋)
    // dispatch({ type: 'SET_EDITUNDONE',
    //     payload: tempDoEditItem
    //   })

    setCurIndex(index);
    setStuListAlert(true);
    setSaveAlert(true);
    setSaving(false);
  };

  const doCancelUndo = (index, sidx) => {
    setCancelProcess(true);
    let tempCancel = rendeList;
    tempCancel[index].student[sidx].undone = false;
    tempCancel[index].undoneNum = tempCancel[index].undoneNum - 1;
    setRenderList([].concat(tempCancel));

    let tempCancelJson = JSON.stringify(tempCancel[index]);
      const ciphertext = CryptoJS.AES.encrypt(tempCancelJson, ajhslkdfhk, {iv: hakjlsdhfk});
      const encryptedData = ciphertext.toString();
      let tempBody = {body: encryptedData}

      try {
        fetch(dbUrl.concat('/classroom/updateNotificationCry'), {
          method: 'POST',
          body: JSON.stringify(tempBody),
          headers: {
            'content-type': 'application/json',
          },
        }).then(
          r => r.json()
        ).then((r) => console.log(r))
      } catch (error) {
        console.log(error)
      }

    // try {
    //   fetch(dbUrl.concat('/classroom/updateNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(tempCancel[index]),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       //console.log(r);
    //     });
    // } catch (error) {
    //   console.log(error)
    // }
  };

  const renderSaveDataAlertClose = () => {
    confirmClick = 1;
    doFiliterUnDone(rendeList.concat(accomplishList));
    //console.log(curIndex);
    setStuListAlert(false);
    let rendeListJson = JSON.stringify(rendeList[curIndex]);
      const ciphertext = CryptoJS.AES.encrypt(rendeListJson, ajhslkdfhk, {iv: hakjlsdhfk});
      const encryptedData = ciphertext.toString();
      let tempBody = {body: encryptedData}

      try {
        fetch(dbUrl.concat('/classroom/updateNotificationCry'), {
          method: 'POST',
          body: JSON.stringify(tempBody),
          headers: {
            'content-type': 'application/json',
          },
        }).then(
          r => r.json()
        ).then((r) => console.log(r))
      } catch (error) {
        console.log(error)
      }
    
    // try {
    //   fetch(dbUrl.concat('/classroom/updateNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(rendeList[curIndex]),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       //console.log(r);
    //     });
    // } catch (error) {
    //   console.log(error)
    // }
  };

  const renderNoneDataAlertClose = () => {
    //當沒儲存時還原快照
    //console.log(oriRenderList);
    let oriRL = oriRenderList;
    doFiliterUnDone(rendeList.concat(accomplishList));
    //setRenderList([].concat(oriRL));
    //console.log(oriRenderList);
    //當沒儲存時還原快照
    // let arr = []
    // setCurItem([].concat(arr));
    // setCurIndex(-1);
    setStuListAlert(false);
  };

  const doCheckStu = (index) => {
    //console.log(rendeList);
    let tempRendeList = rendeList;
    let tempCheck = [].concat(curItem);
    if (tempCheck[0].student[index].undone) {
      tempCheck[0].student[index].undone = false;
      tempRendeList[curIndex].undoneNum = tempRendeList[curIndex].undoneNum - 1;
      //if()
    } else {
      tempCheck[0].student[index].undone = true;
    }
    setCurItem([].concat(tempCheck));
  };

  const doTransferAC = () => {
    let oriTempList = rendeList;
    let tempAccomplishList = accomplishList;
    let targetItem = oriTempList[curIndex];
    //targetItem.accomplish = true;
    targetItem.isAccomplish = true;
    tempAccomplishList.unshift(targetItem);
    //console.log(tempAccomplishList);
    //console.log(targetItem);
    setAccomplishList([].concat(tempAccomplishList)); // 將目標項目移至完成清單中
    //let reduceTemplist = oriTempList.splice(curIndex,1);
    oriTempList.splice(curIndex, 1);
    setRenderList([].concat(oriTempList));
    setTranferAccomplishDialog(false);

    let targetItemJson = JSON.stringify(targetItem);
    const ciphertext = CryptoJS.AES.encrypt(targetItemJson, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/updateNotificationCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      }).then(
        r => r.json()
      ).then((r) => console.log(r))
    } catch (error) {
      console.log(error)
    }


    // try {
    //   fetch(dbUrl.concat('/classroom/updateNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetItem),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   }).then((r) => {
    //   //console.log(r)
    //   });
    // } catch (error) {
    //   console.log(error)
    // }
  };

  const clickRecovery = (accitem, accindex) => {
    setCurItem([].concat(accitem));
    setCurIndex(accindex);
    setRecoveryDialog(true);
  };

  const doRecovery = () => {
    let tempAccomplishList = accomplishList;
    let tempRendeList = rendeList;
    let tempTarget = curItem;
    //tempTarget[curIndex].isAccomplish = false;
    tempTarget[0].isAccomplish = false;

    let tempTargetJson = JSON.stringify(tempTarget[curIndex]);
      const ciphertext = CryptoJS.AES.encrypt(tempTargetJson, ajhslkdfhk, {iv: hakjlsdhfk});
      const encryptedData = ciphertext.toString();
      let tempBody = {body: encryptedData}

      try {
        fetch(dbUrl.concat('/classroom/updateNotificationCry'), {
          method: 'POST',
          body: JSON.stringify(tempBody),
          headers: {
            'content-type': 'application/json',
          },
        }).then(
          r => r.json()
        ).then((r) => console.log(r))
      } catch (error) {
        console.log(error)
      }


    // try {
    //   fetch(dbUrl.concat('/classroom/updateNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(tempTarget[curIndex]),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   }).then((r) => {
    //     //console.log(r)
    //     });
    // } catch (error) {
    //   console.log(error)
    // }

    setAccomplishList([].concat(tempAccomplishList.splice(curIndex, 1)));

    if (tempTarget[0].category === '作業考試') {
      tempRendeList.unshift(tempTarget[0]);
    } else {
      tempRendeList.push(tempTarget[0]);
    }
    setRenderList([].concat(tempRendeList));
    //console.log(accomplishList);
    setAccomplishList([].concat(accomplishList));

    setRecoveryDialog(false);
  };

  const doRefresh = () => {
    if (refreshing) return;
    setRefreshing(true);
    // setRenderList([].concat(JSON.parse(JSON.stringify([]))));
    // setAccomplishList([].concat(JSON.parse(JSON.stringify([]))));
    setRenderList([].concat([]));
    setAccomplishList([].concat());
    let tdn = moment().valueOf();
    let tdnt = moment(tdn).format('YYYY/MM/DD');
    let rtime = moment().tz('Asia/Taipei').format('HH:mm');
    setcurMS(rtime)
    //console.log(rtime)
    let tempTargetClass = {
      class_id: state.device.ClassID,
      date: tdnt,
      call_component:'teacher/src/components/main/today',
    };

    let tempTargetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      date: tdnt,
      //date: '2023/03/10',
      call_component:'teacher/src/components/main/today',
    };
    setTargetClass(tempTargetClass2);

    let targetClass2Json = JSON.stringify(tempTargetClass2);
      
    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          let temp = finalData.filter((i) => i.category !== '通知事項' && !i.isCancel);
          temp.forEach((i) => {
            switch (i.category) {
              case '作業考試':
                i.prioritize = 2;
                break;
              case '攜帶物品':
                i.prioritize = 3;
                break;
              default:
                break;
            }
          });
          temp.sort((a, b) => {
            if (a.prioritize < b.prioritize) {
              return -1;
            }
            if (a.prioritize > b.prioritize) {
              return 1;
            }
            return 0;
          });
          doFiliterUnDone(temp);
          setRefreshing(false);
        });
    } catch (error) {
      console.log(error);
    }

    // try {
    //   fetch(dbUrl.concat('/classroom/getNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(tempTargetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       //console.log(r);
    //       let temp = r.filter((i) => i.category !== '通知事項' && !i.isCancel);
    //       temp.forEach((i) => {
    //         switch (i.category) {
    //           case '作業考試':
    //             i.prioritize = 2;
    //             break;
    //           case '攜帶物品':
    //             i.prioritize = 3;
    //             break;
    //           default:
    //             break;
    //         }
    //       });
    //       temp.sort((a, b) => {
    //         if (a.prioritize < b.prioritize) {
    //           return -1;
    //         }
    //         if (a.prioritize > b.prioritize) {
    //           return 1;
    //         }
    //         return 0;
    //       });
    //       doFiliterUnDone(temp);
    //       setRefreshing(false);
    //     });
    // } catch (error) {
    //   console.log(error)
    // }
    
  };

  //console.log(rendeList)

  const rendeRecoveryDialog = () =>
    recoveryDialog && (
      <Dialog open={recoveryDialog} onClose={() => setRecoveryDialog(true)}>
        <DialogTitle>移至「確認中的任務」中</DialogTitle>
        <DialogContent>
          按下「確認」後，會將此項目移至「確認中的任務」中
        </DialogContent>
        <DialogActions>
          <Button onClick={doRecovery} color="primary">
            確認
          </Button>
          <Button onClick={() => setRecoveryDialog(false)} color="primary">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    );

  const renderDialog = () =>
    tranferAccomplishDialog && (
      <Dialog
        open={tranferAccomplishDialog}
        onClose={() => setTranferAccomplishDialog(false)}
      >
        <DialogTitle>移至「完成的任務」中</DialogTitle>
        <DialogContent>
          按下「確認」後，會將此項目移至「完成的任務」中
        </DialogContent>
        <DialogActions>
          <Button onClick={doTransferAC} color="primary">
            確認
          </Button>
          <Button
            onClick={() => setTranferAccomplishDialog(false)}
            color="primary"
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    );

  const renderUnTranferDialog = () =>
    unTranferDialog && (
      <Dialog open={unTranferDialog} onClose={() => setUnTranferDialog(false)}>
        <DialogTitle>提示</DialogTitle>
        <DialogContent>
          目前還有尚未完成任務的學生，「無法」將此項目移至完成的任務
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnTranferDialog(false)} color="primary">
            確認
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <>
      {renderDialog()}
      {rendeRecoveryDialog()}
      {renderUnTranferDialog()}
      <Paper className={classes.root}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
          <Typography
            variant="h6"
            style={{
              // marginTop: theme.spacing(2),
              // marginLeft: theme.spacing(2),
              flex: '4',
            }}
          >
            <div>今日任務({rendeList.length + accomplishList.length})</div>
          </Typography>
          {/* <RefreshButton
            variant="contained"
            color="primary"
            onClick={doRefresh}
            disabled={refreshing}
            style={{ marginTop: '19px', marginRight: theme.spacing(2) }}
          >
            重新整理
          </RefreshButton> */}
          
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
          <Tabs
            value={titleValue}
            onChange={handleTitleChange}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label={`確認中的任務(${rendeList.length})`} id="1" />
            <Tab
              style={{ marginLeft: '-40px' }}
              label={`完成的任務(${accomplishList.length})`}
              id="2"
            />
          </Tabs>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between',marginRight: '20px'}}>
            <div style={{color: '#a3a3a3',marginRight:'5px'}}>更新：{curMS}</div>
            <img
                src={refresh}
                style={{
                  width: '25px',
                  height: '25px',
                  marginRight: '10px',
                }}
                onClick={doRefresh}
                disabled={refreshing}
            />
          </div>
        </div>
        {titleValue === 0 ? (
          rendeList.length === 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '60%',
                maxWidth: '100%',
              }}
            >
              {refreshing ? <CircularProgress /> : <>沒有「確認中的任務」</>}
            </div>
          ) : (
            <div
              style={{
                overflow: 'auto',
                height: '220px',
                padding: '4px',
                width: '100%',
              }}
            >
              <FormControl
                style={{
                  marginLeft: '10px',
                }}
                //component="fieldset"
              >
                <RadioGroup
                  aria-label="unfinish"
                  name="unfinish"
                  value={value}
                  onClick={handleChange}
                >
                  {rendeList.map((renditem, rendindex) => (
                    <FormControlLabel
                      style={{
                        marginBottom: '8px',
                        width: '90%',
                        minHeight: '90%',
                      }}
                      value={renditem.id}
                      //disabled={renditem.undoneNum > 0 ? true : false}
                      control={<Radio />}
                      label={
                        <div className={classes.todayList}>
                          <div className={classes.formControlLabelTitle} noWrap>
                            {rendindex + 1}. {renditem.content}
                          </div>
                          {renditem.undoneNum === 0 ? (
                            <Typography
                              style={{ width: '62%', padding: '10px' }}
                            >
                              全部同學都完成囉！
                            </Typography>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '70%',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                flexWrap: 'wrap',
                                alignItems: 'baseline',
                                marginTop: '-25px',
                                minHeight: '40px',
                              }}
                            >
                              {renditem.student.map(
                                (stu, idx) =>
                                  stu.undone && (
                                    // <div>{stu.seatNo}</div>
                                    <div style={{ marginTop: '10px' }}>
                                      <div className={classes.seatNoO}>
                                        <div className={classes.seatNoI}>
                                          {stu.seatNo < 10
                                            ? '0' + stu.seatNo
                                            : stu.seatNo}
                                        </div>
                                      </div>
                                      <div
                                        className={classes.crossO}
                                        onClick={() =>
                                          doCancelUndo(rendindex, idx)
                                        }
                                      >
                                        <img
                                          className={classes.crossI}
                                          src={Close}
                                        />
                                      </div>
                                    </div>
                                  ),
                              )}
                            </div>
                          )}
                          <IconButton
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              maxHeight: '40px',
                            }}
                            onClick={() => doTodayItemEdit(renditem, rendindex)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </div>
                      }
                    >
                      <div>{renditem.content}</div>
                    </FormControlLabel>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )
        ) : accomplishList.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '60%',
              maxWidth: '100%',
            }}
          >
            沒有「完成的任務」
          </div>
        ) : (
          <div
            style={{
              overflow: 'auto',
              height: '220px',
              padding: '4px',
              width: '100%',
            }}
          >
            <FormControl
              style={{
                marginLeft: '10px',
              }}
              //component="fieldset"
            >
              <RadioGroup
                aria-label="unfinish"
                name="unfinish"
                value={value}
                //onClick={handleChange}
              >
                {accomplishList.map((accitem, accindex) => (
                  <FormControlLabel
                    style={{
                      marginBottom: '8px',
                      width: '90%',
                      minHeight: '90%',
                    }}
                    value={accitem.id}
                    //disabled={renditem.undoneNum > 0 ? true : false}
                    control={
                      <Button
                        size="small"
                        style={{ color: '#1db7bd' }}
                        onClick={() => clickRecovery(accitem, accindex)}
                      >
                        復原
                      </Button>
                    }
                    label={
                      <div className={classes.todayList2}>
                        <div className={classes.formControlLabelTitle2} noWrap>
                          {accindex + 1}. {accitem.content}
                        </div>
                      </div>
                    }
                  >
                    <div>{accitem.content}</div>
                  </FormControlLabel>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </Paper>
      <Dialog
        open={stuListAlert}
        onClose={renderNoneDataAlertClose}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
        }}
        classes={{
          paper: classes.dialogStyle,
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {curItem.length === 0 ? '尚無相關項目' : curItem[0].content}
        </DialogTitle>
        <DialogContent>
          {curItem.length === 0 ? (
            <div>尚無相關項目的學生</div>
          ) : (
            <div>
              {curItem[0].student.map((curitem, curindex) =>
                !curitem.undone ? (
                  <Button
                    onClick={() => doCheckStu(curindex)}
                    variant="outlined"
                    style={{
                      border: 'solid 2px #e0e0e0',
                      borderRadius: '100px',
                      margin: '5px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ color: '#323232', width: '15px' }}>
                        {curitem.seatNo > 9
                          ? curitem.seatNo
                          : '0' + curitem.seatNo}
                      </Typography>
                      <Typography style={{ marginLeft: '10px' }}>
                        {curitem.studentName}
                      </Typography>
                    </div>
                    <ControlPointIcon
                      fontSize="small"
                      style={{
                        marginLeft: '10px',
                        marginRight: '-10px',
                        color: '#a3a3a3',
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => doCheckStu(curindex)}
                    variant="outlined"
                    style={{
                      border: 'solid 2px #1db7bd',
                      borderRadius: '100px',
                      margin: '5px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ color: '#323232', width: '15px' }}>
                        {curitem.seatNo > 9
                          ? curitem.seatNo
                          : '0' + curitem.seatNo}
                      </Typography>
                      <Typography style={{ marginLeft: '10px' }}>
                        {curitem.studentName}
                      </Typography>
                    </div>
                    <CheckCircleIcon
                      fontSize="small"
                      style={{
                        marginLeft: '10px',
                        marginRight: '-10px',
                        color: '#1fb6bd',
                      }}
                    />
                  </Button>
                ),
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {/* <Button onClick={renderSaveDataAlertClose} variant="contained" style={{ backgroundColor: '#1db7bd'}}>
          <Typography color='white'>
                確認
           </Typography>
          </Button> */}
          <ColorButton
            variant="contained"
            color="primary"
            onClick={renderSaveDataAlertClose}
          >
            確認
          </ColorButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Component;
