import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  ArrowDropDown as ArrowDownIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExitToApp as ExitToAppIcon,
  CloudUpload as CloudUploadIcon,
  AccountBox as AccountBoxIcon,
  History as HistoryIcon,
  Menu as MenuIcon,
  Sms as SmsIcon,
  Tv as TvIcon,
} from '@material-ui/icons';
import { useParams } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../../App';

import envConfig from '../../config';

import * as Cloud from './cloud';
import * as Rec from './rec';
import * as Gaset from './gaset';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listroot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  logoHeader: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  toolHeader: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#fff',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


const Component = () => {
  const styles = useStyles();
  const theme = useTheme();
  const [tokenInfo] = useState(
    JSON.parse(window.localStorage.getItem('tokenInfo')),
  );
  // const [tokenInfo] = useState(
  //   state.accessToken,
  // );
  const { state, dispatch } = useContext(AppContext);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [view, setView] = useState('');
  const [exitApp, setExitApp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [author, setAuthor] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [classes, setClasses] = useState([]);
  const [customsLoading, setCustomsLoading] = useState(true);
  const [customs, setCustoms] = useState([]);

  useEffect(() => {
    if (!dispatch || !state.accessToken) return;
    //console.log(state);
    //console.log(dispatch);
    //console.log(state.accessToken);

    fetch(
      envConfig.SERVICE_URL.getUserToken.replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log('getUserToken : ' + response);
        //console.log(response);
        //console.log(state)
        if (!response.token) {
          window.localStorage.clear();
          //window.location.reload();
          //window.location.replace('https://cooc.tp.edu.tw');
          window.location.replace('https://classroom.tp.edu.tw/teacher');
          return;
        }
        
        if(response.fromSession) {
          fetch(
            envConfig.SERVICE_URL.getUserInfo.replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ),
          )
            .then((response) => response.json())
            .then((response) => {
              //console.log(response);
              setLoading(false);
              dispatch({
                type: 'SET_AUTHOR',
                payload: {
                  ...response,
                  name: `${response.last_name}${response.first_name}`,
                },
              });
              const schools = []
                .concat(
                  response.sso_role
                    .filter((item) => item.source === 'ldap')
                    .map((item) => item.role)
                    .reduce((i, j) => [].concat(i).concat(j)),
                )
                .filter((item) => item.role_type === 'teacher')
                .map((item) => ({
                  ...item,
                  avatar: item.school_name.substr(0, 1),
                  name: item.school_name,
                  dsns: item.school_code,
                  key: item.school_code,
                }));

              setSchools(schools);
              //console.log(schools);
            });
        } else {
          firebase
            .auth()
            .signInWithCustomToken(response.token)
            .then(() => {
              fetch(
                envConfig.SERVICE_URL.getUserInfo.replace(
                  /_ACCESS_TOKEN_/gi,
                  state.accessToken,
                ),
              )
                .then((response) => response.json())
                .then((response) => {
                  //console.log(response);
                  setLoading(false);
                  dispatch({
                    type: 'SET_AUTHOR',
                    payload: {
                      ...response,
                      name: `${response.last_name}${response.first_name}`,
                    },
                  });
                  const schools = []
                    .concat(
                      response.sso_role
                        .filter((item) => item.source === 'ldap')
                        .map((item) => item.role)
                        .reduce((i, j) => [].concat(i).concat(j)),
                    )
                    .filter((item) => item.role_type === 'teacher')
                    .map((item) => ({
                      ...item,
                      avatar: item.school_name.substr(0, 1),
                      name: item.school_name,
                      dsns: item.school_code,
                      key: item.school_code,
                    }));
  
                  setSchools(schools);
                  //console.log(schools);
                });
            });
        }
      });
    // eslint-disable-next-line
  }, []);


  return (
    <div className={styles.root}>
      {exitApp && (
        <Dialog open={true}>
          <DialogTitle>確定離開管理功能？</DialogTitle>
          <DialogActions>
            <div style={{ flex: 1 }} />
            <Button color="secondary" onClick={() => setExitApp(false)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() => {
                window.localStorage.clear();
                window.location.reload();
              }}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <AppBar
        position="fixed"
        className={clsx(styles.appBar, {
          [styles.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(styles.menuButton, open && styles.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {/* {state.author ? `${state.author.name} 老師您好` : ''} */}
            {schools.length > 0 ? schools[0].school_name : ''}
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton
            color="inherit"
            onClick={() => setExitApp(true)}
            edge="end"
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={styles.drawer}
        classes={{
          paper: styles.drawerPaper,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className={styles.drawerHeader}>
          <div className={styles.logoHeader}>
            <Typography variant="h5" color="primary">
              課堂工具
            </Typography>
          </div>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
              )}
          </IconButton>
        </div>
        <Divider />
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress size="3rem" color="primary" />
          </div>
        ) : (
            <React.Fragment>

              <List component="nav" className={classes.root} aria-label="mailbox folders">
                <ListItem 
                button
                  onClick={() => {
                    setView('cloud');
                  }}>
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
                  <ListItemText primary="設定我的雲端平台" />
                </ListItem>
                <Divider />
                <ListItem 
                button 
                divider
                  onClick={() => {
                    setView('gaset');
                  }}
                >
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary="自訂Google Drive帳號" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    setView('rec');

                    
                  }}
                >
                  <ListItemIcon>
                    <HistoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="瀏覽教學歷程" />
                </ListItem>
                <Divider light />
              </List>
            </React.Fragment>
          )}
      </Drawer>
      <main
        className={clsx(styles.content, {
          [styles.contentShift]: open,
        })}
      >
        <div className={styles.drawerHeader} />
        {view === 'cloud' && <Cloud.Editor />}
        {view === 'gaset' && <Gaset.Editor />}
        {view === 'rec' && <Rec.Editor />}
      </main>
    </div>
  );
};
export default Component;
// export { Editor, Items };
