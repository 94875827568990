import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, Delete as DeleteIcon, Settings as SettingIcon} from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../App';
import envConfig from '../config';

const Route = {
  Cooc: [
    {
      key: 'School',
      label: '請選擇學校、建立的班級',
    },
  ],
  CoocSelfClass: [
    {
      key: 'School',
      label: '請選擇學校、建立的班級',
    },
    { key: 'Class', label: '請選擇班級' },
  ],
  CoocCustom: [
    {
      key: 'School',
      label: '請選擇學校、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
  ],
  CoocCustomName: [
    {
      key: 'School',
      label: '請選擇學校、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
  ],
  CoocCustomStudent: [
    {
      key: 'School',
      label: '請選擇學校、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
    { key: 'ClassStudents', label: '請輸入班級人數' },
  ],
};

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  buttonbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0, 0),
  },
}));
let numOfEntries = 1 // 讓後續判斷是否為第一次進入
let resToken = ''
const dbUrl = envConfig.SERVER_URL;
const Component = ({ callback }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [tokenInfo] = useState(
    JSON.parse(window.localStorage.getItem('tokenInfo')),
  );
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [customsLoading, setCustomsLoading] = useState(true);
  const [customs, setCustoms] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [inputClassName, setInputClassName] = useState('');
  const [inputStudentCount, setInputStudentCount] = useState(1);
  const [author, setAuthor] = useState({});
  const [removeItem, setRemoveItem] = useState(null);
  const [schoolIndex, setSchoolIndex] = useState();
  const [classLoading, setClassLoading] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    //console.log(navigator.userAgent);
    fetch(
      envConfig.SERVICE_URL.getUserToken.replace(
        /_ACCESS_TOKEN_/gi,
        tokenInfo.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        if (!response.token) {
          window.localStorage.clear();
          //window.location.reload();
          //window.location.replace('https://cooc.tp.edu.tw');
          window.location.replace('https://classroom.tp.edu.tw/teacher');
          return;
        }
        resToken = response.token

        if(response.fromSession) {
          fetch(
            envConfig.SERVICE_URL.getUserInfo.replace(
              /_ACCESS_TOKEN_/gi,
              tokenInfo.accessToken,
            ),
          )
            .then((response) => response.json())
            .then((response) => {
              setLoading(false);
              setAuthor({
                ...response,
                name: `${response.last_name}${response.first_name}`,
              });

              let tempAuthor = {
                ...response,
                name: `${response.last_name}${response.first_name}`,
              };
              let tempSchooles = []
                .concat(
                  response.sso_role
                    .filter((item) => item.source === 'ldap')
                    .map((item) => item.role)
                    .reduce((i, j) => [].concat(i).concat(j)),
                )
                .filter((item) => item.role_type === 'teacher')
                .map((item) => ({
                  ...item,
                  avatar: item.school_name.substr(0, 1),
                  name: item.school_name,
                  dsns: item.school_code,
                  key: item.school_code,
                }))

              setSchools([].concat(tempSchooles)
              );
              if(state.screenSchool){
                let i = tempSchooles.findIndex(item => item.key === state.screenSchool.school_code);
                if(i !== -1){
                  getSchool(tempSchooles[i],tempAuthor)
                } else {
                  setSteps(Route.Cooc);
                }
              } else {
                setSteps(Route.Cooc);
              } 
              
            });
        } else {
          //console.log(response)
          firebase
            .auth()
            .signInWithCustomToken(response.token)
            .then(() => {
              //console.log('signInWithCustomToken');
              fetch(
                envConfig.SERVICE_URL.getUserInfo.replace(
                  /_ACCESS_TOKEN_/gi,
                  tokenInfo.accessToken,
                ),
              )
                .then((response) => response.json())
                .then((response) => {
                  setAuthor({
                    ...response,
                    name: `${response.last_name}${response.first_name}`,
                  });
  
                  let tempAuthor = {
                    ...response,
                    name: `${response.last_name}${response.first_name}`,
                  };
                  let tempSchooles = []
                    .concat(
                      response.sso_role
                        .filter((item) => item.source === 'ldap')
                        .map((item) => item.role)
                        .reduce((i, j) => [].concat(i).concat(j)),
                    )
                    .filter((item) => item.role_type === 'teacher')
                    .map((item) => ({
                      ...item,
                      avatar: item.school_name.substr(0, 1),
                      name: item.school_name,
                      dsns: item.school_code,
                      key: item.school_code,
                    }))
  
                  setSchools([].concat(tempSchooles)
                  );
                  if(state.screenSchool){
                    let i = tempSchooles.findIndex(item => item.key === state.screenSchool.school_code);
                    if(i !== -1){
                      getSchool(tempSchooles[i],tempAuthor)
                      setLoading(false);
                    } else {
                      setSteps(Route.Cooc);
                      setLoading(false);
                    }
                  } else {
                    setSteps(Route.Cooc);
                    setLoading(false);
                  } 
                });
            });
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!author && schools.length === 0) return;

    if (tokenInfo.school) {
      const [school] = schools.filter(
        (item) => item.school_code === tokenInfo.school,
      );

      if (school) {
        return getSchool(school);
      }
    }
    // eslint-disable-next-line
  }, [author, schools]);

  const getSchool = (school, tempAuthor) => {
    setClassLoading(true);
    setSteps(Route.CoocSelfClass);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //console.log(school)

    let tempTarget = {
      school_code: school.school_code,
      school_type: school.school_type,
      id_number: author.id_number ? author.id_number : tempAuthor.id_number,
      call_component: 'teacher/src/components/Chooser'
    };
    //console.log(tempTarget)
    try {
      fetch(dbUrl.concat('/classroom/getIMDBClassANDCourse'), {
        method: 'POST',
        body: JSON.stringify(tempTarget),
        headers: {
          'content-type': 'application/json'
        },
      }).then(
        r => r.json()
      ).then(r => {
        //console.log(r)
        if(numOfEntries === 1 && [].concat(r).length === 0) {
          setClasses([].concat(r));
          //console.log(numOfEntries);
          setSteps(Route.Cooc);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setInputClassName('');
          setInputStudentCount(1);
          setClassLoading(false);
          numOfEntries++;
        } else {
          numOfEntries++;
          setClasses([].concat(r));
          setClassLoading(false);
        }
      })
    } catch(error){
      console.log(error)
    }
    // fetch(
    //   envConfig.SERVICE_URL.getIMDBAccessToken.replace(
    //     /_ACCESS_TOKEN_/gi,
    //     tokenInfo.accessToken,
    //   ),
    // )
    //   .then((response) => response.json())
    //   .then((response) => {
    //     Promise.all([
    //       new Promise((rs) => {
    //         fetch(
    //           envConfig.SERVICE_URL.getIMDBClass
    //             .replace(/_DSNS_/gi, school.school_code)
    //             .replace(/_ACCESS_TOKEN_/gi, response.access_token)
    //             .replace(/_ID_NUMBER_/gi, author.id_number),
    //         )
    //           .then((response) => response.json())
    //           .then((response) => {
    //             rs(
    //               response.Class.map((item) => ({
    //                 ...item,
    //                 key: item.ClassID,
    //                 avatar: item.ClassName.substr(0, 1),
    //                 SchoolCode: school.school_code,
    //                 Type: 'Class',
    //                 UID: item.ClassID,
    //                 Name: item.ClassName,
    //               })),
    //             );
    //           })
    //           .catch(() => {
    //             rs([]);
    //           });
    //       }),
    //       new Promise((rs) => {
    //         fetch(
    //           envConfig.SERVICE_URL.getIMDBCourse
    //             .replace(/_DSNS_/gi, school.school_code)
    //             .replace(/_ACCESS_TOKEN_/gi, response.access_token)
    //             .replace(/_ID_NUMBER_/gi, author.id_number),
    //         )
    //           .then((response) => response.json())
    //           .then((response) => {
    //             rs(
    //               response.Course.map((item) => ({
    //                 ...item,
    //                 key: item.CourseID,
    //                 avatar: item.CourseName.substr(0, 1),
    //                 SchoolCode: school.school_code,
    //                 Type: 'Course',
    //                 UID: item.CourseID,
    //                 Name: item.CourseName,
    //               })),
    //             );
    //           })
    //           .catch(() => {
    //             rs([]);
    //           });
    //       }),
    //     ]).then(([classes, courses]) => {
    //       setSteps(Route.CoocSelfClass);
    //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //       setClasses([].concat(classes).concat(courses));
    //     });
    //   });
  };

  const getCustoms = () => {
    setCustomsLoading(true);
    setSteps(Route.CoocCustom);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    firebase
      .firestore()
      .collection('classes')
      .where('author', '==', author.uuid)
      .get()
      .then((querySnapshot) => {
        const customs = [];
        querySnapshot.forEach((doc) => {
          customs.push({
            id: doc.id,
            ...doc.data(),
            key: `custom.${doc.id}`,
            avatar: doc.data().name.substr(0, 1),
          });
        });
        customs.sort((x, y) => (x.name > y.name ? 1 : -1));

        setCustoms(customs);
        setCustomsLoading(false);
      });
  };

  const getStep = (step) => {
    switch (step) {
      case 'School': {
        return (
          <React.Fragment>
            <List>
              {schools.map((item, index) => (
                <React.Fragment key={item.key}>
                  <ListItem button onClick={() => getSchool(item)}>
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.primary.main,
                          ),
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {item.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {schools.length > 1 && index < schools.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
              {schools.length > 0 && <Divider variant="inset" />}
              <ListItem key="customs" button onClick={() => getCustoms()}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    建
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="建立的班級" />
              </ListItem>
            </List>
          </React.Fragment>
        );
      }
      case 'Class': {
        return (
          <React.Fragment>
            {classLoading ? (
              <div className={styles.loading}>
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2), color: 'black' }}
                />
                <Typography variant="h6" style={{ color: 'black' }}>
                  資料讀取中...
                </Typography>
              </div>
            ): (
            <div>
              {classes.length === 0 ? (
                <Typography variant="h6" color="secondary">
                  尚無可以上課的班級
                </Typography>
              ) : (
                <List>
                  {classes.map((item, index) => (
                    <React.Fragment key={item.key}>
                      <ListItem
                        button
                        onClick={() => {
                          const roomId = `${
                            item.SchoolCode
                          }.${item.Type.toLowerCase()}.${item.UID}`;
                          const target = {
                            author: author.uuid,
                            dsns: item.SchoolCode,
                            schoolName: item.SchoolName ? item.SchoolName: ' ',
                            schoolType: item.SchoolType ? item.SchoolType: ' ',
                            schoolYear: item.SchoolYear ? item.SchoolYear: ' ',
                            code: Math.random()
                              .toString(36)
                              .substr(2, 6)
                              .toUpperCase(),
                            teacher: { name: author.name },
                            classroom: {
                              uid: item.UID,
                              name: item.Name,
                              type: item.Type,
                            },
                            container: state.container,
                            type: 'class',
                          };
                          firebase
                          .auth()
                          .signInWithCustomToken(resToken)
                          .then(() => {
                            firebase
                              .firestore()
                              .doc(`/classrooms/${roomId}`)
                              .set({
                                ...target,
                                status: 'open',
                                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                              })
                              .then(() =>
                                callback({
                                  action: 'SIGN_IN',
                                  payload: {
                                    roomId,
                                    accessToken: tokenInfo.accessToken,
                                  },
                                }),
                              );
                          })
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              color: theme.palette.getContrastText(
                                theme.palette.primary.main,
                              ),
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            {item.avatar}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.Name}
                          secondary={item.TeacherName}
                        />
                      </ListItem>
                      {classes.length > 1 && index < classes.length - 1 && (
                        <Divider variant="inset" />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </div>
            )}
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.Cooc);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Custom': {
        return (
          <React.Fragment>
            {customs.length === 0 ? (
              customsLoading ? (
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2) }}
                />
              ) : (
                <Typography variant="h6" color="secondary">
                  尚無建立的班級
                </Typography>
              )
            ) : (
              <List>
                {customs.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      onClick={() => {
                        const roomId = `custom.${item.id}`;
                        const target = {
                          author: author.uuid,
                          dsns: 'custom',
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: {
                            name: author.name,
                          },
                          classroom: {
                            uid: item.id,
                            name: item.name,
                            cid: item.id,
                            students: item.students,
                          },
                          container: state.container,
                          type: 'custom',
                        };

                        firebase
                          .firestore()
                          .doc(`/classrooms/${roomId}`)
                          .set({
                            ...target,
                            status: 'open',
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                          })
                          .then(() =>
                            callback({
                              action: 'SIGN_IN',
                              payload: {
                                roomId,
                                accessToken: tokenInfo.accessToken,
                              },
                            }),
                          );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main,
                            ),
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.name} />
                      <ListItemSecondaryAction
                        onClick={() => setRemoveItem({ ...item, index })}
                      >
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {customs.length > 1 && index < customs.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            {removeItem && (
              <Snackbar
                open={true}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                message={`確定刪除「${removeItem.name}」？`}
                action={
                  <React.Fragment>
                    <Button
                      color="secondary"
                      onClick={() => setRemoveItem(null)}
                    >
                      取消
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        customs.splice(removeItem.index, 1);

                        firebase
                          .firestore()
                          .doc(`/classes/${removeItem.id}`)
                          .delete();

                        setRemoveItem(null);
                      }}
                    >
                      確定
                    </Button>
                  </React.Fragment>
                }
              ></Snackbar>
            )}
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.Cooc);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  setInputClassName('');
                  setInputStudentCount(1);
                }}
              >
                上一步
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.CoocCustomName);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  setRemoveItem(null);
                }}
              >
                建立班級
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'ClassName': {
        return (
          <React.Fragment>
            <TextField
              variant="outlined"
              placeholder="班級名稱"
              fullWidth
              value={inputClassName}
              onInput={(e) => {
                setInputClassName(e.target.value);
              }}
            />
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.CoocCustom);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  setInputClassName('');
                  setInputStudentCount(1);
                }}
              >
                上一步
              </Button>
              <Button
                disabled={!inputClassName}
                color="primary"
                onClick={() => {
                  setSteps(Route.CoocCustomStudent);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
              >
                下一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'ClassStudents': {
        return (
          <React.Fragment>
            <TextField
              variant="outlined"
              placeholder="學生人數"
              type="number"
              inputProps={{ min: 1, step: 1 }}
              value={inputStudentCount}
              onInput={(e) => {
                setInputStudentCount(parseInt(e.target.value, 10));
              }}
            />
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.CoocCustomName);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (author.uuid) {
                    firebase
                      .firestore()
                      .collection('classes')
                      .add({
                        author: author.uuid,
                        name: inputClassName,
                        students: inputStudentCount,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                      })
                      .then((docRef) => {
                        const roomId = `custom.${docRef.id}`;
                        const target = {
                          author: author.uuid,
                          dsns: 'custom',
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: {
                            name: author.name,
                          },
                          classroom: {
                            gid: '----',
                            uid: docRef.id,
                            name: inputClassName,
                            students: inputStudentCount,
                          },
                          container: state.container,
                          type: 'custom',
                        };

                        firebase
                          .firestore()
                          .doc(`/classrooms/${roomId}`)
                          .set({
                            ...target,
                            status: 'open',
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                          })
                          .then(() =>
                            callback({
                              action: 'SIGN_IN',
                              payload: {
                                roomId,
                                accessToken: tokenInfo.accessToken,
                              },
                            }),
                          );
                      });
                  }
                }}
              >
                完成
              </Button>
            </div>
          </React.Fragment>
        );
      }
      default: {
        /** empty */
      }
    }
  };

  return loading ? (
    <Backdrop open={true}>
      <div className={styles.loading}>
        <CircularProgress
          color="primary"
          style={{ margin: theme.spacing(2), color: '#f1f1f1' }}
        />
        <Typography variant="h6" style={{ color: '#f1f1f1' }}>
          資料讀取中...
        </Typography>
      </div>
    </Backdrop>
  ) : (
    <Dialog open={true} >
      <DialogActions style={{marginTop: '12px',padding:'6px'}}>
      {/* <DialogActions> */}
      <div style={{ flex: 0 }} />
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="h6">
          進入：
        </Typography>
        <Button
            variant="outlined"
            color="primary"
            style={{color: 'Gray'}}
            startIcon={<SettingIcon />}
            onClick={() =>
              callback({
                action: 'MANGER_IN',
                payload: {
                  accessToken: tokenInfo.accessToken,
                },
              })
            }
          >
            教師端管理功能
        </Button>
      </div>
        <div style={{ flex: 1 }} />
        {!window.opener && (
          <IconButton
            size="small"
            onClick={() =>
              callback({
                action: 'EXIT_CHOOSER',
              })
            }
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogActions>
      {/* </DialogActions> */}
      <Typography variant="h6" style={{padding: '8px', marginLeft: '6px', marginBottom:'-10px'}}>
        進入課程：
      </Typography>
      <DialogContent
        style={{ padding: theme.spacing(1), width: theme.spacing(34) }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ padding: theme.spacing(1) }}
        >
          {steps.map((step) => (
            <Step key={step.key}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent style={{ padding: 0 }}>
                {getStep(step.key)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default Component;
