import React, { useContext, useEffect, useState } from 'react';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import envConfig from '../../../config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink, CSVDownload } from "react-csv";
import {
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDownIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Event as ScheduleIcon,
  LowPriority as PriorityLowIcon,
  PriorityHigh as PriorityHighIcon,
  Schedule as NowIcon,
  Send as SendIcon,
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    margin: theme.spacing(2),
    flexDirection: 'column',
  },
  svgroot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  priorityrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  typerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  schedulerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    width: '40%',
  },
  targetbox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 1, 1),
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  targetlabel: {
    position: 'absolute',
    top: theme.spacing(-2),
    background: '#fff',
    padding: theme.spacing(3 / 4),
  },
  targetrow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  btntrow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  top: {
    marginTop: theme.spacing(1),
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

}));

function CustomPagination(props) {
  const { pagination, api } = props;
  const classes = useStyles();
  let finalPagCount = 0
  let tempQuotient = parseInt(pagination.rowCount/pagination.pageSize);
  let tempRemainder = pagination.rowCount%pagination.pageSize;
  // console.log(`${tempQuotient},${tempRemainder}`)
  if(tempRemainder> 0) {
    finalPagCount = tempQuotient + 1
  }
  return (
    <Pagination
      className={classes.root}
      color="primary"
      page={pagination.page}
      count={finalPagCount}
      onChange={(event, value) => {
        // console.log(event);
        // console.log(api);
        // console.log(pagination);
        api.current.setPage(value)}}
    />
  );
}

function DateAndTimePickers() {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label="Next appointment"
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}

CustomPagination.propTypes = {
  /**
   * ApiRef that let you manipulate the grid.
   */
  api: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
  /**
   * The object containing all pagination details in [[PaginationState]].
   */
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    paginationMode: PropTypes.oneOf(['client', 'server']).isRequired,
    rowCount: PropTypes.number.isRequired,
  }).isRequired,
};

// 處理沒資料圖示顯示
function CustomNoRowsOverlay() {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.svgroot}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>沒有資料</div>
    </GridOverlay>
  );
}

const Component = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [targetsRec, setTargetsRec] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSEl, setAnchorSEl] = React.useState(null);
  const [anchorCEl, setAnchorCEl] = React.useState(null);
  const [anchorRTEl, setAnchorRTEl] = React.useState(null);
  const [classActive, setClassActive] = useState('disabled');
  const [menuSName, setMenuSName] = useState('請選擇學校');
  const [menuCName, setMenuCName] = useState('請選擇班級或課程');
  const [teacherInfo, setTeacherInfo] = useState({});
  const [searchActive, setSearchActive] = useState('disabled');
  const [curSchool, setCurSchool] = useState('');
  const [curCC, setCurCC] = useState(0);
  const [pClassID, setpClassID] = useState('');
  const [pSchoolID, setpSchoolID] = useState('');
  const [defscheduleS, setDefSSchedule] = useState(moment(Date.now()).format('YYYY-MM-DD'));
  const [defscheduleE, setDefESchedule] = useState(moment(Date.now()).format('YYYY-MM-DD'));


  const [scheduleS, setSSchedule] = useState(moment(Date.now()));
  const [scheduleE, setESchedule] = useState(moment(Date.now()));
  const [clicking, setClick] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [column, setColumn] = useState(scoreColumns);
  const [curheaders, setHeader] = useState([]);
  const [classLoading, setClassLoading] = useState(false);
  const [recType, setRecType] = useState('課堂表現');

  // const recTypeList = ['課堂表現', '學生分組', '隨機抽人', '快問快答', '上線紀錄'];
  const recTypeList = ['課堂表現', '學生分組', '隨機抽人', '快問快答', '課間呼叫', '上線紀錄'];

  const scoreColumns = [
    { field: 'room_name', headerName: '班級/課程', width: 180, },
    { field: 'stud_name', headerName: '學生姓名', width: 130 },
    { field: 'stud_no', headerName: '學號', width: 130, },
    { field: 'seat_no', headerName: '座號', width: 90, },
    { field: 'score_by', headerName: '加分來源', width: 100, },
    { field: 'score', headerName: '分數', type: 'number', width: 90, },
    { field: 'creat_time', headerName: '建立時間', type: 'dateTime', width: 180, }
  ];

  const scroeHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'room_type' },
    { label: '教室ID', key: 'room_id' },
    { label: '教室名稱', key: 'room_name' },
    { label: '老師名稱', key: 'teacher_name' },
    { label: '學生座號', key: 'seat_no' },
    { label: '學生學號', key: 'stud_no' },
    { label: '學生姓名', key: 'stud_name' },
    { label: '加減分來源', key: 'score_by' },
    { label: '加減分', key: 'score' },
    { label: '建立時間', key: 'creat_time' },
  ];


  const groupColumns = [
    { field: 'group_id', headerName: '分組ID', type: 'number', width: 150, },
    { field: 'room_name', headerName: '班級/課程', width: 180, },
    { field: 'group_category', headerName: '分組類型', width: 130 },
    { field: 'group_by', headerName: '分組母群', width: 90, },
    { field: 'group_number', headerName: '分組數量', width: 90, },
    { field: 'group', headerName: '組別', width: 80, },
    { field: 'stuList', headerName: '組內學生', width: 200, },
    { field: 'creat_time', headerName: '建立時間', type: 'dateTime', width: 180, }
  ];

  const groupHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'room_type' },
    { label: '教室ID', key: 'room_id' },
    { label: '班級/課程名稱', key: 'room_name' },
    { label: '老師名稱', key: 'teacher_name' },
    { label: '分組ID', key: 'group_id' },
    { label: '分組類型', key: 'group_category' },
    { label: '分組母群', key: 'group_by' },
    { label: '分組數量', key: 'group_number' },
    { label: '組別', key: 'group' },
    { label: '組內學生', key: 'stuList' },
    { label: '建立時間', key: 'creat_time' },
  ];

  const randomColumns = [
    { field: 'room_name', headerName: '班級/課程', width: 180, },
    { field: 'method', headerName: '抽人方式', width: 130 },
    { field: 'random_number', headerName: '抽人數量', width: 90, },
    { field: 'random_by', headerName: '抽人母群', width: 90, },
    { field: 'stuList', headerName: '抽中學生', width: 200, },
    { field: 'creat_time', headerName: '建立時間', type: 'dateTime', width: 180, }
  ];

  const randomHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'room_type' },
    { label: '教室ID', key: 'room_id' },
    { label: '班級/課程名稱', key: 'room_name' },
    { label: '老師名稱', key: 'teacher_name' },
    { label: '排除座號', key: 'random_exclude' },
    { label: '抽人方式', key: 'method' },
    { label: '抽人數量', key: 'random_number' },
    { label: '抽人母群', key: 'random_by' },
    { label: '抽人開始範圍(座號)', key: 'rangenoa' },
    { label: '抽人結束範圍(座號)', key: 'rangenob' },
    { label: '抽中學生', key: 'stuList' },
    { label: '建立時間', key: 'creat_time' },
  ];

  const quizColumns = [
    { field: 'room_name', headerName: '班級/課程', width: 180, },
    { field: 'quiz_id', headerName: '題目ID', width: 180, },
    { field: 'quiz_number', headerName: '選項數量', width: 90, },
    { field: 'stud_number', headerName: '學生學號', width: 130 },
    { field: 'stud_no', headerName: '學生座號', width: 90 },
    { field: 'stud_name', headerName: '學生姓名', width: 130 },
    { field: 'stu_ans', headerName: '學生答案', width: 90, },
    { field: 'creat_time', headerName: '建立時間', type: 'dateTime', width: 180, }
  ];

  const quizHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'room_type' },
    { label: '教室ID', key: 'room_id' },
    { label: '班級/課程名稱', key: 'room_name' },
    { label: '老師名稱', key: 'teacher_name' },
    { label: '問答ID', key: 'quiz_id' },
    { label: '學生姓名', key: 'stud_name' },
    { label: '學生座號', key: 'stud_no' },
    { label: '學生學號', key: 'stud_number' },
    { label: '答案數量', key: 'quiz_number' },
    { label: '學生答案', key: 'stu_ans' },
    { label: '建立時間', key: 'creat_time' },
  ];

  const checkInColumns = [
    { field: 'room_name', headerName: '班級/課程', width: 180, },
    { field: 'attendance_id', headerName: '上課紀錄ID', width: 180, },
    { field: 'stud_number', headerName: '學生學號', width: 130 },
    { field: 'stud_no', headerName: '學生座號', width: 90 },
    { field: 'stud_name', headerName: '學生姓名', width: 130 },
    { field: 'creat_time', headerName: '簽到時間', type: 'dateTime', width: 180, }
  ];

  const checkInHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'room_type' },
    { label: '教室ID', key: 'room_id' },
    { label: '班級/課程名稱', key: 'room_name' },
    { label: '老師名稱', key: 'teacher_name' },
    { label: '點名ID', key: 'attendance_id' },
    { label: '學生姓名', key: 'stud_name' },
    { label: '學生座號', key: 'stud_no' },
    { label: '學生學號', key: 'stud_number' },
    { label: '簽到時間', key: 'creat_time' },
  ];

  const attendColumns = [
    { field: 'class_name', headerName: '班級/課程', width: 130, },
    { field: 'classroom_code', headerName: '教室代碼', width: 110, },
    { field: 'seat_no', headerName: '學生座號', width: 110, },
    { field: 'student_name', headerName: '學生姓名', width: 110 },
    { field: 'enter_time', headerName: '進教室時間', type: 'dateTime', width: 210 },
    { field: 'last_update', headerName: '教室建立時間', type: 'dateTime', width: 210 },
  ];

  const attendHeaders = [
    { label: 'ID', key: 'id' },
    { label: '學校名稱', key: 'school_name' },
    { label: '學制', key: 'school_type' },
    { label: '教室類型', key: 'class_type' },
    { label: '教室ID', key: 'classroom_code' },
    { label: '班級/課程名稱', key: 'class_name' },
    { label: '學生座號', key: 'seat_no' },
    { label: '學生姓名', key: 'student_name' },
    { label: '教室建立時間', key: 'last_update' },
    { label: '進教室時間', key: 'enter_time' },
  ];

  const dbUrl = envConfig.SERVER_URL;

  useEffect(() => {
    if (!dispatch || !state.accessToken) return;

    //console.log(state);
    //console.log(dispatch);
    //console.log(state.accessToken);
    fetch(
      envConfig.SERVICE_URL.getUserToken.replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        if (!response.token) {
          window.localStorage.clear();
          //window.location.reload();
          //window.location.replace('https://cooc.tp.edu.tw');
          
          window.location.replace('https://classroom.tp.edu.tw/teacher');
          return;
        }
                setTeacherInfo(response);
                //console.log(teacherInfo);
                const schools = []
                  .concat(
                    response.sso_role
                      .filter((item) => item.source === 'ldap')
                      .map((item) => item.role)
                      .reduce((i, j) => [].concat(i).concat(j)),
                  )
                  .filter((item) => item.role_type === 'teacher')
                  .map((item) => ({
                    ...item,
                    avatar: item.school_name.substr(0, 1),
                    name: item.school_name,
                    dsns: item.school_code,
                    key: item.school_code,
                  }));
                setSchools(schools);
                //console.log(schools);
                let tempTarget = {
                  school_code: schools[0].school_code,
                  school_type: schools[0].school_type,
                  id_number: teacherInfo.id_number,
                  call_component: 'teacher/src/components/manager/rec/editor'
                };
          
                try {
                  fetch(dbUrl.concat('/classroom/getIMDBClassANDCourse'), {
                    method: 'POST',
                    body: JSON.stringify(tempTarget),
                    headers: {
                      'content-type': 'application/json'
                    },
                  }).then(
                    r => r.json()
                  ).then(r => {
                    //console.log(r)
                    setClasses([].concat(r));
                    setClassLoading(false)
                  })
                } catch(error){
                  console.log(error)
                }
                setLoading(false);
        
        // firebase
        //   .auth()
        //   .signInWithCustomToken(response.token)
        //   .then(() => {
        //     fetch(
        //       envConfig.SERVICE_URL.getUserInfo.replace(
        //         /_ACCESS_TOKEN_/gi,
        //         state.accessToken,
        //       ),
        //     )
        //       .then((response) => response.json())
        //       .then((response) => {
                //console.log(response);
                // setLoading(false);
                // setTeacherInfo(response);
                // console.log(teacherInfo);
                // const schools = []
                //   .concat(
                //     response.sso_role
                //       .filter((item) => item.source === 'ldap')
                //       .map((item) => item.role)
                //       .reduce((i, j) => [].concat(i).concat(j)),
                //   )
                //   .filter((item) => item.role_type === 'teacher')
                //   .map((item) => ({
                //     ...item,
                //     avatar: item.school_name.substr(0, 1),
                //     name: item.school_name,
                //     dsns: item.school_code,
                //     key: item.school_code,
                //   }));
                // setSchools(schools);
                // console.log(schools);
          //     });
          // });
      });
  }, []);

  const handleChange = (event, newValue) => {
    setTargetsRec([]);
    setTabValue(newValue);
  };

  const handleSClick = (event) => {
    setAnchorSEl(event.currentTarget);
    // setTargetsRec([]);
  };

  const handleCClick = (event) => {
    setAnchorCEl(event.currentTarget);
    // setTargetsRec([]);
  };

  const handleRTClick = (event) => {
    setAnchorRTEl(event.currentTarget);
    // setTargetsRec([]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorSEl(null);
    setAnchorCEl(null);
    setAnchorRTEl(null);
  };

  const handleSchoolClose = (item) => {
    let pitem = item;
    //console.log(item);
    if (item.school_name || item.school_code) {
      setClassActive('');
    }
    setMenuSName(item.school_name);
    if (curSchool !== item.school_code) {
      setTargetsRec([]);
    }
    setCurSchool(item.school_code);
    getSchool(item);
    setAnchorEl(null);
    setAnchorSEl(null);
    setAnchorCEl(null);
    setAnchorRTEl(null);
  };

  const handleClassClose = (item) => {
    //console.log(item.CourseID);
    //console.log(curCC);
    if (item.Name || item.CourseID) {
      setSearchActive('');
    }
    if (curCC !== item.CourseID) {
      setTargetsRec([]);
    }
    setCurCC(item.UID);
    setMenuCName(item.Name);
    setAnchorEl(null);
    setAnchorSEl(null);
    setAnchorCEl(null);
    setAnchorRTEl(null);
  };

  const handleRecTypeClose = (item) => {
    //console.log(item);
    if (recType !== item) {
      setTargetsRec([]);
    }
    setRecType(item);
    setAnchorEl(null);
    setAnchorSEl(null);
    setAnchorCEl(null);
    setAnchorRTEl(null);
  };

  const getSchool = (school) => {
    let tempTarget = {
      school_code: school.school_code,
      school_type: school.school_type,
      id_number: teacherInfo.id_number,
      call_component: 'teacher/src/components/manager/rec/editor'
    };
    try {
      fetch(dbUrl.concat('/classroom/getIMDBClassANDCourse'), {
        method: 'POST',
        body: JSON.stringify(tempTarget),
        headers: {
          'content-type': 'application/json'
        },
      }).then(
        r => r.json()
      ).then(r => {
        //console.log(r)
        if(!r || [].concat(r).length === 0) {
          setMenuCName('沒有相關班級');
          setClassActive('disabled');
          setSearchActive('disabled');
          setTargetsRec([].concat([]));
        }
        setClasses([].concat(r));
        setClassLoading(false)
      })
    } catch(error){
      console.log(error)
    }
    // fetch(
    //   envConfig.SERVICE_URL.getIMDBAccessToken.replace(
    //     /_ACCESS_TOKEN_/gi,
    //     state.accessToken,
    //   ),
    // )
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log(response);
    //     Promise.all([
    //       new Promise((rs) => {
    //         fetch(
    //           envConfig.SERVICE_URL.getIMDBClass
    //             .replace(/_DSNS_/gi, school.school_code)
    //             .replace(/_ACCESS_TOKEN_/gi, response.access_token)
    //             .replace(/_ID_NUMBER_/gi, teacherInfo.id_number),
    //         )
    //           .then((response) => response.json())
    //           .then((response) => {
    //             console.log(response);
    //             rs(
    //               response.Class.map((item) => ({
    //                 ...item,
    //                 key: item.ClassID,
    //                 avatar: item.ClassName.substr(0, 1),
    //                 SchoolCode: school.school_code,
    //                 Type: 'Class',
    //                 UID: item.ClassID,
    //                 Name: item.ClassName,
    //               })),
    //             );
    //           })
    //           .catch(() => {
    //             rs([]);
    //           });
    //       }),
    //       new Promise((rs) => {
    //         fetch(
    //           envConfig.SERVICE_URL.getIMDBCourse
    //             .replace(/_DSNS_/gi, school.school_code)
    //             .replace(/_ACCESS_TOKEN_/gi, response.access_token)
    //             .replace(/_ID_NUMBER_/gi, teacherInfo.id_number),
    //         )
    //           .then((response) => response.json())
    //           .then((response) => {
    //             console.log(response);
    //             rs(
    //               response.Course.map((item) => ({
    //                 ...item,
    //                 key: item.CourseID,
    //                 avatar: item.CourseName.substr(0, 1),
    //                 SchoolCode: school.school_code,
    //                 Type: 'Course',
    //                 UID: item.CourseID,
    //                 Name: item.CourseName,
    //               })),
    //             );
    //           })
    //           .catch(() => {
    //             rs([]);
    //           });
    //       }),
    //     ]).then(([classes, courses]) => {
    //       setClasses([].concat(classes).concat(courses));

    //     });
    //   });
  };

  const getScoreRec = () => {
    //console.log(state);
    fetch(dbUrl.concat('/classroom/getScoreRec'), {
      // fetch('http://localhost:8088/classroom/getScoreRec', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
      r.forEach(i => {
        i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
      });
      setTargetsRec(r);
    })
  };

  const getRec = (i) => {
    //console.log(i);
    //console.log(scheduleE);
    //console.log(scheduleS);

    setSearchActive('disabled');
    setClick(true);
    setTargetsRec([]);
    setESchedule(Date.parse(scheduleE));
    setSSchedule(Date.parse(scheduleS));

    //console.log(scheduleE);
    //console.log(scheduleS);
    if (scheduleE < scheduleS) {
      alert('結束時間不得小於開始時間，請重新選擇');
      setSSchedule(moment(scheduleS).format('YYYY-MM-DD'));
      setESchedule(moment(scheduleE).format('YYYY-MM-DD'));
      setClick(false);
      return
    }

    let targetSearch = {
      school_code: curSchool,
      room_id: curCC.toString(),
      teacher_identity: state.author.id_number,
      start_time: moment(scheduleS).format('YYYY-MM-DD 00:00:00'),
      end_time: moment(scheduleE).format('YYYY-MM-DD 23:59:59'),
      call_component:'teacher/src/components/manager/rec/editor'
    }
    //console.log(targetSearch);
    setSSchedule(moment(scheduleS).format('YYYY-MM-DD'));
    setESchedule(moment(scheduleE).format('YYYY-MM-DD'));

    switch (recType) {
      case '課堂表現':
        if (i === 'search') {
          setColumn(scoreColumns);
        }
        setHeader(scroeHeaders);

        fetch(dbUrl.concat('/classroom/getScoreRec'), {
          // fetch('http://localhost:8088/classroom/getScoreRec2', {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
            });
          }
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;

      case '學生分組':
        if (i === 'search') {
          setColumn(groupColumns);
        }
        setHeader(groupHeaders);
        fetch(dbUrl.concat('/classroom/getGroupRec'), {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
            });
          }
          //console.log(r);
          r.forEach(item => {
            item.stuList = '';
            item.results.forEach((stu, index) => {
              if (index == item.results.length) {
                item.stuList = item.stuList + stu.SeatNo + ':' + stu.Name;
              } else {
                item.stuList = item.stuList + stu.SeatNo + ':' + stu.Name + ' ; ';
              }
              //console.log(item.stuList);
            })
          })
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;

      case '隨機抽人':
        if (i === 'search') {
          setColumn(randomColumns);
        }
        setHeader(randomHeaders);
        fetch(dbUrl.concat('/classroom/getRandomRec'), {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
            });
          }
          //console.log(r);
          r.forEach(item => {
            item.stuList = '';
            item.results.forEach((stu, index) => {
              if (index == item.results.length) {
                item.stuList = item.stuList + stu.SeatNo + ':' + stu.Name;
              } else {
                item.stuList = item.stuList + stu.SeatNo + ':' + stu.Name + ' ; ';
              }
              //console.log(item.stuList);
            })
          })
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;
      case '快問快答':
        if (i === 'search') {
          setColumn(quizColumns);
        }
        setHeader(quizHeaders);
        fetch(dbUrl.concat('/classroom/getQuizRec'), {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
            });
          }
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;
      // case '上線紀錄':
      case '課間呼叫':
        if (i === 'search') {
          setColumn(checkInColumns);
        }
        setHeader(checkInHeaders);
        fetch(dbUrl.concat('/classroom/getAttendanceRec'), {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              i.creat_time = moment(i.creat_time).format('MM/DD HH:mm');
            });
          }
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;

        case '上線紀錄':
        if (i === 'search') {
          setColumn(attendColumns);
        }
        setHeader(attendHeaders);
        fetch(dbUrl.concat('/classroom/getAttendRec'), {
          method: 'POST',
          body: JSON.stringify(targetSearch),
          headers: {
            'content-type': 'application/json'
          },
        }).then(
          r => r.json()
        ).then(r => {
          if (r) {
            r.forEach(i => {
              if(i.enter_time) {
                i.enter_time = moment(i.enter_time).format('MM/DD HH:mm');
              }
              if(i.last_update) {
                i.last_update = moment(i.last_update).format('MM/DD HH:mm');
              }
              
            });
          }
          //console.log(r);
          setTargetsRec(r);
          setSSchedule(moment(scheduleS).format('YYYY-MM-DDThh:mm'));
          setESchedule(moment(scheduleE).format('YYYY-MM-DDThh:mm'));
          setSearchActive('');
          setClick(false);
        })
        break;
      default:
        break;
    }

  };


  //console.log(targetsRec);
  // if (targetsRec.length === 0 || !targetsRec || targetsRec == null) {
  //   return (
  if (schools.length === 0) {
    return (
      <div className={styles.loading}>
        <CircularProgress size="3rem" color="primary" />
      </div>
    );
  } else {
    return (
      // <div className={styles.content}>
      <div className={styles.root}>
        <div className={styles.priorityrow}>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
          >
            選擇學校：
                </Typography>
          <Button
            aria-controls="school-menu"
            aria-haspopup="true"
            onClick={handleSClick}
            endIcon={<ArrowDownIcon />}
            variant="outlined"
          >
            {menuSName}
          </Button>
          <Menu
            id="school-menu"
            anchorEl={anchorSEl}
            keepMounted
            open={Boolean(anchorSEl)}
            onClose={handleClose}
          >
            {schools.length === 0 && (
              <MenuItem>
                讀取中...
              </MenuItem>)}
            {schools.map((item) => (
              <MenuItem onClick={() => handleSchoolClose(item)}>{item.school_name}</MenuItem>
            ))}
          </Menu>
        </div>

        <div className={styles.typerow}>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
          >
            選擇班級或課程：
                </Typography>
          <Button
            aria-controls="class-menu"
            aria-haspopup="true"
            endIcon={<ArrowDownIcon />}
            variant="outlined"
            onClick={handleCClick}
            disabled={classActive}>
            {menuCName}
          </Button>
          
          <Menu
            id="class-menu"
            anchorEl={anchorCEl}
            keepMounted
            open={Boolean(anchorCEl)}
            onClose={handleClose}
          >
            {classes.length === 0 || !schools[0].school_code && (
              <MenuItem>
                沒有班級或課程
              </MenuItem>)}
            {classes.map((item) => (
              <MenuItem onClick={() => handleClassClose(item)}>{item.Name}</MenuItem>
            ))}
          </Menu>
        </div>
        <div>
          <Typography
              color="secondary"
            >
              <ul>
                <li>
                  每個班級及課程選項，代表查看使用該班級或課程進入課「課堂互動工具」的歷程紀錄。
                </li>
                <li>
                  例：選擇「5年3班」僅能查看使用「5年3班」進入「課堂互動工具」的紀錄。
                </li>
              </ul>
            
            </Typography>
        </div>

        <div className={styles.typerow}>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
          >
            選擇資料類型：
                </Typography>
          <Button
            aria-controls="type-menu"
            aria-haspopup="true"
            endIcon={<ArrowDownIcon />}
            variant="outlined"
            onClick={handleRTClick}
          >
            {recType}
          </Button>
          <Menu
            id="type-menu"
            anchorEl={anchorRTEl}
            keepMounted
            open={Boolean(anchorRTEl)}
            onClose={handleClose}
          >
            {recTypeList.map((item) => (
              <MenuItem onClick={() => handleRecTypeClose(item)}>{item}</MenuItem>
            ))}
          </Menu>
        </div>

        <div className={styles.schedulerow}>
          <TextField
            label="開始時間"
            type="date"
            defaultValue={defscheduleS}
            margin="normal"
            variant="outlined"
            onChange={(event) =>{
                setSSchedule(Date.parse(event.target.value))
                setTargetsRec([])
              }
            }
          />
        </div>
        <div className={styles.schedulerow}>
          <TextField
            label="結束時間"
            type="date"
            defaultValue={defscheduleE}
            margin="normal"
            variant="outlined"
            onChange={(event) => {
              setESchedule(Date.parse(event.target.value));
              setTargetsRec([]);
              }
            }
          />
        </div>
        <div style={{display: 'flex', flexDirection:'row'}}>
          <div className={classes.btntrow}>
            <Button
              startIcon={<SendIcon />}
              variant="contained"
              color="primary"
              onClick={() => getRec('search')}
              disabled={searchActive}
            >
              查詢
            </Button>
          </div>
          {/* <div className={styles.top}>
            {targetsRec.length !== 0 && (
              <CSVLink
                data={targetsRec}
                headers={curheaders}
                filename={`${menuCName}_${recType}.csv`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  startIcon={<GetAppIcon />}
                  variant="contained"
                  color="primary"
                >下載</Button>
              </CSVLink>
            )}
          </div> */}
          <div style={{marginLeft:'8px'}}>
              <CSVLink
                data={targetsRec}
                headers={curheaders}
                filename={`${menuCName}_${recType}.csv`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  startIcon={<GetAppIcon />}
                  variant="contained"
                  color="primary"
                  disabled= {targetsRec.length !== 0 ? false : true } 
                >下載</Button>
              </CSVLink>
          </div>
        </div>
        {/* <div className={styles.top}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="課堂表現" />
            <Tab label="學生分組" />
            <Tab label="隨機抽人" />
            <Tab label="快問快答" />
            <Tab label="上線紀錄" />
          </Tabs>
        </Paper>
      </div> */}
        <div className={styles.top}>
          {clicking && (
            <div style={{ height: 300, width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #ccc', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}

          {targetsRec.length === 0 && clicking === false && (
            <div style={{ height: 300, width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #ccc', justifyContent: 'center' }}>
              <CustomNoRowsOverlay />
            </div>)}

          {targetsRec.length !== 0 && (
            <div style={{ height: 500, width: '90%', alignItems: 'center' }}>
              <DataGrid
                pagination
                pageSize={10}
                rows={targetsRec}
                columns={column}
                disableSelectionOnClick
                components={{
                  pagination: CustomPagination,
                }}
                 />
            </div>)}
        </div>
      </div>
    );
  }
}
export default Component;
