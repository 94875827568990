import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  VolumeUp as VolumeUpIcon,
} from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  classroom: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  broadcast: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  moreButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreNormalDot: {
    color: 'rgba(0, 0, 0, .38)',
    margin: theme.spacing(0, 0.25),
  },
  moreCurrentDot: {
    color: 'rgba(0, 0, 0, 1)',
    margin: theme.spacing(0, 0.25),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 480,
  },
  titleText: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#000000',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
  },
  contentText: {
    flex: 1,
    fontSize: '1.5rem',
    // color: '#f1f1f1',
    color: '#000000',
    lineHeight: '2rem',
    overflowY: 'auto',
    marginTop: theme.spacing(2),
  },
  timeText: {
    fontSize: '1.2rem',
    color: '#000000',
    marginTop: theme.spacing(2),
  },
  messageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    height: 60,
  },
  messageText: {
    fontSize: '2rem',
    color: '#000000',
  },
}));

const columns = [
  { field: 'time', headerName: '推送時間', type: 'dateTime', width: 150 },
  { field: 'content', headerName: '廣播內容', width: 549 },
];

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [current, setCurrent] = useState(-1);
  const [origins, setOrigins] = useState([]);
  const [items, setItems] = useState([]);
  const [timestamp, setTimestamp] = useState(15);
  const [open, setOpen] = useState(false);
  const [contentOpen, setContentOpen] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);
  const [viewItems, setViewItems] = useState([]);
  const [clock, setClock] = useState(moment());
  const [threeDay, setThreeDay] = useState([]);
  const [selectItem, setSelectItem] = useState({});

  useEffect(() => {
    let unsubscribe1 = () => {};

    const unsubscribe2 = firebase
      .firestore()
      .collection('broadcasts')
      .where('schoolCode', '==', state.device.schoolCode)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          if (item.targets.includes(state.device.id)) {
            data.push({
              ...item,
              key: doc.id,
              schedule:
                item.schedule !== 'now'
                  ? item.schedule
                  : Date.parse(item.timestamp.toDate()),
              time: moment(
                item.schedule !== 'now'
                  ? item.schedule
                  : Date.parse(item.timestamp.toDate()),
              ).format('YYYY-MM-DD HH:mm'),
              url: (() => {
                if (item.type === 'video') {
                  const regYoutube = item.url.match(
                    /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
                  );
                  const regVimeo = item.url.match(
                    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
                  );

                  let url = '';
                  if (regYoutube) {
                    url = `https://www.youtube.com/embed/${regYoutube[1]}`;
                  } else if (regVimeo) {
                    url = `https://player.vimeo.com/video/${regVimeo[5]}`;
                  }
                  return url;
                }
              })(),
            });
          }
        });
        data.sort((x, y) => (y.schedule > x.schedule ? 1 : -1));

        if (data.length > 0) {
          const now = Date.now();
          let temp = moment().add(-3, 'd').valueOf();

          setOrigins([...data]);
          // setItems([...data.filter((item) => item.schedule < now).slice(0, 5)]);
          setItems([...data.filter((item) => item.schedule > temp)]);

          let threeDay = [...data.filter((item) => item.schedule > temp)];
          setThreeDay([].concat(threeDay));
          if (threeDay.length === 0) {
            setCurrent(-1);
          } else {
            setCurrent(0);
          }

          unsubscribe1 = firebase
            .firestore()
            .doc(`/pubsubs/broadcast.${state.device.schoolCode}`)
            .onSnapshot((doc) => {
              if (doc.exists) {
                const now = Date.now();
                setItems(
                  //data.filter((item) => item.schedule < now).slice(0, 5),
                  data.filter((item) => item.schedule > temp),
                );

                let threeDay = [...data.filter((item) => item.schedule > temp)];
                setThreeDay([].concat(threeDay));
                if (threeDay.length === 0) {
                  setCurrent(-1);
                } else {
                  setCurrent(0);
                }
              }
            });
        }
      });

    const interval = setInterval(() => {
      setClock(moment());
    }, 1000);

    return () => {
      unsubscribe1();
      unsubscribe2();
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let _timestamp = 15;
    const interval = setInterval(() => {
      _timestamp -= 1;

      if (_timestamp === 0) {
        let temp = moment().add(-3, 'd').valueOf();
        let threeDayList = origins.filter((item) => item.schedule > temp);
        //console.log(origins.filter((item) => item.schedule > temp));
        if (threeDayList.length === 0) {
          setCurrent(-1);
        } else {
          setCurrent((current) =>
            current + 1 < items.length ? current + 1 : 0,
          );
          _timestamp = 15;
        }
        // setCurrent((current) => (current + 1 < items.length ? current + 1 : 0));
        // _timestamp = 15;
      }

      setTimestamp(_timestamp);
    }, 1000);

    return () => clearInterval(interval);
  }, [items]);

  const previousItem = () => {
    const index = viewIndex - 1 >= 0 ? viewIndex - 1 : 0;
    setViewIndex(index);
  };

  const nextItem = () => {
    const index =
      viewIndex + 1 < viewItems.length ? viewIndex + 1 : viewItems.length - 1;
    setViewIndex(index);
  };

  const openList = () => {
    if (origins.length === 0) {
      return;
    }
    // console.log(viewItems);
    // console.log(origins);
    // console.log(
    //   threeDay
    // );
    let originsList = origins;

    let threeDayList = threeDay;
    // threeDayList.forEach(td => {
    originsList.forEach((td) => {
      if (td.type === 'video') {
        td.content = '內容是影片，請點選顯示更多觀看';
        td.typeTxt = '影片';
      } else {
        td.typeTxt = '文字';
      }
      td.id = td.schedule;
      switch (td.priority) {
        case 'normal':
          td.priorityTxt = '一般';
          break;
        case 'high':
          td.priorityTxt = '緊急';
          break;
        default:
          break;
      }
    });
    //console.log(originsList);
    setOrigins(originsList);
    setOpen(true);
  };

  const selectBroadcast = (event) => {
    const selectID = event.rowIds[0];
    const originsList = [].concat(origins);
    const selItem = originsList.find((item) => item.id.toString() === selectID);
    //console.log(selItem);
    setSelectItem(selItem);
    setContentOpen(true);
  };

  const renderContentDialog = () =>
    contentOpen && (
      <>
        {selectItem.type !== 'video' && (
          <Dialog
            maxWidth="ls"
            fullWidth="true"
            open={contentOpen}
            onClose={() => setContentOpen(false)}
          >
            <DialogContent style={{ minWidth: '700px', height: '600px' }}>
              <Typography
                variant="h5"
                color="textSecondary"
                gutterBottom
                style={{ wordBreak: 'break-all' }}
              >
                {selectItem.time}
              </Typography>
              {/* {selectItem.type !== 'video' && ( */}
              <DialogContentText
                style={{
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-line',
                  marginTop: 10,
                  fontSize: '40px',
                }}
              >
                {selectItem.content}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
        {selectItem.type === 'video' && (
          <Dialog open={contentOpen} onClose={() => setContentOpen(false)}>
            <DialogContent style={{ minWidth: 320 }}>
              <Typography
                variant="h5"
                color="textSecondary"
                gutterBottom
                style={{ wordBreak: 'break-all' }}
              >
                {selectItem.time}
              </Typography>
              <iframe
                title="Broadcast"
                src={selectItem.url}
                style={{
                  width: 552,
                  height: 310,
                  border: 'none',
                }}
              />
            </DialogContent>
          </Dialog>
        )}
        {/* </DialogContent> */}
      </>
    );

  const renderDialog = () =>
    open && (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xl"
        // fullWidth='true'
      >
        <DialogContent
          style={{ minWidth: '700px', maxWidth: '700px', height: '600px' }}
        >
          {renderContentDialog()}
          <DataGrid
            rows={threeDay}
            columns={columns}
            hideFooterRowCount="true"
            pageSize={10}
            hideFooterSelectedRowCount
            onSelectionChange={selectBroadcast}
          />
        </DialogContent>
      </Dialog>
    );

  return (
    <>
      {current === -1 ? (
        <Paper
          elevation={3}
          style={{
            display: 'flex',
            borderRadius: '16px',
            flexDirection: 'row',
          }}
          onClick={() => {
            // setOpen(true);
            openList();
          }}
        >
          <div
            style={{
              backgroundColor: '#81D8D0',
              borderRadius: '15px 0px 0px 15px',
            }}
          >
            <VolumeUpIcon
              style={{
                fontSize: 60,
                padding: 6,
                color: 'white',
              }}
            />
          </div>
          {/* {renderDialog()} */}
          <Typography variant="h5" noWrap style={{ padding: '20px' }}>
            還沒有任何廣播耶!
            {/* <div className={classes.messageText}>還沒有任何廣播耶!</div> */}
          </Typography>
        </Paper>
      ) : (
        <Paper
          elevation={3}
          onClick={() => {
            // setOpen(true);
            openList();
          }}
          style={{
            display: 'flex',
            borderRadius: '15px',
            flexDirection: 'row',
          }}
        >
          {renderDialog()}
          <div
            style={{
              backgroundColor: '#81D8D0',
              borderRadius: '15px 0px 0px 15px',
            }}
          >
            <VolumeUpIcon
              style={{
                fontSize: 60,
                padding: 6,
                color: 'white',
              }}
              onClick={() => {
                // setOpen(true);
                openList();
              }}
            />
          </div>
          <Fade in={timestamp > 2} timeout={2000}>
            {items[current] ? (
              <Typography
                key={items[current].key}
                variant="h5"
                noWrap
                style={{ padding: '20px' }}
                onClick={() => {
                  // setOpen(true);
                  openList();
                }}
              >
                {items[current].content || '內容是影片，請點選顯示更多觀看'}
                {/* <div className={classes.timeText}>{items[current].time}</div> */}
              </Typography>
            ) : (
              <div />
            )}
          </Fade>
        </Paper>
      )}
    </>
  );
};

export default Component;
