export default {
  // develope
  // SERVER_URL: 'http://localhost:8088',
  // BASE_URL: 'http://localhost:8088/teacher',
  // AUTHORIZE_URL:
  //   'https://cooc.tp.edu.tw/oauth/authorize?client_id=b54ab7829300e4c31f30a89a2dd7f87142686dd6&response_type=code&redirect_uri=http://localhost:8088/service/authback&scope=User.Info,User.IDNumber,User.EMail,User.Role,User.RoleDetail,User.SSORole&state=teacher',
  // SERVICE_URL: {
  //   getUserToken:
  //     'http://localhost:8088/service/getUserToken?access_token=_ACCESS_TOKEN_',
  //   getUserInfo:
  //     'http://localhost:8088/service/getUserInfo?access_token=_ACCESS_TOKEN_',
  //   getIMDBAccessToken:
  //     'http://localhost:8088/service/getIMDBAccessToken?access_token=_ACCESS_TOKEN_',
  //   getIMDBClass:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClass?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
  //   getIMDBCourse:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourse?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
  //   getIMDBClassStudent:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClassStudent?access_token=_ACCESS_TOKEN_&class_id=_CLASS_ID_',
  //   getIMDBCourseStudent:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourseStudent?access_token=_ACCESS_TOKEN_&course_id=_COURSE_ID_',
  // },

  // production
  SERVER_URL: 'https://classroom.tp.edu.tw',
  BASE_URL: 'https://classroom.tp.edu.tw/teacher',
  AUTHORIZE_URL:
    'https://cooc.tp.edu.tw/oauth/authorize?client_id=412f99fe433302848f0af0e75d5b90b10d740a82&response_type=code&redirect_uri=https://classroom.tp.edu.tw/service/authback&scope=User.Info,User.IDNumber,User.EMail,User.Role,User.RoleDetail,User.SSORole&state=teacher',
  SERVICE_URL: {
    getUserToken:
      'https://classroom.tp.edu.tw/service/getUserToken?access_token=_ACCESS_TOKEN_',
    getUserInfo:
      'https://classroom.tp.edu.tw/service/getUserInfo?access_token=_ACCESS_TOKEN_',
    getIMDBAccessToken:
      'https://classroom.tp.edu.tw/service/getIMDBAccessToken?access_token=_ACCESS_TOKEN_',
    getIMDBClass:
      'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClass?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
    getIMDBCourse:
      'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourse?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
    getIMDBClassStudent:
      'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClassStudent?access_token=_ACCESS_TOKEN_&class_id=_CLASS_ID_',
    getIMDBCourseStudent:
      'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourseStudent?access_token=_ACCESS_TOKEN_&course_id=_COURSE_ID_',
  },

  // debug
  // SERVER_URL: 'https://classroom-test.tp.edu.tw',
  // BASE_URL: 'https://classroom-test.tp.edu.tw/teacher',
  // AUTHORIZE_URL:
  //   'https://cooc.tp.edu.tw/oauth/authorize?client_id=412f99fe433302848f0af0e75d5b90b10d740a82&response_type=code&redirect_uri=https://classroom.tp.edu.tw/service/authback&scope=User.Info,User.IDNumber,User.EMail,User.Role,User.RoleDetail,User.SSORole&state=teacher',
  // SERVICE_URL: {
  //   getUserToken:
  //     'https://classroom-test.tp.edu.tw/service/getUserToken?access_token=_ACCESS_TOKEN_',
  //   getUserInfo:
  //     'https://classroom-test.tp.edu.tw/service/getUserInfo?access_token=_ACCESS_TOKEN_',
  //   getIMDBAccessToken:
  //     'https://classroom-test.tp.edu.tw/service/getIMDBAccessToken?access_token=_ACCESS_TOKEN_',
  //   getIMDBClass:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClass?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
  //   getIMDBCourse:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourse?access_token=_ACCESS_TOKEN_&teacher_id_number=_ID_NUMBER_',
  //   getIMDBClassStudent:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetClassStudent?access_token=_ACCESS_TOKEN_&class_id=_CLASS_ID_',
  //   getIMDBCourseStudent:
  //     'https://imdb-api.tp.edu.tw/api/_DSNS_/GetCourseStudent?access_token=_ACCESS_TOKEN_&course_id=_COURSE_ID_',
  // },
};
