import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as ConfirmIcon,
  DoneAll as ScoreIcon,
  HighlightOffOutlined as CancleIcon,
  Group as GroupIcon,
  MoreVert as MoreIcon,
  Person as StudentIcon,
  Shuffle as RandomIcon,
  Tune as SettingIcon,
  Wifi as OnlineIcon,
  WifiOff as OfflineIcon,
} from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import envConfig from '../../../config';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 2),
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  setting: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  noneItems: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 2, 0),
  },
  balanceAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  plusAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  negAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));
const dbUrl = envConfig.SERVER_URL;
let i = 1;

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [mode, setMode] = useState('loading');
  const [scores, setScores] = useState({});
  const [students, setStudents] = useState([]);
  const [randoms, setRandoms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputTextStudents, setInputTextStudents] = useState('');
  const [studentAnchorEl, setStudentAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [batchScore, setBatchScore] = useState(false);
  const [batchs, setBatchs] = useState([]);

  useEffect(() => {
    //console.log(state)
    if (!state.target) return;

    fetchStudents();

    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setScores(doc.data());
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();
          setStudents((students) => {
            return students.map((student) => ({
              ...student,
              online: item[`student_${student.ID}`].status === 'V',
            }));
          });
        }
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
    // eslint-disable-next-line
  }, [state.target]);

  useEffect(() => {
    if (!students) return;
    // 學生上線狀態實際回饋
    if(students.length>0 && i === 1){
      i++
      const timestamp = Date.now();
      const event = { timestamp, status: 'open' };
      students.forEach((student) => {
        event[`student_${student.ID}`] = {
          className: student.ClassName,
          seatNo: student.SeatNo,
          name: student.Name,
          status: 'X',
          timestamp: '',
        };
      });
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
        .set(event);
    }
    // 學生上線狀態實際回饋

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/mode`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { mode } = doc.data();

          if (mode === 'random') {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  const { target, results } = doc.data();
                  if (['全班', '座號'].includes(target)) {
                    setRandoms([
                      {
                        caption: '',
                        data: students.filter((student) =>
                          results.join().split(',').includes(`${student.ID}`),
                        ),
                      },
                    ]);
                    setMode(mode);
                  } else {
                    setRandoms(
                      results.map((item, index) => ({
                        caption: `第 ${index + 1} 組 ${
                          item.split(',').length
                        } 人`,
                        data: students.filter((student) =>
                          item.split(',').includes(`${student.ID}`),
                        ),
                      })),
                    );
                    setMode(mode);
                  }
                } else {
                  setRandoms([]);
                  setMode(mode);
                }
              });
          } else if (mode === 'group') {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  const { results } = doc.data();
                  setGroups(
                    [].concat(results).map((item, index) => ({
                      caption: `第 ${index + 1} 組 ${
                        item.split(',').length
                      } 人`,
                      data: students.filter((student) =>
                        item.split(',').includes(`${student.ID}`),
                      ),
                    })),
                  );
                  setMode(mode);
                } else {
                  setGroups([]);
                  setMode(mode);
                }
              });
          } else {
            setMode(mode);
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [students]);



  //const 

  const fetchStudents = (reload = false) => {
    // if (!reload && window.localStorage.getItem('students')) {
    //   const students = JSON.parse(window.localStorage.getItem('students'));
    //   setStudents(students);
    //   dispatch({ type: 'SET_STUDENTS', payload: students });
    //   setMode('student');
    //   return;
    // }

    setMode('loading');

    if (state.target.type.toLowerCase() === 'custom') {
      const students = [];
      if (typeof state.target.classroom.students === 'number') {
        for (let i = 0; i < state.target.classroom.students; i += 1) {
          students.push({
            ID: i + 1,
            ClassName: '',
            Name: `學生 ${i + 1}`,
            SeatNo: i + 1,
          });
        }
      } else {
        const names = state.target.classroom.students.split(',');
        names.forEach((name, i) => {
          if (name) {
            students.push({
              ID: i + 1,
              ClassName: '',
              Name: `${i + 1} ${name.trim()}`,
              SeatNo: i + 1,
            });
          }
        });
      }

      // window.localStorage.setItem('students', JSON.stringify(students));

      setStudents(students);
      dispatch({ type: 'SET_STUDENTS', payload: students });
      setMode('student');
    } else {
  
      let classroomType = state.target.classroom.type.toLowerCase() === 'class' ? 'getIMDBClassStudent' : 'getIMDBCourseStudent';
          let target = {
            school_code: state.target.dsns,
            school_type: state.target.schoolType,
            id_number: '',
            //access_token: response.access_token,
            accessToken: state.accessToken,
            cc_id: state.target.classroom.uid,
            call_component:'teacher/src/components/teach/main/students'
          }
          //console.log(target);
          try {
            fetch(dbUrl.concat(`/classroom/${classroomType}`), {
              method: 'POST',
              body: JSON.stringify(target),
              headers: {
                'content-type': 'application/json'
              },
            }).then(
              r => r.json()
            ).then(r => {
              //console.log(r)
              const target = r[state.target.classroom.type][0] || {};
              const students = []
                .concat(target.Student || [])
                .map((student, idx) => ({
                  ID: student.IDNumber,
                  ClassName: student.ClassName ? student.ClassName : target.ClassName,
                  Name: student.StudentName,
                  SeatNo: student.SeatNo,
                  StudentNumber: student.StudentNumber,
                  DSNS: true,
                }));

              window.localStorage.setItem('students', JSON.stringify(students));
              //console.log(students)
              setStudents(students);
              dispatch({ type: 'SET_STUDENTS', payload: students });
              setMode('student');
            })
          } catch(error){
            console.log(error)
          }

      // fetch(
      //   envConfig.SERVICE_URL.getIMDBAccessToken.replace(
      //     /_ACCESS_TOKEN_/gi,
      //     state.accessToken,
      //   ),
      // )
      //   .then((response) => response.json())
      //   .then((response) => {
      //     //console.log(state)
      //     let classroomType = state.target.classroom.type.toLowerCase() === 'class' ? 'getIMDBClassStudent' : 'getIMDBCourseStudent';
      //     let target = {
      //       school_code: state.target.dsns,
      //       school_type: state.target.schoolType,
      //       id_number: '',
      //       //access_token: response.access_token,
      //       accessToken: state.accessToken,
      //       cc_id: state.target.classroom.uid,
      //       call_component:'teacher/src/components/teach/main/students'
      //     }
      //     //console.log(target);
      //     try {
      //       fetch(dbUrl.concat(`/classroom/${classroomType}`), {
      //         method: 'POST',
      //         body: JSON.stringify(target),
      //         headers: {
      //           'content-type': 'application/json'
      //         },
      //       }).then(
      //         r => r.json()
      //       ).then(r => {
      //         //console.log(r)
      //         const target = r[state.target.classroom.type][0] || {};
      //         const students = []
      //           .concat(target.Student || [])
      //           .map((student, idx) => ({
      //             ID: student.IDNumber,
      //             ClassName: student.ClassName ? student.ClassName : target.ClassName,
      //             Name: student.StudentName,
      //             SeatNo: student.SeatNo,
      //             StudentNumber: student.StudentNumber,
      //             DSNS: true,
      //           }));

      //         window.localStorage.setItem('students', JSON.stringify(students));
      //         //console.log(students)
      //         setStudents(students);
      //         dispatch({ type: 'SET_STUDENTS', payload: students });
      //         setMode('student');
      //       })
      //     } catch(error){
      //       console.log(error)
      //     }

    }
  };

  const toggleMode = (mode) => {
    setMode('loading');
    setMenuAnchorEl(null);

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/mode`).set({
      mode,
      timestamp: Date.now(),
    });
  };

  const plus = () => {
    let score = scores[`student_${selected.ID}`] || 0;
    score += 1;

    let targetStu = {
      school_code: state.myInfo.sso_role[0].role[0].school_code,
      school_name: state.myInfo.sso_role[0].role[0].school_name,
      school_type: state.myInfo.sso_role[0].role[0].school_type,
      room_type: state.target.classroom.type,
      room_id: state.target.classroom.uid,
      room_name: state.target.classroom.name,
      teacher_identity: state.myInfo.id_number,
      teacher_name: state.myInfo.first_name,
      stud_identity: selected.ID,
      stud_name: selected.Name,
      stud_no: selected.StudentNumber,
      seat_no: selected.SeatNo,
      score_by: "手動",
      score: 1,
      call_component: 'teacher/src/components/teach/main/students'
    };
    //console.log(targetStu);
    fetch(dbUrl.concat('/classroom/addScoreRec'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetStu)
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update({
        [`student_${selected.ID}`]: score,
        target: {
          name: selected.Name,
          score: 1,
        },
        timestamp: Date.now(),
      });

    setTimeout(() => {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/score`)
        .update({
          target: firebase.firestore.FieldValue.delete(),
          timestamp: Date.now(),
        });
    }, 3000);

    setSelected(null);
    setStudentAnchorEl(null);
  };

  const neg = () => {
    let score = scores[`student_${selected.ID}`] || 0;
    score -= 1;
    //console.log(state);
    let targetStu = {
      school_code: state.myInfo.sso_role[0].role[0].school_code,
      school_name: state.myInfo.sso_role[0].role[0].school_name,
      school_type: state.myInfo.sso_role[0].role[0].school_type,
      room_type: state.target.classroom.type,
      room_id: state.target.classroom.uid,
      room_name: state.target.classroom.name,
      teacher_identity: state.myInfo.id_number,
      teacher_name: state.myInfo.first_name,
      stud_identity: selected.ID,
      stud_name: selected.Name,
      stud_no: selected.StudentNumber,
      seat_no: selected.SeatNo,
      score_by: "手動",
      score: -1,
      call_component: 'teacher/src/components/teach/main/students'
    }
    //console.log(targetStu);
    fetch(dbUrl.concat('/classroom/addScoreRec'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetStu)
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update({
        [`student_${selected.ID}`]: score,
        target: {
          name: selected.Name,
          score: -1,
        },
        timestamp: Date.now(),
      });

    setTimeout(() => {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/score`)
        .update({
          target: firebase.firestore.FieldValue.delete(),
          timestamp: Date.now(),
        });
    }, 3000);

    setSelected(null);
    setStudentAnchorEl(null);
  };

  const plusMulti = () => {
    const event = { timestamp: Date.now() };

    batchs.forEach((item) => {
      if (item.checked) {
        event[`student_${item.ID}`] = (scores[`student_${item.ID}`] || 0) + 1;

        let targetStu = {
          school_code: state.myInfo.sso_role[0].role[0].school_code,
          school_name: state.myInfo.sso_role[0].role[0].school_name,
          school_type: state.myInfo.sso_role[0].role[0].school_type,
          room_type: state.target.classroom.type,
          room_id: state.target.classroom.uid,
          room_name: state.target.classroom.name,
          teacher_identity: state.myInfo.id_number,
          teacher_name: state.myInfo.first_name,
          stud_identity: item.ID,
          stud_name: item.Name,
          stud_no: item.StudentNumber,
          seat_no: item.SeatNo,
          score_by: "手動",
          score: 1,
          call_component: 'teacher/src/components/teach/main/students'
        };
        //console.log(targetStu);
        fetch(dbUrl.concat('/classroom/addScoreRec'), {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(targetStu)
        }).then(
          r => r.json()
        ).then(r => {
          //console.log(r);
        });
        // item:{
        //   ClassName: "五年三班"
        //   DSNS: true
        //   ID: "YA96106122"
        //   Name: "林子庸"
        //   SeatNo: 1
        //   StudentNumber: "106022"
        //   checked: false
        //   online: false
        // }
      }
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(event);

    setBatchScore(false);
  };

  const negMulti = () => {
    const event = { timestamp: Date.now() };

    batchs.forEach((item) => {
      if (item.checked) {
        event[`student_${item.ID}`] = (scores[`student_${item.ID}`] || 0) - 1;

        let targetStu = {
          school_code: state.myInfo.sso_role[0].role[0].school_code,
          school_name: state.myInfo.sso_role[0].role[0].school_name,
          school_type: state.myInfo.sso_role[0].role[0].school_type,
          room_type: state.target.classroom.type,
          room_id: state.target.classroom.uid,
          room_name: state.target.classroom.name,
          teacher_identity: state.myInfo.id_number,
          teacher_name: state.myInfo.first_name,
          stud_identity: item.ID,
          stud_name: item.Name,
          stud_no: item.StudentNumber,
          seat_no: item.SeatNo,
          score_by: "手動",
          score: -1,
          call_component: 'teacher/src/components/teach/main/students'
        };
        //console.log(targetStu);
        fetch(dbUrl.concat('/classroom/addScoreRec'), {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(targetStu)
        }).then(
          r => r.json()
        ).then(r => {
          //console.log(r);
        });
      }
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(event);

    setBatchScore(false);
  };

  const renderBatchScore = () =>
    batchScore && (
      <Dialog open={true} onClose={() => setBatchScore(false)}>
        <DialogTitle>批次加減分</DialogTitle>
        <DialogContent style={{ minWidth: 240 }}>
          <List>
            {batchs.map((student, sdx) => (
              <ListItem
                key={`batch-${student.ID}`}
                dense
                button
                onClick={() =>
                  setBatchs((batchs) =>
                    batchs.map((item) =>
                      item.ID === student.ID
                        ? { ...item, checked: !item.checked }
                        : item,
                    ),
                  )
                }
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={student.checked}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    student.DSNS
                      ? `${student.SeatNo} ${student.Name}`
                      : student.Name
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={negMulti}>
            減 1 分
          </Button>
          <Button variant="contained" color="primary" onClick={plusMulti}>
            加 1 分
          </Button>
        </DialogActions>
      </Dialog>
    );

  const download = (name, content) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(content),
    );
    element.setAttribute('download', name);
    element.style.display = 'none';
    (document.body || {}).appendChild(element);
    element.click();
    (document.body || {}).removeChild(element);
  };

  const renderContent = () => {
    switch (mode) {
      case 'loading': {
        return (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        );
      }
      case 'student': {
        return (
          <>
          {students.length > 0 ? (
            <List style={{ flex: 1 }}>
            {students.map((student, sdx) => (
              <React.Fragment key={`student-${student.ID}`}>
                <ListItem
                  button
                  onClick={(e) => {
                    setSelected(student);
                    setStudentAnchorEl(e.currentTarget);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: 'transparent' }}>
                      {student.online ? (
                        <OnlineIcon color="primary" />
                      ) : (
                        <OfflineIcon color="secondary" />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      student.DSNS
                        ? `${student.SeatNo} ${student.Name}`
                        : student.Name
                    }
                    secondary={student.ClassName}
                  />
                  {scores[`student_${student.ID}`] !== undefined && (
                    <Avatar
                      className={
                        scores[`student_${student.ID}`] === 0
                          ? classes.balanceAvatar
                          : scores[`student_${student.ID}`] > 0
                          ? classes.plusAvatar
                          : classes.negAvatar
                      }
                    >
                      {scores[`student_${student.ID}`]}
                    </Avatar>
                  )}
                </ListItem>
                {sdx !== students.length - 1 && <Divider variant="inset" />}
              </React.Fragment>
            ))}
          </List>
          ) : (
            <div style={{display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center',flex: 'auto'}}>
              <Typography variant="h6">
               沒有相關班級或課程學生
              </Typography>
            </div>
          )}
        </>
        );
      }
      case 'random': {
        return randoms.length > 0 ? (
          <List style={{ flex: 1 }}>
            {randoms.map((item, idx) => (
              <React.Fragment key={`random-${idx + 1}`}>
                <div className={classes.itemHeader}>
                  <Typography variant="h6" color="primary">
                    {item.caption}
                  </Typography>
                </div>
                {item.data.map((student, sdx) => (
                  <React.Fragment key={`student-${student.ID}`}>
                    <ListItem
                      button
                      onClick={(e) => {
                        setSelected(student);
                        setStudentAnchorEl(e.currentTarget);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          {student.online ? (
                            <OnlineIcon color="primary" />
                          ) : (
                            <OfflineIcon color="secondary" />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          student.DSNS
                            ? `${student.SeatNo} ${student.Name}`
                            : student.Name
                        }
                        secondary={student.ClassName}
                      />
                      {scores[`student_${student.ID}`] !== undefined && (
                        <Avatar
                          className={
                            scores[`student_${student.ID}`] === 0
                              ? classes.balanceAvatar
                              : scores[`student_${student.ID}`] > 0
                              ? classes.plusAvatar
                              : classes.negAvatar
                          }
                        >
                          {scores[`student_${student.ID}`]}
                        </Avatar>
                      )}
                    </ListItem>
                    {sdx !== item.data.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
                {idx !== groups.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        ) : (
          <div className={classes.noneItems}>
            <Typography variant="h4" color="secondary">
              尚無隨機抽人
            </Typography>
          </div>
        );
      }
      case 'group': {
        return groups.length > 0 ? (
          <List style={{ flex: 1 }}>
            {groups.map((item, idx) => (
              <React.Fragment key={`group-${idx + 1}`}>
                <div className={classes.itemHeader}>
                  <Typography variant="h6" color="primary">
                    {item.caption}
                  </Typography>
                </div>
                {item.data.map((student, sdx) => (
                  <React.Fragment key={`student-${student.ID}`}>
                    <ListItem
                      button
                      onClick={(e) => {
                        setSelected(student);
                        setStudentAnchorEl(e.currentTarget);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: 'transparent' }}>
                          {student.online ? (
                            <OnlineIcon color="primary" />
                          ) : (
                            <OfflineIcon color="secondary" />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          student.DSNS
                            ? `${student.SeatNo} ${student.Name}`
                            : student.Name
                        }
                        secondary={student.ClassName}
                      />
                      {scores[`student_${student.ID}`] !== undefined && (
                        <Avatar
                          className={
                            scores[`student_${student.ID}`] === 0
                              ? classes.balanceAvatar
                              : scores[`student_${student.ID}`] > 0
                              ? classes.plusAvatar
                              : classes.negAvatar
                          }
                        >
                          {scores[`student_${student.ID}`]}
                        </Avatar>
                      )}
                    </ListItem>
                    {sdx !== item.data.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
                {idx !== groups.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        ) : (
          <div className={classes.noneItems}>
            <Typography variant="h4" color="secondary">
              尚無學生分組
            </Typography>
          </div>
        );
      }
      case 'setting': {
        return (
          <div className={classes.setting}>
            <TextField
              autoFocus
              variant="outlined"
              value={inputTextStudents}
              style={{ marginBottom: 16 }}
              onInput={(e) => {
                setInputTextStudents(e.target.value);
              }}
            />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    A
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="輸入學生人數，依人數顯示【學生 1、學生 2、學生 3】" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    B
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="輸入學生姓名，使用逗號分隔【AAA,BBB,CCC】，依輸入姓名顯示【1
              AAA、2 BBB、3 CCC】"
                />
              </ListItem>
            </List>
            <div className={classes.row}>
              <IconButton color="secondary" onClick={() => setMode('student')}>
                <CancleIcon style={{ fontSize: '3rem' }} />
              </IconButton>
              <div style={{ width: theme.spacing(3) }} />
              <IconButton
                color="primary"
                onClick={() => {
                  const students = parseInt(inputTextStudents, 10);
                  state.target.classroom.students = isNaN(students)
                    ? inputTextStudents
                    : students;

                  firebase
                    .firestore()
                    .doc(`/classrooms/${state.roomId}`)
                    .update({ classroom: { ...state.target.classroom } });

                  firebase
                    .firestore()
                    .doc(`/classes/${state.target.classroom.uid}`)
                    .update({ students: state.target.classroom.students })
                    .then(() => {
                      fetchStudents(true);
                    });
                }}
              >
                <ConfirmIcon style={{ fontSize: '3rem' }} />
              </IconButton>
            </div>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {mode === 'student' && <Typography variant="h5">班級學生</Typography>}
        {mode === 'random' && <Typography variant="h5">隨機抽人</Typography>}
        {mode === 'group' && <Typography variant="h5">學生分組</Typography>}
        {mode === 'setting' && <Typography variant="h5">編輯學生</Typography>}
        <div style={{ flex: 1 }} />
        <IconButton
          color="inherit"
          onClick={() => {
            setBatchScore(true);
            setBatchs(
              students.map((student) => ({ ...student, checked: false })),
            );
          }}
        >
          <ScoreIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <ListSubheader>顯示</ListSubheader>
          <MenuItem onClick={() => toggleMode('student')}>
            <ListItemIcon>
              <StudentIcon />
            </ListItemIcon>
            <ListItemText>班級學生</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => toggleMode('random')}>
            <ListItemIcon>
              <RandomIcon />
            </ListItemIcon>
            <ListItemText>隨機抽人</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => toggleMode('group')}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText>學生分組</ListItemText>
          </MenuItem>
          <Divider variant="fullWidth" />
          <MenuItem
            onClick={() => {
              const timestamp = moment().format('MMDDHHmm');
              const content = [`學生編號,班級名稱,座號,姓名`];
              students.forEach((i) =>
                content.push(`${i.ID},${i.ClassName},${i.SeatNo},${i.Name}`),
              );

              download(
                `${state.target.classroom.name}_學生名單_${timestamp}.csv`,
                content.join('\r\n'),
              );

              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <StudentIcon />
            </ListItemIcon>
            <ListItemText>下載學生名單</ListItemText>
          </MenuItem>
          {state.target && state.target.type.toLowerCase() === 'custom' && (
            <MenuItem
              onClick={() => {
                setInputTextStudents(state.target.classroom.students);
                setMode('setting');
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <SettingIcon />
              </ListItemIcon>
              <ListItemText>編輯班級學生</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>
      <div className={classes.content}>
        {Boolean(studentAnchorEl) && (
          <Popover
            open={true}
            anchorEl={studentAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={() => {
              setSelected(null);
              setStudentAnchorEl(null);
            }}
            disableRestoreFocus
          >
            <List
              subheader={
                <ListSubheader component="div">
                  {`${selected.SeatNo} ${selected.Name}`}
                </ListSubheader>
              }
            >
              <ListItem>
                <Button variant="contained" color="secondary" onClick={neg}>
                  減 1 分
                </Button>
                <div style={{ width: theme.spacing(1) }} />
                <Button variant="contained" color="primary" onClick={plus}>
                  加 1 分
                </Button>
              </ListItem>
            </List>
          </Popover>
        )}
        {renderBatchScore()}
        {renderContent()}
      </div>
    </div>
  );
};

export default Component;
