import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Button, 
  Slide, 
  Typography,
  CircularProgress,
 } from '@material-ui/core';

import GooglePicker from 'react-google-picker';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/performance';
import envConfig from '../../../config.js'
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

//const DEVELOPER_KEY = 'AIzaSyA15KFsCnvpVETAblOT1UGeCvOLwEMq1xU';
const dbUrl = envConfig.SERVER_URL;
let accessToken = '';

const Component = ({ callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [accountSetState, setAccountSetState] = useState(false);
  const [memberInfo, setMemberInfo] = useState({});
  const [showText, setShowText] = useState('選擇檔案');

  React.useEffect(()=>{
    //console.log(state);
  },[])
  
  React.useEffect(()=>{
    //console.log(state);
    let temp = {
      author:{
        id_number:state.myInfo.id_number,
        first_name:state.myInfo.first_name,
        user_uuid: state.target.author,
      },
      accessToken:state.accessToken,
    };
    
    //uuid: "7cbd0a28-9acd-4200-b2c2-3cfd9023792b"

    fetch(dbUrl.concat('/classroom/getMyInfo'), {
      // fetch('http://localhost:8088/classroom/getScoreRec', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(temp)
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
      setMemberInfo(r);

      if(r.google_account === null || r.google_account === '' || !r.google_account){
        setAccountSetState(false);
        setShowText('尚未綁定帳號');
      } else {
        setAccountSetState(true);
        setShowText('選擇檔案');
      }

      // setAccountSetState(true);
      // setShowText('選擇檔案');
    });

    window.gapi.load('auth2');
    window.gapi.load('picker');
  },[])

  const getToken = () => {
    //console.log(state);
    fetch(dbUrl.concat('/classroom/getAccessToken'), {
      // fetch('http://localhost:8088/classroom/getScoreRec2', {
      method: 'POST',
      body: JSON.stringify(state),
      headers: {
        'content-type': 'application/json'
      },
    }).then(
      r => r.json()
    ).then( r => {
      //console.log(r);
      accessToken = r.access_token;
      buildPicker();
    });
    callback();
  };

  const buildPicker = () => {
    // 呼叫「報表列印API」
    //console.log(state);
    let targetLog = [{
     system: "課堂工具",
     action: "使用課堂工具功能",
     school_type: state.target.schoolType,
     school_code: state.target.dsns,
     role_type: "teacher",
     identity: state.appsChooseItem.title,
     call_component: 'teacher/src/components/teach/action/gdrive',
     id_number:`${state.target.classroom.name}(${state.target.schoolYear})`,
   }];
   //console.log(targetLog);
   fetch(dbUrl.concat('/classroom/postToLog'), {
     method: 'POST',
     body: JSON.stringify(targetLog),
     headers: {
       'content-type': 'application/json'
     },
   }).then(
     r => r.json()
   ).then(r => {
     //console.log(r);

   })
  //  console.log('AIzaSyBgIrx1rvC0xqDHI2oixor_DcM3DDokqeE')
  //  console.log(accessToken)
  const picker = new window.google.picker.PickerBuilder()
        //.addView(window.google.picker.ViewId.DOCS)
        .addView(window.google.picker.ViewId.DOCS)
        .addView(window.google.picker.ViewId.DOCUMENTS)
        .addView(window.google.picker.ViewId.DRAWINGS)
        .addView(window.google.picker.ViewId.FOLDERS)
        .addView(window.google.picker.ViewId.FORMS)
        .addView(window.google.picker.ViewId.PDFS)
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .addView(window.google.picker.ViewId.PRESENTATIONS)
        .setLocale('zh-TW')
        .setOAuthToken(accessToken)
        //.setOAuthToken('ya29.A0ARrdaM_yzboTfgC_MKpbdKqE3h_TlteajsQ32h6hcVBsL88ctQZc0Uch1dvfOgBjtO7aXPozMmqulX8rsNNG3Q9JFr68p98AF8dU6Uc2rBsPIxYPFWYsqXCXs7KkOVQBTXcrFEAzwFO0fELq8pBVoUyok73kcg')
        .setDeveloperKey('AIzaSyBgIrx1rvC0xqDHI2oixor_DcM3DDokqeE')
        .setCallback((e)=>{
          //console.log('Custom picker is ready!');
          //console.log(e);

          if (e.action === 'picked') {
            //const { access_token } = act;
      
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/events/main`)
              .set({
                key: 'classroom.gdrive.embedUrl',
                url: `${e.docs[0].embedUrl}?access_token=${accessToken}&overridemobile=true`,
                timestamp: Date.now(),
              });
          }
        });

    picker.build().setVisible(true);
  }

  const renderContent = () => (
    <div className={classes.section}>
      <div className={classes.content}>   
          <Button 
            variant="contained"
            size="large"
            color="primary"
            disabled={!accountSetState} 
            fullWidth
            //onClick={callback}
            onClick={() => getToken()}
            >
              {showText}
            {/* <Typography variant="h4" color="primary" >
            </Typography> */}
          </Button>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>{renderContent()}</div>
    </Slide>
  );
};

export default Component;
