import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import envConfig from './config';
import * as serviceWorker from './serviceWorker';

try {
  // const { accessToken, school } = JSON.parse( 
  const { access_token, school } = JSON.parse(
    `{"${decodeURIComponent(
      window.location.search
        .substr(1)
        .replace(/=/g, '":"')
        .replace(/&/g, '","'),
    )}"}`,
  );
  const accessToken = access_token;

  //if (accessToken) {
  if (access_token) {
    //console.log(accessToken);
    window.localStorage.setItem('tokenInfo',
      JSON.stringify({accessToken, access_token, school,}),
    );
    window.location.replace(envConfig.BASE_URL);
  }
} catch {
  // empty
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const jsYoutube = document.createElement('script');
jsYoutube.setAttribute('type', 'text/javascript');
jsYoutube.setAttribute('src', 'https://www.youtube.com/iframe_api');
jsYoutube.setAttribute('async', '');

document.getElementsByTagName('head')[0].appendChild(jsYoutube);

const jsVimeo = document.createElement('script');
jsVimeo.setAttribute('type', 'text/javascript');
jsVimeo.setAttribute('src', 'https://player.vimeo.com/api/player.js');
jsVimeo.setAttribute('async', '');

document.getElementsByTagName('head')[0].appendChild(jsVimeo);

const jsGapi = document.createElement('script');
jsGapi.setAttribute('type', 'text/javascript');
jsGapi.setAttribute('src', 'https://apis.google.com/js/api.js');
jsGapi.setAttribute('async', '');

document.getElementsByTagName('head')[0].appendChild(jsGapi);
