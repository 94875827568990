import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  ZoomOutMap as ZoomOutMapIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import moment from 'moment';
import zoomin from '../../assets/ZoomIn.svg';
import refresh from '../../assets/refresh.svg';
import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../config';
import { AppContext } from '../../App';
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    borderRadius: '15px',
    marginTop: '-20px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleIcon: {
    marginTop: '10px',
    marginRight: theme.spacing(2),
  },
  missionList: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    // marginTop: '30px',
    marginTop: '10px',
    marginBottom: '8px',
    marginLeft: '15px',
    overflow: 'auto',
    maxHeight: '75%',
  },
  missionList2: {
    display: 'flex',
    flexDirection: 'column',
    width: '1100px',
    marginTop: '10px',
    marginBottom: '4px',
    marginLeft: '15px',
    height: '360px',
    flexWrap: 'nowrap',
  },
  missionItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
    marginBottom: '8px',
    minWidth: '90%',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
    padding: '9px',
  },
  missionItem2: {
    display: 'flex',
    flexDirection: 'row',
    margin: '8px',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
    padding: '9px',
    marginRight: '10px',
  },
  missionText: {
    width: '80%',
    fontSize: '15px',
    marginLeft: '10px',
    display: 'flex',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  missionText2: {
    //width: '90%',
    //fontSize:'15px',
    marginLeft: '5px',
    overflow: 'auto',
  },
  missionList3: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '300px',
    marginTop: '10px',
    marginBottom: '4px',
    marginLeft: '15px',
    overflow: 'auto',
  },
  missionItem3: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    marginTop: '8px',
    width: '255px',
    minHeight: '55px',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
  },
  missionText3: {
    width: '90%',
    fontSize: '18px',
    marginLeft: '10px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  todayList2: {
    display: 'flex',
    flexDirection: 'row',
    width: '1170px',
    borderRadius: '10px',
    //marginLeft: theme.spacing(2),
    backgroundColor: '#f6f6f6',
  },
  formControlLabelTitle2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    //width:'40%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'blcak !important',
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: '90px',
    height: '30px',
    frontSize: '10px',
    margin: '5px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

const renderList2 = [{content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, {content: '測試測試測試測試測試測試'}, ]
const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');
const dbUrl = envConfig.SERVER_URL;
const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [renderList, setRenderList] = useState([]);
  const [tomorrowDialog, setTomorrowDialog] = useState(false);
  const [schoolCode] = useState(window.localStorage.getItem('schoolCode'));
  const [fontSize, setFontSize] = useState(35);

  const [ClassID, setClassID] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if(!state.changeToday) return;
    doRefresh();
  },[state.changeToday]);

  useEffect(() => {
    if (!state.device && !state.device.ClassID) return;
    //setClassID(state.device.ClassID);
    setClassID(state.device.finalClassID)
    let tdn = moment().add(1, 'day').valueOf();
    let tdnt = moment(tdn).format('YYYY/MM/DD');

    let targetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      date: tdnt,
      call_component:'teacher/src/components/main/tomorrow',
    };

    doGetNotification(targetClass2);

    // eslint-disable-next-line
  }, [state.device]);

  useEffect(() => {
    setClassID(state.device.finalClassID);
    doRefresh();
  }, [state.device]);

  const doZoomFont = (n) => {
    let temp = fontSize + n;
    if (temp < 15 || temp > 150) {
      return;
    } else {
      setFontSize(temp);
    }
  };

  

  const doGetNotification = (targetClass) => {
    let targetClass2Json = JSON.stringify(targetClass);
    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          let temp = finalData.filter((i) => !i.isCancel);
          temp.forEach((i) => {
            switch (i.category) {
              case '作業考試':
                i.prioritize = 2;
                break;
              case '攜帶物品':
                i.prioritize = 3;
                break;
              case '通知事項':
                i.prioritize = 4;
                break;
              default:
                break;
            }
          });
          temp.sort((a, b) => {
            if (a.prioritize < b.prioritize) {
              return -1;
            }
            if (a.prioritize > b.prioritize) {
              return 1;
            }
            return 0;
          });

          setRenderList([].concat(temp));
          setRefreshing(false);
        });
    } catch (error) {
      console.log(error);
    }

    // try {
    //   fetch(dbUrl.concat('/classroom/getNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       //console.log(r);
    //       let temp = r.filter((i) => !i.isCancel);
    //       temp.forEach((i) => {
    //         switch (i.category) {
    //           case '作業考試':
    //             i.prioritize = 2;
    //             break;
    //           case '攜帶物品':
    //             i.prioritize = 3;
    //             break;
    //           case '通知事項':
    //             i.prioritize = 4;
    //             break;
    //           default:
    //             break;
    //         }
    //       });
    //       temp.sort((a, b) => {
    //         if (a.prioritize < b.prioritize) {
    //           return -1;
    //         }
    //         if (a.prioritize > b.prioritize) {
    //           return 1;
    //         }
    //         return 0;
    //       });
    //       console.log(temp)
    //       setRenderList([].concat(temp));
    //       setRefreshing(false);
    //       //setRenderList(tempList);
    //     });
    // } catch (error) {
    //   console.log(error)
    // }
  };

  const doRefresh = () => {
    if (refreshing) return;
    setRefreshing(true);
    setRenderList([].concat([]));
    let tdn = moment().add(1, 'day').valueOf();
    let tdnt = moment(tdn).format('YYYY/MM/DD');

    let targetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      date: tdnt,
      call_component:'teacher/src/components/main/tomorrow',
    };
    doGetNotification(targetClass2);
  };

  const tomorrowListDialog = () =>
    tomorrowDialog && (
      <Dialog
        open={tomorrowDialog}
        onClose={() => setTomorrowDialog(false)}
        maxWidth="ls"
        fullWidth="true"
        style={{ marginTop: '150px' }}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle style={{ flex: '4' }}>
            明日待辦({renderList.length})
          </DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '10px',
              color: 'white',
            }}
          >
            目前字體大小：{fontSize}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '10px',
              
              alignItems: 'center',
              color: 'white',
            }}
          >
            {/* <ZoomInIcon
              fontSize="large"
              onClick={() => doZoomFont(5)}
              style={{ color: 'white' }}
            />
            <ZoomOutIcon
              fontSize="large"
              onClick={() => doZoomFont(-5)}
              style={{ color: 'white' }}
            /> */}
            {/* <ColorButton
              variant="contained"
              color="primary"
              onClick={doRefresh}
              disabled={refreshing}
            >
              重新整理
            </ColorButton> */}
            <div style={{width:'100%',display: 'flex',justifyContent: 'flex-end'}}>
              <img
                src={refresh}
                    style={{
                      width: '25px',
                      height: '25px',
                      
                    }}
                    onClick={doRefresh}
                    disabled={refreshing}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '10px',
              marginRight:'10px',
            }}
          >
            {/* <FullscreenExitIcon onClick={() => setTomorrowDialog(false)}/> */}
            <img
              src={zoomin}
              style={{
                width: '25px',
                height: '25px',
                marginRight: '10px',
              }}
              onClick={() => setTomorrowDialog(false)}
            />
          </div>
        </div>
        {renderList.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '350px',
            }}
          >
            <CircularProgress size="60px" />
          </div>
        ) : (
          // <DialogContent
          //   style={{ display:'flex',flexDirection:'column',minWidth:'700px',height:'400px', flexWrap:'wrap'}}
          // >
          //   <div className={classes.missionList2}>
          //     {renderList.map((item, index) => (
          //       <div className={classes.missionItem2}>
          //         {/* <Typography variant='h3' className={classes.missionText2} style={{ fontSize:`${fontSize}px`}}>{index+1}. {item.content}</Typography> */}
          //         <h className={classes.missionText2} style={{ fontSize:`${fontSize}px`}}>{index+1}. {item.content}</h>
          //       </div>
          //       ))}
          //   </div>
          // </DialogContent>
          <div
            style={{
              overflow: 'auto',
              height: '400px',
              padding: '4px',
              width: '97%',
              marginLeft: '10px',
            }}
          >
            <FormControl
              style={{
                marginLeft: '10px',
              }}
            >
              <RadioGroup
                aria-label="unfinish"
                name="unfinish"
              >
                {renderList.map((item, index) => (
                  <FormControlLabel
                    style={{
                      marginBottom: '8px',
                      width: '90%',
                      minHeight: '90%',
                    }}
                    value={item.id}
                    //disabled={renditem.undoneNum > 0 ? true : false}
                    control={<></>}
                    label={
                      <div className={classes.todayList2}>
                        <div
                          className={classes.formControlLabelTitle2}
                          style={{ fontSize: `${fontSize}px` }}
                          noWrap
                        >
                          {index + 1}. {item.content}
                        </div>
                      </div>
                    }
                  >
                    <div>{item.content}</div>
                  </FormControlLabel>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </Dialog>
    );

  return (
    <>
      {tomorrowListDialog()}
      <Paper className={classes.root}>
        <div className={classes.title}>
          <Typography
            variant="h6"
            style={{
              marginTop: theme.spacing(2),
              marginLeft: theme.spacing(2),
            }}
          >
            <div>明日待辦({renderList.length})</div>
          </Typography>
          {/* <ColorButton
            variant="contained"
            color="primary"
            onClick={doRefresh}
            disabled={refreshing}
            style={{ marginTop: '18px' }}
          >
            重新整理
          </ColorButton> */}
          <IconButton
            size="small"
            className={classes.titleIcon}
            disabled={renderList.length === 0 ? true : false}
          >
            <ZoomOutMapIcon onClick={() => setTomorrowDialog(true)} />
          </IconButton>
        </div>
        <div style={{width:'100%',display: 'flex',justifyContent: 'flex-end'}}>
          <img
            src={refresh}
                style={{
                  width: '25px',
                  height: '25px',
                  marginRight: '18px',
                  marginTop:'10px',
                }}
                onClick={doRefresh}
                disabled={refreshing}
          />
        </div>
        {renderList.length === 0 ? (
          <div className={classes.missionList}>
            <div className={classes.missionItem}>
              <Typography className={classes.missionText}>
                明日無相關事項
              </Typography>
            </div>
          </div>
        ) : (
          // <div className={classes.missionList}>
          //   {renderList.map((item, index) => (
          //     <div className={classes.missionItem}>
          //       <Typography className={classes.missionText} noWrap>{index+1}. {item.content}</Typography>
          //     </div>
          //     ))}
          // </div>
          <div className={classes.missionList3}>
            {renderList.map((item, index) => (
              <div className={classes.missionItem3}>
                <Typography className={classes.missionText3} noWrap="true">
                  {index + 1}. {item.content}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </Paper>
    </>
  );
};

export default Component;
