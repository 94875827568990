import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Fade, 
    Typography, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    CircularProgress,
    Avatar,
    Chip,} from '@material-ui/core';
import { 
    AccountCircleOutlined as AccountIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import { AppContext } from '../../../App';
import envConfig from '../../../config';
import moment from 'moment';

import CryptoJS from 'crypto-js';
const useStyles = makeStyles((theme) => ({
   root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      //justifyContent: 'center',
      minHeight:'280px',
      width: '280px',
      padding:'8px'
    },
    titleroot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding:'5px',
        width: '280px'
      },
    contentds: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    signin: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
      borderWidth: 1,
      backgroundColor: 'rgba(255,255,255,1)',
    },
    codeValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const weekNumbers = ['一', '二', '三', '四', '五', '六', '日'];
const dbUrl = envConfig.SERVER_URL;
const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const Component = ({result}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useContext(AppContext);
    const [schoolYear, setSchoolYear] = useState('');
    const [semester, setSemester] = useState('');
    const [month, setMonth] = useState('');
    const [timeStamp, setTimeStamp] = useState(moment());
    const [monthList,setMonthList] = useState([]);
    const [calList, setCalList] = useState([]);
    const [ClassID, setClassID] = useState('');
    const [firstList, setFirstList] = useState([]);
    const [secondList, setSecondList] = useState([]);
    const [thirdList, setThirdList] = useState([]);
    const [fourthList, setFourthList] = useState([]);
    const [fifthList, setFifthList] = useState([]);
    const [sixthList, setSixthList] = useState([]);
    const [curWeekNum, setCurWeekNum] = useState(9);
    const [todayText, setTodayText] = useState('');
    const [todayWeekNum, setTodayWeekNum] = useState(0);

    useEffect(() => {
        if(!state.device && !state.device.ClassID) return
        setClassID(state.device.ClassID)
        let tempCalList = [];
        
        let targetClass = {
            class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
            call_component:'teacher/src/components/main/subItem/picker',
        };

        let targetClass2 = {
            class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
            call_component:'teacher/src/components/main/subItem/picker',
          };
      
          let targetClass2Json = JSON.stringify(targetClass2);
      
          const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
          const encryptedData = ciphertext.toString();
          let tempBody = {body: encryptedData}
          try {
            fetch(dbUrl.concat('/classroom/getNotificationCalendarCry'), {
              method: 'POST',
              body: JSON.stringify(tempBody),
              headers: {
                'content-type': 'application/json',
              },
            })
              .then((r) => r.json())
              .then((r) => {
                const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                let finalData = JSON.parse(decryptedData);
                tempCalList = finalData
                setCalList([].concat(tempCalList));
              });
          } catch (error) {
            console.log(error);
          }

        // try {
        //     fetch(dbUrl.concat('/classroom/getNotificationCalendar'), {
        //         method: 'POST',
        //         body: JSON.stringify(targetClass),
        //         headers: {
        //           'content-type': 'application/json'
        //         },
        //       }).then(  
        //         r => r.json()
        //       ).then(r => {
        //         tempCalList = r
        //         //console.log(tempCalList)
        //         setCalList([].concat(tempCalList));
        //       })
        // } catch (error) {
        //     console.log(error)
        // }
        
      // eslint-disable-next-line
    }, [state.device]);

    useEffect(() =>{
        if(!calList) return;
        let tempCalList = [].concat(calList);
        let temp = moment(); 
        setTimeStamp(temp);
        let tempP = new moment();
        let tempN = new moment();
        tempP = tempP.subtract(1, 'months');
        tempN = tempN.add(1, 'months');  
        //let tempNowYear = parseInt(temp.format('YYYY')) - 1911;
        let tempNowYear = parseInt(temp.format('YYYY')); // 當月所屬西元年
        let tempPYear = parseInt(tempP.format('YYYY')); // 上月所屬西元年
        let tempNYear = parseInt(tempN.format('YYYY')); // 下月所屬西元年
        let tempNowMonth = parseInt(temp.format('MM')); // 當月
        let tempPMonth = parseInt(tempP.format('MM')); // 上月
        let tempNMonth = parseInt(tempN.format('MM')); // 下月
        let tempTodayWeekNum = temp.isoWeekday();
        //console.log(tempTodayWeekNum);
        setTodayWeekNum(tempTodayWeekNum);
        let tempMonthText = tempNowMonth < 10 ? '0' + tempNowMonth : tempNowMonth;
        let tempDayText = temp.format('DD');
        let tempCurDayText = tempNowYear + '/' + tempMonthText + '/' + tempDayText;
        setTodayText(tempCurDayText)
        doCalSS(temp, tempNowMonth, tempNowYear);
        doCalMonthList(temp, tempNowMonth, tempNowYear, tempP, tempPMonth, tempPYear, tempN, tempNMonth, tempNYear, tempCalList, tempCurDayText);
    },[calList]);

    /**
     * 計算學年度學期
     * @param {今日時間} time 
     * @param {月份} Month 
     * @param {年} Year 
     */
    const doCalSS = (time, Month, Year) => {
        let semester;
        let schoolYear;
        let twYear;

        Month === 1 ? twYear = Year - 1912 :twYear = Year - 1911;
        setMonth(Month);
        let tempNow = time.format('YYYY-MM-DD');
        let startDate = `${Year}-01-31`;
        let endDate = `${Year}-08-01`;
        moment(tempNow).isBetween(startDate,endDate) ? semester = '2' : semester = '1';
        setSemester(semester);
        semester === '1' ? schoolYear = twYear : schoolYear = twYear - 1
        setSchoolYear(schoolYear.toString());
      }

    const changeMonth = (n) => {
        //setCurWeekNum(9); // 切換月份不選擇week
        let changeMonth = true;
        let tempCalList = calList;
        let temp = timeStamp
        temp = temp.add(n, 'months'); 
        let tempNowYear = parseInt(temp.format('YYYY')); // 當月所屬西元年
        let tempNowMonth = parseInt(temp.format('MM')); // 當月
        let tempP;
        let tempN;
        setTimeStamp(temp);
        switch (tempNowMonth) {
            case 1:
                let pm = 12;
                let py= tempNowYear - 1;
                tempP = new moment(`${py}-${pm}-01`);
                tempN = new moment(`${tempNowYear}-${tempNowMonth+1}-01`);
                break;
            case 12:
                let nm = 1;
                let ny = tempNowYear + 1;
                tempP = new moment(`${tempNowYear}-${tempNowMonth-1}-01`);
                tempN = new moment(`${ny}-${nm}-01`);
                break;
            default:
                tempP = new moment(`${tempNowYear}-${tempNowMonth-1}-01`);
                tempN = new moment(`${tempNowYear}-${tempNowMonth+1}-01`);
                break;
        }
        
        let tempPYear = parseInt(tempP.format('YYYY')); // 上月所屬西元年
        let tempNYear = parseInt(tempN.format('YYYY')); // 下月所屬西元年
        let tempPMonth = parseInt(tempP.format('MM')); // 上月
        let tempNMonth = parseInt(tempN.format('MM')); // 下月

        doCalSS(temp, tempNowMonth, tempNowYear);
        doCalMonthList(temp, tempNowMonth, tempNowYear, tempP, tempPMonth, tempPYear, tempN, tempNMonth, tempNYear, tempCalList,todayText, changeMonth);
    }

    const doCalMonthList = (ctime, month, year, ptime, pmonth, pyear, ntime, nmonth, nyear, calList, curDayText, changeMonth) => {
        let monthList = [];
        let monthText = month.toString(); // 月份文字
        let yearText = year.toString(); // 年文字
        let targetFirst = `${year}/${month}/01`; // 第一日完整年月日文字
        //let endNum = moment().endOf('month'); 
        let priMonLastDW = ptime.endOf('month').isoWeekday(); // 上月最後一天星期幾
        let nextMonFirstDW = ntime.startOf("month").isoWeekday(); // 下月最後一天星期幾
        let priMonthDays = moment(`${pyear}-${pmonth}`,'YYYY-MM').daysInMonth()// 上個月有幾天
        let nextMonthDays = moment(`${nyear}-${nmonth}`,'YYYY-MM').daysInMonth()// 下個月有幾天
        let targetMonthDays = moment(`${year}-${month}`,'YYYY-MM').daysInMonth()// 當月有幾天
        let targetWeekNum = ctime.isoWeekday(); 
        // let tempMonthText = month < 10 ? '0' + month : month;
        // let tempDayText = ctime.format('DD');
        // let tempCurDayText = year + '/' + tempMonthText + '/' + tempDayText;
        let tempCurDayText;
        if(todayText) {
            tempCurDayText = todayText;
        } else {
            tempCurDayText = curDayText;
        };
        
        let priMonthList = [];
        let nextMonthList = [];
        let curMonthList = doTargetMonthBasicList(ctime, month, year, targetMonthDays, calList, tempCurDayText); // 當月清單
        // 當上月最後一天不在星期天，整體清單前面需加入上月其他天數
        if ( priMonLastDW < 7 ) {
            priMonthList =[].concat(doPriMonList(priMonLastDW, priMonthDays, ptime, pmonth, pyear, calList, tempCurDayText));
            curMonthList = priMonthList.concat(curMonthList);
        }
        
        // 當下個月第一天不在星期一，整體清單後面需加入下月其他天數
        if (nextMonFirstDW > 1) {
            nextMonthList =[].concat(doNextMonList(nextMonFirstDW, nextMonthDays, ntime, nmonth, nyear, calList, tempCurDayText));
            curMonthList = curMonthList.concat(nextMonthList);
        }
        //console.log(curMonthList.length);
        setMonthList(curMonthList);


        if(!state.calendarWeekList && calList.length > 0 && !changeMonth){
            let tempTarget = {
                dayText: tempCurDayText,
                week: targetWeekNum, 
                finalMonthList: curMonthList,
                month: month,
                schoolYear: schoolYear,
                semester: semester,
            };
            dispatch({ type: 'SET_CALENDARWEEKLIST', payload: JSON.parse(JSON.stringify(tempTarget))});
        }
        
        // 存放當月當天的資訊，供之後重新整理使用
        // if(!state.curCalendarList && calList.length > 0){
        //     let tempTarget = {
        //         dayText: tempCurDayText,
        //         week: targetWeekNum, 
        //         finalMonthList: curMonthList,
        //         month: month,
        //     };
        //     dispatch({ type: 'SET_CURCALENDARLIST', payload: JSON.parse(JSON.stringify(tempTarget))});
        // }
        doCalWeekNum(curMonthList, tempCurDayText)
        doWeekList(curMonthList);
        //console.log(curMonthList);
    }

    const doCalWeekNum = (curMonthList, todayText) => {
        // 來控制灰色外框初始座落位置
        // 當月會初始在當天那週
        // 切換月份會歸零，代表沒有框列
        let tempDayNum = curMonthList.findIndex(item => {
           return item.daytext === todayText
        });
        if(tempDayNum > -1) {
            if(tempDayNum < 7 ){
                setCurWeekNum(0);
            } else {
                let n = Math.floor(tempDayNum/7)
                setCurWeekNum(n);
            }
        } else {
            setCurWeekNum(9);
        }
        //console.log(tempDayNum)
        //console.log(curMonthList[tempDayNum])
    }

    const doWeekList = (curMonthList) => {
        let tempFirstWeek = [];
        let tempSecondWeek = [];
        let tempThirdWeek = [];
        let tempFourthWeek = [];
        let tempFifthWeek = [];
        let tempSixthWeek = [];

        curMonthList.forEach((item,index) =>{
            if(index < 7 ){
                tempFirstWeek.push(item)
            } else {
                let n = Math.floor(index/7)
                switch (n) {
                    case 1:
                        tempSecondWeek.push(item);
                        break;
                    case 2:
                        tempThirdWeek.push(item);
                        break;
                    case 3:
                        tempFourthWeek.push(item);
                        break;
                    case 4:
                        tempFifthWeek.push(item);
                        break;
                    case 5:
                        tempSixthWeek.push(item);
                        break;
                    default:
                        break;
                }
            }
        });
        setFirstList([].concat(tempFirstWeek));
        setSecondList([].concat(tempSecondWeek));
        setThirdList([].concat(tempThirdWeek));
        setFourthList([].concat(tempFourthWeek));
        setFifthList([].concat(tempFifthWeek));
        setSixthList([].concat(tempSixthWeek));
    }
    

    const doTargetMonthBasicList = (ctime, month, year, endNum, calList, todayText) => {
        let tempMonthList = [];
        let tempDay;
        let targetDay;
        let tempMonthText = month < 10 ? '0' + month : month;
       

        for(let i=1; i<=endNum; i++){
            if (i < 10) {
                tempDay = year + '/' + tempMonthText + '/' + "0" + i;
              } else {
                tempDay = year + '/' + tempMonthText + '/' + i;
              }
        
        if(calList.length > 0){
            targetDay = calList.find((item) => {
                return item.eventDate === tempDay;
              });
        }
    
          if (targetDay) {
            //console.log(targetDay)
            let temp = [
              {
                dayNo: i,
                dayContent: targetDay.count,
                daytext: tempDay,
                chooseDay: tempDay === todayText ? true : false ,
                eventDateTimeTick: targetDay.eventDateTimeTick,
                curMonth: true,
              }
            ];
            tempMonthList = tempMonthList.concat(temp);
          } else {
            let temp = [
              {
                dayNo: i,
                dayContent: 0,
                daytext: tempDay,
                chooseDay: tempDay === todayText ? true : false,
                eventDateTimeTick: '0000000000000',
                curMonth: true,
              }
            ];
            tempMonthList = tempMonthList.concat(temp);
          }
        }
        [].concat(tempMonthList);
        return tempMonthList;
    }

    const doPriMonList = (priMonLastDW, priMonthDays, ptime, pmonth, pyear, calList, todayText) => {
        let tempMonthList = [];
        let tempDay;
        let targetDay;
        let tempMonthText = pmonth < 10 ? '0' + pmonth : pmonth;
        let priFistDay = priMonthDays - priMonLastDW + 1;
        for(let i = priFistDay; i < priMonthDays + 1; i++ ) {
            tempDay = pyear + '/' + tempMonthText + '/' + i;

            if(calList.length > 0){
                targetDay = calList.find((item) => {
                    return item.eventDate === tempDay;
                });
            }
        
              if (targetDay) {
                //console.log(targetDay)
                let temp = [
                  {
                    dayNo: i,
                    dayContent: targetDay.count,
                    daytext: tempDay,
                    chooseDay: tempDay === todayText ? true : false,
                    eventDateTimeTick: targetDay.eventDateTimeTick,
                    curMonth: false,
                  }
                ];
                tempMonthList = tempMonthList.concat(temp);
              } else {
                let temp = [
                  {
                    dayNo: i,
                    dayContent: 0,
                    daytext: tempDay,
                    chooseDay: tempDay === todayText ? true : false,
                    eventDateTimeTick: '0000000000000',
                    curMonth: false,
                  }
                ];
                tempMonthList = tempMonthList.concat(temp);
              }
        }
        [].concat(tempMonthList);
        return tempMonthList;
    }

    const doNextMonList = (nextMonFirstDW, nextMonthDays, ntime, nmonth, nyear, calList, tempTodayText) => {
        let tempMonthList = [];
        let tempDay;
        let targetDay;
        let tempMonthText = nmonth < 10 ? '0' + nmonth : nmonth;
        let nextMonthRange = 7 - nextMonFirstDW + 1;

        for (let i=1; i<=nextMonthRange; i++) {
            let tempDay =
            nyear + '/' + tempMonthText + '/' + "0" + i;


            if(calList.length > 0){
                targetDay = calList.find((item) => {
                    return item.eventDate === tempDay;
                });
            }

            if(targetDay) {
                let temp = [
                  {
                    dayNo: i,
                    dayContent: targetDay.count,
                    daytext: tempDay,
                    chooseDay: false,
                    eventDateTimeTick: targetDay.eventDateTimeTick
                  }
                ];
                tempMonthList = tempMonthList.concat(temp);
            } else {
                let temp = [
                  {
                    dayNo: i,
                    dayContent: 0,
                    daytext: tempDay,
                    chooseDay: false,
                  }
                ];
                tempMonthList = tempMonthList.concat(temp);
            }

        }
        [].concat(tempMonthList);
        return tempMonthList;
    }

    const doSelectWeek = (n) => {
        setCurWeekNum(n);
        //console.log(todayText);
        let temp = [];
        switch (n) {
            case 0:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: firstList});
                temp = doCheckToday(firstList);
                break;
            case 1:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: secondList});
                temp = doCheckToday(secondList);
                break;
            case 2:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: thirdList});
                temp = doCheckToday(thirdList);
                break;
            case 3:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: fourthList});
                temp = doCheckToday(fourthList);
                break;
            case 4:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: fifthList});
                temp = doCheckToday(fifthList);
                break;
            case 5:
                //dispatch({ type: 'SET_CALENDARWEEKLIST', payload: sixthList});
                temp = doCheckToday(sixthList);
                break;
            default:
                break;
        }
        let tempFullList = [].concat(monthList)
       
        let tempTarget = {
            dayText: temp[0],
            week: temp[1], 
            finalMonthList: tempFullList,
            month: month,
            schoolYear: schoolYear,
            semester: semester,
        };
        //console.log(tempTarget);
        dispatch({ type: 'SET_CALENDARWEEKLIST', payload: JSON.parse(JSON.stringify(tempTarget))});

        
    }

    const doCheckToday = (weekList) => {
        let tempIndex = weekList.findIndex(item => {return item.daytext === todayText});
                if(tempIndex > -1) {
                    return [todayText, todayWeekNum]
                } else {
                    return [weekList[0].daytext, 1];
                }
    }

    const doClose = () => {
        //console.log('close');
        result(false);
    }


return (
    <>      
      <div className={classes.root}>
          <div className={classes.titleroot}>
            <KeyboardArrowLeftIcon  fontSize="small" onClick={() => changeMonth(-1)}/>
            <div>
                {schoolYear} 學年 {semester} 學期 {month}月
            </div>
            <KeyboardArrowRightIcon  fontSize="small" onClick={() => changeMonth(1)}/>
            <CloseIcon onClick={doClose}/>
          </div>
          <div style={{
                  display: 'flex',
                  flexDirection:'row',
                  marginTop:'5px',
                  padding:'5px',
                  width: '270px',
                  alignItems: 'center',
                  justifyContent: 'space-around',}}>
            {weekNumbers.map((item) => (
              <div>
                  {item}
              </div>
            ))}
          </div>
          {monthList.length === 0 ?(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent:'center',
                height:'150px'
              }}
            >
              <CircularProgress size="40px" />
            </div>
          ):(
            <div style={{
                    display: 'flex',
                    flexDirection:'column', 
                    alignItems: 'center',
                    width: '280px',
                    minHeight: '200px',
                    justifyContent: 'space-around',
                }}> 
                <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 0 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {firstList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(0)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 1 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {secondList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(1)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 2 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {thirdList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(2)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 3 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {fourthList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(3)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 4 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {fifthList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(4)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                {sixthList.length === 0 ? (
                <div/>
                ):(
                    <div
                    style={{
                        display: 'flex',
                        flexDirection:'row', 
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        border: 'solid 2px',
                        width: '270px',
                        height: '40px',
                        borderRadius: '10px',
                        border: curWeekNum === 5 ? 'solid 2px #a3a3a3' :'2px solid #ffffff'
                    }}
                >
                    {sixthList.map((item,index) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            key={index}
                            onClick={() => doSelectWeek(5)}
                        >
                            {item.chooseDay ? (
                                <Avatar
                                    style={{
                                    color: item.chooseDay ? "white" : "black",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            ):(
                                <Avatar
                                    style={{
                                    color: item.curMonth ? "black" : "#828282",
                                    backgroundColor: item.chooseDay
                                        ? "#ff9b3f"
                                        : "white",
                                    height: "25px",
                                    width: "25px",
                                    fontSize: "14px",
                                    marginTop: "4px",
                                    zIndex: "0"
                                    }}
                                    key={item.daytext}
                                >
                                    {item.dayNo}
                                </Avatar>
                            )}
                            <div
                                style={{
                                    height: "1px",}}
                            />
                             <div
                                style={{
                                display: "block",
                                height: "1px",
                                width: "1px",
                                borderRadius: "50%",
                                border: item.dayContent > 0 ? "2px solid #ff9b3f" : '2px solid #ffffff',
                                backgroundColor: item.dayContent > 0 ? "#ff9b3f" : '2px solid #ffffff',
                                marginTop: "-0.5px",
                                zIndex: "1"
                                }}
                            />
                        </div>
                    ))}
                </div>
                )}
            </div>
          )}
      </div>
    </>
    );
};

export default Component;