import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../../App';
import envConfig from '../../config';

import Action from './action';
import Main from './main';
import Content from './content';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
}));

const dbUrl = envConfig.SERVER_URL;
let targetconfig = [];
let targetLog = [];

const Component = () => {
  const classes = useStyles();
  const { first } = useParams();

  const { state, dispatch } = useContext(AppContext);

  const [target, setTarget] = useState(null);
  const [config, setConfig] = useState(null);
  const [dbconfig, setDBConfig] = useState([]);
  //const [dbconfig, setDBConfig] = useState([]);
  const [allDBconfig, setAllDBconfig] = useState([]);
  useEffect(() => {
    fetch(
      envConfig.SERVICE_URL.getUserToken.replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log('getUserToken : ' + response);
        if (!response.token) {
          // console.log(
          //   state
          // )
          let coocURL = 'https://cooc.tp.edu.tw/'
          fetch('https://cooc.tp.edu.tw/api/auth/logout');
          window.localStorage.clear();
          // window.location.replace(
          //   `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`, // 這邊調整token 失效，重新整理後的處理
          // );
          
          // if(state.device.ClassID && state.device.ClassID > 0) {
          //   window.location.replace('https://classroom.tp.edu.tw/teacher');
          // } else {
          //   window.location.replace('https://cooc.tp.edu.tw');
          // }
          window.location.replace('https://classroom.tp.edu.tw/teacher');
          return;
        }

        //console.log(response);
        if(response.fromSession) {
          firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const target = doc.data();
                  const { status, dsns } = target;

                  if (status === 'close') {
                    
                    fetch('https://cooc.tp.edu.tw/api/auth/logout');
                    window.localStorage.clear();
                    window.location.replace(
                      `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                    );
                    return;
                  }

                  let domain = '';
                  const match = dsns.match(
                    /([a-z0-9.]+)\.([a-z0-9]+)\.edu\.tw/,
                  );

                if (match) {
                    domain = `${match[2]}.edu.tw`;
                  }

                  setTarget({ ...target, domain });
                } else {
                  
                  fetch('https://cooc.tp.edu.tw/api/auth/logout');
                  window.localStorage.clear();
                  window.location.replace(
                    `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                  );
                }
              })
              .catch(() => {
                
                fetch('https://cooc.tp.edu.tw/api/auth/logout');
                window.localStorage.clear();
                window.location.replace(
                  `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                );
              });

            if (first === 'true') {
              firebase
                .firestore()
                .collection(`/classrooms/${state.roomId}/chats`)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    doc.ref.delete();
                  });
                });

              firebase
                .firestore()
                .collection(`/classrooms/${state.roomId}/events`)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    doc.ref.delete();
                  });
                });

              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
                .delete();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
                .delete();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
                .delete();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
                .delete();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
                .delete();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/votes/0000000000000`)
                .delete();

              setTimeout(() => {
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/events/score`)
                  .set({
                    timestamp: new Date().toISOString(),
                  });
              }, 3000);
            }
        } else {
          firebase
            .auth()
            .signInWithCustomToken(response.token)
            .then(() => {
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}`)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const target = doc.data();
                    const { status, dsns } = target;
  
                    if (status === 'close') {
                      
                      fetch('https://cooc.tp.edu.tw/api/auth/logout');
                      window.localStorage.clear();
                      window.location.replace(
                        `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                      );
                      return;
                    }
  
                    let domain = '';
                    const match = dsns.match(
                      /([a-z0-9.]+)\.([a-z0-9]+)\.edu\.tw/,
                    );
  
                  if (match) {
                      domain = `${match[2]}.edu.tw`;
                    }
  
                    setTarget({ ...target, domain });
                  } else {
                    
                    fetch('https://cooc.tp.edu.tw/api/auth/logout');
                    window.localStorage.clear();
                    window.location.replace(
                      `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                    );
                  }
                })
                .catch(() => {
                  
                  fetch('https://cooc.tp.edu.tw/api/auth/logout');
                  window.localStorage.clear();
                  window.location.replace(
                    `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                  );
                });
  
              if (first === 'true') {
                firebase
                  .firestore()
                  .collection(`/classrooms/${state.roomId}/chats`)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      doc.ref.delete();
                    });
                  });
  
                firebase
                  .firestore()
                  .collection(`/classrooms/${state.roomId}/events`)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      doc.ref.delete();
                    });
                  });
  
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
                  .delete();
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
                  .delete();
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
                  .delete();
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
                  .delete();
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
                  .delete();
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/votes/0000000000000`)
                  .delete();
  
                setTimeout(() => {
                  firebase
                    .firestore()
                    .doc(`/classrooms/${state.roomId}/events/score`)
                    .set({
                      timestamp: new Date().toISOString(),
                    });
                }, 3000);
              }
          });
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!target) return;

    dispatch({ type: 'SET_TARGET', payload: target });

    fetch(
      envConfig.SERVICE_URL.getUserInfo.replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: 'SET_MY_INFO',
          payload: {
            ...response,
            name: `${
              response.last_name && response.last_name !== 'null'
                ? response.last_name
                : ''
            }${
              response.first_name && response.first_name !== 'null'
                ? response.first_name
                : ''
            }`,
          },
        });
      });

    firebase
      .firestore()
      .doc('/configs/develope')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(JSON.parse(doc.data().content));

        }
      });
    // eslint-disable-next-line
  }, [target]);

      if(dbconfig.length === 0 && state.myInfo && state.myInfo.id_number){ 
        //console.log(state.myInfo.id_number)
        try {
          fetch(dbUrl.concat('/classroom/getCloudConfig'), {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({ teacher_identity: state.myInfo.id_number, call_component: 'teacher/src/components/teach/index' })
          }).then(
            r => r.json()
          ).then(r => {
            //console.log(r);
            if(r.results){
              setDBConfig(r.results[2].items);
            }
            setAllDBconfig(r.results);
          });
        } catch (error) {
          console.log(error);
        }
      }

        
      
    

  useEffect(() => {
    // if (!config) return;
    if (!config) return;

    let myConfig = dbconfig;
    let defconfig = config;
    
    if(targetconfig.length === 0){
      targetconfig = defconfig[1].items.concat(myConfig);
    }
    defconfig[1].items = targetconfig;
      setConfig(defconfig);

    // dispatch({ type: 'SET_CONFIG', payload: config });
    dispatch({ type: 'SET_CONFIG', payload: config });
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { status, container} = doc.data();
          let dataInfo = doc.data();
          dataInfo.error = '';
          dataInfo.timestamp =  Date.now()
          dataInfo.ReactNativeWebView = window.ReactNativeWebView ? true : false;

          if (status === 'close') {
            state.communication.postMessage(
              JSON.stringify({
                action: 'SIGNOUT',
              }),
            );

            //console.log(doc.data());
            
            try {
              firebase
                .firestore()
                .doc(`/screenlogout/${state.roomId}.${dataInfo.timestamp}`)
                .set(dataInfo);
            } catch (error) {
              console.log(error);
            }
            // if (containerType === 'native' || !window.opener) {
            try {
              fetch('https://cooc.tp.edu.tw/api/auth/logout');
                window.localStorage.clear();
                window.location.replace(
                  `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
                );
              // if (container.type === 'native') {
              //   fetch('https://cooc.tp.edu.tw/api/auth/logout');
              //   window.localStorage.clear();
              //   window.location.replace(
              //     `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
              //   );
              // } else {
              //     window.localStorage.clear();
              //     window.close();
              //   }
            } catch (error) {
              dataInfo.error = error.message
              firebase
                .firestore()
                .doc(`/screenlogout/${state.roomId}.${dataInfo.timestamp}`)
                .set(dataInfo);
            }
            // fetch('https://cooc.tp.edu.tw/api/auth/logout');
            // window.localStorage.clear();
            // window.location.replace(
            //   `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${envConfig.BASE_URL}`,
            // );
            
          }
        }
      });

    firebase.firestore().doc(`/classrooms/${state.roomId}`).update({
      container: state.container,
    });

    

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [config]);
  // }, [config]);

  

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>
        <Action />
        <Main />
        <Content />
      </div>
    </SnackbarProvider>
  );
};

export default Component;
