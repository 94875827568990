import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  IconButton, 
  Tooltip, 
  Typography, 
  Paper, 
  Chip,
  Button 
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  HighlightOffOutlined as CloseIcon,
} from '@material-ui/icons';

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../../config';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.75)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  statusBtn: {
    color: '#ff0000',
    fontSize: '5rem',
  },
  closeBtn: {
    color: '#f1f1f1',
    fontSize: '5rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  content0: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  content2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: 250 
  },
  contentrow1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  contentsw: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentrow: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 240px)',
    gridGap: theme.spacing(2),
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'auto',
    margin: theme.spacing(2),
  },
  contentrow1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(1, 0),
  },
  groupchip: {
    fontSize: '1rem',
    margin: theme.spacing(1),
  },

}));
const dbUrl = envConfig.SERVER_URL;
const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  //const [status, setStatus] = useState('close');
  const [status, setStatus] = useState('open');
  const [timestamp, setTimestamp ] = useState('');
  const [method,  setMethod ] = useState('');
  const [targetStu, setTargetStu] = useState([]);
  const [ targetGroup, setTargetGroup ] = useState([]);
  const [ targetGroupStu, setTargetGroupStu ] = useState([]);
  const [ checkAssess, setCheckAssess ] = useState(false);
  const [ notAssessList, setNotAssessList ] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/votes/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();
          //const data = item.data.map((item) => 0);
          //console.log(item.timestamp);
          setTimestamp(item.timestamp);
          //console.log(timestamp);
          //console.log(item);
          
          let groupTempList = [];
          item.data.forEach((item, index) => {
            if(index > 0) {
              groupTempList.push(item.groupNo);
            }
           }
          );
          let result = Array.from(new Set(groupTempList));
          //console.log(result);
          const data = result.map((item) => 0);
          //console.log(data);
          
          if(item.method === 'groups'){
            setMethod('groups');
            Object.keys(item).forEach((key) => {
              if (key.includes('student')) {
                
                item[key].content.forEach((c, i) => {
                  data[i] += c;
                });
              }
            });
            setTimestamp(item.timestamp);
            doCreateGroupObj(item, data, result);
            doCreateGroupStu(item);
            doCheck(item); // 學生是否尚未投票
      
  
            const labels = item.data.map(
              (item, idx) => `${item} : ${data[idx]} 分`,
            );
            setStatus(item.status);
          } else {
            const data2 = item.data.map((item) => 0);
            //console.log(item);
            
            // 建立目標清單
            let stuList = doStuList(item);
            //console.log(stuList);
            setMethod('student');
            // Object.keys(item).forEach((key) => {
            //   if (key.includes('student')) {
            //     data[item[key].content - 1] = data[item[key].content - 1] + 1;
            //   }
            // });
            Object.keys(item).forEach((key) => {
              if (key.includes('student')) {
                let targetNum = item[key].content;
                stuList[targetNum - 1].score = stuList[targetNum - 1].score + 1;
                //console.log(stuList);
                
              }
            });

            setTargetStu([].concat(stuList));
            //console.log(targetStu);
            let temp = targetStu;
            //console.log(temp);
            //console.log(data2);
            //console.log(item);
            doCheck(item); // 學生是否尚未投票
          }

          const labels = item.data.map(
            (item, idx) => `${item} : ${data[idx]} 票`,
          );

          // var ctx = (document.getElementById('chart') || {}).getContext('2d');

          // new Chart(ctx, {
          //   type: 'doughnut',
          //   data: {
          //     labels,
          //     datasets: [{ data }],
          //   },
          //   options: {
          //     responsive: true,
          //     legend: {
          //       position: 'left',
          //       labels: { fontSize: 14, fontColor: '#f1f1f1' },
          //     },
          //     layout: {
          //       padding: {
          //         left: theme.spacing(2),
          //         right: theme.spacing(2),
          //         top: theme.spacing(2),
          //         bottom: theme.spacing(2),
          //       },
          //     },
          //     tooltips: { enabled: false },
          //     plugins: {
          //       colorschemes: {
          //         scheme: Tableau10,
          //       },
          //     },
          //   },
          // });
          //console.log(item.status);
          setStatus(item.status);
        }
      });

      
    return () => {
      unsubscribe();
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/votes/0000000000000`)
        //.update({ status: 'close' });
        .update({ status: 'open' });
    };
    // eslint-disable-next-line
  }, []);

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/votes/0000000000000`)
      .update({
        status: status === 'open' ? 'close' : 'open',
      });
  };

  const doStuList = (item) => {
    //console.log(item);
    let temp = [];
    item.data.forEach(i => {
      i.score = 0;
      temp.push(i);
    })
    //console.log(temp);
    setTargetStu(temp);
    //console.log(targetStu);
    return temp;
  }

  const doCheck = (item) => {
    //console.log(item);
    let n = 0;
    let c = 0;
    let notAssessListTemp = [];
    state.students.forEach(stu => {
      if(stu.choose){
        c = c + 1;
      }
    });
    
    state.students.forEach(stu => {
      let temp = {};
      if(c === 1 && !item[stu.ID] && stu.choose){
        if(item[stu.ID]){
          n = n + 1;
        }
      } else {
        if(item[stu.ID]){
          n = n + 1;
        } else if(!item[stu.ID]){
          temp = {
            identity: `${stu.SeatNo} ${stu.Name}`,
            name: stu.Name,
            seatNo:stu.SeatNo,
            id: stu.ID,
            className: stu.ClassName,
            choose: stu.choose,
            showText: `${stu.Name}(座號：${stu.SeatNo})`,
            assessState: false
          };
          notAssessListTemp.push(temp);
        }
      }
    });
    setNotAssessList(notAssessListTemp);
    if(n > 0) {
      setCheckAssess(true);
      //console.log(checkAssess);
    }
  };

  const doCreateGroupStu = (item) => {
    let temp = [].concat(item.data);
    temp.shift();
    setTargetGroupStu([].concat(temp));
  };


  const doCreateGroupObj = (item, scoreList, result) => {

    let groupTemp = [];
    result.forEach((r, index) => {
      let temp ={
        groupNo: r,
        score: scoreList[index],
      };
      groupTemp.push(temp);
      setTargetGroup([].concat(groupTemp))
    })
  };

  const doClose = () => {
    if(method === 'student'){
      let targetLog = {
        timestamp: timestamp,
        classroom_code: state.target.code,
        school_name: state.target.schoolName,
        school_code: state.target.dsns,
        school_type: state.target.schoolType,
        class_name: state.target.classroom.name,
        class_type: state.target.classroom.type,
        teacher_name: state.myInfo.first_name ? state.myInfo.first_name : '',
        teacher_id: state.myInfo.id_number ? state.myInfo.id_number : '',
        teacher_ac: state.accessToken,
        targetInfo: targetStu,
        method: method,
        call_component: 'teacher/src/components/teach/content/vote'
      };
      //console.log(targetLog);
      fetch(dbUrl.concat('/classroom/addVoteRec'), {
        method: 'POST',
        body: JSON.stringify(targetLog),
        headers: {
          'content-type': 'application/json'
        },
      }).then(
        r => r.json()
      ).then(r => {
        switch (r.code) {
          case '201':
            console.log('已有此紀錄');
            break;
          case '200':
            console.log('已寫入紀錄');
            break;
          case '501':
            console.log('寫入互投紀錄發生錯誤');
            break;
          default:
            break;
        }
      })
      callback();
    } else if(method === 'groups') {
      callback();
    }
  }

  // return (
  //   <div className={classes.root}>
  //     <div className={classes.header}>
  //       <Typography variant="h4" className={classes.caption}>
  //         學生互投
  //       </Typography>
  //       <div style={{ flex: 1 }} />
  //       <Tooltip title={status === 'open' ? '開啟互投' : '關閉互投'}>
  //         <IconButton onClick={toggleStatus}>
  //           {status === 'open' ? (
  //             <AlarmOnIcon className={classes.statusIcon} />
  //           ) : (
  //             <AlarmOffIcon className={classes.statusIcon} />
  //           )}
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title="關閉">
  //         <IconButton onClick={callback}>
  //           <CloseIcon className={classes.closeIcon} />
  //         </IconButton>
  //       </Tooltip>
  //     </div>
  //     <div className={classes.content}>
  //       <canvas id="chart"></canvas>
  //     </div>
  //   </div>
  // );
  //console.log(timestamp);
  //console.log(targetStu);
  //console.log(checkAssess);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          學生互投
        </Typography>
      </div>
      <div className={classes.content0}>
      { checkAssess ? (
        <div className={classes.contentrow}>
          <Paper variant="outlined">
            <Typography
              variant="h5"
              color="secondary"
              style={{ padding: theme.spacing(2) }}
            >
              <div>未投票學生</div>
            </Typography>
            <div style={{ marginLeft: theme.spacing(0.5), marginBottom: theme.spacing(2), marginRight: theme.spacing(1),}}>
              {notAssessList.map((item) =>(
                <Chip
                  style={{ marginLeft: theme.spacing(2)}}
                  className={classes.chip}
                  label={item.name}
                  avatar={<Avatar>{item.seatNo}</Avatar>}
                  variant="default"
                  color={item.assessState ? 'secondary' : 'primary'}
                />
              ))}  
            </div>
          </Paper>
          <Paper variant="outlined">
            <Typography
              variant="h5"
              color="primary"
              style={{ padding: theme.spacing(2) }}
            >
              <div>投票結果</div>
            </Typography>
            { method === 'student' ?(
              <div style={{ marginLeft: theme.spacing(2), marginBottom: theme.spacing(2)}}>
                {targetStu.map((item) =>(
                  <Chip
                    className={classes.chip}
                    label={`${item.Name}  ${item.score} 票`}
                    avatar={<Avatar>{item.SeatNo}</Avatar>}
                    variant="default"
                    color='primary'
                  />
                ))}
              </div>
              ) : (
                <div style={{ marginLeft: theme.spacing(2), marginBottom: theme.spacing(2)}}>
                  {targetGroup.map((item) =>(
                  <Chip
                    className={classes.groupchip}
                    label={`第 ${item.groupNo} 組  ${item.score} 票`}
                    variant="default"
                    color='primary'
                  />
                ))}
                </div>
              )
            }
          </Paper>
        </div>
        ) : (
        <div className={classes.content}>
          <Typography variant="h4" className={classes.caption}>
            等待中.....
          </Typography>
        </div>
          )
      }
        <div className={classes.contentsw}>
          <Tooltip title={status === 'open' ? '開啟互投' : '關閉互投'}>
            <IconButton onClick={toggleStatus}>
              {status === 'open' ? (
                <Button 
                variant="contained"
                color="primary"
                >
                  開啟互投中
                </Button>
              ) : (
                <Button 
                variant="contained"
                color="secondary"
                >
                  已關閉互投
                </Button>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="關閉">
            <IconButton onClick={() => {
                doClose();
              }}>
              <Button 
                variant="contained"
                color="primary"
              >
                回到主畫面
              </Button>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Component;
