import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  Typography,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { 
  List as ListIcon, 
  MoreVert as MoreIcon,
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  Pets as PetsIcon,
  PlayArrow as PlayArrowIcon,
  People as PeopleIcon,
} from '@material-ui/icons';

import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../../config';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  content2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: 250,
    overflow: 'auto',
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(0.5, 0),
    width: '120px',
  },
}));
let targetStuTemp = [];
const dbUrl = envConfig.SERVER_URL;
const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [groups, setGroups] = useState([]);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);
  const [stuVisible, setStuVisible] = useState(false);
  const [stuGroupVisible, setStuGroupVisible] = useState(false);
  const [targetStu, setTargetStu] = useState([]);
  const [allChooseState, setAllChooseState] = useState(false);
  const [allGChooseState, setAllGChooseState] = useState(false);
  const [groupSelOption, setGroupSelOption] = useState([]);

  useEffect(() => {
    //console.log(state)
    targetStuTemp = state.students;
    
    targetStuTemp.forEach(student => {
      student.choose = false;
      student.showText = student.SeatNo + ' ' + student.Name; 
    })
    setTargetStu(targetStuTemp);
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/assess`)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            items.push({
              id: doc.id,
              data,
              primaryText: { students: '學生互評', groups: '小組互評' }[
                data.method
              ],
              secondaryText: moment(data.timestamp).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            });
          }
        });
        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        if(items.length>0){
          let sevenday = items[0].data.timestamp - 604800000;
          let temp = items.filter(x => x.data.timestamp > sevenday);
     
          setHistories(
            // items.map((item, index) => ({ ...item, avatar: index + 1 })),
            temp.map((item, index) => ({ ...item, avatar: index + 1 })),
          );
        }
      });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { results } = doc.data();

          if (results) {
            setGroups(
              results.map((group) => {
                return state.students.filter((student) =>
                  group.split(',').includes(`${student.ID}`),
                );
              }),
            );
          }
        }
      });
      
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // 將群組加入勾選選項
  const doAddChoose = (groups) => {
      let temp = groups;
      temp.forEach(g => g.unshift(false));
      setGroupSelOption([].concat(temp));
      
  }

  const doAllChoose = () => {
    let temp = targetStu;
    if(!allChooseState){
        temp.forEach((student) => {
        student.choose = true;
      })
      setTargetStu([].concat(temp));
      setAllChooseState(true);
    } else if(allChooseState){
        temp.forEach((student) => {
        student.choose = false;
      })
      setTargetStu([].concat(temp));
      setAllChooseState(false);
    }
  }

  const doGAllChoose = () => {
    let temp = groupSelOption;
    if(!allGChooseState){
        temp.forEach((group) => {
          group[0] = true;
      })
      setGroupSelOption([].concat(temp));
      setAllGChooseState(true);
    } else if(allGChooseState){
        temp.forEach((group) => {
          group[0] = false;
      })
      setGroupSelOption([].concat(temp));
      setAllGChooseState(false);
    }
  }



  const doChoose = (student) => {
    targetStuTemp = targetStu;
    const index = targetStuTemp.findIndex(item => 
      item.ID === student.ID);
    if( index > -1 ){
      if(!student.choose){
        student.choose = true;
      } else if(student.choose){
        student.choose = false;
      }
    }
    setTargetStu([].concat(targetStuTemp));
  }

  const start = (method) => {
    const timestamp = Date.now();
    const event = { timestamp, method, status: 'open' };
    //console.log(state);

    // 呼叫「報表列印API」
    let targetLog = [{
      system: "課堂工具",
      action: "使用課堂工具功能",
      school_type: state.target.schoolType,
      school_code: state.target.dsns,
      role_type: "teacher",
      identity: state.appsChooseItem.title,
      call_component: 'teacher/src/components/teach/action/assess',
      id_number:`${state.target.classroom.name}(${state.target.schoolYear})`,
    }];
    fetch(dbUrl.concat('/classroom/postToLog'), {
      method: 'POST',
      body: JSON.stringify(targetLog),
      headers: {
        'content-type': 'application/json'
      },
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
    })


    if (method === 'students') {
      // event.data = state.students.map((item) => `${item.SeatNo} ${item.Name}`);
      // 調整成讓學生評比教師選擇的學生
      event.data = [];
      let stuInfo = {
        nameText:'',
        Name:'',
        SeatNo:'',
        assessType: '個人',
      }
      state.students.forEach((item) => {
        stuInfo={
          nameText:`${item.SeatNo} ${item.Name}`,
          Name:item.Name,
          SeatNo:item.SeatNo,
          ID:item.ID
        }
        if(item.choose){
          //event.data.push(`${item.SeatNo} ${item.Name}`);
          event.data.push(stuInfo);
        }
      })
      // event.data = state.students.map((item) => {
      //     if(item.choose){
      //       `${item.SeatNo} ${item.Name}`
      //     }
      //   }
      // );
    } else if (method === 'groups') {
      // event.data = groups.map((item, index) => `第 ${index + 1} 組`);
      // console.log(groups);
      event.data = [];
      let temp = groupSelOption.filter(g => g[0] === true);
      temp.forEach(t => {
        t.forEach((i, index) => {
          if(index > 0) {
            event.data.push(i);
          } 
        })
      })
      event.data.unshift('groups');
    }
    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.assess.open',
      timestamp: Date.now(),
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/${timestamp}`)
      .set(event);
  };

  const doCheckG = () =>{
    doAddChoose(groups);
    setStuGroupVisible(true);
  }

  const doGroupChoose = (groups, index) =>{
    let temp = groupSelOption;
    let groupNoTemp = index;

    if(temp[index][0] === true) { 
      temp[index][0] = false;
    } else if(temp[index][0] === false) {
      temp[index][0] = true;
    }
    temp[groupNoTemp].forEach((stu, index) => {
      if(index > 0){
        stu.groupNo = groupNoTemp + 1;
      }
    })
    setGroupSelOption([].concat(temp));
  }

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

    
  const renderInputDialog = () =>
    stuVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content2}>
          <Typography variant="h5" gutterBottom>
            請選擇目標學生
          </Typography>
          {/* {targetStu.map((item) =>(
            <Chip
              className={classes.chip}
              label={item.showText}
              variant="default"
              color={item.choose ? 'secondary' : 'primary'}
              onClick={() => {
                doChoose(item);
              }}
            />
          ))} */}
          <div
            style={{
              overflow: 'auto',
              padding: '4px',
              width: '90%',
              marginLeft: '10px',
            }}
          >
            <FormControl
                style={{
                  marginLeft: '10px',
                  width: '90%',
                }}
              >
                <RadioGroup
                  aria-label="unfinish"
                  name="unfinish"
                >
                  {targetStu.map((item) =>(
                    <FormControlLabel
                      value={item.ID}
                      control={<></>}
                      label={
                        <Chip
                          className={classes.chip}
                          label={item.showText}
                          variant="default"
                          color={item.choose ? 'secondary' : 'primary'}
                          onClick={() => {
                            doChoose(item);
                          }}
                        />
                      }
                    >
                    </FormControlLabel>
                  ))}
                </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className={classes.row}>
          <div style={{ width: theme.spacing(3) }} />
          {/* <IconButton
            color="primary"
            onClick={() => {
              setStuVisible(false);
              start('students');
              // setTime(parseInt(inputText, 10) || 0);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton> */}
          <Button
                startIcon={<PeopleIcon />}
                variant="contained"
                color="primary"
                style={{ margin: theme.spacing(2) }}
                onClick={() => {
                  doAllChoose();
                }}
              >全選</Button>
          <Button
                startIcon={<PlayArrowIcon />}
                variant="contained"
                color="secondary"
                style={{ margin: theme.spacing(2) }}
                onClick={() => {
                  setStuVisible(false);
                  start('students');
                  // setTime(parseInt(inputText, 10) || 0);
                }}
              >開始</Button>
        </div>
      </div>
    );

    const renderGroupInputDialog = () =>
    stuGroupVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content2}>
          <Typography variant="h5" gutterBottom>
            請選擇目標組別
          </Typography>
          <div
            style={{
              overflow: 'auto',
              padding: '4px',
              width: '90%',
              marginLeft: '10px',
            }}
          >
            <FormControl
              style={{
                marginLeft: '10px',
                width: '90%',
              }}
            >
              <RadioGroup
                aria-label="unfinish"
                name="unfinish"
              >
                {groupSelOption.map((item, index) =>(
                  <FormControlLabel
                    value={index}
                    control={<></>}
                    label={
                      <Chip
                        className={classes.chip}
                        label={`第 ${index+1} 組`}
                        variant="default"
                        color={item[0] ? 'secondary' : 'primary'}
                        onClick={() => {
                          doGroupChoose(item, index);
                        }}
                      />
                    }
                  >
                  </FormControlLabel>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className={classes.row}>
          <div style={{ width: theme.spacing(3) }} />
          {/* <IconButton
            color="primary"
            onClick={() => {
              setStuVisible(false);
              start('students');
              // setTime(parseInt(inputText, 10) || 0);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton> */}
          <Button
                startIcon={<PeopleIcon />}
                variant="contained"
                color="primary"
                style={{ margin: theme.spacing(2) }}
                onClick={() => {
                  doGAllChoose();
                }}
              >全選</Button>
          <Button
                startIcon={<PlayArrowIcon />}
                variant="contained"
                color="secondary"
                style={{ margin: theme.spacing(2) }}
                onClick={() => {
                  setStuVisible(false);
                  start('groups');
                  // setTime(parseInt(inputText, 10) || 0);
                }}
              >開始</Button>
        </div>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
      <Button 
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={() => setStuVisible(true)}>
            學生互評
        </Button>
        <div style={{ height: theme.spacing(4) }} />
        {/* <Button onClick={() => start('students')}>
          <Typography variant="h4" color="primary">
            學生互評
          </Typography>
        </Button>
        <div style={{ height: theme.spacing(4) }} /> */}
        {/* <Button disabled={groups.length === 0} onClick={() => start('groups')}>
          <Typography
            variant="h4"
            color={groups.length === 0 ? 'textSecondary' : 'primary'}
          >
            小組互評
          </Typography>
        </Button>
        <div style={{ height: theme.spacing(4) }} /> */}
        <Button 
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={groups.length === 0} 
          onClick={() => {
          doCheckG();
        }}>
          小組互評
        </Button>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderHistoryModal()}
        {renderInputDialog()}
        {renderContent()}
        {renderGroupInputDialog()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            const timestamp = moment().format('MMDDHHmm');
            const content = [
              `${item.data.method === 'students' ? '學生' : '小組'},分數`,
            ];
            const data = item.data.data.map((i) => 0);
            Object.keys(item.data).forEach((key) => {
              if (key.includes('student')) {
                item.data[key].content.forEach((c, i) => {
                  data[i] += c;
                });
              }
            });
            item.data.data.forEach((item, index) => {
              content.push(`${item},${data[index]}`);
            });

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              'data:text/plain;charset=utf-8,' +
                encodeURIComponent(content.join('\r\n')),
            );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_學生互評_${timestamp}.csv`,
            );

            element.style.display = 'none';
            (document.body || {}).appendChild(element);

            element.click();

            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/assess/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.assess.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
