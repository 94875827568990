import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import {
  Paper,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ControlPoint as ControlPointIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import moment from 'moment';
import Edit from '../../assets/edit.png';
import firebase from 'firebase/app';
import 'firebase/firestore';
import envConfig from '../../config';
import CryptoJS from 'crypto-js';
import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    borderRadius: '15px',
    marginTop: '-20px',
  },
  onDutyList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '88%',
    height: '44px',
    borderRadius: '5px',
    marginLeft: theme.spacing(2),
    backgroundColor: 'rgba(255,155,63,0.12)',
    marginTop: '2px',
  },
  // seatNoI: {
  //   color: 'white',
  //   marginTop: '-1.9px',
  //   marginLeft: '-2.2px',
  // },
  seatNoI: {
    color: 'white',
    marginTop: '0.1px',
    marginLeft: '-0.2px',
  },
  numberArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    marginTop: '6px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    width: '76%',
  },
  seatNoO: {
    display: 'block',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: '6px solid #1db7bd',
    backgroundColor: '#1db7bd',
    marginLeft: '12px',
    marginTop: '-2px',
  },
  edit: {
    width: '30px',
    height: '30px',
    padding: '2px',
    marginTop: '5px',
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: '120px',
    height: '30px',
    frontSize: '15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);


const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const onDutyList = ['04', '18', '22'];
const dbUrl = envConfig.SERVER_URL;
let firebaseUID = '';
let curToday = moment().format('YYYYMMDD');
const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [noneStudentsErrorView, setNoneStudentsErrorView] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [selectView, setSelectView] = useState(false);
  const [editIconState, setEditIconState] = useState(true);

  useEffect(() => {
    if (!state.device || !state.device.finalClassID) return;

    let targetClass2 = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_id: state.device.finalClassID,
    };

    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    let targetClass2Json = JSON.stringify(targetClass2);

    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}
    
    try {
      fetch(dbUrl.concat('/classroom/getClassStudents'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        }}).then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          if (finalData.length === 0 || typeof finalData === 'undefined') {
            setStudentList([].concat([]));
          } else {
            let tempStuList = [].concat(finalData[0].Student);
            tempStuList.forEach((i) => {i.onduty = false});
            firebase
              .firestore()
              .doc(`/onduty/${firebaseUID}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const results = doc.data();
                  if(results.cur_today !== curToday){
                  //if(results.cur_today !== '20220823'){
                    setStudentList([].concat(tempStuList));
                    firebase
                      .firestore()
                      .doc(`/onduty/${firebaseUID}`)
                      .set({
                        school_code: state.device.schoolCode,
                        school_type: state.device.schoolType,
                        class_id: state.device.ClassID,
                        cur_today: curToday,
                        stu_list: []
                      });
                  } else {
                    let ondutyStuList = [].concat(results.stu_list);
                    //console.log(ondutyStuList);
                    ondutyStuList.forEach(stu => {
                      let targetIndex = tempStuList.findIndex(i => i.UUID === stu.UUID);
                      if(targetIndex > -1) {
                        tempStuList[targetIndex].onduty = true
                      }
                    })
                    setStudentList([].concat(tempStuList));
                  }
                } else {
                  setStudentList([].concat(tempStuList));
                }
                setEditIconState(false);
              })
          }
        })
    } catch (error) {
      setEditIconState(false);
      console.log(error);
    }

   
    // try {
    //   fetch(dbUrl.concat('/classroom/getClassStudents'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       if (r.length === 0 || typeof r === 'undefined') {
    //         setStudentList([].concat([]));
    //       } else {
    //         let tempStuList = [].concat(r[0].Student);
    //         tempStuList.forEach((i) => {i.onduty = false});
    //         firebase
    //           .firestore()
    //           .doc(`/onduty/${firebaseUID}`)
    //           .get()
    //           .then((doc) => {
    //             if (doc.exists) {
    //               const results = doc.data();
    //               if(results.cur_today !== curToday){
    //               //if(results.cur_today !== '20220823'){
    //                 setStudentList([].concat(tempStuList));
    //                 firebase
    //                   .firestore()
    //                   .doc(`/onduty/${firebaseUID}`)
    //                   .set({
    //                     school_code: state.device.schoolCode,
    //                     school_type: state.device.schoolType,
    //                     class_id: state.device.ClassID,
    //                     cur_today: curToday,
    //                     stu_list: []
    //                   });
    //               } else {
    //                 let ondutyStuList = [].concat(results.stu_list);
    //                 //console.log(ondutyStuList);
    //                 ondutyStuList.forEach(stu => {
    //                   let targetIndex = tempStuList.findIndex(i => i.UUID === stu.UUID);
    //                   if(targetIndex > -1) {
    //                     tempStuList[targetIndex].onduty = true
    //                   }
    //                 })
    //                 setStudentList([].concat(tempStuList));
    //               }
    //             } else {
    //               setStudentList([].concat(tempStuList));
    //             }
    //             setEditIconState(false);
    //           })
    //           console.log(tempStuList)
    //       }
    //     });
    // } catch (error) {
    //   setEditIconState(false);
    //   console.log(error);
    // }

  }, [state.device]);

  useEffect(() => {
    if(!state.changeToday) return;
    let curToday1 = moment().format('YYYYMMDD');
    if(state.changeToday === curToday1) return;
    setEditIconState(true);
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;

    let targetClass2 = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_id: state.device.finalClassID,
    };
    let targetClass2Json = JSON.stringify(targetClass2);

    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getClassStudents'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        }}).then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          if (finalData.length === 0 || typeof finalData === 'undefined') {
            setStudentList([].concat([]));
          } else {
            let tempStuList = [].concat(finalData[0].Student);
            tempStuList.forEach((i) => {i.onduty = false});
            setStudentList([].concat(tempStuList));
            setEditIconState(false);
            firebase
              .firestore()
              .doc(`/onduty/${firebaseUID}`)
              .set({
                school_code: state.device.schoolCode,
                school_type: state.device.schoolType,
                class_id: state.device.ClassID,
                cur_today: curToday,
                stu_list: []
              });
          }
        })
    } catch (error) {
      console.log(error);
      setEditIconState(false);
    }


    // try {
    //   fetch(dbUrl.concat('/classroom/getClassStudents'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       if (r.length === 0 || typeof r === 'undefined') {
    //         setStudentList([].concat([]));
    //       } else {
    //         let tempStuList = [].concat(r[0].Student);
    //         tempStuList.forEach((i) => {i.onduty = false});
    //         setStudentList([].concat(tempStuList));
    //         setEditIconState(false);
    //         firebase
    //           .firestore()
    //           .doc(`/onduty/${firebaseUID}`)
    //           .set({
    //             school_code: state.device.schoolCode,
    //             school_type: state.device.schoolType,
    //             class_id: state.device.ClassID,
    //             cur_today: curToday,
    //             stu_list: []
    //           });
    //       }
    //     });
    // } catch (error) {
    //   console.log(error);
    //   setEditIconState(false);
    // }
  },[state.changeToday]);

  const doGetClassStudents = () => {
    if (loading || editIconState) return;
    setLoading(true);

    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    setSelectView(true);

    let targetClass2 = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_id: state.device.finalClassID,
    };
    let targetClass2Json = JSON.stringify(targetClass2);

    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getClassStudents'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        }}).then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          if (finalData.length === 0 || typeof finalData === 'undefined') {
            setNoneStudentsErrorView(true);
          } else {
            let tempStuList = [].concat(finalData[0].Student);
            tempStuList.forEach((i) => {i.onduty = false});
            firebase
              .firestore()
              .doc(`/onduty/${firebaseUID}`)
              .get()
              .then((doc) => {
                //console.log(doc.exists);
                if (doc.exists) {
                  const results = doc.data();
                  //console.log(results);
                  let ondutyStuList = [].concat(results.stu_list);
                  //console.log(ondutyStuList);
                  ondutyStuList.forEach(stu => {
                    let targetIndex = tempStuList.findIndex(i => i.UUID === stu.UUID);
                    if(targetIndex > -1) {
                      tempStuList[targetIndex].onduty = true
                    }
                  })
                  setStudentList([].concat(tempStuList));
                } else {
                  setStudentList([].concat(tempStuList));
                  
                }
                setLoading(false);
              })
          }
        })
    } catch (error) {
      console.log(error);
      setNoneStudentsErrorView(true);
    }

    // try {
    //   fetch(dbUrl.concat('/classroom/getClassStudents'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       //console.log(r);
    //       if (r.length === 0 || typeof r === 'undefined') {
    //         setNoneStudentsErrorView(true);
    //       } else {
    //         let tempStuList = [].concat(r[0].Student);
    //         tempStuList.forEach((i) => {i.onduty = false});
    //         firebase
    //           .firestore()
    //           .doc(`/onduty/${firebaseUID}`)
    //           .get()
    //           .then((doc) => {
    //             //console.log(doc.exists);
    //             if (doc.exists) {
    //               const results = doc.data();
    //               //console.log(results);
    //               let ondutyStuList = [].concat(results.stu_list);
    //               //console.log(ondutyStuList);
    //               ondutyStuList.forEach(stu => {
    //                 let targetIndex = tempStuList.findIndex(i => i.UUID === stu.UUID);
    //                 if(targetIndex > -1) {
    //                   tempStuList[targetIndex].onduty = true
    //                 }
    //               })
    //               setStudentList([].concat(tempStuList));
    //             } else {
    //               setStudentList([].concat(tempStuList));
                  
    //             }
    //             setLoading(false);
    //           })
    //       }
    //     });
    // } catch (error) {
    //   console.log(error);
    //   setNoneStudentsErrorView(true);
    // }
  };

  // useEffect(() => {
  //   const messageListener = (event) => {
  //     let temp = {
  //       school_code: state.device.schoolCode,
  //       school_type: state.device.schoolType,
  //       class_id: state.device.ClassID,
  //       stuList: [].concat(studentList)
  //     }
  //     window.android.postMessage({ondutyList:[temp]});
  //   }
  //   window.addEventListener('message', messageListener);

  //   return () => {
  //     window.removeEventListener('message', messageListener);
  //   };

  // },[studentList])

  const saveOndutyList = () => {
    if(studentList.length === 0 ) {
      setSelectView(false);
    } else {
      let tempStuList = [].concat(studentList);
      let finalList = [];
      tempStuList.forEach(stu => {
        if(stu.onduty) {
          finalList.push(stu);
        }
      })

      //console.log(finalList)
      firebase
      .firestore()
      .doc(`/onduty/${firebaseUID}`)
      .set({
        school_code: state.device.schoolCode,
        school_type: state.device.schoolType,
        class_id: state.device.ClassID,
        cur_today: curToday,
        stu_list: [].concat(finalList)
      });
      setSelectView(false);
    }
  };

  const doCheckStu = (index) => {
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    //console.log(firebaseUID);
    let tempStuList = studentList;
    if (tempStuList[index].onduty) {
      tempStuList[index].onduty = false;
    } else {
      tempStuList[index].onduty = true;
    }
    //console.log(tempStuList);
    setStudentList([].concat(tempStuList));
  };

  const noneStudentsErrorDialog = () =>
    noneStudentsErrorView && (
      <Dialog
        open={noneStudentsErrorView}
        onClose={() => setNoneStudentsErrorView(false)}
      >
        <DialogTitle>沒有學生提示</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              direction: 'row',
              flexWrap: 'wrap',
            }}
          >
            <DialogContentText color="error">
              此班級沒有相關學生。
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNoneStudentsErrorView(false)}
            color="primary"
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const selectDialog = () =>
    selectView && (
      <Dialog
        disableBackdropClick
        open={selectView}
        onClose={() => setSelectView(false)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
        }}
        classes={{
          paper: classes.dialogStyle,
        }}
      >
        <div style={{ display: 'flex',flexDirection: 'row',alignItems: 'center',justifyContent: 'space-between'}}>
          <DialogTitle>請選擇值日學生</DialogTitle>
          <CloseIcon
            style={{padding:'20px'}}
            onClick={()=>setSelectView(false)}
          />
        </div>
        {/* <DialogTitle
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          請選擇值日學生
        </DialogTitle> */}
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
                <div>
                  {studentList.map((stuitem, stuindex) =>
                    !stuitem.onduty ? (
                      <Button
                        onClick={() => doCheckStu(stuindex)}
                        variant="outlined"
                        style={{
                          border: 'solid 2px #e0e0e0',
                          borderRadius: '100px',
                          margin: '5px',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography style={{ color: '#323232', width: '15px' }}>
                            {stuitem.SeatNo > 9
                              ? stuitem.SeatNo
                              : '0' + stuitem.SeatNo}
                          </Typography>
                          <Typography style={{ marginLeft: '10px' }}>
                            {stuitem.StudentName}
                          </Typography>
                        </div>
                        <ControlPointIcon
                          fontSize="small"
                          style={{
                            marginLeft: '10px',
                            marginRight: '-10px',
                            color: '#a3a3a3',
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => doCheckStu(stuindex)}
                        variant="outlined"
                        style={{
                          border: 'solid 2px #1db7bd',
                          borderRadius: '100px',
                          margin: '5px',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography style={{ color: '#323232', width: '15px' }}>
                            {stuitem.SeatNo > 9
                              ? stuitem.SeatNo
                              : '0' + stuitem.SeatNo}
                          </Typography>
                          <Typography style={{ marginLeft: '10px' }}>
                            {stuitem.StudentName}
                          </Typography>
                        </div>
                        <CheckCircleIcon
                          fontSize="small"
                          style={{
                            marginLeft: '10px',
                            marginRight: '-10px',
                            color: '#1fb6bd',
                          }}
                        />
                      </Button>
                    ),
                  )}
                </div> 
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <ColorButton
            variant="contained"
            color="primary"
            onClick={saveOndutyList}
          >
            確認
          </ColorButton>
        </DialogActions>
      </Dialog>
    );

  return (
    <>
      {noneStudentsErrorDialog()}
      {selectDialog()}
      <Paper
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100px',
          borderRadius: '15px',
          maxWidth: '290px'
        }}
      >
        <Typography
          variant="h6"
          style={{
            marginTop: theme.spacing(1.5),
            marginLeft: theme.spacing(2),
          }}
        >
          值日生
        </Typography>
        <div className={classes.onDutyList}>
          <div className={classes.numberArea}>
            {studentList.map(
              (stuitem) =>
                stuitem.onduty && (
                  <div className={classes.seatNoO}>
                    <div className={classes.seatNoI}>
                      {stuitem.SeatNo > 9
                        ? stuitem.SeatNo
                        : '0' + stuitem.SeatNo}
                    </div>
                  </div>
                ),
            )}
          </div>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              maxHeight: '40px',
            }}
            onClick={() => doGetClassStudents()}
          >
            {editIconState ? <CircularProgress size='1.5rem' style={{color:'#1db7bd'}}/> : <EditIcon fontSize="medium" />}
          </IconButton>
        </div>
      </Paper>
    </>
  );
};

export default Component;
