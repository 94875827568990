import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { AppContext } from '../../../App';
import envConfig from '../../../config.js'

const useStyles = makeStyles((theme) => ({
  cards: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(0, 0, 0, 0.27)',
  },
  caption: {
    fontSize: '1.6rem',
    color: '#f1f1f1',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 3),
  },
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: theme.spacing(1),
  },
  image: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    margin: theme.spacing(1),
  },
  myimage: {
    width: theme.spacing(14.5),
    height: theme.spacing(14.5),
    margin: theme.spacing(1),
    fontSize: "50px",
    borderRadius: "20px"
  },
  myxsimage: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
    borderRadius: "30px"
  },
  title: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    margin: theme.spacing(1, 0),
    width: theme.spacing(17.5),
  },
  title2: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    margin: theme.spacing(1, 0),
    width: theme.spacing(17.5),
  },
  lists: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 0,
  },
  icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 'initial',
    marginRight: theme.spacing(2),
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const dbUrl = envConfig.SERVER_URL;
let targetLog = [];

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xsMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const { state, dispatch } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  const [onoUserInfoView, setOnoUserInfoView] = useState(false);
  const [onoUserInfo, setOnoUserInfo] = useState({
    school_name:'',
    school_type:'',
    school_code:'',
    teacher_name:'',
    teacher_uid: '',
    class_name:'',
    class_code:'',
    accessToken:'',
  });

  useEffect(() => {
    if (!state.config) return;
    //console.log(state);
    //e.x.
    //{
    // icon: "/assets/cooc.png"
    // key: "cooc.tp.edu.tw"
    // title: "臺北酷課雲"
    // url: "https://cooc.tp.edu.tw/auth/SyncSignI
    //}
    let onoConfig = {
      icon: "/assets/ono.png",
      key: "ono.tp.edu.tw",
      title: "教材資源庫",
      url: "https://ono.tp.edu.tw/",
    }
    let tempConfig = [].concat(state.config);
    
    if(state.target.dsns === '319998'){
      tempConfig[0].items.push(onoConfig);
    }
    //console.log(tempConfig);
    //tempConfig.push(onoConfig);
    setCategories(tempConfig);
    let targetClassroom = {
      classroom_code: state.target.code,
      school_name: state.target.schoolName,
      school_code: state.target.dsns,
      school_type: state.target.schoolType,
      class_name: state.target.classroom.name,
      class_type: state.target.classroom.type,
      teacher_name: state.target.teacher.name,
      access_token: state.accessToken,
      call_component: 'teacher/src/components/teach/main/apps',
    };

    fetch(dbUrl.concat('/classroom/addClassroomCreate'), {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(targetClassroom)
    }).then(
      r => r.json()
    ).then(r => {
      if(r){
        //console.log(r.classroom_code);
      }
    });

  }, [state.config]);


  useEffect(() => {
    //console.log(state);
    if(!state.target || !state.myInfo) return
    //console.log(state);
    targetLog = [{
        system: "課堂工具",
        action: "使用課堂工具",
        school_type: state.target.schoolType,
        school_code: state.target.dsns,
        role_type: "teacher",
        identity: state.myInfo.id_number,
        call_component: 'teacher/src/components/teach/main/apps',
        id_number:`${state.target.classroom.name}(${state.target.schoolYear})`,
      }]
    try {
      fetch(dbUrl.concat('/classroom/postToLog'), {
        method: 'POST',
        body: JSON.stringify(targetLog),
        headers: {
          'content-type': 'application/json'
        },
      }).then(
        r => r.json()
      ).then(r => {
        //console.log(r);
      })
     } catch (error) {
        console.log(error);
      }
  },[state.myInfo]);


  //console.log(categories);

  // 0331前排版
  // return categories.length === 0 ? (
  //   <div className={classes.loading}>
  //     <CircularProgress style={{ color: '#f1f1f1' }} />
  //   </div>
  // ) : xsMediaQuery ? (
  //   <Paper className={classes.lists}>
  //     {categories.map((category, sdx) => (
  //       <List
  //         key={`list-${sdx}`}
  //         component="nav"
  //         subheader={
  //           <ListSubheader disableSticky={true}>{category.title}</ListSubheader>
  //         }
  //       >
  //         {category.items.map((item, idx) => (
  //           <div key={`item-${sdx}-${idx}`}>
  //             <ListItem
  //               button
  //               onClick={() => {
  //                 dispatch({
  //                   type: 'APPS_CHOOSE_ITEM',
  //                   payload: { ...item, timestamp: Date.now() },
  //                 });
  //               }}
  //             >
  //               <ListItemAvatar>
  //                 {/* <Avatar
  //                   src={String(item.icon)}
  //                   className={classes.icon}
  //                   imgProps={{ style: { backgroundColor: 'transparent' } }}
  //                 /> */}
  //                 {item.type === 'mycloud' ?
  //                         <Avatar
  //                           variant="rounded"
  //                           className={classes.myxsimage}
  //                           style={{ backgroundColor: item.icon }}
  //                         >
  //                           {item.title.substr(0, 1)}
  //                         </Avatar>
  //                         :
  //                         <Avatar
  //                             src={String(item.icon)}
  //                             className={classes.icon}
  //                             imgProps={{ style: { backgroundColor: 'transparent' } }}
  //                           />
  //                       }
  //               </ListItemAvatar>
  //               {/* <ListItemText primary={item.title} /> */}
  //               {item.type === 'mycloud' ?
  //                         // <div className={classes.title2}>
  //                         //   {item.title}
  //                         // </div>
  //                         <ListItemText style={{marginRight: theme.spacing(2) }} primary={item.title} />
  //                         : <ListItemText primary={item.title} />
  //                       }

  //             </ListItem>
  //             {idx !== category.items.length - 1 && (
  //               <Divider variant="middle" />
  //             )}
  //           </div>
  //         ))}
  //       </List>
  //     ))}
  //   </Paper>
  // ) : (
  //       <div className={classes.cards}>
  //         {categories.map((category) => (
  //           <div key={`category-${category.title}`}>
  //             <div className={classes.caption}>{category.title}</div>
  //             <div className={classes.row}>
  //               {category.items.map((item) => (
  //                 <Tooltip key={item.key} title={item.title}>
  //                   <Card className={classes.card}>
  //                     <CardActionArea
  //                       onClick={() =>
  //                         dispatch({
  //                           type: 'APPS_CHOOSE_ITEM',
  //                           payload: { ...item, timestamp: Date.now() },
  //                         })
  //                       }
  //                     >
  //                       {item.type === 'mycloud' ?
  //                         <Avatar
  //                           variant="rounded"
  //                           className={classes.myimage}
  //                           style={{ backgroundColor: item.icon }}
  //                         >
  //                           {item.title.substr(0, 1)}
  //                         </Avatar>
  //                         :
  //                         <img
  //                           src={String(item.icon)}
  //                           className={classes.image}
  //                           alt={item.title}
  //                         />
  //                       }
  //                       {item.type === 'mycloud' ?
  //                         <div className={classes.title2}>
  //                           {item.title}
  //                         </div>
  //                         : <div className={classes.title}>{item.title}</div>
  //                       }
  //                     </CardActionArea>

  //                   </Card>
  //                 </Tooltip>
  //               ))}
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     );
  // 0331前排版

  const doOpenOnoUserInfo = (item) => {
    let tempUrl_1 = dbUrl.concat(`/classroom/getOpenONO?school_name=${state.target.schoolName}&school_type=${state.target.school_type}&school_code=${state.target.dsns}&teacher_name=${state.target.teacher.name}&teacher_uid=${state.target.author}&class_name=${state.target.classroom.name}&class_code=${state.target.classroom.uid}&accessToken=${state.accessToken}`);
    let tempUrl = 'https://ono.tp.edu.tw/user/resources/files#?pageIndex=1&sortPredicate=created_at&isReverse=true';
      item.url = tempUrl
      dispatch({
        type: 'APPS_CHOOSE_ITEM',
        payload: { ...item, timestamp: Date.now() },
      });
  }

  const onoUserInfoDialog = () =>
    onoUserInfoView && (
      <Dialog
        open={onoUserInfoView}
        onClose={() => setOnoUserInfoView(false)}
        classes={{
          paper: classes.dialogStyle,
        }}
      >
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column',padding: '10px'}}>
            <div>學校名稱:{onoUserInfo.school_name}</div>
            <div>學校學制:{onoUserInfo.school_type}</div>
            <div>學校代碼:{onoUserInfo.school_code}</div>
            <div>教師姓名:{onoUserInfo.teacher_name}</div>
            <div>教師uid:{onoUserInfo.teacher_uid}</div>
            <div>班級名稱:{onoUserInfo.class_name}</div>
            <div>班級代碼:{onoUserInfo.class_code}</div>
            <div>使用者accessToken:{onoUserInfo.accessToken}</div>
          </div>
        </DialogContent>
      </Dialog>
    );


  // 因應記者會調整
  return categories.length === 0 ? (
    <div className={classes.loading}>
      <CircularProgress style={{ color: '#f1f1f1' }} />
    </div>
  ) : xsMediaQuery ? (
    <>
      {onoUserInfoDialog()}
      <Paper className={classes.lists}>
        {categories.map((category, sdx) => (
          <List
            key={`list-${sdx}`}
            component="nav"
            subheader={
              <ListSubheader disableSticky={true}>{category.title}</ListSubheader>
            }
          >
            {category.items.map((item, idx) => (
              <div key={`item-${sdx}-${idx}`}>
                <ListItem
                  button
                  onClick={() => {
                    if(item.key === "ono.tp.edu.tw") {
                      // console.alert(item);
                      doOpenOnoUserInfo(item);
                    } else {
                      dispatch({
                        type: 'APPS_CHOOSE_ITEM',
                        payload: { ...item, timestamp: Date.now() },
                      });
                    }
                  }}
                >
                  <ListItemAvatar>
                    {item.type === 'mycloud' ?
                            <Avatar
                              variant="rounded"
                              className={classes.myxsimage}
                              style={{ backgroundColor: item.icon }}
                            >
                              {item.title.substr(0, 1)}
                            </Avatar>
                            :
                            <Avatar
                                src={String(item.icon)}
                                className={classes.icon}
                                imgProps={{ style: { backgroundColor: 'transparent' } }}
                              />
                          }
                  </ListItemAvatar>
                  {item.type === 'mycloud' ?
                            <ListItemText style={{marginRight: theme.spacing(2) }} primary={item.title} />
                            : <ListItemText primary={item.title} />
                          }

                </ListItem>
                {idx !== category.items.length - 1 && (
                  <Divider variant="middle" />
                )}
              </div>
            ))}
          </List>
        ))}
      </Paper>
    </>
  ) : (
        <div className={classes.cards}>
          {onoUserInfoDialog()}
          {categories.map((category) => (
            <div key={`category-${category.title}`}>
              <div className={classes.caption}>{category.title}</div>
              <div className={classes.row}>
                {category.items.map((item) => (
                  <Tooltip key={item.key} title={item.title}>
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={() =>
                          {if(item.key === "ono.tp.edu.tw") {
                            //console.log(item);
                            doOpenOnoUserInfo(item);
                          } else {
                            dispatch({
                              type: 'APPS_CHOOSE_ITEM',
                              payload: { ...item, timestamp: Date.now() },
                            });
                          }}
                        }
                      >
                        {item.type === 'mycloud' ?
                          <Avatar
                            variant="rounded"
                            className={classes.myimage}
                            style={{ backgroundColor: item.icon }}
                          >
                            {item.title.substr(0, 1)}
                          </Avatar>
                          :
                          <img
                            src={String(item.icon)}
                            className={classes.image}
                            alt={item.title}
                          />
                        }
                        {item.type === 'mycloud' ?
                          <div className={classes.title2}>
                            {item.title}
                          </div>
                          : <div className={classes.title}>{item.title}</div>
                        }
                      </CardActionArea>

                    </Card>
                  </Tooltip>
                ))}
              </div>
            </div>
          ))}
        </div>
      );

    
};

export default Component;
