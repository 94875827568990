import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import zoomin from '../../assets/ZoomIn.svg';
import refresh from '../../assets/refresh.svg';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Weekly from './subItem/weeklyb';
import Picker from './subItem/picker';
import { AppContext } from '../../App';
import envConfig from '../../config';
import CryptoJS from 'crypto-js';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    borderRadius: '15px',
    marginTop: '-20px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleText: {
    marginTop: '15px',
    marginLeft: theme.spacing(2),
  },
  titleIcon: {
    marginTop: '7px',
    marginRight: theme.spacing(2),
  },
  yaerSel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '6px',
  },
  yearText: {
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  weekView: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    width: '243px',
    height: '100px',
    borderRadius: '10px',
    border: 'solid 2px #a3a3a3',
    marginLeft: '11px',
    alignItems: 'center',
  },
  weekNumbers: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-around',
    width: '87%',
  },
  dayNumbers: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '90%',
  },
  dayDot: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    width: '90%',
  },
  currentDay: {
    display: 'block',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: '6px solid #ff9b3f',
    backgroundColor: '#ff9b3f',
    marginLeft: '14px',
    marginTop: '-2px',
  },
  currentDay2: {
    display: 'block',
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    border: '9px solid #ff9b3f',
    backgroundColor: '#ff9b3f',
    marginLeft: '18px',
    marginTop: '6px',
  },
  missionDot: {
    display: 'block',
    height: '1px',
    width: '1px',
    borderRadius: '50%',
    border: '2px solid #ff9b3f',
    backgroundColor: '#ff9b3f',
    marginLeft: '12px',
    marginTop: '-2px',
  },
  missionView: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    marginTop: '5px',
    marginBottom: '4px',
  },
  missionList: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    height: '130px',
    marginTop: '-2px',
    marginBottom: '4px',
    marginLeft: '15px',
    overflow: 'auto',
  },
  missionList2: {
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
    marginTop: '10px',
    marginBottom: '4px',
    //height: '260px',
    alignItems: 'center',
  },
  missionItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    marginTop: '8px',
    width: '200px',
    minHeight: '55px',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
  },
  missionItem2: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    minHeight: '30px',
    marginBottom: '8px',
    maxHeight: '100%',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
    padding: '5px',
  },
  missionText: {
    width: '90%',
    fontSize: '18px',
    marginLeft: '10px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
  },
  missionText2: {
    //width: '76%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '15px',
    marginLeft: '10px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  missionItem3: {
    display: 'flex',
    flexDirection: 'row',
    margin: '8px',
    width: '100%',
    minHeight: '24px',
    borderRadius: '10px',
    backgroundColor: '#f6f6f6',
    padding: '9px',
    marginRight: '10px',
  },
  missionText3: {
    width: '90%',
    fontSize: '15px',
    marginLeft: '5px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogStyle: {
    borderRadius: 15,
    position: 'absolute',
    left: 30,
    top: 240,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: '100px',
    height: '30px',
    frontSize: '15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');


const dbUrl = envConfig.SERVER_URL;
const weekNumbers = ['一', '二', '三', '四', '五', '六', '日'];
const dayNumbers = ['5', '6', '7', '8', '9', '10', '11'];
var now = new Date();
const year = now.getFullYear() - 1911;

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [targetDayText, setTargetDayText] = useState({
    dayText: '',
    weekText: '',
  });
  const [targetDayList, setTargetDayList] = useState([]);
  const [weekListView, setWeekListView] = useState(false);
  const [weekList, setWeekList] = useState([]);

  const [ClassID, setClassID] = useState('');

  const [curSchoolYear, setCurSchoolYear] = useState('');
  const [curSemester, setCurSemester] = useState('');

  const [schoolYear, setSchoolYear] = useState('');
  const [semester, setSemester] = useState('');
  const [month, setMonth] = useState('');
  const [dayText, setDayText] = useState('');

  const [calendarView, setCalendarView] = useState(false);
  const [curInfo, setCurInfo] = useState();
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if(!state.changeToday) return;
    doRefresh();
  },[state.changeToday]);

  useEffect(() => {
    if (!state.targetDay || state.device.id === 'classroom.tp.edu.tw') return;
    //console.log(state.targetDay);
    //setClassID(state.device.ClassID);
    setClassID(state.device.finalClassID);
    doSetContent(state.targetDay);

    // eslint-disable-next-line
  }, [state.targetDay]);

  useEffect(() => {
    if (!state.device || state.device.id === 'classroom.tp.edu.tw') return;
    //console.log(state.device);
    if (state.device.ClassID) {
      //setClassID(state.device.ClassID);
      setClassID(state.device.finalClassID)
      doRefresh();
    }
    //doSetContent(state.targetDay);
  }, [state.device]);

  useEffect(() => {
    if (!state.calendarWeekList) return;
    setSchoolYear(state.calendarWeekList.schoolYear);
    setSemester(state.calendarWeekList.semester);
    
  }, [state.calendarWeekList]);

  // useEffect(() =>{
  //   if(!state.today) return
  //   console.log(state.today)
  //   doDefaultDay(state.today)
  // },[state.today])

  const doDefaultDay = (i) => {
    dispatch({ type: 'SET_CALENDARWEEKLIST', payload: false });

    setDayText(i.daytext);
    //console.log(state);
    doCalSS();
    doSetContent(i);
  };

  const doCalSS = () => {
    let temp = moment();
    let tempNowYear = temp.format('YYYY');
    let tempNowMonth = parseInt(temp.format('MM'));
    setMonth(temp.format('MM'));
    let semester;
    let schoolYear;
    let twYear;
    tempNowMonth === 1
      ? (twYear = tempNowYear - 1912)
      : (twYear = tempNowYear - 1911);
    let tempNow = temp.format('YYYY-MM-DD');
    let startDate = `${tempNowYear}-01-31`;
    let endDate = `${tempNowYear}-08-01`;
    moment(tempNow).isBetween(startDate, endDate)
      ? (semester = '2')
      : (semester = '1');
    setSemester(semester);
    setCurSemester(semester);
    semester === '1' ? (schoolYear = twYear) : (schoolYear = twYear - 1);
    setSchoolYear(schoolYear.toString());
    setCurSchoolYear(schoolYear.toString());
  };

  const doSetContent = (item) => {

    let tempTargetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      date: item.daytext,
      //date: '2023/03/10',
      call_component:'teacher/src/components/main/calendar',
    };

    setClassID(tempTargetClass2.class_id);
    let targetClass2Json = JSON.stringify(tempTargetClass2);
      
    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          finalData = doMakePrioritize(finalData);
          setTargetDayList([].concat(finalData));
        });
    } catch (error) {
      console.log(error);
    }

    // try {
    //   fetch(dbUrl.concat('/classroom/getNotification'), {
    //     method: 'POST',
    //     body: JSON.stringify(tempTargetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       console.log(r);
    //       r = doMakePrioritize(r);
    //       setTargetDayList([].concat(r));
    //     });
    // } catch (error) {
    //   console.log(error);
    // }

    //console.log(item);
    
    let todayText;
    if (item.dayNo < 10) {
      todayText = '0' + item.dayNo;
    } else {
      todayText = '' + item.dayNo;
    }
    let temp = {
      dayText: todayText,
      weekText: item.weekText,
    };
    setTargetDayText(temp);
  };

  const renderWeek = () => {
    //console.log(state);
    if (!state.weekList) {
      renderWeek();
    }
    setWeekListView(true);
    let tempWeekList = {
      week: state.weekList,
      classID: ClassID,
      call_component:'teacher/src/components/main/calendar/'
    };

    try {
      fetch(dbUrl.concat('/classroom/getWeeklyNotification'), {
        method: 'POST',
        body: JSON.stringify(tempWeekList),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          r.forEach((i) => {
            i.content = doMakePrioritize(i.content);
          });
          setWeekList([].concat(r));
        });
    } catch (error) {
      console.log(error);
    }
  };
  //console.log(targetDayList);

  const doMakePrioritize = (item) => {
    item.forEach((i) => {
      switch (i.category) {
        case '通知事項':
          i.prioritize = 1;
          break;
        case '作業考試':
          i.prioritize = 2;
          break;
        case '攜帶物品':
          i.prioritize = 3;
          break;
        default:
          break;
      }
    });
    item.sort((a, b) => {
      if (a.prioritize < b.prioritize) {
        return -1;
      }
      if (a.prioritize > b.prioritize) {
        return 1;
      }
      return 0;
    });
    return item;
  };

  const doPickerClose = (i) => {
    //console.log(i);
    dispatch({ type: 'SET_CALENDARWEEKLIST', payload: false });
    setCalendarView(i);
  };

  const doRefresh = () => {
    if (refreshing) return;
    setRefreshing(true);
    let targetClass = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      call_component:'teacher/src/components/main/calendar',
    };

    let targetClass2 = {
      class_id: state.device.finalClassID ? state.device.finalClassID : state.device.ClassID,
      call_component:'teacher/src/components/main/calendar',
    };

    let targetClass2Json = JSON.stringify(targetClass2);

    const ciphertext = CryptoJS.AES.encrypt(targetClass2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getNotificationCalendarCry'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          let finalData = JSON.parse(decryptedData);
          dispatch({
            type: 'SET_NOTIFICATIONCALEDAR',
            payload: JSON.parse(JSON.stringify(finalData)),
          });
          setRefreshing(false);
        });
    } catch (error) {
      console.log(error);
    }
    
    // try {
    //   fetch(dbUrl.concat('/classroom/getNotificationCalendar'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetClass),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       dispatch({
    //         type: 'SET_NOTIFICATIONCALEDAR',
    //         payload: JSON.parse(JSON.stringify(r)),
    //       });
    //       setRefreshing(false);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const doOpenCalendarView = () => {
    // setSchoolYear(curSchoolYear) ;
    // setSemester(curSemester);
    setCalendarView(true);
  };

  const calendarDialog = () =>
    calendarView && (
      <Dialog
        open={calendarView}
        onClose={() => doPickerClose(false)}
        classes={{
          paper: classes.dialogStyle,
        }}
      >
        <Picker result={(i) => doPickerClose(i)} />
      </Dialog>
    );

  const weekListDialog = () =>
    weekListView && (
      <Dialog
        open={weekListView}
        onClose={() => setWeekListView(false)}
        maxWidth="ls"
        fullWidth="true"
        style={{ marginTop: '150px' }}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '5px',
            padding: '5px',
          }}
        >
          <DialogTitle className={classes.dialogTitle}>班級行事曆</DialogTitle>
          <DialogTitle className={classes.dialogTitle} style={{ flex: '1' }}>
            {schoolYear} 學年 {semester} 學期
          </DialogTitle>
          <DialogTitle
            className={classes.dialogTitle}
            style={{ flex: '1.5', marginLeft: '50px' }}
          >
            {month}月
          </DialogTitle>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            {/* <FullscreenExitIcon onClick={() => setTomorrowDialog(false)}/> */}
            <img
              src={zoomin}
              style={{
                width: '25px',
                height: '25px',
                marginRight: '20px',
              }}
              onClick={() => setWeekListView(false)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '700px',
            height: '400px',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {weekList.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: '700px',
                //flexWrap:'wrap',
                marginLeft: '10px',
                paddingBottom: '8px',
              }}
            >
              {weekList.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '15px',
                    border: 'solid 2px #a3a3a3',
                    width: '155px',
                    // minHeight: '360px',
                    // maxHeight: '100%',
                    marginRight: '10px',
                    height: '380px',
                    overflow: 'auto',
                  }}
                >
                  <Typography variant="h6" style={{ padding: '10px' }}>
                    {weekNumbers[index]}
                  </Typography>
                  <Avatar
                    variant="h6"
                    style={{
                      marginBottom: '5px',
                      color: item.daytext === dayText ? 'white' : 'black',
                      backgroundColor:
                        item.daytext === dayText ? '#ff9b3f' : 'white',
                      fontSize: '20px',
                    }}
                  >
                    {item.daytext.substring(8)}
                  </Avatar>
                  <div className={classes.missionList2}>
                    {item.content.map((ci, cindex) => (
                      <div className={classes.missionItem2}>
                        <Typography className={classes.missionText2}>
                          {cindex + 1}. {ci.content}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '150px',
              }}
            >
              <CircularProgress size="60px" />
            </div>
          )}
          <div></div>
        </div>
      </Dialog>
    );

  return (
    <>
      {weekListDialog()}
      <Paper className={classes.root}>
        <div>
          <div className={classes.title}>
            <Typography variant="h6" className={classes.titleText}>
              班級行事曆
            </Typography>
            {/* <ColorButton
              variant="contained"
              color="primary"
              onClick={doRefresh}
              disabled={refreshing}
              style={{ marginTop: theme.spacing(2) }}
            >
              重新整理
            </ColorButton> */}
            <IconButton size="small" className={classes.titleIcon}>
              <ZoomOutMapIcon onClick={() => renderWeek()} />
            </IconButton>
          </div>
          <div className={classes.yaerSel}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Typography variant="subtitle1" className={classes.yearText}>
                {schoolYear} 學年 {semester} 學期
              </Typography>
              <IconButton style={{ padding: '1px' }}>
                <ArrowDropDownIcon onClick={() => doOpenCalendarView()} />
                {calendarDialog()}
              </IconButton>
            </div>
            <img
                src={refresh}
                style={{
                  width: '25px',
                  height: '25px',
                  marginRight: '10px',
                }}
                onClick={doRefresh}
                disabled={refreshing}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Weekly result={(i) => doDefaultDay(i)} />
            {/* <Weekly/> */}
          </div>
          <div className={classes.missionView}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.currentDay2}>
                <div
                  style={{
                    color: 'white',
                    marginTop: '-3px',
                    marginLeft: '-4px',
                    fontSize: '22px',
                  }}
                >
                  {targetDayText.dayText}
                </div>
                <div
                  style={{
                    width: '25px',
                    fontSize: '8px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                    //marginLeft:'-3px',
                  }}
                >
                  週{targetDayText.weekText}
                </div>
              </div>

              {targetDayList.length > 0 ? (
                <div className={classes.missionList}>
                  {targetDayList.map((item, index) => (
                    <div className={classes.missionItem}>
                      <Typography className={classes.missionText} noWrap="true">
                        {index + 1}. {item.content}
                      </Typography>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={classes.missionList}>
                  <div className={classes.missionItem}>
                    <Typography className={classes.missionText}>
                      今日無相關事項
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Component;
