import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Fade,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Slide,
  Tooltip,
  Typography,
  Zoom,
  Button,
} from '@material-ui/core';
import { HighlightOffOutlined as CloseIcon } from '@material-ui/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 240px)',
    gridGap: theme.spacing(2),
    //alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    margin: theme.spacing(2),
  },
  content0: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    //alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  contentsw: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  photo: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const audioStep = useRef(null);
  const { state } = useContext(AppContext);
  const [origin, setOrigin] = useState(null);
  const [results, setResults] = useState([]);
  const [transition, setTransition] = useState('Fade');
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let interval;
    let timeout;

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          //console.log(state);
          //console.log(data)
          setOrigin(data);
          setTransition(data.transition || 'Fade');

          let count = 0;
          data.results.forEach((item) => {
            count = Math.max(count, item.split(',').length);
          });

          if (audioStep && audioStep.current && count > 0) {
            audioStep.current.play();

            let _interval = 0;
            interval = setInterval(() => {
              _interval += 1;
              setCurrent(_interval);
              if (_interval === count - 1) {
                window.clearInterval(interval);
              }
            }, 1000);

            timeout = setTimeout(() => {
              if (audioStep && audioStep.current) {
                audioStep.current.pause();
                state.tts.speak(`已分組完成，請大家確認自已的組別`);
              }
            }, 1000 * (count - 1));
          }

          setResults(
            data.results.map((item, index) => ({
              caption: `第 ${index + 1} 組 ${item.split(',').length} 人`,
              data: state.students.filter((student) =>
                item.split(',').includes(`${student.ID}`),
              ),
            })),
          );
        } else {
          callback();
        }
      });

    return () => {
      unsubscribe();
      state.tts.stop();
      window.clearInterval(interval);
      window.clearTimeout(timeout);

      state.communication.postMessage(
        JSON.stringify({
          action: 'PAUSE_VIDEO',
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.caption}>
            學生分組
          </Typography>
          <div style={{ flex: 1 }} />
        </div>
        <Grow in timeout={1000}>
          <div className={classes.content0}>
            <div className={classes.content}>
              {results.map((item, index) => (
                <GroupColumn
                  key={`group-${index}`}
                  item={item}
                  transition={transition}
                  current={current}
                  columnIndex={index}
                  handleMoveItem={(from, to) => {
                    const results = origin.results.map((item) => item.split(','));
                    results[from.columnIndex].splice(
                      results[from.columnIndex].indexOf(from.item.ID),
                      1,
                    );
                    results[to.columnIndex].push(from.item.ID);

                    firebase
                      .firestore()
                      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
                      .update({
                        results: results.map((item) => item.join(',')),
                        transition: 'None',
                      });
                    firebase
                      .firestore()
                      .doc(
                        `/classrooms/${state.roomId}/groups/${origin.timestamp}`,
                      )
                      .update({ results: results.map((item) => item.join(',')) });
                  }}
                />
              ))}
            </div>
            <div className={classes.contentsw}>
              <Tooltip title="關閉">
                <IconButton onClick={callback}>
                  <Button 
                  variant="contained"
                  color="primary"
                  >
                    回到主畫面
                  </Button>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Grow>
        <audio loop ref={audioStep}>
          <track kind="captions" />
          <source src={Assets.Audio.Process} type="audio/mpeg" />
        </audio>
      </div>
    </DndProvider>
  );
};

const GroupColumn = ({
  item,
  transition,
  current,
  columnIndex,
  handleMoveItem,
}) => {
  const theme = useTheme();
  const [, dropRef] = useDrop({
    accept: 'student',
    drop: (item) => {
      const from = item;
      const to = { columnIndex };
      handleMoveItem(from, to);
    },
    canDrop: (item) => item.columnIndex !== columnIndex,
  });

  return (
    <Paper 
    style={{maxHeight:'80%',display: 'flex', flexDirection: 'column'}}
    ref={dropRef}>
      <Typography
        variant="h5"
        color="primary"
        style={{ padding: theme.spacing(2) }}
      >
        {item.caption}
      </Typography>
      <List
        style={{maxHeight:'80%', overflow: 'auto'}}
      >
        {item.data.map((student, index) => (
          <GroupRow
            key={`student-${student.ID}`}
            item={student}
            transition={transition}
            index={index}
            current={current}
            columnIndex={columnIndex}
          />
        ))}
      </List>
    </Paper>
  );
};

const GroupRow = ({ item, transition, index, current, columnIndex }) => {
  const classes = useStyles();
  const [, drag] = useDrag({
    item: { type: 'student', columnIndex, item },
  });

  return withTransition(
    <ListItem ref={drag}>
      <ListItemAvatar>
        <Avatar className={classes.photo}>
          {item.Custom ? index + 1 : item.SeatNo}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={item.Name} secondary={item.ClassName} />
    </ListItem>,
    transition,
    index,
    current,
  );
};

const withTransition = (content, transition, index, current) => {
  switch (transition) {
    case 'Fade': {
      return (
        <Fade in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Fade>
      );
    }
    case 'Grow': {
      return (
        <Grow in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Grow>
      );
    }
    case 'Slide': {
      return (
        <Slide
          in={current >= index}
          direction="up"
          timeout={1000}
          key={`student-${index}`}
        >
          {content}
        </Slide>
      );
    }
    case 'Zoom': {
      return (
        <Zoom in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Zoom>
      );
    }
    case 'None': {
      return <div key={`student-${index}`}>{content}</div>;
    }
    default: {
      return (
        <Fade in={current >= index} timeout={1000} key={`student-${index}`}>
          {content}
        </Fade>
      );
    }
  }
};

export default Component;
