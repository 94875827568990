import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

import Chat from './chat';
import Code from './code';

import Assess from './assess';
import Vote from './vote';
import Attendance from './attendance';
import Interactive from './interactive';
import Timer from './timer';
import Random from './random';
import Group from './group';

const useStyles = makeStyles((theme) => ({
  overlay1: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
  },
  overlay2: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 2,
    display: 'flex',
  },
  overlay3: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 3,
    display: 'flex',
  },
  overlay4: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 4,
    display: 'flex',
  },
  overlay5: {
    position: 'fixed',
    top: 8,
    right: 8,
    zIndex: theme.zIndex.drawer + 5,
  },
  overlay6: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 6,
    display: 'flex',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);

  // layer 1

  // layer 2
  const [interactiveLayer, setInteractiveLayer] = useState(false);
  const [assessLayer, setAssessLayer] = useState(false);
  const [voteLayer, setVoteLayer] = useState(false);
  const [attendanceLayer, setAttendanceLayer] = useState(false);

  // layer 3
  const [timerLayer, setTimerLayer] = useState(false);
  const [randomLayer, setRandomLayer] = useState(false);
  const [groupLayer, setGroupLayer] = useState(false);

  // layer 4
  const [codeLayer, setCodeLayer] = useState(false);

  // layer 5
  const [chatLayer, setChatLayer] = useState(false);

  useEffect(() => {
    if (!state.config) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/main`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          handleEvent(doc.data());
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.config]);

  useEffect(() => {
    if (!state.appsChooseItem) return;

    const data = { ...state.appsChooseItem };

    if (data.url) {
      openWeb(data);
    } else {
      state.config.forEach((category) => {
        if (category.type === 'clouds') {
          const [cloud] = category.items.filter(
            (item) => item.key === data.key,
          );

          if (cloud) {
            openWeb({ ...data, url: cloud.url });
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [state.appsChooseItem]);

  const handleEvent = (event) => {
    switch (event.key) {
      case 'classroom.home': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        setCodeLayer(false);
        setChatLayer(false);

        setInteractiveLayer(false);
        setAssessLayer(false);
        setVoteLayer(false);
        setAttendanceLayer(false);

        setTimerLayer(false);
        setRandomLayer(false);
        setGroupLayer(false);
        break;
      }
      case 'classroom.code.open': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        setCodeLayer(true);
        break;
      }
      case 'classroom.code.close': {
        setCodeLayer(false);
        break;
      }
      case 'classroom.chat.open': {
        setChatLayer(true);
        break;
      }
      case 'classroom.chat.close': {
        setChatLayer(false);
        break;
      }
      case 'classroom.interactive.open': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        resetOverlay(2);
        setInteractiveLayer(true);
        break;
      }
      case 'classroom.interactive.close': {
        setInteractiveLayer(false);
        break;
      }
      case 'classroom.assess.open': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        resetOverlay(2);
        setAssessLayer(true);
        break;
      }
      case 'classroom.assess.close': {
        setAssessLayer(false);
        break;
      }
      case 'classroom.vote.open': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        resetOverlay(2);
        setVoteLayer(true);
        break;
      }
      case 'classroom.vote.close': {
        setVoteLayer(false);
        break;
      }
      case 'classroom.attendance.open': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'BACK_MAIN_PAGE',
          }),
        );

        resetOverlay(2);
        setAttendanceLayer(true);
        break;
      }
      case 'classroom.attendance.close': {
        setAttendanceLayer(false);
        break;
      }
      case 'classroom.timer.open': {
        if (!timerLayer) {
          state.communication.postMessage(
            JSON.stringify({
              action: 'BACK_MAIN_PAGE',
            }),
          );

          resetOverlay(3);
          setTimerLayer(true);
        }
        break;
      }
      case 'classroom.timer.close': {
        setTimerLayer(false);
        break;
      }
      case 'classroom.random.open': {
        if (!randomLayer) {
          state.communication.postMessage(
            JSON.stringify({
              action: 'BACK_MAIN_PAGE',
            }),
          );

          resetOverlay(3);
          setRandomLayer(true);
        }
        break;
      }
      case 'classroom.random.close': {
        setRandomLayer(false);
        break;
      }
      case 'classroom.group.open': {
        if (!groupLayer) {
          state.communication.postMessage(
            JSON.stringify({
              action: 'BACK_MAIN_PAGE',
            }),
          );

          resetOverlay(3);
          setGroupLayer(true);
        }
        break;
      }
      case 'classroom.group.close': {
        setGroupLayer(false);
        break;
      }
      default: {
        if (event.url) {
          openWeb(event);
        } else {
          if (state.config) {
            state.config.forEach((category) => {
              if (category.type === 'clouds') {
                const [cloud] = category.items.filter(
                  (item) => item.key === event.key,
                );

                if (cloud) {
                  openWeb({ ...event, url: cloud.url });
                }
              }
            });
          }
        }
      }
    }
  };

  const openWeb = (item, material = false, slides = false) => {
    if (!item.url) return;

    resetOverlay(1);

    state.communication.postMessage(
      JSON.stringify({
        action: 'CREATE_NEW_PAGE',
        data: {
          key: material ? item.url : item.key,
          url: item.url.replace(/_ACCESSTOKEN_/, state.accessToken),
          slides,
          roomId: state.roomId,
        },
      }),
    );
  };

  const triggerEvent = (key) => {
    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key,
      timestamp: Date.now(),
    });
  };

  const resetOverlay = (layer) => {
    switch (layer) {
      case 1: {
        break;
      }
      case 2: {
        setInteractiveLayer(false);
        setAssessLayer(false);
        setVoteLayer(false);
        setAttendanceLayer(false);
        break;
      }
      case 3: {
        setTimerLayer(false);
        setRandomLayer(false);
        setGroupLayer(false);
        break;
      }
      default: {
        /** empty */
      }
    }
  };

  const renderCode = () =>
    codeLayer && (
      <div className={classes.overlay4}>
        <Code callback={() => triggerEvent('classroom.code.close')} />
      </div>
    );

  const renderChat = () =>
    chatLayer && (
      <div className={classes.overlay5}>
        <Chat callback={() => triggerEvent('classroom.chat.close')} />
      </div>
    );

  const renderInteractive = () =>
    interactiveLayer && (
      <div className={classes.overlay2}>
        <Interactive
          callback={() => triggerEvent('classroom.interactive.close')}
        />
      </div>
    );

  const renderTimer = () =>
    timerLayer && (
      <div className={classes.overlay3}>
        <Timer callback={() => triggerEvent('classroom.timer.close')} />
      </div>
    );

  const renderRandom = () =>
    randomLayer && (
      <div className={classes.overlay3}>
        <Random callback={() => triggerEvent('classroom.random.close')} />
      </div>
    );

  const renderGroup = () =>
    groupLayer && (
      <div className={classes.overlay3}>
        <Group callback={() => triggerEvent('classroom.group.close')} />
      </div>
    );

  const renderAssess = () =>
    assessLayer && (
      <div className={classes.overlay2}>
        <Assess callback={() => triggerEvent('classroom.assess.close')} />
      </div>
    );

  const renderVote = () =>
    voteLayer && (
      <div className={classes.overlay2}>
        <Vote callback={() => triggerEvent('classroom.vote.close')} />
      </div>
    );

  const renderAttendance = () =>
    attendanceLayer && (
      <div className={classes.overlay2}>
        <Attendance
          callback={() => triggerEvent('classroom.attendance.close')}
        />
      </div>
    );

  return (
    <React.Fragment>
      {renderCode()}
      {renderChat()}
      {renderInteractive()}
      {renderTimer()}
      {renderRandom()}
      {renderGroup()}
      {renderAssess()}
      {renderVote()}
      {renderAttendance()}
    </React.Fragment>
  );
};

export default Component;
