import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ControlPoint as ControlPointIcon,
  CheckCircle as CheckCircleIcon,
  ZoomOutMap as ZoomOutMapIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import moment from 'moment';
import envConfig from '../../config';
import firebase from 'firebase/app';
import 'firebase/firestore';
import zoomin from '../../assets/ZoomIn.svg';
import refresh from '../../assets/refresh.svg';
import Close from '../../assets/Close.png';
import { AppContext } from '../../App';
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    borderRadius: '15px',
    maxWidth: '290px'
  },
  paperroot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    borderRadius: '15px',
    marginTop: '-20px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleIcon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  absenceList: {
    display: 'flex',
    flexDirection: 'row',
    width: '88%',
    height: '44px',
    borderRadius: '5px',
    marginLeft: theme.spacing(2),
    backgroundColor: 'rgba(255,155,63,0.12)',
    marginTop: '2px',
  },
  onDutyList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '250px',
    height: '44px',
    borderRadius: '5px',
    marginLeft: theme.spacing(2),
    backgroundColor: 'rgba(255,155,63,0.12)',
    marginTop: '2px',
  },
  numberArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    width: '76%',
  },
  seatNoI: {
    color: 'white',
    marginTop: '-2.9px',
    marginLeft: '-1.2px',
  },
  seatNoO: {
    display: 'block',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: '6px solid #1db7bd',
    backgroundColor: '#1db7bd',
    marginLeft: '12px',
    marginTop: '12px',
  },
  edit: {
    width: '30px',
    height: '30px',
    padding: '2px',
    marginLeft: '55px',
    marginTop: '5px',
  },
  lastNoI: {
    color: '#828282',
    marginTop: '-2.9px',
    marginLeft: '-4px',
  },
  lastNoO: {
    display: 'block',
    height: '13px',
    width: '13px',
    borderRadius: '50%',
    border: '6px solid #e0e0e0',
    backgroundColor: '#e0e0e0',
    marginLeft: '6px',
    marginTop: '-1px',
  },
  crossO: {
    display: 'block',
    height: '0px',
    width: '0px',
    borderRadius: '50%',
    border: '6px solid #ff4438',
    backgroundColor: '#ff4438',
    marginLeft: '29px',
    marginTop: '-32px',
  },
  crossI: {
    width: '7px',
    height: '7px',
    display: 'block',
    marginLeft: '-3px',
    marginTop: '-4px',
  },
  todayList: {
    display: 'flex',
    flexDirection: 'row',
    width: '545px',
    borderRadius: '10px',
    //marginLeft: theme.spacing(2),
    backgroundColor: 'rgba(255,155,63,0.12)',
  },
  formControlLabelTitle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '25%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'blcak !important',
  },
  dialogStyle: {
    borderRadius: 15,
    maxWidth:'900px'
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: '120px',
    height: '30px',
    frontSize: '15px',
    margin: '10px',
    color: theme.palette.getContrastText(common['black']),
    backgroundColor: '#1db7bd',
    '&:hover': {
      backgroundColor: '#1baaaf',
    },
  },
}))(Button);

const ajhslkdfhk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const hakjlsdhfk = CryptoJS.enc.Utf8.parse('iamscreen3345678');

const unattendLiar = ['16', '19', '23', '37', '40'];
let leaveTypeList = [
  {
    id: 1,
    leaveName: '無故未到',
    Student: [],
    absenceNum: 0,
  },
  {
    id: 2,
    leaveName: '事假',
    Student: [],
    absenceNum: 0,
  },
  {
    id: 3,
    leaveName: '病假',
    Student: [],
    absenceNum: 0,
  },
  {
    id: 4,
    leaveName: '公假',
    Student: [],
    absenceNum: 0,
  },
  {
    id: 5,
    leaveName: '其他假別',
    Student: [],
    absenceNum: 0,
  },
];
let firebaseUID = '';
const dbUrl = envConfig.SERVER_URL;
let curToday = moment().format('YYYYMMDD');
const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [getStuErrorView, setGetStuErrorView] = useState(false);
  const [noneStudentsErrorView, setNoneStudentsErrorView] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [selectView, setSelectView] = useState(false);
  const [renderList, setRenderList] = useState([].concat(leaveTypeList));
  const [curAbsenceNum, setCurAbsenceNum] = useState(0);
  const [stuListEditView, setStuListEditView] = useState(false);

  const [curEditLeaveName, setCurEditLeaveName] = useState('');
  const [curEditIndex, setCurEditIndex] = useState();
  const [allAbsenceStuList, setAllAbsenceStuList] = useState([]);

  const [editIconState, setEditIconState] = useState(true);

  useEffect(() => {
    if (!state.device || state.device.id === 'classroom.tp.edu.tw' || !state.device.finalClassID) return;
    //console.log(state);
    setLoading(true);
    setAllAbsenceStuList([]);
    setCurAbsenceNum(0);
    setEditIconState(true);
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    let tempRendeList = [];
    let targetSearch = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_name: state.device.classroomName,
      //class_id: state.device.ClassID, 學期切換調整
      class_id: state.device.finalClassID,
      device_id: state.device.deviceId,
      start_time: moment().format('YYYY-MM-DD 00:00:00'),
      end_time: moment().format('YYYY-MM-DD 23:59:59'),
    };

    let targetSearch2 = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_name: state.device.classroomName,
      class_id: state.device.finalClassID,
      device_id: state.device.deviceId,
      start_time: moment().format('YYYY-MM-DD 00:00:00'),
      end_time: moment().format('YYYY-MM-DD 23:59:59'),
    }

    let targetSearch2Json = JSON.stringify(targetSearch2);

    const ciphertext = CryptoJS.AES.encrypt(targetSearch2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getSCAttendance'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      }).then((r) => r.json())
      .then((r) => {
        const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        let finalData = JSON.parse(decryptedData);
        if (finalData.length === 0 || typeof finalData === 'undefined') {
          setRenderList([].concat([]));
          setCurAbsenceNum(0);
        } else {
          //console.log(r);
          tempRendeList = [].concat(finalData);
          let tempAllAbsenceStuList = [].concat(
            JSON.parse(JSON.stringify(finalData[0].Student)),
          );
          finalData.forEach((litem) => {
            litem.Student.forEach((stuItem, index) => {
              if (stuItem.absence) {
                tempAllAbsenceStuList[index].absence = true;
              }
            });
          });
          //console.log(tempAllAbsenceStuList);
          firebase
              .firestore()
              .doc(`/absence/${firebaseUID}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const results = doc.data();
                  if(results.cur_today !== curToday){
                    setRenderList(tempRendeList);
                    setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
                    firebase
                      .firestore()
                      .doc(`/absence/${firebaseUID}`)
                      .set({
                        school_code: state.device.schoolCode,
                        school_type: state.device.schoolType,
                        class_id: state.device.ClassID,
                        cur_today: curToday,
                        stu_list: [].concat(tempRendeList)
                      });
                  } else {
                    let tempResults = [].concat(results.stu_list);
                    //console.log(results);
                    let finalList = [];
                    tempResults.forEach((item) => {
                      if(item.absence === 0){
                        finalList.push(item);
                      } else {
                        item.Student.sort(function (a, b) {
                          return parseInt(a.SeatNo) - parseInt(b.SeatNo);
                        });
                        item.Student.forEach(stu => {
                          if(stu.absence){
                            let targetIdIndex = tempRendeList.findIndex(i => i.id === item.id);
                            let targetStu =  tempRendeList[targetIdIndex].Student.findIndex(student => student.UUID === stu.UUID);
                            //console.log(tempRendeList[targetIdIndex].Student[targetStu]);
                            tempRendeList[targetIdIndex].Student[targetStu].absence = true;
                          }
                        });
                        finalList.push(item);
                      }
                    });
                    setRenderList([].concat(finalList));
                    doFiliterAbsence(tempAllAbsenceStuList, finalList);
                  }
                } else {
                  setRenderList(tempRendeList);
                  setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
                }
                setEditIconState(false);
                setLoading(false);
              })
        }
      })
    } catch (error) {
      console.log(error);
      setLoading(false);
      setEditIconState(false);
    }

    // try {
    //   fetch(dbUrl.concat('/classroom/getSCAttendance'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetSearch),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       console.log(r)
    //       if (r.length === 0 || typeof r === 'undefined') {
    //         setRenderList([].concat([]));
    //         setCurAbsenceNum(0);
    //       } else {
    //         //console.log(r);
    //         tempRendeList = [].concat(r);
    //         let tempAllAbsenceStuList = [].concat(
    //           JSON.parse(JSON.stringify(r[0].Student)),
    //         );
    //         r.forEach((litem) => {
    //           litem.Student.forEach((stuItem, index) => {
    //             if (stuItem.absence) {
    //               tempAllAbsenceStuList[index].absence = true;
    //             }
    //           });
    //         });
    //         //console.log(tempAllAbsenceStuList);
    //         firebase
    //             .firestore()
    //             .doc(`/absence/${firebaseUID}`)
    //             .get()
    //             .then((doc) => {
    //               if (doc.exists) {
    //                 const results = doc.data();
    //                 if(results.cur_today !== curToday){
    //                   setRenderList(tempRendeList);
    //                   setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
    //                   firebase
    //                     .firestore()
    //                     .doc(`/absence/${firebaseUID}`)
    //                     .set({
    //                       school_code: state.device.schoolCode,
    //                       school_type: state.device.schoolType,
    //                       class_id: state.device.ClassID,
    //                       cur_today: curToday,
    //                       stu_list: [].concat(tempRendeList)
    //                     });
    //                 } else {
    //                   let tempResults = [].concat(results.stu_list);
    //                   //console.log(results);
    //                   let finalList = [];
    //                   tempResults.forEach((item) => {
    //                     if(item.absence === 0){
    //                       finalList.push(item);
    //                     } else {
    //                       item.Student.sort(function (a, b) {
    //                         return parseInt(a.SeatNo) - parseInt(b.SeatNo);
    //                       });
    //                       item.Student.forEach(stu => {
    //                         if(stu.absence){
    //                           let targetIdIndex = tempRendeList.findIndex(i => i.id === item.id);
    //                           let targetStu =  tempRendeList[targetIdIndex].Student.findIndex(student => student.UUID === stu.UUID);
    //                           //console.log(tempRendeList[targetIdIndex].Student[targetStu]);
    //                           tempRendeList[targetIdIndex].Student[targetStu].absence = true;
    //                         }
    //                       });
    //                       finalList.push(item);
    //                     }
    //                   });
    //                   setRenderList([].concat(finalList));
    //                   doFiliterAbsence(tempAllAbsenceStuList, finalList);
    //                 }
    //               } else {
    //                 setRenderList(tempRendeList);
    //                 setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
    //               }
    //               setEditIconState(false);
    //               setLoading(false);
    //             })
    //       }
    //     });  
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    //   setEditIconState(false);
    // }
    
    //doGetClassStudents();
    // eslint-disable-next-line
  }, [state.device]);

  useEffect(() => {
    if(!state.changeToday) return;
    let curToday1 = moment().format('YYYYMMDD');
    if(state.changeToday === curToday1) return;
    setEditIconState(true);
    setAllAbsenceStuList([]);
    setCurAbsenceNum(0);
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    let tempRendeList = [];
    let targetSearch = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_name: state.device.classroomName,
      //class_id: state.device.ClassID, 學期切換調整
      class_id: state.device.finalClassID,
      device_id: state.device.deviceId,
      start_time: moment().format('YYYY-MM-DD 00:00:00'),
      end_time: moment().format('YYYY-MM-DD 23:59:59'),
    };
    //console.log(targetSearch);

    let targetSearch2 = {
      school_code: state.device.schoolCode,
      school_type: state.device.schoolType,
      class_name: state.device.classroomName,
      class_id: state.device.finalClassID,
      device_id: state.device.deviceId,
      start_time: moment().format('YYYY-MM-DD 00:00:00'),
      end_time: moment().format('YYYY-MM-DD 23:59:59'),
    }

    let targetSearch2Json = JSON.stringify(targetSearch2);

    const ciphertext = CryptoJS.AES.encrypt(targetSearch2Json, ajhslkdfhk, {iv: hakjlsdhfk});
    const encryptedData = ciphertext.toString();
    let tempBody = {body: encryptedData}

    try {
      fetch(dbUrl.concat('/classroom/getSCAttendance'), {
        method: 'POST',
        body: JSON.stringify(tempBody),
        headers: {
          'content-type': 'application/json',
        },
      }).then((r) => r.json())
      .then((r) => {
        const bytes = CryptoJS.AES.decrypt(r.data, ajhslkdfhk, {iv: hakjlsdhfk});
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        let finalData = JSON.parse(decryptedData);
   
        tempRendeList = [].concat(finalData);
          firebase
            .firestore()
            .doc(`/absence/${firebaseUID}`)
            .set({
              school_code: state.device.schoolCode,
              school_type: state.device.schoolType,
              class_id: state.device.ClassID,
              cur_today: curToday,
              stu_list: [].concat(tempRendeList)
            });
            let tempAllAbsenceStuList = [].concat(
              JSON.parse(JSON.stringify(finalData[0].Student)),
            );
            finalData.forEach((litem) => {
              litem.Student.forEach((stuItem, index) => {
                if (stuItem.absence) {
                  tempAllAbsenceStuList[index].absence = true;
                }
              });
            });

            setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
            setEditIconState(false);
      })
    } catch (error) {
      console.log(error);
      setEditIconState(false);
    }


    // try {
    //   fetch(dbUrl.concat('/classroom/getSCAttendance'), {
    //     method: 'POST',
    //     body: JSON.stringify(targetSearch),
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((r) => {
    //       tempRendeList = [].concat(r);
    //       firebase
    //         .firestore()
    //         .doc(`/absence/${firebaseUID}`)
    //         .set({
    //           school_code: state.device.schoolCode,
    //           school_type: state.device.schoolType,
    //           class_id: state.device.ClassID,
    //           cur_today: curToday,
    //           stu_list: [].concat(tempRendeList)
    //         });
    //         let tempAllAbsenceStuList = [].concat(
    //           JSON.parse(JSON.stringify(r[0].Student)),
    //         );
    //         r.forEach((litem) => {
    //           litem.Student.forEach((stuItem, index) => {
    //             if (stuItem.absence) {
    //               tempAllAbsenceStuList[index].absence = true;
    //             }
    //           });
    //         });
    //         setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
    //         setEditIconState(false);
    //     })
    // } catch (error) {
    //   console.log(error);
    //   setEditIconState(false);
    // }
  },[state.changeToday]);

  // useEffect(() => {
  //   if (stuListEditView && selectView) return;
  //   doFiliterAbsence();
  // }, [stuListEditView, selectView]);

  const doFiliterAbsence = (aasl, rl) => {
      let tempAllAbsenceStuList = [].concat(aasl);
      let tempRendeList = [].concat(rl);
      let targetAbsenceStuList = []; 

      // tempAllAbsenceStuList = [].concat(allAbsenceStuList);
      let tempNum = 0;
      tempRendeList.forEach((item) => {
        item.absenceNum = 0
        item.Student.forEach((stuItem, index) => {
          if (stuItem.absence) {
            targetAbsenceStuList.push(stuItem);
            item.absenceNum = item.absenceNum + 1;
          } 
        });
      });
      //console.log(targetAbsenceStuList);
      let tempResult = Array.from(new Set(targetAbsenceStuList));
      //console.log(tempResult);
      tempAllAbsenceStuList.forEach(item => {
        let targetIndex = targetAbsenceStuList.findIndex(s => s.UUID === item.UUID);
        if(targetIndex > -1) {
          item.absence = true;
        } else {
          item.absence = false;
        }
      })

      tempAllAbsenceStuList.forEach((i) => {
        if (i.absence) {
          tempNum++;
        }
      });
      // console.log(tempAllAbsenceStuList);
      // console.log(tempNum);
      setRenderList([].concat(tempRendeList));
      setCurAbsenceNum(tempNum);
      setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
  };

  const doOpenAttendanceList = () => {
    if(loading || editIconState) return;
    // let tempNum = 0;
    // renderList.forEach((item) => {
    //   tempNum = tempNum + item.absenceNum;
    // });
    // setCurAbsenceNum(tempNum);
    setSelectView(true);
  };

  const doTodayItemEdit = (item, index) => {
    setCurEditIndex(index);
    setCurEditLeaveName(item.leaveName);
    setStuListEditView(true);
  };

  const doCancelUndo = (renderIndex, stuIndex) => {
    //doFiliterAbsence();
    let tempNum = 0;
    let tempRenderList = [].concat(renderList);
    setRenderList([].concat(tempRenderList));
    tempRenderList[renderIndex].Student[stuIndex].absence = false;
    let tempAllAbsenceStuList = [].concat(allAbsenceStuList);
    doFiliterAbsence(tempAllAbsenceStuList, tempRenderList);
    // tempAllAbsenceStuList[stuIndex] = false;
    // setAllAbsenceStuList([].concat(tempAllAbsenceStuList));
    // tempAllAbsenceStuList.forEach((i) => {
    //   if (i.absence) {
    //     tempNum++;
    //   }
    // });
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    firebase
      .firestore()
      .doc(`/absence/${firebaseUID}`)
      .set({
        school_code: state.device.schoolCode,
        school_type: state.device.schoolType,
        class_id: state.device.ClassID,
        cur_today: curToday,
        stu_list: [].concat(tempRenderList)
      });
    //setCurAbsenceNum(tempNum);
  };

  const stuListEditViewClose = () => {
    //console.log(renderList);
    doFiliterAbsence(allAbsenceStuList, renderList);
    firebaseUID = '' + state.device.schoolCode + state.device.ClassID;
    firebase
      .firestore()
      .doc(`/absence/${firebaseUID}`)
      .set({
        school_code: state.device.schoolCode,
        school_type: state.device.schoolType,
        class_id: state.device.ClassID,
        cur_today: curToday,
        stu_list: [].concat(renderList)
      });
    setStuListEditView(false);
  };

  const doCheckStu = (index) => {
    //console.log(rendeList);
    //console.log(index);

    let tempStuList = [].concat(renderList);
    let tempAbsenceNum = tempStuList[curEditIndex].absenceNum;
    if (tempStuList[curEditIndex].Student[index].absence) {
      tempStuList[curEditIndex].Student[index].absence = false;
      tempAbsenceNum = tempAbsenceNum - 1;
    } else {
      tempStuList[curEditIndex].Student[index].absence = true;
      tempAbsenceNum = tempAbsenceNum + 1;
    }
    tempStuList[curEditIndex].absenceNum = tempAbsenceNum;
    setRenderList([].concat(tempStuList));
  };

  const stuListEditDialog = () =>
    stuListEditView && (
      <Dialog
        disableBackdropClick
        //fullWidth
        maxWidth="lg"
        open={stuListEditView}
        onClose={() => setStuListEditView(false)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
        }}
        classes={{
          paper: classes.dialogStyle,
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {curEditLeaveName}
        </DialogTitle>
        <DialogContent>
          {renderList[curEditIndex].Student.length === 0 ? (
            <div>尚無相關項目的學生</div>
          ) : (
            <div
              style={{ 
                display: 'flex', 
                flexDirection: 'row', 
                flexWrap: 'wrap', 
                maxHeight:'300px', 
                alignContent:'start',  
                padding:'10px',
                }}
            >
              {/* 改測試資料 */}
              {/* {testStuList.map((curitem, curindex) => */}
              {renderList[curEditIndex].Student.map((curitem, curindex) =>
                !curitem.absence ? (
                  <Button
                    onClick={() => doCheckStu(curindex)}
                    variant="outlined"
                    style={{
                      border: 'solid 2px #e0e0e0',
                      borderRadius: '100px',
                      margin: '5px',
                      // width: '110px',
                      minWidth: '110px',
                      maxWidth: '140px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ color: '#323232', width: '15px' }}>
                        {curitem.SeatNo > 9
                          ? curitem.SeatNo
                          : '0' + curitem.SeatNo}
                      </Typography>
                      <Typography style={{ marginLeft: '10px' }}>
                        {curitem.StudentName}
                      </Typography>
                    </div>
                    <ControlPointIcon
                      fontSize="small"
                      style={{
                        marginLeft: '10px',
                        marginRight: '-10px',
                        color: '#a3a3a3',
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => doCheckStu(curindex)}
                    variant="outlined"
                    style={{
                      border: 'solid 2px #1db7bd',
                      borderRadius: '100px',
                      margin: '5px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ color: '#323232', width: '15px' }}>
                        {curitem.SeatNo > 9
                          ? curitem.SeatNo
                          : '0' + curitem.SeatNo}
                      </Typography>
                      <Typography style={{ marginLeft: '10px' }}>
                        {curitem.StudentName}
                      </Typography>
                    </div>
                    <CheckCircleIcon
                      fontSize="small"
                      style={{
                        marginLeft: '10px',
                        marginRight: '-10px',
                        color: '#1fb6bd',
                      }}
                    />
                  </Button>
                ),
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {/* <Button onClick={renderSaveDataAlertClose} variant="contained" style={{ backgroundColor: '#1db7bd'}}>
          <Typography color='white'>
                確認
           </Typography>
          </Button> */}
          <ColorButton
            variant="contained"
            color="primary"
            onClick={stuListEditViewClose}
          >
            確認
          </ColorButton>
        </DialogActions>
      </Dialog>
    );

  const getStuErrorDialog = () =>
    getStuErrorView && (
      <Dialog open={getStuErrorView} onClose={() => setGetStuErrorView(false)}>
        <DialogTitle>資料取得失敗</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              direction: 'row',
              flexWrap: 'wrap',
            }}
          >
            <DialogContentText color="error">
              取得班級學生失敗，請重新嘗試。
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setGetStuErrorView(false);
              setSelectView(false);
            }}
            color="primary"
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const noneStudentsErrorDialog = () =>
    noneStudentsErrorView && (
      <Dialog
        open={noneStudentsErrorView}
        onClose={() => setNoneStudentsErrorView(false)}
      >
        <DialogTitle>沒有學生提示</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              direction: 'row',
              flexWrap: 'wrap',
            }}
          >
            <DialogContentText color="error">
              此班級沒有相關學生。
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNoneStudentsErrorView(false)}
            color="primary"
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const selectDialog = () =>
    selectView && (
      <Dialog
        open={selectView}
        onClose={() => setSelectView(false)}
        maxWidth="ls"
        style={{ marginTop: '80px' }}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px',
            width: '575px',
          }}
        >
          <DialogTitle variant="h6" style={{ flex: '4' }}>
            <div>今日未到({curAbsenceNum})</div>
          </DialogTitle>
          <img
            src={zoomin}
            style={{
              width: '25px',
              height: '25px',
              marginRight: '10px',
            }}
            onClick={() => setSelectView(false)}
          />
        </div>
        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '260px',
                width: '555px',
                paddingBottom: '20px',
              }}
            >
              <CircularProgress size="60px" />
            </div>
          ) : (
            <div
              style={{
                overflow: 'auto',
                padding: '4px',
                width: '555px',
                marginLeft: '25px',
                paddingBottom: '20px',
              }}
            >
              <FormControl style={{ marginLeft: '10px' }}>
                <RadioGroup aria-label="unfinish" name="unfinish">
                  {renderList.map((item, index) => (
                    <FormControlLabel
                      style={{
                        marginBottom: '8px',
                        width: '90%',
                        minHeight: '90%',
                      }}
                      value={item.id}
                      control={<></>}
                      label={
                        <div className={classes.todayList}>
                          <div className={classes.formControlLabelTitle} noWrap>
                            {index + 1}. {item.leaveName}
                          </div>
                          {item.absenceNum === 0 ? (
                            <Typography
                              style={{ width: '62%', padding: '10px' }}
                            >
                              目前尚無資料
                            </Typography>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '70%',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                flexWrap: 'wrap',
                                alignItems: 'baseline',
                                marginTop: '-25px',
                                minHeight: '40px',
                              }}
                            >
                              {item.Student.map(
                                (stu, idx) =>
                                  stu.absence && (
                                    <div style={{ marginTop: '10px' }}>
                                      <div className={classes.seatNoO}>
                                        <div className={classes.seatNoI}>
                                          {stu.SeatNo < 10
                                            ? '0' + stu.SeatNo
                                            : stu.SeatNo}
                                        </div>
                                      </div>
                                      <div
                                        className={classes.crossO}
                                        onClick={() => doCancelUndo(index, idx)}
                                      >
                                        <img
                                          className={classes.crossI}
                                          src={Close}
                                        />
                                      </div>
                                    </div>
                                  ),
                              )}
                            </div>
                          )}
                          <IconButton
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              maxHeight: '40px',
                            }}
                          >
                            <EditIcon
                              fontSize="small"
                              onClick={() => doTodayItemEdit(item, index)}
                            />
                          </IconButton>
                        </div>
                      }
                    ></FormControlLabel>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </div>
      </Dialog>
    );

  return (
    <>
      {selectDialog()}
      {noneStudentsErrorDialog()}
      {getStuErrorDialog()}
      {stuListEditDialog()}
      <Paper className={classes.root}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginTop: theme.spacing(1.5),
              marginLeft: theme.spacing(2),
            }}
          >
            今日未到({curAbsenceNum})
          </Typography>
          {/* <IconButton
            size="small"
            className={classes.titleIcon}
            onClick={() => doOpenAttendanceList()}
          >
            <ZoomOutMapIcon />
          </IconButton> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '88%',
            height: '44px',
            borderRadius: '5px',
            marginLeft: theme.spacing(2),
            backgroundColor: 'rgba(255,155,63,0.12)',
            marginTop: '2px',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5px',
              //marginTop:'6px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '60%',
            }}
          >
            {allAbsenceStuList.map(
              (i) =>
                i.absence && (
                  <div
                    style={{
                      display: 'block',
                      height: '15px',
                      width: '15px',
                      borderRadius: '50%',
                      border: '6px solid #1db7bd',
                      backgroundColor: '#1db7bd',
                      marginLeft: '12px',
                      //marginTop:'-2px',
                    }}
                  >
                    <div
                      style={{
                        color: 'white',
                        marginTop: '0.1px',
                        marginLeft: '-0.2px',
                      }}
                    >
                      {i.SeatNo > 9 ? i.SeatNo : '0' + i.SeatNo}
                    </div>
                  </div>
                ),
            )}
          </div>
          {curAbsenceNum > 4 && (
            <div
              style={{
                display: 'block',
                height: '15px',
                width: '15px',
                borderRadius: '50%',
                border: '6px solid #e0e0e0',
                backgroundColor: '#e0e0e0',
                //marginLeft:'12px',
                //marginTop:'-2px',
              }}
            >
              <div
                style={{
                  color: 'white',
                  marginTop: '0.1px',
                  marginLeft: '-0.2px',
                  color: '#828282',
                }}
              >
                +{curAbsenceNum - 4}
              </div>
            </div>
          )}
        <IconButton
            // disabled={editIconState}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
             
            }}
            onClick={() => doOpenAttendanceList()}
          >
            {/* <EditIcon fontSize="medium" /> */}
            {editIconState ? <CircularProgress size='1.5rem' style={{color:'#1db7bd'}}/> : <EditIcon fontSize="medium" />}
          </IconButton>
        </div>
      </Paper>
    </>
  );
};

export default Component;
