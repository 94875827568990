import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    overflow: 'auto',
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 'initial',
    marginRight: theme.spacing(2),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!state.config) return;
    setCategories(state.config);
    // eslint-disable-next-line
  }, [state.config]);

  return (
    <div className={classes.main}>
      {categories.map((category, sdx) => (
        <List
          key={`list-${sdx}`}
          component="nav"
          subheader={
            <ListSubheader disableSticky={true}>{category.title}</ListSubheader>
          }
        >
          {category.items.map((item, idx) => (
            <div key={`item-${sdx}-${idx}`}>
              <ListItem
                button
                onClick={() =>
                  dispatch({
                    type: 'APPS_CHOOSE_ITEM',
                    payload: { ...item, timestamp: Date.now() },
                  })
                }
              >
                <ListItemAvatar>
                  <Avatar
                    src={String(item.icon)}
                    className={classes.icon}
                    imgProps={{ style: { backgroundColor: 'transparent' } }}
                  />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
              </ListItem>
              {idx !== category.items.length - 1 && (
                <Divider variant="middle" />
              )}
            </div>
          ))}
        </List>
      ))}
    </div>
  );
};

export default Component;
