import React, { useContext, useEffect, useState } from 'react';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import envConfig from '../../../config.js'
import Assets from '../../../assets/gaset.png';
import {
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDownIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Event as ScheduleIcon,
  LowPriority as PriorityLowIcon,
  PriorityHigh as PriorityHighIcon,
  Schedule as NowIcon,
  Send as SendIcon,
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import moment from 'moment';
import GoogleSignin from '../../../assets/btn_google_signin_light_normal_web.png';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    margin: theme.spacing(2),
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  media: {
    height: 140,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  priorityrow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  typerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  schedulerow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  targetbox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 1, 1),
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  targetlabel: {
    position: 'absolute',
    top: theme.spacing(-2),
    background: '#fff',
    padding: theme.spacing(3 / 4),
  },
  targetrow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  setbutton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  }
}));
//console.log(envConfig);
const dbUrl = envConfig.SERVER_URL;

function CustomPagination(props) {
  const { pagination, api } = props;
  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      color="primary"
      page={pagination.page}
      count={pagination.pageCount}
      onChange={(event, value) => api.current.setPage(value)}
    />
  );
}

CustomPagination.propTypes = {
  /**
   * ApiRef that let you manipulate the grid.
   */
  api: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
  /**
   * The object containing all pagination details in [[PaginationState]].
   */
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    paginationMode: PropTypes.oneOf(['client', 'server']).isRequired,
    rowCount: PropTypes.number.isRequired,
  }).isRequired,
};

// 處理沒資料圖示顯示
function CustomNoRowsOverlay() {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.root}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>沒有資料</div>
    </GridOverlay>
  );
}

const oriMsg = '無（您尚未設定Google Drive帳號，請點選上方「Sign in with Google」中進行綁定）'

const Component = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [targetsRec, setTargetsRec] = useState([]);
  const [memberInfo, setMemberInfo] = useState({});
  const [ori, setOri] = useState(1);
  const [showmsg, setShowmsg] = useState(oriMsg);
  const [codeValue, setCodeValue] = useState();
  const [sbutton, setSButton] = useState('設定');

  
  
  React.useEffect(()=>{
    //console.log(state);
    fetch(dbUrl.concat('/classroom/getMyInfo'), {
      // fetch('http://localhost:8088/classroom/getScoreRec', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(state)
    }).then(
      r => r.json()
    ).then(r => {
      //console.log(r);
      setMemberInfo(r);

      if(r.google_account === null || r.google_account === ''){
        setShowmsg(oriMsg);
        setSButton('設定');
      } else {
        setShowmsg(r.google_account);
        setSButton('重新設定');
      }
    });
  },[])

  
  const doConnectGoogle = () => {
    window.open(dbUrl.concat(`/classroom/doConnectGoogle?${state.accessToken}`));
    addFirebaseListen();
  };

  const doDeleteGA = async () => {
   await fetch(dbUrl.concat('/classroom/deleteGA'), {
      // fetch('http://localhost:8088/classroom/getScoreRec', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(state)
    });
    setShowmsg(oriMsg);
    setSButton('設定');
  };

  const getSession = () => {
    //console.log(state);
    fetch(dbUrl.concat('/classroom/getAccessToken'), {
      // fetch('http://localhost:8088/classroom/getScoreRec2', {
      method: 'POST',
      body: JSON.stringify(state),
      headers: {
        'content-type': 'application/json'
      },
    })

    // window.open(dbUrl.concat(`classroom/getSession?${state.accessToken}`));
  }
  const addFirebaseListen = () => {

    //console.log(state.author.uuid);
    
    var db = firebase.firestore();
    var ref = db.collection('pubsubs').doc(state.author.uuid);
    ref.onSnapshot((doc)=>{
      if(doc.exists){
        //console.log(doc.data());
        const targetState =doc.data();
        if(targetState.account !== ''){
          setShowmsg(targetState.account);
          setSButton('重新設定');
        }
      }
    });
    ref.set({
      account: '',
      state: '綁定中',
    })
  }

  if (memberInfo === {}) {
    return (
      <div className={styles.loading}>
        <CircularProgress size="3rem" color="primary" />
      </div>
    );
  } else {
    return (
      <div className={styles.box}>
        <Card style={{ borderRadius: '10' }}
          square={true}
        >
          <CardMedia
            style={{
              margin: theme.spacing(1), maxWidth: 540, display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={Assets}
            title="Contemplative Reptile"
          />
          <CardContent style={{ margin: theme.spacing(1), }}>
            <Typography gutterBottom variant="h5" component="h2" >
              綁定Google帳號
            </Typography>
            <Button
              style={{marginLeft:'-10px'}}
              onClick={doConnectGoogle}
            >
              <img src={GoogleSignin}/>
            </Button>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: theme.spacing(2)}}>
              綁定帳號：{showmsg}
            </Typography>
            <Typography variant="body2" color="red" component="p" style={{ marginTop: theme.spacing(2) }}>
            於課堂工具中開啟 Google Drive 檔案，需將檔案連結設為『任何知道這個連結的網際網路使用者都能查看』，才能進行瀏覽及教學使用。
            </Typography>
            <Typography variant="body2" component="p" style={{ marginTop: theme.spacing(2), color: "red" }}>
              <ul>
                <li>
                  提醒您！Google 雲端硬碟可支援開啟 Office 的文件檔 (包括 Word、Excel 、PowerPoint 等檔案)，但因為會被轉成 Google 文件格式，故會有格式跑版情形。建議您可直接使用 Google 文件、試算表、簡報來進行教學檔案建立，來避免此情況發生。(詳情可參考 Google 文件編輯器說明)
                </li>
              </ul>
            </Typography>
          </CardContent>
          <div>
          </div>

          <div style={{ textAlign: 'right', margin: theme.spacing(2) }}>
            {/* {memberInfo.google_account &&(
              <Button size="small" color="primary" onClick={doDeleteGA}>
                解除綁定
              </Button>
            )} */}
            {/* <Button
              size="small"
              color="primary"
              style={{ textAlign: 'right', margin: theme.spacing(2) }}
              onClick={doConnectGoogle}
            >
              {sbutton}
            </Button> */}
            {/* <img
                src={GoogleSignin}
                onClick={doConnectGoogle}
            /> */}
          </div>
        </Card>
      </div>
    );
  }

  // if (!state) {
  //   return (
  //     <div className={styles.loading}>
  //       <CircularProgress size="3rem" color="primary" />
  //     </div>
  //   );
  // } else if (!memberInfo.g_refreshtoken){
  //   return (
  //     <div className={styles.box}>
  //       <div>
  //         <div 
  //         >{showmsg}</div>
  //         <Button 
  //           className={styles.setbutton}
  //           startIcon={<LinkIcon />}
  //           color="primary"
  //           variant="contained"
  //           onClick={doConnectGoogle}
  //         >設定</Button>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div>
  //       <button onClick={doConnectGoogle}>綁定帳號</button>
  //       <button onClick={getSession}>getSession</button>
  //     </div>
  //   );
  // }
};

export default Component;
