 import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import moment from 'moment';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  classroom: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  broadcast: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,.67)',
    position: 'relative',
  },
  moreButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreNormalDot: {
    color: 'rgba(255, 255, 255, .38)',
    margin: theme.spacing(0, 0.25),
  },
  moreCurrentDot: {
    color: 'rgba(255, 255, 255, 1)',
    margin: theme.spacing(0, 0.25),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 450,
  },
  titleText: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#f1f1f1',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
  },
  contentText: {
    flex: 1,
    fontSize: '1.5rem',
    color: '#f1f1f1',
    lineHeight: '2rem',
    overflowY: 'auto',
    marginTop: theme.spacing(2),
  },
  timeText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    marginTop: theme.spacing(2),
  },
  messageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    height: 450,
  },
  messageText: {
    fontSize: '2rem',
    color: '#f1f1f1',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [current, setCurrent] = useState(-1);
  const [origins, setOrigins] = useState([]);
  const [items, setItems] = useState([]);
  const [timestamp, setTimestamp] = useState(15);
  const [open, setOpen] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);
  const [viewItems, setViewItems] = useState([]);
  const [clock, setClock] = useState(moment());
  const [threeDay, setThreeDay] = useState([]);

  useEffect(() => {
    let unsubscribe1 = () => {};
    const unsubscribe2 = firebase
      .firestore()
      .collection('broadcasts')
      .where('schoolCode', '==', state.device.schoolCode)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          if (item.targets.includes(state.device.id)) {
            data.push({
              ...item,
              key: doc.id,
              schedule:
                item.schedule !== 'now'
                  ? item.schedule
                  : Date.parse(item.timestamp.toDate()),
              time: moment(
                item.schedule !== 'now'
                  ? item.schedule
                  : Date.parse(item.timestamp.toDate()),
              ).format('YYYY-MM-DD HH:mm'),
              url: (() => {
                if (item.type === 'video') {
                  const regYoutube = item.url.match(
                    /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
                  );
                  const regVimeo = item.url.match(
                    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
                  );

                  let url = '';
                  if (regYoutube) {
                    url = `https://www.youtube.com/embed/${regYoutube[1]}`;
                  } else if (regVimeo) {
                    url = `https://player.vimeo.com/video/${regVimeo[5]}`;
                  }
                  return url;
                }
              })(),
            });
          }
        });
        data.sort((x, y) => (y.schedule > x.schedule ? 1 : -1));

        if (data.length > 0) {
          const now = Date.now();
          let temp = moment().add(-3,'d').valueOf();

          setOrigins([...data]);
          // setItems([...data.filter((item) => item.schedule < now).slice(0, 5)]);
          setItems([...data.filter((item) => item.schedule > temp)]);
          setCurrent(0);

          let threeDay = [...data.filter((item) => item.schedule > temp)]; 
          setThreeDay([].concat(threeDay));
          if(threeDay.length === 0){
            setCurrent(-1);
          } else {
            setCurrent(0);
          }

          unsubscribe1 = firebase
            .firestore()
            .doc(`/pubsubs/broadcast.${state.device.schoolCode}`)
            .onSnapshot((doc) => {
              if (doc.exists) {
                const now = Date.now();
                setItems(
                  //data.filter((item) => item.schedule < now).slice(0, 5),
                  data.filter((item) => item.schedule > temp),
                );
                let threeDay = [...data.filter((item) => item.schedule > temp)]; 
                  setThreeDay([].concat(threeDay));
                  if(threeDay.length === 0){
                    setCurrent(-1);
                  } else {
                    setCurrent(0);
                  }
              }
            });
        }
      });

    const interval = setInterval(() => {
      setClock(moment());
    }, 1000);

    return () => {
      unsubscribe1();
      unsubscribe2();
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let _timestamp = 15;
    const interval = setInterval(() => {
      _timestamp -= 1;
      if (_timestamp === 0) {
        let temp = moment().add(-3,'d').valueOf();
        let threeDayList = origins.filter((item) => item.schedule > temp);
        //console.log(origins.filter((item) => item.schedule > temp));
        if(threeDayList.length === 0){
          setCurrent(-1);
        } else {
          setCurrent((current) => (current + 1 < items.length ? current + 1 : 0));
          _timestamp = 15;
        };
        // setCurrent((current) => (current + 1 < items.length ? current + 1 : 0));
        // _timestamp = 15;
      }

      setTimestamp(_timestamp);
    }, 1000);

    return () => clearInterval(interval);
  }, [items]);

  const previousItem = () => {
    const index = viewIndex - 1 >= 0 ? viewIndex - 1 : 0;
    setViewIndex(index);
  };

  const nextItem = () => {
    const index =
      viewIndex + 1 < viewItems.length ? viewIndex + 1 : viewItems.length - 1;
    setViewIndex(index);
  };

  const renderDialog = () =>
    open && (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ wordBreak: 'break-all' }}>
          {viewItems[viewIndex].title}
        </DialogTitle>
        <DialogContent style={{ minWidth: 320 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
            style={{ wordBreak: 'break-all' }}
          >
            {viewItems[viewIndex].time}
          </Typography>
          {viewItems[viewIndex].type !== 'video' && (
            <DialogContentText
              style={{
                wordBreak: 'break-all',
                whiteSpace: 'pre-line',
                marginTop: 10,
              }}
            >
              {viewItems[viewIndex].content}
            </DialogContentText>
          )}
          {viewItems[viewIndex].type === 'video' && (
            <iframe
              title="Broadcast"
              src={viewItems[viewIndex].url}
              style={{
                width: 552,
                height: 310,
                border: 'none',
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={previousItem} color="primary">
            <ChevronLeftIcon />
          </IconButton>
          <div style={{ flex: 1 }} />
          <Typography variant="subtitle2" color="primary">
            {viewIndex + 1} / {viewItems.length}
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton onClick={nextItem} color="primary">
            <ChevronRightIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.classroom} elevation={3} style={{ marginTop: theme.spacing(2) }} >
        <Typography variant="h5" noWrap gutterBottom>
          {`${clock.format('MM 月 DD 日')} ${
            [
              '星期日',
              '星期一',
              '星期二',
              '星期三',
              '星期四',
              '星期五',
              '星期六',
            ][clock.day()]
          }`}{' '}
          {clock.format('HH:mm')}
        </Typography>
        <Typography variant="h5" noWrap>
          {state.device.schoolName}
        </Typography>
        <Typography variant="h6" noWrap gutterBottom>
          {state.device.classroomName}
        </Typography>
        <Typography variant="body1" noWrap>
          {state.device.deviceId}
        </Typography>
      </Paper>
      <div style={{ height: theme.spacing(2) }} />
      {current === -1 ? (
        <Paper elevation={3} className={classes.broadcast}>
          <div className={classes.messageContent}>
            <div className={classes.messageText}>還沒有任何廣播耶!</div>
          </div>
        </Paper>
      ) : (
        <Paper elevation={3} className={classes.broadcast}>
          {renderDialog()}
          <Tooltip title="顯示更多">
            <Button
              className={classes.moreButton}
              onClick={() => {
                setViewIndex(0);
                setViewItems(() => {
                  const now = Date.now();
                  return origins.filter((item) => item.schedule < now);
                });
                setOpen(true);
              }}
            >
              {items.map((item, index) => (
                <div
                  key={`dot-${item.key}`}
                  className={
                    current === index
                      ? classes.moreCurrentDot
                      : classes.moreNormalDot
                  }
                >
                  ●
                </div>
              ))}
            </Button>
          </Tooltip>
          <Fade in={timestamp > 2} timeout={2000}>
            {items[current] ? (
              <div className={classes.content} key={items[current].key}>
                {/* <div className={classes.titleText}>{items[current].title}</div> */}
                <div className={classes.titleText}>{' '}</div>
                <div className={classes.contentText}>
                  {items[current].content || '內容是影片，請點選顯示更多觀看'}
                </div>
                <div className={classes.timeText}>{items[current].time}</div>
              </div>
            ) : (
              <div className={classes.content} />
            )}
          </Fade>
        </Paper>
      )}
    </div>
  );
};

export default Component;
