import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Fade, 
    Typography, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Chip,} from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import { AppContext } from '../../App';
import envConfig from '../../config';

const useStyles = makeStyles((theme) => ({
    signinroot: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(3),
    },
    contentds: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    signin: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
      borderWidth: 1,
      backgroundColor: 'rgba(255,255,255,1)',
    },
    codeValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const Component = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useContext(AppContext);
    const [codeValue, setCodeValue] = useState(
      Math.random().toString(10).substr(2, 6),
    );
    const [timestamp, setTimestamp] = useState(300);
  
    useEffect(() => {
      dispatch({ type: 'CODE_VALUE', payload: codeValue });
      //console.log(codeValue);
  
      // let _timestamp = 300;
      // const interval = setInterval(() => {
      //   _timestamp -= 1;
      //   if (_timestamp === 0) {
      //     _timestamp = 300;
  
      //     const codeValue = Math.random().toString(10).substr(2, 6);
  
      //     setCodeValue(codeValue);
      //     dispatch({ type: 'CODE_VALUE', payload: codeValue });
      //   }
  
      //   setTimestamp(_timestamp);
      // }, 1000);
  
      // return () => clearInterval(interval);
      // eslint-disable-next-line
    }, []);



return (
    <>      
      <div>
        <div className={classes.signinroot}>
          <div className={classes.contentds}>
            <div className={classes.signin}>
                  <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
                    請使用酷課 App
                  </Typography>
                  <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
                    掃描 QRCode 或輸入代碼登入
                  </Typography>
                  <div style={{ height: theme.spacing(3) }} />
                  {/* <Fade in={timestamp > 2} timeout={2000}> */}
                  <div>
                    <div className={classes.codeValue}>
                      <QRCode
                        //value={`https://cooc.tp.edu.tw/app_links/classroom2?th=b&codeValue=${codeValue}`}
                        value={`https://cooc.tp.edu.tw/app_links/classroom?th=m&codeValue=${codeValue}`}
                        //value={`${envConfig.SERVER_URL}/c/R/${codeValue}`}
                        size={150}
                        fgColor="#454545"
                        bgColor="transparent"
                      />
                      <div style={{ height: '15px' }} />
                      <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
                        {codeValue}
                      </Typography>
                    </div>
                  </div>
                  {/* {state.container.type === 'chrome' && (
                    <Chip
                      label="使用帳號登入"
                      icon={<AccountIcon style={{ color: 'rgba(0,0,0,.54)' }} />}
                      variant="outlined"
                      style={{
                        color: 'rgba(0,0,0,.54)',
                        borderColor: 'rgba(0,0,0,.54)',
                        marginTop: theme.spacing(3),
                      }}
                      onClick={() => window.location.replace(envConfig.AUTHORIZE_URL)}
                    />
                  )} */}

              </div>
            </div>
        </div>
      </div>
    </>
    );
};

export default Component;