import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { HighlightOffOutlined as CloseIcon } from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../App';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2000,
    backgroundColor: 'rgba(0,0,0,.87)',
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '5rem',
  },
  videoContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(10),
    overflowY: 'auto',
  },
  title: {
    fontSize: '4rem',
    color: '#f1f1f1',
    wordBreak: 'break-all',
    marginRight: theme.spacing(6),
  },
  content: {
    flex: 1,
    fontSize: '3rem',
    lineHeight: '4rem',
    color: '#f1f1f1',
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    //marginTop: theme.spacing(5),
    padding: theme.spacing(8),
    overflowY: 'auto',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const videoRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!state.device) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/pubsubs/broadcast.${state.device.schoolCode}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.targets.includes(state.device.id)) {
            // if (data.priority === 'high') setData(data);
            // else if (!state.roomId) setData(data);
            if (!state.roomId && data.priority === 'high') setData(data);

          }
        }
      });

    return () => unsubscribe();
  }, [state.device, state.roomId]);

  useEffect(() => {
    if (!data) return;

    state.tts.stop();

    (document.querySelector('iframe') || { remove: () => {} }).remove();

    if (data.docId) {
      firebase
        .firestore()
        .doc(`/broadcasts/${data.docId}/devices/${state.device.id}`)
        .set({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    if (data.type === 'video') {
      const regYoutube = data.url.match(
        /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
      );
      const regVimeo = data.url.match(
        /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
      );
      const videoTag = Date.now();

      if (regYoutube) {
        (
          videoRef.current || {}
        ).innerHTML = `<div id="broadcast-youtube-${videoTag}" style="width:100%;height:100%"></div>`;

        const target = new window.YT.Player(`broadcast-youtube-${videoTag}`, {
          height: '100%',
          width: '100%',
          videoId: regYoutube[1],
          playerVars: {
            rel: 0,
            showinfo: 0,
            enablejsapi: 1,
            controls: 1,
          },
          events: {
            onReady: () => {
              target.playVideo();
            },
            onStateChange: (event) => {
              if (event.data === 0) {
                if (state.container.type === 'native') {
                  state.communication.postMessage(
                    JSON.stringify({
                      action: 'PAUSE_VIDEO',
                    }),
                  );
                }
              }
            },
          },
        });
      } else if (regVimeo) {
        (
          videoRef.current || {}
        ).innerHTML = `<iframe id="broadcast-vimeo-${videoTag}" src="https://player.vimeo.com/video/${regVimeo[5]}" style="width:100%;height:100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; encrypted-media"/>`;

        const target = new window.Vimeo.Player(
          document.querySelector(`#broadcast-vimeo-${videoTag}`),
        );

        target.ready().then(() => {
          target.play();
          target.on('ended', () => {
            if (state.container.type === 'native') {
              state.communication.postMessage(
                JSON.stringify({
                  action: 'PAUSE_VIDEO',
                }),
              );
            }
          });
        });
      }
    } else if (data.type === 'voice') {
      state.tts.speak(`${data.title}，${data.content}`);
    }

    return () => {
      state.tts.stop();

      if (state.container.type === 'native') {
        state.communication.postMessage(
          JSON.stringify({
            action: 'PAUSE_VIDEO',
          }),
        );
      }
    };
    // eslint-disable-next-line
  }, [data]);

  return data ? (
    data.type === 'video' ? (
      <div className={classes.root}>
        <IconButton
          className={classes.closeButton}
          style={{ float: 'right', margin: theme.spacing.unit, position: "fixed", bottom: theme.spacing(2)}}
          onClick={() => setData(null)}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div ref={videoRef} className={classes.videoContainer} />
      </div>
    ) : (
      <div className={classes.root}>
        <IconButton
              className={classes.closeButton}
              style={{ float: 'right', position: "fixed", bottom: theme.spacing(2)}}
              onClick={() => setData(null)}
            >
              <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.textContainer}>
          {/* <div className={classes.title}>{data.title}</div> */}
          <div className={classes.content}>{data.content}</div>
        </div>
      </div>
    )
  ) : (
    <React.Fragment />
  );
};

export default Component;
