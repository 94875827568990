import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chip, Fade, Typography } from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../../App';
import envConfig from '../../config';

const useStyles = makeStyles((theme) => ({
  signinroot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(3),
  },
  contentds: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderWidth: 1,
    backgroundColor: 'rgba(255,255,255,1)',
  },
  codeValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [codeValue] = useState(
    Math.random().toString(10).substr(2, 8),
  );

  useEffect(() => {
    dispatch({ type: 'CODE_VALUE', payload: codeValue });
    let roomList = JSON.parse(window.localStorage.getItem('List'));
    //console.log(roomList);
    // roomList.forEach((i) => {
    // })
    let temp = {
      curClassroomList: roomList
    }
    if(roomList.length > 0) {
      firebase.firestore().doc(`/curclasslist/${codeValue}`).set(temp);
    }

    // const unsubscribe = 
    // firebase
    // .firestore()
    // .doc(`/curclasslist/${codeValue}`)
    // .onSnapshot((doc) => {
    //   console.log(doc)
    // })

    // return () => unsubscribe();
    // console.log(`${envConfig.SERVER_URL}/c/R/${codeValue}`);
    // console.log(codeValue);
    // const unsubscribe = 
    // firebase
    // .firestore()
    // .doc(`/pubsubs2/${codeValue}`)
    // .onSnapshot((doc) => {

    // })

    // return () => unsubscribe();
    // eslint-disable-next-line
  }, []);



  return (
    <>      
        <div className={classes.signinroot}>
          <div className={classes.contentds}>
            <div className={classes.signin}>
                  <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
                    請使用酷課 App
                  </Typography>
                  <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
                    掃描 QRCode 或輸入代碼登入
                  </Typography>
                  <div style={{ height: theme.spacing(3) }} />
                  {/* <Fade in={timestamp > 2} timeout={2000}> */}
                    <div className={classes.codeValue}>
                      <QRCode
                        // value={`${envConfig.SERVER_URL}/bind/c/R/${codeValue}`}
                        // https://cooc.tp.edu.tw/app_links/:tp_id?th={th_value}&other_parameters
                        // https://cooc.tp.edu.tw/app_links/classroom2?th=m&codeValue=${codeValue}
                        value={`https://cooc.tp.edu.tw/app_links/classroom?th=b&codeValue=${codeValue}`}
                        size={180}
                        fgColor="#454545"
                        bgColor="transparent"
                      />
                      <div style={{ height: theme.spacing(2) }} />
                      <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
                        {codeValue}
                      </Typography>
                    </div>
              </div>
            </div>
        </div>
    </>
  );
};

export default Component;
